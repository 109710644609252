import {
  Alert,
  AlertIcon,
  AlertTitle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { TFunction } from "i18next";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import api from "../../services/api";

interface ChangelogModalProps {
  isVisible: boolean;
  userHasAcceptedCookies: boolean;
  t: TFunction;
  toggleModal: () => void;
}

export const ChangelogModal: React.FunctionComponent<ChangelogModalProps> = ({
  isVisible,
  userHasAcceptedCookies,
  t,
  toggleModal,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(["new-changelog"]);
  const { width } = useWindowDimensions();
  const { isLoading, isError, data: changelogData } = useQuery(
    ["changelog"],
    // @ts-ignore
    () => api.fetchChangelogData()
  );

  useEffect(() => {
    if (userHasAcceptedCookies) {
      setTimeout(() => {
        setCookie("new-changelog", false, { path: "/" });
      }, 1500);
    }
  }, [setCookie, userHasAcceptedCookies]);

  return isVisible ? (
    <Modal
      isOpen={isVisible}
      onClose={toggleModal}
      blockScrollOnMount={true}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent minW={"75vw"} flex={1}>
        <ModalHeader>
          {t<string>("components.changelogModal.title")} 🚀
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Spinner
              thickness={"4px"}
              size={"xl"}
              width={width * 0.1}
              height={width * 0.1}
            />
          ) : !isError ? (
            <StyledBox dangerouslySetInnerHTML={{ __html: changelogData }} />
          ) : (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>
                {t<string>("components.changelogModal.error")}
              </AlertTitle>
            </Alert>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    <></>
  );
};

const StyledBox = styled.div`
  padding: 5;

  & > h1 {
    -webkit-flex: 0;
    -ms-flex: 0;
    flex: 0;
    -webkit-padding-end: var(--chakra-space-6);
    padding-inline-end: var(--chakra-space-6);
    padding-bottom: var(--chakra-space-4);
    font-size: var(--chakra-fontSizes-xl);
    font-weight: var(--chakra-fontWeights-semibold);
  }
  & > h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  & > h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  & > ul {
    list-style-type: initial;
    -webkit-margin-start: 1em;
    margin-inline-start: 1em;
    margin: 0;
    padding: 0;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1em;
  }
  & > ul > li > img {
    max-width: 75%;
    margin: 1em 0;
  }
`;
