import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProjectStatus, Webhook } from "../../types";
import { WebhookForm } from "../WebhookForm";

type Props = {
  isVisible: boolean;
  webhook: Webhook | null;
  projectsWorkflows: ProjectStatus[];
  onDismiss: () => void;
  onSubmit: (webhook: Pick<Webhook, "url" | "events" | "description">) => void;
  onDelete: () => void;
};

export const WebhookModal: React.FC<Props> = ({
  isVisible,
  webhook,
  projectsWorkflows,
  onSubmit,
  onDismiss,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} onClose={onDismiss} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {webhook
            ? t("screens.webhooks.editTitle")
            : t("screens.webhooks.newTitle")}
        </ModalHeader>
        <ModalBody>
          <WebhookForm
            webhook={webhook}
            projectsWorkflows={projectsWorkflows}
            onSubmit={onSubmit}
            onDelete={onDelete}
            onCancel={onDismiss}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
