const fromRoutes = {
  userId: (pathname: string): string | null => {
    const companyRegex = /\/user\/([^\/?#]+)/i;
    const found = pathname.match(companyRegex);
    return (found && found[1]) || null;
  },
  projectId: (pathname: string): string | null => {
    const companyRegex = /\/projects\/([^\/?#]+)/i;
    const found = pathname.match(companyRegex);
    return (found && found[1]) || null;
  },
};

export default {
  fromRoutes,
};
