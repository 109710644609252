import { Box, HStack } from "@chakra-ui/layout";
import { useRadio, useRadioGroup } from "@chakra-ui/radio";
import React from "react";

const RadioCard: React.FC<any> = (props) => {
  // @ts-ignore
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as={"label"}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  );
};

type Option = {
  value: string;
  label: string;
};

type RadioCardGroupProps = {
  name: string;
  value?: any;
  defaultValue?: string;
  options: Option[];
  onChange: (value: string) => void;
};

export const RadioCardGroup: React.FC<RadioCardGroupProps> = ({
  name,
  value,
  defaultValue,
  options,
  onChange,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    name,
    defaultValue,
    onChange,
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {options.map((option) => {
        // @ts-ignore
        const radio = getRadioProps({ value: option.value });
        return (
          <RadioCard key={option.value} {...radio}>
            {option.label}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
