import React from "react";
import SortDirection from "./SortDirection";

/**
 * Displayed beside a header to indicate that a Table is currently sorted by this column.
 */
export default function SortIndicator({ sortDirection }) {
  const classNames = ["ReactVirtualized__Table__sortableHeaderIcon"];
  if (sortDirection === SortDirection.ASC) {
    classNames.push("ReactVirtualized__Table__sortableHeaderIcon--ASC");
  }
  if (sortDirection === SortDirection.DESC) {
    classNames.push("ReactVirtualized__Table__sortableHeaderIcon--DESC");
  }

  return (
    <svg
      className={classNames.join(" ")}
      width={18}
      height={18}
      viewBox="0 0 24 24"
    >
      {sortDirection === SortDirection.ASC ? (
        <path d="M7 14l5-5 5 5z" />
      ) : (
        <path d="M7 10l5 5 5-5z" />
      )}
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}
