import { makeApiRequest } from "./index";
import { Organization, Plan } from "../../types";
import { toPlan } from "../utils";

export const fetchPlans = async (): Promise<Plan[]> => {
  const response = await makeApiRequest("GET", `subscription`);
  return response.map((item: any) => toPlan(item));
};

export const downgradeToFreePlan = async (
  organizationId: Organization["uid"]
): Promise<Plan> => {
  const response = await makeApiRequest(
    "DELETE",
    `organization/${organizationId}/subscription`
  );

  return toPlan(response);
};
