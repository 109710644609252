import { transparentize, lighten } from "polished";
import styled, { css } from "styled-components";
import { TimeBlockingEventType } from "../../types";
import PlayImage from "../../assets/images/play.svg";
import StopImage from "../../assets/images/pause.svg";

export const EventContainer = styled.div<{
  isTimerRunning: boolean;
  eventType: TimeBlockingEventType;
  color: string;
  elapsedTime: number;
}>`
  display: flex;
  flex-direction: column;
  height: calc(100% + 4px);
  width: ${(props) =>
    props.eventType === "planning" ? "calc(100% + 10px)" : "calc(100% + 10px)"};
  margin: -2px;
  padding: 5px;
  padding-top: ${(props) => props.elapsedTime < 1810 ? '0px' : '5px'};
  color: #fff;
  overflow: hidden;
  border: 1px solid ${(props) => lighten(0.2, props.color || "#3788d8")};
  border-left: solid 3px ${(props) => props.color || "#3788d8"};
  background-color: ${(props) =>
    props.eventType === "planning"
      ? transparentize(0.3, props.color || "#3788d8")
      : lighten(0.05, props.color || "#3788d8")};
  };

  ${(props) =>
    props.isTimerRunning &&
    css`
      border: solid 2px black;
      border-radius: 3px;
    `}

  ${(props) =>
    props.eventType === "planning" &&
    css`
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    `}
`;

export const TimerButton = styled.button<{ isTimerRunning: boolean }>`
  width: 25px;
  height: 25px;
  background: url(${PlayImage}) no-repeat;
  border: none;
  cursor: pointer;

  ${(props) =>
    props.isTimerRunning &&
    css`
      width: 20px;
      height: 20px;
      background: url(${StopImage}) no-repeat 25% 50%;
    `}
`;

export const RunningEventContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const ElapsedTimeContainer = styled.div<{ elapsedTime: number }>`
  position: absolute;
  top: ${(props) => props.elapsedTime < 1810 ? "5px" : 'auto'};
  bottom: 5px;
  right: 5px;
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 3px;
  }

  font-size: 10px;
`;
