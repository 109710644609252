import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RawAbility, Role } from "../types";
import { actions } from "../config/roles";
import styled from "styled-components";
import { ModalFooterForm } from "./ModalFooterForm";
import {
  Checkbox,
  Input,
  Link,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

type Props = {
  role: Role;
  onCancel: () => any;
  onSubmit: (role: Role) => any;
};

export const RoleForm: React.FC<Props> = ({ role, onCancel, onSubmit }) => {
  const [name, setName] = useState(role.name);
  const [acls, setAcls] = useState(role.acls);
  const { t } = useTranslation();

  const rows = acls.map((ability) => (
    <Tr key={ability.subject.toString()}>
      <Td>{t<string>("acls.subject." + ability.subject)}</Td>
      {actions.map((action) => (
        <Td key={action}>
          <Checkbox
            isChecked={ability.action.includes(action)}
            onChange={(e) => handleSetAction(ability, action, e.target.checked)}
          />
        </Td>
      ))}
    </Tr>
  ));

  const handleSetAction = useCallback(
    (ability: RawAbility, action, checked: boolean) => {
      const newAcls = [...acls];

      for (let i = 0; i < newAcls.length; i++) {
        if (newAcls[i].subject === ability.subject) {
          if (checked) {
            newAcls[i].action.push(action);
          } else {
            newAcls[i].action = newAcls[i].action.filter(
              (aclAction) => aclAction !== action
            );
          }
        }
      }

      setAcls(newAcls);
    },
    [acls]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      onSubmit({
        uid: role.uid,
        name,
        acls,
      });
    },
    [name, acls, role, onSubmit]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ marginBottom: "1.5rem" }}>
        <label htmlFor={"name"}>{t<string>("screens.roles.title")}</label>
        <Input
          name={"name"}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </div>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="gray.400">{t<string>("screens.roles.subject")}</Th>
            {actions.map((action) => (
              <Th color="gray.400" key={action}>
                {t<string>("acls.action." + action)}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>

      <Container>
        <div style={{ flexGrow: 1 }}>
          {/* {onDelete && (
          <Button type={"text"} danger onClick={onDelete}>
            {t<string>("screens.board.deleteButton")}
          </Button>
        )} */}
        </div>
        <ModalFooterForm onCancel={onCancel} />
      </Container>
    </form>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 2em;
`;
