// import React, {Component as RComponent, createElement as RCreateElement, createRef as RCreateRef, Fragment as RFragment, createContext as RCreateContext} from "react";
// import type {Ref as RRef, RefObject as RRefObject, ComponentType as RComponentType, Context as RContext, ComponentC } from "react";

import React from "react";

// NOTE: this file cannot include other helper files. because of how dts is generated

// declare global {
//   namespace FullCalendarVDom {
//     export import Ref = React.Ref;
//     export import RefObject = React.RefObject;
//     export import ComponentType = React.ComponentType;
//     // @ts-ignore
//     export type VNode = React.VNode<any>; // will NOT forward the props type
//     export import Context = React.Context;
//     export import Component = React.Component;
//     // @ts-ignore
//     export import ComponentChild = React.ComponentChild;
//     // @ts-ignore
//     export import ComponentChildren = React.ComponentChildren;
//     export import createElement = React.createElement;
//     // @ts-ignore
//     export import render = React.render;
//     export import createRef = React.createRef;
//     export import Fragment = React.Fragment;
//     export import createContext = React.createContext;
//     export type VUIEvent = UIEvent;
//     export function flushToDom(): void;
//     export function unmountComponentAtNode(node: HTMLElement): void;
//   }
// }

let globalObj = typeof globalThis !== "undefined" ? globalThis : window; // // TODO: streamline when killing IE11 support

// if (globalObj.FullCalendarVDom) {
//   console.warn("FullCalendar VDOM already loaded");
// } else {
//   // @ts-ignore
//   globalObj.FullCalendarVDom = {
//     Component: React.Component,
//     createElement: React.createElement,
//     // @ts-ignore
//     render: React.render,
//     createRef: React.createRef,
//     Fragment: React.Fragment,
//     createContext, // custom implementation
//     flushToDom,
//     unmountComponentAtNode,
//   };
// }

// HACKS...
// TODO: lock version
// TODO: link gh issues

function flushToDom() {
  // @ts-ignore
  let oldDebounceRendering = React.options.debounceRendering; // orig
  let callbackQ = [];

  function execCallbackSync(callback) {
    // @ts-ignore
    callbackQ.push(callback);
  }
  // @ts-ignore
  React.options.debounceRendering = execCallbackSync;
  // @ts-ignore
  React.render(
    React.createElement(FakeComponent, {}),
    document.createElement("div")
  );

  while (callbackQ.length) {
    // @ts-ignore
    callbackQ.shift()();
  }
  // @ts-ignore
  React.options.debounceRendering = oldDebounceRendering;
}

class FakeComponent extends React.Component {
  render() {
    return React.createElement("div", {});
  }
  componentDidMount() {
    this.setState({});
  }
}

// function createContext<T>(defaultValue: T) {
//   let ContextType = React.createContext<T>(defaultValue);
//   let origProvider = ContextType.Provider;
//   // @ts-ignore
//   ContextType.Provider = function () {
//     // eslint-disable-line func-names
//     // @ts-ignore
//     let isNew = !this.getChildContext;
//     let children = origProvider.apply(this, arguments as any); // eslint-disable-line prefer-rest-params

//     if (isNew) {
//       let subs = [];
//       // @ts-ignore
//       this.shouldComponentUpdate = (_props) => {
//         // @ts-ignore
//         if (this.props.value !== _props.value) {
//           subs.forEach((c) => {
//             // @ts-ignore
//             c.context = _props.value;
//             // @ts-ignore
//             c.forceUpdate();
//           });
//         }
//       };
//       // @ts-ignore
//       this.sub = (c) => {
//         // @ts-ignore
//         subs.push(c);
//         let old = c.componentWillUnmount;
//         c.componentWillUnmount = () => {
//           // @ts-ignore
//           subs.splice(subs.indexOf(c), 1);
//           old && old.call(c);
//         };
//       };
//     }

//     return children;
//   };

//   return ContextType;
// }

function unmountComponentAtNode(node: HTMLElement) {
  // @ts-ignore
  React.render(null, node);
}

// export type Ref = React.Ref;
//     export const RefObject = React.RefObject;
//     export const ComponentType = React.ComponentType;
//     // @ts-ignore
//     export const VNode = React.VNode<any>; // will NOT forward the props type
//     export const Context = React.Context;
//     export Component;
//     // @ts-ignore
//     export const ComponentChild = React.ComponentChild;
//     // @ts-ignore
//     export const ComponentChildren = React.ComponentChildren;
//     export const createElement = React.createElement;
//     // @ts-ignore
//     export const render = React.render;
//     export const createRef = React.createRef;
//     export const Fragment = React.Fragment;
//     // export const createContext = React.createContext;
//     export type VUIEvent = UIEvent;
// export const flushToDom(): void;
// export const unmountComponentAtNode(node: HTMLElement): void;

// export import Ref = React.Ref;
// export import RefObject = React.RefObject;
// export import ComponentType = React.ComponentType;
// // @ts-ignore
// export type VNode = React.VNode<any>; // will NOT forward the props type
// export import Context = React.Context;
// export import Component = React.Component;
// // @ts-ignore
// export import ComponentChild = React.ComponentChild;
// // @ts-ignore
// export import ComponentChildren = React.ComponentChildren;
// export import createElement = React.createElement;
// // @ts-ignore
// export import render = React.render;
// export import createRef = React.createRef;
// export import Fragment = React.Fragment;
// export import createContext = React.createContext;

export {
  Component,
  createElement,
  createRef,
  Fragment,
  createContext,
} from "react";
export type {
  Ref,
  RefObject,
  ComponentType,
  Context,
  UIEvent as VUIEvent,
} from "react";

export type VNode = any;
export type ComponentChildren = any;
export type ComponentChild = any;

export default {};
