import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { Customer } from "../../../types";
import { CustomerForm, CustomerFormType } from "../CustomerForm";

type Props = {
  customer: Customer | null;
  isVisible: boolean;
  onDismiss: () => void;
  onSubmit: (customer: CustomerFormType) => void;
  onDelete: (customerId: Customer["uid"]) => void;
};

export const CustomerModal: React.FC<Props> = ({
  customer,
  isVisible,
  onSubmit,
  onDismiss,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t<string>("screens.customers.title")}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <CustomerForm
            customer={customer}
            onSubmit={onSubmit}
            onCancel={onDismiss}
            onDelete={onDelete}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
