import { HStack, Text } from "@chakra-ui/layout";
import styled from "styled-components";
import { Option, Select, SingleValue } from "../Select";
import { Props } from "react-select";

export const CategorySelect: React.FC<Props> = (props) => (
  <Select
    {...props}
    components={{ Option: CategoryOption, SingleValue: CategoryControl }}
  />
);

const CategoryOption: React.FC<any> = (props) => {
  return (
    <Option {...props}>
      <HStack>
        <OptionIcon color={props.data.color} />
        <Text>{props.data.label}</Text>
      </HStack>
    </Option>
  );
};

const CategoryControl: React.FC<any> = ({ children, ...props }) => {
  return (
    <SingleValue {...props}>
      {props.data ? (
        <HStack>
          <OptionIcon color={props.data.color} />
          <Text>{props.data.label}</Text>
        </HStack>
      ) : (
        children
      )}
    </SingleValue>
  );
};

const OptionIcon = styled.span<{ color: string | null }>`
  width: 1rem;
  height: 1rem;
  background-color: ${(props) => props.color || "transparent"};
  border-radius: 0.25rem;
`;
