import { createElement, VNode } from "./vdom";
import { BaseComponent } from "./vdom-util";
import { ToolbarWidget } from "./toolbar-struct";
import React from "react";
import { Button as RebassButton } from "rebass/styled-components";
import styled from "styled-components";
import { transparentize } from "polished";
import { Icon } from "../../../components/Icon";
import i18n from "../../../services/i18n";
export interface ToolbarContent {
  title: string;
  activeButton: string;
  isTodayEnabled: boolean;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
}

export interface ToolbarSectionProps extends ToolbarContent {
  widgetGroups: ToolbarWidget[][];
}

export class ToolbarSection extends BaseComponent<ToolbarSectionProps> {
  render() {
    let children = this.props.widgetGroups.map((widgetGroup) =>
      this.renderWidgetGroup(widgetGroup)
    );

    return createElement("div", { className: "fc-toolbar-chunk" }, ...children);
  }

  renderWidgetGroup(widgetGroup: ToolbarWidget[]) {
    let { props } = this;
    let { theme } = this.context;
    // @ts-ignore
    let children: VNode[] = [];
    let isOnlyButtons = true;

    for (let widget of widgetGroup) {
      let { buttonName, buttonClick, buttonText, buttonIcon } = widget;

      if (buttonName === "title") {
        isOnlyButtons = false;
        children.push(<h2 className="fc-toolbar-title">{props.title}</h2>);
      } else {
        let ariaAttrs = buttonIcon ? { "aria-label": buttonName } : {};

        let buttonClasses = [
          `fc-${buttonName}-button`,
          theme.getClass("button"),
        ];
        if (buttonName === props.activeButton) {
          buttonClasses.push(theme.getClass("buttonActive"));
        }

        let isDisabled =
          (!props.isTodayEnabled && buttonName === "today") ||
          (!props.isPrevEnabled && buttonName === "prev") ||
          (!props.isNextEnabled && buttonName === "next");

        // HACK ====================
        let component = (
          <button
            disabled={isDisabled}
            className={buttonClasses.join(" ")}
            onClick={buttonClick}
            type="button"
            {...ariaAttrs}
          >
            {buttonText || (buttonIcon ? <span className={buttonIcon} /> : "")}
          </button>
        );

        if (buttonText === "today") {
          component = (
            <TodayButton
              disabled={isDisabled}
              onClick={buttonClick}
              {...ariaAttrs}
            >
              {i18n.t<string>("calendar.today")}
            </TodayButton>
          );
        } else if (buttonText === "prev") {
          component = (
            <PrevButton
              disabled={isDisabled}
              onClick={buttonClick}
              {...ariaAttrs}
            >
              <Icon iconName={"HiChevronLeft"} />
            </PrevButton>
          );
        } else if (buttonText === "next") {
          component = (
            <NextButton
              disabled={isDisabled}
              onClick={buttonClick}
              {...ariaAttrs}
            >
              <Icon iconName={"HiChevronRight"} />
            </NextButton>
          );
        }

        children.push(component);

        // children.push(
        //   <button
        //     disabled={isDisabled}
        //     className={buttonClasses.join(" ")}
        //     onClick={buttonClick}
        //     type="button"
        //     {...ariaAttrs}
        //   >
        //     {buttonText || (buttonIcon ? <span className={buttonIcon} /> : "")}
        //   </button>
        // );
      }
    }

    if (children.length > 1) {
      let groupClassName =
        (isOnlyButtons && theme.getClass("buttonGroup")) || "";

      return createElement(
        "div",
        {
          className: groupClassName,
          style: { display: "flex", marginRight: "1rem" },
        },
        ...children
      );
    }
    return children[0];
  }
}

const TodayButton = styled(RebassButton)`
  padding: 0.75rem 2rem;
  background-color: ${({ theme }) => theme.white};
  border-radius: 1rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: ${({ theme }) => theme.text1};
  box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
  z-index: 1;
`;

const PrevButton = styled(RebassButton)`
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => transparentize(0.9, theme.primary1)};
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  margin-right: -0.5rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: ${({ theme }) => theme.primary1};
`;

const NextButton = styled(RebassButton)`
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => transparentize(0.9, theme.primary1)};
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-left: -0.5rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: ${({ theme }) => theme.primary1};
`;
