import { transparentize } from "polished";
import { TeamReportRow } from "../../types";
import { sec2time } from "../../utils/dateTimeFormat";

const BUSINESS_WORK_DAYS = 5;

// export const getUserPlannedHoursHint = (userReport: TeamReportRow) => {
//   return {
//     value: sec2time(userReport.plannedTime || 0, "HH:MM"),
//     valuePerc: `${(
//       ((userReport.plannedTime || 0) /
//         ((userReport.contractualHours || 0) * BUSINESS_WORK_DAYS * 60 * 60)) *
//       100
//     ).toFixed(1)}%`,
//   };
// };

// export const toUserPlannedHoursChartData = (userReport: TeamReportRow) => {
//   return [
//     {
//       angle: userReport.plannedTime,
//       color: "#1B48BB",
//     },
//     {
//       angle:
//         (userReport.contractualHours || 0) * BUSINESS_WORK_DAYS * 60 * 60 -
//         (userReport.plannedTime || 0),
//       color: transparentize(0.9, "#1B48BB"),
//     },
//   ];
// };

export const getUserBillableHoursHint = (userReport: TeamReportRow) => {

  const calculate = userReport?.billableWorkLog !== 0 && userReport?.contractualHours !== 0 ?
    `${(
      ((userReport?.billableWorkLog) /
        ((userReport?.contractualHours) * BUSINESS_WORK_DAYS * 60 * 60)) *
      100
    ).toFixed(1)}%`
    : `0%`
  return {
    value: sec2time(userReport.billableWorkLog || 0, "HH:MM"),
    valuePerc: calculate,
  };
};

export const toUserBillableHoursChartData = (userReport: TeamReportRow) => {
  return [
    {
      angle: userReport.billableWorkLog,
      color: "#1B48BB",
    },
    {
      angle:
        (userReport.contractualHours || 0) * BUSINESS_WORK_DAYS * 60 * 60 -
        (userReport.billableWorkLog || 0),
      color: transparentize(0.9, "#1B48BB"),
    },
  ];
};

export const getUserWorkLoggetHint = (userReport: TeamReportRow) => {
  console.log("userReport -> ", userReport);
  const calculate = userReport?.workLog !== 0 && userReport?.contractualHours !== 0 ?
    `${(
      ((userReport?.workLog) /
        ((userReport?.contractualHours) * BUSINESS_WORK_DAYS * 60 * 60)) *
      100
    ).toFixed(1)}%`
    : `0%`
  return {
    value: sec2time(userReport?.workLog || 0, "HH:MM"),
    valuePerc: calculate,
  };
};

export const toUserWorkLoggedChartData = (userReport: TeamReportRow) => {
  const timeNotLogged =
    (userReport?.contractualHours || 0) * BUSINESS_WORK_DAYS * 60 * 60 -
    (userReport?.workLog || 0);

  const timeNotLoggedSerie = [
    {
      angle: timeNotLogged,
      color: transparentize(0.9, "#1B48BB"),
      value: sec2time(timeNotLogged, "HH:MM"),
      valuePerc: `${(
        (timeNotLogged /
          ((userReport?.contractualHours || 0) *
            BUSINESS_WORK_DAYS *
            60 *
            60)) *
        100
      ).toFixed(1)}%`,
    },
  ];

  const timeLoggedSeries =
    userReport?.taskCategoriesReport.map((item) => ({
      angle: item.elapsedTime,
      color: item.color || transparentize(0.9, "#1B48BB"),
      value: sec2time(item.elapsedTime, "HH:MM"),
      valuePerc: `${(
        (item.elapsedTime /
          (userReport?.contractualHours * BUSINESS_WORK_DAYS * 60 * 60)) *
        100
      ).toFixed(1)}%`,
    })) || [];

  return [...timeNotLoggedSerie, ...timeLoggedSeries];
};
