import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { UserContract } from "../../../types";
import { UserContractForm } from "../UserContractForm";

type Props = {
  contract: UserContract | null;
  isVisible: boolean;
  onDismiss: () => void;
  onSubmit: (contract: Partial<UserContract>) => void;
  onDelete: (id: UserContract["uid"]) => void;
};

export const UserContractModal: React.FC<Props> = ({
  contract,
  isVisible,
  onSubmit,
  onDismiss,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} onClose={onDismiss} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {contract
            ? t("screens.userDetails.editContract")
            : t("screens.userDetails.newContract")}
        </ModalHeader>
        <ModalBody>
          <UserContractForm
            contract={contract}
            onCancel={onDismiss}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
