import React, { useEffect, useMemo, useState } from "react";
import Project from "../../../../models/Project";
import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Avatar,
  AvatarGroup,
  Text,
  Link,
  SimpleGrid,
  Tooltip,
  HStack,
  IconButton,
  Button,
  VStack,
  Flex,
  Badge,
  Textarea,
} from "@chakra-ui/react";
import { Editor } from "../../../Editor";
import { IoMdAdd } from "react-icons/io";
import { BsThreeDotsVertical, BsArrowsAngleExpand } from "react-icons/bs";
import { ActionLinks } from "./ProjectDescriptionBeta";
import { ProjectLinks } from "../../../../types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

interface ProjectDetailsSectionProps {
  currentProject: Project;
  projectLInks: ProjectLinks[];
  linksDispatchLinks: React.Dispatch<ActionLinks>;
  setIsLinkModel?: () => void;
}

interface TeamMember {
  name: string;
  avatar: string;
}

interface TeamProps {
  responsible: TeamMember[];
  accountable: TeamMember[];
  consulted: TeamMember[];
  informed: TeamMember[];
}

interface LinkItem {
  name: string;
  url: string;
}

const StyledSection = styled.div`
  margin-bottom: 20px;
`;

export const ProjectDetailsSection = observer(
  ({
    currentProject,
    linksDispatchLinks,
    setIsLinkModel,
    projectLInks,
  }: ProjectDetailsSectionProps) => {
    const [text, setText] = useState<{ id: string | null; text: string }>({
      id: null,
      text: "",
    });

    const { t } = useTranslation();

    const responsibles =
      currentProject.member?.filter((e) =>
        e.userTags.includes("responsible")
      ) || [];
    const accountables =
      currentProject.member?.filter((e) =>
        e.userTags.includes("accountable")
      ) || [];
    const consulteds =
      currentProject.member?.filter((e) => e.userTags.includes("consulted")) ||
      [];
    const informeds =
      currentProject.member?.filter((e) => e.userTags.includes("informed")) ||
      [];

    useEffect(() => {
      if (currentProject.notes) {
        if (currentProject.notes[0]?.uid) {
          setText({
            id: currentProject.notes[0].uid || null,
            text: currentProject.notes[0].note || "",
          });
        }
      }
    }, [currentProject.notes]);


    const LinkItemComponent: React.FC<{ link: ProjectLinks }> = ({ link }) => (
      <HStack
        justifyContent="space-between"
        w="100%"
        p={2}
        borderWidth={1}
        borderRadius="md"
        alignItems={"center"}
      >
        <VStack spacing={1} alignItems={"flex-start"}>
          {/* @ts-ignore */}
          <LinksTitle
            onClick={() => {
              linksDispatchLinks({
                type: "edit-links",
                payload: { links: link },
              });
            }}
          >
            {link.title}
          </LinksTitle>
          {link.url && (
            <Link href={link.url} isExternal>
              <Text>{link.url}</Text>
            </Link>
          )}
        </VStack>
        {/* <IconButton
                aria-label="More options"
                icon={<BsThreeDotsVertical />}
                variant="ghost"
                size="sm"
            /> */}
      </HStack>
    );

    const TeamMemberAvatar: React.FC<{ member: TeamMember }> = ({ member }) => {
      const getInitials = (name: string) => {
        return name
          .split(" ")
          .map((n) => n[0])
          .join("")
          .toUpperCase();
      };

      return (
        // @ts-igonre
        <Tooltip label={member.name} aria-label={`Avatar of ${member.name}`}>
          <Avatar
            name={member.name}
            src={member.avatar}
            bg="blue.500"
            color="white"
          >
            {/* {getInitials(member.name)} */}
          </Avatar>
        </Tooltip>
      );
    };

    const TeamSection: React.FC<{ title: string; members: TeamMember[] }> = ({
      title,
      members,
    }) => (
      <Box mb={4}>
        <Text fontWeight="normal" mb={2} fontSize={13} color={"#475467"}>
          {title}
        </Text>
        <AvatarGroup size="md" max={5} gap="2px">
          {members.length !== 0 ? (
            members.map((member, index) => (
              <TeamMemberAvatar key={index} member={member} />
            ))
          ) : (
            <Text>{"--"}</Text>
          )}
        </AvatarGroup>
      </Box>
    );

    const LinksSection: React.FC<{ links: ProjectLinks[] }> = ({ links }) => (
      <Box>
        <HStack justifyContent="space-between" mb={4}>
          <Text fontWeight="bold">Links</Text>
          <Button
            leftIcon={<IoMdAdd />}
            colorScheme="teal"
            size="sm"
            onClick={() => {
              linksDispatchLinks({ type: "new-links" });
            }}
          >
            Add new
          </Button>
        </HStack>
        <VStack spacing={2} align="stretch">
          {links.length ? (
            links.map((link, index) => (
              <LinkItemComponent key={index} link={link} />
            ))
          ) : (
            <Text>No Links available.</Text>
          )}
        </VStack>
      </Box>
    );

    return (
      <>
        <StyledSection>
          <TypeContainer>
            <VStack spacing={0} alignItems={'flex-start'}>
              <Text fontWeight="normal" mb={2} color={"#475467"} fontSize={13}>
                {t('screens.projects.category')}
              </Text>
              <Text fontWeight="normal" mb={2} color={"#101828"} fontSize={14}>
                {currentProject?.category ? currentProject.category.name : '--'}
              </Text>
            </VStack>
            <VStack spacing={0} alignItems={'flex-start'}>
              <Text fontWeight="normal" mb={2} color={"#475467"} fontSize={13}>
                {t('screens.projects.type')}
              </Text>
              <Text fontWeight="normal" mb={2} color={"#101828"} fontSize={14}>
                {currentProject?.projectType ? currentProject.projectType : '--'}
              </Text>
            </VStack>
          </TypeContainer>
          <Text fontWeight="medium" mb={2} color={"#101828"} fontSize={14}>
            Team
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4}>
            {responsibles && (
              <TeamSection
                title="Responsible"
                members={
                  responsibles.map((e) => {
                    return { name: e.nominative, avatar: String(e.imageUrl) };
                  }) || []
                }
              />
            )}
            {accountables && (
              <TeamSection
                title="Accountable"
                members={
                  accountables.map((e) => {
                    return { name: e.nominative, avatar: String(e.imageUrl) };
                  }) || []
                }
              />
            )}
            {consulteds && (
              <TeamSection
                title="Consulted"
                members={
                  consulteds.map((e) => {
                    return { name: e.nominative, avatar: String(e.imageUrl) };
                  }) || []
                }
              />
            )}
            {informeds && (
              <TeamSection
                title="Informed"
                members={
                  informeds.map((e) => {
                    return { name: e.nominative, avatar: String(e.imageUrl) };
                  }) || []
                }
              />
            )}
          </SimpleGrid>
        </StyledSection>

        <StyledSection>
          <LinksSection links={projectLInks} />
        </StyledSection>

        {/* Editor component after the Details accordion */}
        <Box mt={6}>
          <Text fontWeight="bold" mb={2}>
            Project brief
          </Text>
          {/* <Editor
                    onSubmit={(description) => currentProject.updateDescription(description)}
                    value={currentProject.description}
                    submitting={currentProject.isSubmitting}
                /> */}
          <Textarea
            rows={4}
            value={text.text}
            onChange={(e) =>
              setText((prev) => ({ ...prev, text: e.target.value }))
            }
            placeholder="*text*"
            onBlur={() => {
              if (text.id !== null) {
                currentProject.submitNote({ uid: text.id, note: text.text });
              } else {
                currentProject.submitNote({ uid: null, note: text.text });
              }
            }}
          />
        </Box>
      </>
    );
  }
);

const LinksTitle = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  color: #101828;

  &:hover {
    text-decoration: underline; /* Add underline on hover */
    cursor: pointer;
  }
`;

const TypeContainer = styled.section`
  margin-bottom: 24px;
  display: flex;
  gap: 131px;
  @media (max-width: 960px) {
     flex-direction: column;
     gap: 30px;
  }
`;

