import { Checkbox } from "@chakra-ui/checkbox";
import { Heading, Stack } from "@chakra-ui/layout";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton } from "../../components/Button";
import { Column } from "../../components/Column";
import { Icon } from "../../components/Icon";
import integrations from "../../config/integrations";
import {
  fetchAsanaProjects,
  fetchAsanaTasks,
  fetchAsanaWorkspaces,
  updateAsanaTask,
} from "../../services/api";
import { ColumnHeader } from "./styled";
import { Draggable } from "../../libs/@fullcalendar/interaction";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { useTranslation } from "react-i18next";
import { ExternalEvent } from "../../components/ExternalEvent";
import { useQuery } from "react-query";
import { Select } from "../../components/Select";
import { AsanaProject, AsanaWorkspace } from "../../types";
import { IntegrationIcon } from "../../components/IntegrationIcon";

export const getSelectedWorspace = (
  workspaceId: AsanaWorkspace["gid"] | null,
  worspaces: AsanaWorkspace[]
): { value: string; label: string } | null => {
  if (!workspaceId) return null;

  const data = worspaces.find((worspace) => worspace.gid === workspaceId);

  return data
    ? {
      value: data.gid,
      label: data.name,
    }
    : null;
};

export const getSelectedProject = (
  projectId: AsanaProject["gid"] | null,
  projects: AsanaProject[]
): { value: string; label: string } | null => {
  if (!projectId) return null;

  const data = projects.find((project) => project.gid === projectId);

  return data
    ? {
      value: data.gid,
      label: data.name,
    }
    : null;
};

interface AsanaViewProps {
  toast: any;
}

export const AsanaView: React.FC<AsanaViewProps> = observer(({ toast }) => {
  const [isUpdatingTask, setIsUpdatingTask] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState<any>([]);
  const SORUCE = "asana";

  const { t } = useTranslation();
  const [activeFilter, setActiveFilter] = useState(null);
  const [activeWorkspaceId, setActiveWorkspaceId] = useState<
    AsanaWorkspace["gid"] | null
  >(null);
  const [activeProjectId, setActiveProjectId] = useState<
    AsanaProject["gid"] | null
  >(null);

  const {
    isFetching: isFetchingWorkspaces,
    data: workspacesData = [],
  } = useQuery(["asana-wokspaces"], () => fetchAsanaWorkspaces());

  const {
    isFetching: isFetchingProjects,
    data: projectsData = [],
    refetch: refetchProjects,
  } = useQuery(
    ["asana-projects", activeWorkspaceId],
    () => activeWorkspaceId && fetchAsanaProjects(activeWorkspaceId),
    {
      enabled: false,
    }
  );

  const {
    isFetching: isFetchingTasks,
    data: tasksData = [],
    refetch: refetchTasks,
  } = useQuery(
    ["asana-tasks", activeProjectId],
    () =>
      activeProjectId && fetchAsanaTasks(activeProjectId),
    {
      enabled: false,
      onError: (err) => {
        alert(err);
      }
    }
  );

  const workspaces = useMemo(() => {
    return workspacesData
      ? workspacesData.map((workspace) => ({
        value: workspace.gid,
        label: workspace.name,
      }))
      : [];
  }, [workspacesData]);

  const projectsOptions = useMemo(() => {
    let allProjects = [
      {
        value: null,
        label: t("screens.integrations.allProjects"),
      },
    ];
    return [
      ...allProjects,
      ...projectsData.map((project) => ({
        value: project.gid,
        label: project.name,
      })),
    ];
  }, [projectsData, t]);

  const tasks = useMemo(() => {
    return tasksData ? tasksData.map((task: any) => ({
      id: task.gid,
      title: task.name,
      elapsedTime: 0,
      due: task.due_on,
      projects: task.projects,
      icon: null,
      description: task.html_notes,
      complete: false,
      permalink_url: task.permalink_url,
    })) : [];
  }, [tasksData]);

  const handleChangeWorkspace = useCallback(
    (workspaceId: AsanaWorkspace["gid"]) => {
      setActiveWorkspaceId(workspaceId);
    },
    []
  );

  useEffect(() => {
    if (activeWorkspaceId) {
      // refetchTasks();
      refetchProjects();
    }
    if (activeProjectId) {
      refetchTasks();
    }
  }, [activeWorkspaceId, refetchProjects, refetchTasks, activeProjectId]);

  useEffect(() => {
    if (workspaces.length === 1) {
      handleChangeWorkspace(workspaces[0].value);
    }
  }, [handleChangeWorkspace, workspaces]);

  // const filteredTask = useMemo(() => {
  //   if (!activeFilter) {
  //     return tasks;
  //   } else if (activeFilter === "today") {
  //     const today = new Date();
  //     today.setHours(0, 0, 0, 0);
  //     return tasks.filter((task) => {
  //       const taskDueDate = task.due ? new Date(task.due.date) : null;
  //       if (taskDueDate) {
  //         taskDueDate.setHours(0, 0, 0, 0);
  //       }
  //       return taskDueDate ? taskDueDate.getTime() === today.getTime() : false;
  //     });
  //   } else {
  //     return tasks.filter((task) => task.projectId === activeFilter);
  //   }
  // }, [tasks, activeFilter]);

  useEffect(() => {
    //fetchData();

    let draggableEl = document.getElementById("external-events");
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("data-title");
          let eventSourceId = eventEl.getAttribute("data-source-id");
          let source = eventEl.getAttribute("data-source");
          return {
            title,
            eventSourceId,
            source,
          };
        },
      });
    }
  }, []);

  const fetchData = useCallback(async () => {
    refetchTasks();
    refetchProjects();
  }, [refetchProjects, refetchTasks]);

  const handleToggleFilter = useCallback(
    (key, checked) => {
      setActiveFilter(checked ? key : null);
    },
    [setActiveFilter]
  );

  const handleCompletedTask = useCallback(
    async (taskId: string) => {
      try {
        setIsUpdatingTask(true);
        const taskCompleted = await updateAsanaTask(taskId);

        if (taskCompleted.success) {
          toast({
            title: t("common.actionExecutedSuccessfully"),
            status: "success",
            position: "bottom-left",
          });
          setFilteredTasks(filteredTasks.filter((task) => task.id !== taskId));
        } else {
          throw new Error(taskCompleted.err.toString());
        }
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      } finally {
        setIsUpdatingTask(false);
      }
    },
    [filteredTasks, t, toast]
  );

  useEffect(() => {
    setFilteredTasks(tasks);
  }, [tasks]);

  return (
    <Column>
      <ColumnHeader>
        {/* @ts-ignore */}
        <Heading
          as={"h4"}
          size={"md"}
          fontWeight={500}
          display={"flex"}
          gap={"5px"}
        >
          <IntegrationIcon service={SORUCE} />
          {integrations[SORUCE].title}
        </Heading>
        <div>
          <Popover>
            <PopoverTrigger>
              <IconButton style={{ marginLeft: "1rem" }}>
                <Icon iconName={"HiOutlineFilter"} />
              </IconButton>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack>
                  <Checkbox
                    isChecked={activeFilter === "today"}
                    onChange={(event) =>
                      handleToggleFilter("today", event.target.checked)
                    }
                  >
                    {t<string>("calendar.today")}
                  </Checkbox>

                  {/* {projects.map((project) => (
                    <Checkbox
                      isChecked={activeFilter === project.id}
                      onChange={(event) =>
                        handleToggleFilter(project.id, event.target.checked)
                      }
                    >
                      {project.name}
                    </Checkbox>
                  ))} */}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <IconButton onClick={fetchData}>
            <Icon iconName={"HiRefresh"} />
          </IconButton>
        </div>
      </ColumnHeader>
      {workspaces.length > 1 && (
        <Select
          options={workspaces}
          value={getSelectedWorspace(activeWorkspaceId, workspacesData)}
          isLoading={isFetchingWorkspaces}
          onChange={(option) => option && setActiveWorkspaceId(option.value)}
        />
      )}

      {workspaces.length > 0 && (
        <Select
          options={projectsOptions}
          value={getSelectedProject(activeProjectId, projectsData)}
          isLoading={isFetchingProjects}
          onChange={(option) => option && setActiveProjectId(option.value)}
          placeholder={t<string>("screens.integrations.selectProject")}
        />
      )}

      {(isFetchingWorkspaces || isFetchingProjects || isFetchingTasks) && (
        <div>Loading...</div>
      )}

      <div
        id={"external-events"}
        style={{
          height: "100%",
          flex: "1 1 auto",
          position: "relative",
          overflowY: "auto",
          marginLeft: "0.2rem",
          overflowX: "hidden",
        }}
      >
        {filteredTasks.length > 0 &&
          filteredTasks.map((task) => (
            <ExternalEvent
              className={"fc-event"}
              title={task.title}
              sourceId={task.id}
              key={task.id}
              source={SORUCE}
              description={task.description}
              icon={task.icon}
              onCompleted={handleCompletedTask}
              t={t}
              showId={false}
              due={task.due}
              permalinkUrl={task.permalink_url}
              disabled={isUpdatingTask}
            >
              {task.title}
            </ExternalEvent>
          ))}
      </div>
    </Column>
  );
});
