import React, { useCallback } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { Button } from "@chakra-ui/button";

interface Values {
  nominative: string;
  email: string;
  password: string;
  confirmPassword: string;
}

type SignUpFormProps = {
  onSubmit: (nominative: string, email: string, password: string) => any;
};

export const SignUpForm: React.FC<SignUpFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      setSubmitting(false);
      onSubmit(values.nominative, values.email, values.password);
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={{
        nominative: "",
        email: "",
        password: "",
        confirmPassword: "",
      }}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack spacing={"1rem"}>
            <Field id={"nominative"} name={"nominative"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor="nominative">
                    {t<string>("screens.login.nominative")}
                  </StyledFormLabel>
                  <Input
                    type={"text"}
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field id={"email"} name={"email"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor="email">
                    {t<string>("screens.login.email")}
                  </StyledFormLabel>
                  <Input
                    type={"email"}
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field id={"password"} name={"password"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor="password">
                    {t<string>("screens.login.password")}
                  </StyledFormLabel>
                  <Input
                    type={"password"}
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field id={"confirmPassword"} name={"confirmPassword"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor="confirmPassword">
                    {t<string>("screens.login.confirmPassword")}
                  </StyledFormLabel>
                  <Input
                    type={"password"}
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              colorScheme={"teal"}
              type={"submit"}
              disabled={
                props.values.nominative.length === 0 ||
                props.values.email.length === 0 ||
                props.values.password.length === 0 ||
                props.values.password !== props.values.confirmPassword
              }
            >
              {t<string>("screens.board.confirmButton")}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
