import { Stat, StatHelpText } from "@chakra-ui/react";
import { StyledStatLabel, StyledStatNumber } from "./GridCell.styled";

type GridCellProps = {
  label: string;
  value: string | JSX.Element | JSX.Element[];
  helpText?: string;
};

export const GridCell: React.FC<GridCellProps> = ({
  label,
  value,
  helpText,
}) => {
  return (
    <Stat>
      <StyledStatLabel>{label}</StyledStatLabel>
      <StyledStatNumber>{value}</StyledStatNumber>
      {helpText && <StatHelpText>{helpText}</StatHelpText>}
    </Stat>
  );
};
