import React, { useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import { Stores } from "../types";
import { SessionStore } from "../stores/Session";

import { StoresContext } from "../contexts";

import { Login } from "./Login";
import { SignUp } from "./SignUp";
import { observer } from "mobx-react";
import { BoardStore } from "../stores/Board";
import { RedirectToUserBoard } from "./RedirectToUserBoard";
import { AppLayout } from "./AppLayout";
import { OrganizationStore } from "../stores/Organization";
import {
  Box,
  ChakraProvider,
  Container,
  useToast,
  Spinner,
  Text,
} from "@chakra-ui/react";
import ThemeProvider from "../theme";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { IconButton } from "../components/Button";
import { Icon } from "../components/Icon";
import { UiStore } from "../stores/UI";
import { useServiceWorker } from "../hooks/useServiceWorker";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { LostPassword } from "./LostPassword";
import { ChangePassword } from "./ChangePassword";
import { useTranslation } from "react-i18next";
import useScript from "../hooks/useScript";
import keys from "../config/keys";
import { BrowserView, MobileView } from "react-device-detect";
import MobileSvg from "../assets/images/mobile.svg";
// import useWindowDimensions from "../hooks/useWindowDimensions";
import { useCookies } from "react-cookie";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const Root: React.FC = observer(() => {
  // const [waitingWorker, setWaitingWorker] = useState<any>(null);
  // const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const serviceWorker = useServiceWorker();
  // const { width } = useWindowDimensions();
  const [cookies, setCookie, removeCookie] = useCookies(["new-changelog"]);
  useScript(keys.TIDIO_URL);

  const toast = useToast();
  const { t } = useTranslation();

  const [stores] = useState<Stores>(() => {
    const root: Stores = {} as Stores;

    root.session = new SessionStore(root);
    root.board = new BoardStore(root);
    root.organization = new OrganizationStore(root);
    root.ui = new UiStore(root);

    return root;
  });

  useEffect(() => {
    if (!stores.session.isInitialized) {
      stores.session.initialize();
    }
  }, []);

  const refreshAction = useCallback(() => {
    //render the snackbar button
    return (
      serviceWorker && (
        <IconButton
          onClick={() => {
            if (stores.session.userHasAcceptedCookies) {
              setCookie("new-changelog", true, { path: "/" });
            }
            serviceWorker.updateAssets();
          }}
        >
          <Icon iconName={"HiRefresh"} />
        </IconButton>
      )
    );
  }, [serviceWorker, setCookie, stores.session.userHasAcceptedCookies]);

  useEffect(() => {
    if (serviceWorker?.isUpdateAvailable) {
      toast({
        status: "info",
        position: "bottom",
        render: ({ id }) => (
          <Box
            id={id.toString()}
            color="white"
            p={3}
            bg={"black"}
            borderRadius={"3px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {t<string>("common.newVersionAvailable")}
            {refreshAction()}
          </Box>
        ),
        duration: 999999,
      });
    }
  }, [refreshAction, serviceWorker, t, toast]);

  // const onServiceWorkerUpdate = useCallback(
  //   (registration) => {
  //     setWaitingWorker(registration);
  //     setNewVersionAvailable(true);
  //   },
  //   [setWaitingWorker, setNewVersionAvailable]
  // );

  // const updateServiceWorker = useCallback(() => {
  //   waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
  //   setNewVersionAvailable(false);
  //   window.location.reload();
  // }, [waitingWorker, setNewVersionAvailable]);

  const RoutesList = () => {
    let location = useLocation();
    const ProtectedRoute = ({ children }) => {
      return stores.session.isLogged ? children : <Navigate to="/login" state={{ from: location }} />;
    };

    return (
      <Routes>
        <Route path={`/`} element={<RedirectToUserBoard />} />
        <Route path={"/login"} element={<Login />} />
        <Route path={"/signup"} element={<SignUp />} />
        <Route path={"/lost-password"} element={<LostPassword />} />
        <Route path={"/change-password"} element={<ChangePassword />} />
        <Route
          path={"/user/:userId/*"}
          element={
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/organization/*"}
          element={
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          }
        />
      </Routes>
    );
  };

  return (
    <>
      <BrowserView>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider>
            <ThemeProvider>
              <StoresContext.Provider value={stores}>
                <Router>
                  {stores.session.isInitialized ? <RoutesList /> : <div />}
                </Router>
              </StoresContext.Provider>
            </ThemeProvider>
          </ChakraProvider>
          <ReactQueryDevtools position={"top-left"} />
        </QueryClientProvider>
      </BrowserView>
      {/* <MobileView>
        <Container>
          <Container padding={"15%"}>
            <MobileImage src={MobileSvg} />
          </Container>
          <Container
            flexDirection={"column"}
            textAlign={"center"}
            padding={"0 10%"}
          >
            <Spinner
              thickness={"4px"}
              size={"xl"}
              width={width * 0.1}
              height={width * 0.1}
            />
            <Text fontSize={"22px"}>{t("screens.login.desktopOnly")}</Text>
          </Container>
        </Container>
      </MobileView> */}
    </>
  );
});

export default styled(Root)`
  & {
    min-height: 100vh;
  }
`;

const MobileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
