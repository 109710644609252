import { HStack, Stack, Text, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/useStores";
import { Period } from "../../types";
import { AbilityContext } from "../../components/Can";
import { CalendarNavButtons } from "../../components/CalendarNavButtons";
import dayjs from "dayjs";
import { TeamDashboard } from "./TeamDashboard";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { Icon } from "../../components/Icon";
import { UserDashboard } from "./UserDashboard";
import { isLimited } from "../../utils/plan";

type Props = {};

export const Dashboard: React.FC<Props> = observer(() => {
  const { organization, session } = useStores();
  const { t } = useTranslation();
  const toast = useToast();
  const ability = useContext(AbilityContext);
  const [period, setPeriod] = useState<{ fromDate: Date; toDate: Date }>({
    fromDate: dayjs().startOf("week").toDate(),
    toDate: dayjs().endOf("week").toDate(),
  });
  const BUSINESS_WORK_DAYS = 5;

  // useEffect(() => {
  //   // @ts-ignore
  //   refetch && ability.can("read", "team") && refetch();
  //   refetchUserInsights && refetchUserInsights();
  // }, [ability, period, refetch, refetchUserInsights]);

  // const userPlannedHours = useMemo(() => {
  //   return userReportData ? toUserPlannedHoursChartData(userReportData) : [];
  // }, [userReportData]);

  // const userPlannedHoursHint = useMemo(() => {
  //   return userReportData ? getUserPlannedHoursHint(userReportData) : null;
  // }, [userReportData]);

  console.log(
    session.user?.organization?.plan,
    session.user &&
    session.user.organization &&
    isLimited(session.user.organization.plan, "TEAM_DASHBOARD")
  );

  return (
    <Stack
      w="100%"
      h="100vh"
      overflowY="scroll"
      bg={'white'}
      minH="100%"
    >
      <Tabs variant="soft-rounded" colorScheme="teal">
        <HStack
          justifyContent={"space-between"}
          marginTop="1rem"
          marginBottom="0.5rem"
          marginRight="2rem"
        >
          <HStack>
            <CalendarNavButtons onChange={setPeriod} />
            <StyledPeriod period={period} />
          </HStack>

          <TabList>
            <Tab>
              <Icon iconName="HiUser" size="xs" />
              {t<string>("screens.dashboard.personal")}
            </Tab>
            {
              // @ts-ignore
              ability.can("read", "team") &&
              (session.user && session.user.organization
                ? !isLimited(session.user.organization.plan, "TEAM_DASHBOARD")
                : false) && (
                <Tab>
                  <Icon iconName="HiUserGroup" size="xs" />
                  {t<string>("screens.dashboard.team")}
                </Tab>
              )
            }
          </TabList>
        </HStack>

        <TabPanels>
          <TabPanel>
            <UserDashboard period={period} />
          </TabPanel>
          {
            // @ts-ignore
            ability.can("read", "team") &&
            (session.user && session.user.organization
              ? !isLimited(session.user.organization.plan, "TEAM_DASHBOARD")
              : false) && (
              <TabPanel>
                <TeamDashboard period={period} />
              </TabPanel>
            )
          }
        </TabPanels>
      </Tabs>
    </Stack>
  );
});

const StyledPeriod: React.FC<{ period: Period }> = ({ period }) => (
  <Text>
    {dayjs(period.fromDate).format("DD MMM YY")} -{" "}
    {dayjs(period.toDate).format("DD MMM YY")}
  </Text>
);
