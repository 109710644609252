import React, { useCallback } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { Button } from "@chakra-ui/button";

interface Values {
  email: string;
}

type LostPasswordProps = {
  isLoading: boolean;
  onSubmit: (email: string) => any;
};

export const LostPasswordForm: React.FC<LostPasswordProps> = ({
  isLoading,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      setSubmitting(false);
      onSubmit(values.email);
    },
    [onSubmit]
  );

  return (
    <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack spacing={"1rem"}>
            <Field id={"email"} name={"email"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor="email">
                    {t<string>("screens.login.email")}
                  </StyledFormLabel>
                  <Input
                    type={"email"}
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              colorScheme={"teal"}
              type={"submit"}
              disabled={props.values.email.length === 0}
              isLoading={isLoading}
            >
              {t<string>("screens.lostPassword.sendRecoveryLink")}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
