import { Stack } from "@chakra-ui/react";
import styled from "styled-components";
import BackgroundImage from "../../assets/images/scrum_board.svg";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 95% 90%;
  background-size: min(80vh, 80vw);
  padding-top: 5rem;
`;

export const BrandContainer = styled.div`
  position: absolute;
  left: 12px;
  top: 6px;
`;

export const BrandIcon = styled.div`
  display: block;
  width: 52px;
  height: 52px;

  img {
    width: 100%;
  }
`;

export const Card = styled(Stack)`
  text-align: center;
  width: 400px;
  padding: 1rem;
  border: 1px solid #f0f0f0;
`;
