import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { TFunction } from "react-i18next";

interface MultiValueInputProps {
  name: string;
  placeholder: string;
  initialValue?: Array<string>;
  disabled?: boolean;
  onChange: (values: Array<string>) => void;
  checkIfValidInput?: (value: string) => boolean;
  t: TFunction;
}
export const MultiValueInput: React.FunctionComponent<MultiValueInputProps> = ({
  name,
  placeholder,
  initialValue,
  disabled = false,
  onChange,
  checkIfValidInput,
  t,
}) => {
  const [values, setValues] = useState<Array<string>>(
    initialValue ? initialValue : []
  );
  const [inputValue, setInputValue] = useState("");

  const addValue = useCallback(() => {
    setValues((prevValues) => {
      const newValues = [...prevValues, inputValue];
      onChange(newValues);
      return newValues;
    });
    setInputValue("");
  }, [inputValue, onChange]);

  const removeValue = useCallback(
    (value: string) => {
      setValues((prevValues) => {
        const newValues = [...prevValues.filter((val) => val !== value)];
        onChange(newValues);
        return newValues;
      });
    },
    [onChange]
  );

  return (
    <VStack>
      <InputGroup size="md">
        <InputLeftAddon children="@" />
        <Input
          name={name}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          placeholder={placeholder}
          disabled={disabled}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            size="sm"
            onClick={addValue}
            disabled={
              disabled ||
              (checkIfValidInput ? !checkIfValidInput(inputValue) : false)
            }
          >
            {t<string>("screens.organization.addDomain")}
          </Button>
        </InputRightElement>
      </InputGroup>
      <HStack flexWrap={"wrap"} maxWidth={"50vw"} rowGap={3} width={"100%"}>
        {values.length > 0 &&
          values.map((val) => (
            <HStack spacing={4}>
              <Tag
                size={"md"}
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
              >
                <TagLabel>{val}</TagLabel>
                <TagCloseButton
                  isDisabled={disabled}
                  onClick={() => {
                    removeValue(val);
                  }}
                />
              </Tag>
            </HStack>
          ))}
      </HStack>
    </VStack>
  );
};
