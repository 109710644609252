import React, { useCallback } from "react";
import { DatePicker } from "../DatePicker";
import { Icon } from "../Icon";
import { DatePeriodPickerContainer } from "./styled";

export type DatePeriodPickerPeriod = {
  from: Date;
  to: Date;
};

type Props = {
  value: DatePeriodPickerPeriod;
  onChange: (period: DatePeriodPickerPeriod) => void;
};

export const DatePeriodPicker: React.FC<Props> = ({ value, onChange }) => {
  //const inputEl = useRef(null);

  const handleFromDateChange = useCallback(
    (date) => {
      if (date) {
        onChange({ ...value, from: date });
      }
    },
    [onChange, value]
  );

  const handleToDateChange = useCallback(
    (date) => {
      if (date) {
        onChange({ ...value, to: date });
      }
    },
    [onChange, value]
  );

  return (
    <DatePeriodPickerContainer>
      <DatePicker
        // @ts-ignore
        dateFormat={"dd/MM/yyyy"}
        selectedDate={value.from}
        onChange={(date) => handleFromDateChange(date)}
      />
      <Icon iconName={"HiOutlineArrowNarrowRight"} />
      <DatePicker
        //ref={inputEl}
        // @ts-ignore
        dateFormat={"dd/MM/yyyy"}
        selectedDate={value.to}
        onChange={(date) => handleToDateChange(date)}
        popperPlacement={"bottom-end"}
        // autoFocus
      />
    </DatePeriodPickerContainer>
  );
};
