import React, { useCallback } from "react";
import { Link, Navigate } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import LogoImg from "../../assets/icons/timetrap-logo.svg";
import { BrandContainer, Container, BrandIcon, Card } from "../Login/styled";
import { Text } from "@chakra-ui/layout";
import { LostPasswordForm } from "../../components/LostPasswordForm";
import { useToast } from "@chakra-ui/react";

export const LostPassword: React.FC = observer(() => {
  const { session } = useStores();
  const { t } = useTranslation();
  const toast = useToast();

  const handleSubmit = useCallback(
    async (email: string) => {
      try {
        await session.sendRecoveryLink(email);

        toast({
          title: t("screens.lostPassword.requestSuccess"),
          status: "success",
          position: "bottom-left",
        });
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [session, toast, t]
  );

  if (session.isLogged) {
    return <Navigate to={"/"} />;
  }

  return (
    <Container>
      <BrandContainer>
        <BrandIcon>
          <img src={LogoImg} alt={"TimeTrap"} />
        </BrandIcon>
      </BrandContainer>
      <Card>
        <Text fontSize={"3xl"} fontWeight={600}>
          {t<string>("screens.lostPassword.title")}
        </Text>

        <LostPasswordForm
          onSubmit={handleSubmit}
          isLoading={session.isSubmitting}
        />

        <Text style={{ marginTop: "2rem" }}>
          <Link color="teal.500" to={"/login"}>
            {t<string>("common.backToLogin")}
          </Link>
        </Text>
      </Card>
    </Container>
  );
});
