import { Button } from "@chakra-ui/button";
import { Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import styled from "styled-components";
import { ActionGroup } from "../../components/ActionGroup";
import { Box } from "../../components/Box";
import { PageHeader } from "../../components/PageHeader";
import { UsersTable } from "../../components/Users/UsersTable";
import { useStores } from "../../hooks/useStores";
import { SearchBar } from "../../components/SearchBar";
import useDebounce from "../../hooks/useDebounce";
import { fuzzyMatch } from "../../utils/string";
import { isLimited } from "../../utils/plan";
import { InviteUserModal } from "../../components/Users/InviteUserModal";
import { InvitedUser } from "../../types";

export const Users: React.FC = observer(() => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const { organization, session } = useStores();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 300);
  const [stateFilter, setStateFilter] = useState("active");
  const {
    isOpen: isInviteUserOpen,
    onClose: onInviteUserClose,
    onOpen: onInviteUserOpen,
  } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (organizationId) {
      organization.fetchUsers(organizationId);
    }
  }, [organizationId]);

  const filteredUsers = useMemo(() => {
    return debouncedQuery && debouncedQuery.length > 0
      ? organization.users.filter((user) =>
        fuzzyMatch(user.nominative + user.email, debouncedQuery)
      )
      : stateFilter === "active" ? organization.users.filter(e => e.status === "active")
        : stateFilter === "inactive" ? organization.users.filter(e => e.status === "inactive")
          : stateFilter === "invited" ? organization.users.filter(e => e.status === "invited")
            : organization.users;
  }, [debouncedQuery, organization.users, stateFilter]);

  const handleToggleStateFilter = useCallback(
    (key, checked) => {
      setStateFilter(checked ? key : null);
    },
    [setStateFilter]
  );

  const handleInviteUserSubmit = useCallback(
    async (invitedUser: InvitedUser) => {
      if (isSubmitting) {
        return;
      }

      try {
        if (organizationId) {
          setIsSubmitting(true);
          const { email, nominative, roleId } = invitedUser;
          await organization.inviteUser(
            organizationId,
            email,
            nominative,
            roleId
          );
          toast({
            title: t("screens.users.actions.userInvited"),
            status: "success",
            position: "bottom-left",
          });

          organization.fetchUsers(organizationId);
        } else {
          throw new Error("OrganizationId is not defined");
        }
      } catch (err) {
        console.log(err);

        toast({
          title: t("common.error"),
          status: "error",
        });
      } finally {
        setIsSubmitting(false);
        onInviteUserClose();
      }
    },
    [isSubmitting, onInviteUserClose, organization, organizationId, t, toast]
  );

  return (
    <StyledStack w="100%" h="100%">
      <PageHeader>
        <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
          {t<string>("screens.users.titleList")}
        </Heading>
        <ActionGroup>
          {organization.isFetchingUsers && <Spinner />}
          <SearchBar value={query} onChange={setQuery} />
          <Button
            id={"usetiful-invite-user"}
            colorScheme="teal"
            onClick={onInviteUserOpen}
            disabled={
              session.user && session.user.organization
                ? isLimited(
                  session.user.organization.plan,
                  "USER",
                  organization.users.length
                )
                : false
            }
          >
            {t<string>("screens.users.inviteUser")}
          </Button>
        </ActionGroup>
      </PageHeader>
      <Stack>
        <Box overflowX={{ sm: "scroll", xl: "hidden" }}>
          <UsersTable users={filteredUsers}
            handleToggleStateFilter={handleToggleStateFilter}
            stateFilter={stateFilter}
          />
        </Box>
      </Stack>

      {isInviteUserOpen && organizationId && (
        <InviteUserModal
          isVisible={isInviteUserOpen}
          onDismiss={onInviteUserClose}
          onSubmit={handleInviteUserSubmit}
          isFetchingRoles={organization.isFetchingRoles}
          roles={organization.roles}
          onLoad={() => organization.fetchRoles(organizationId)}
          isSubmitting={isSubmitting}
        />
      )}
    </StyledStack>
  );
});

const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
`;
