import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { Role } from "../../types";
import { RoleForm } from "../RoleForm";

type Props = {
  isVisible: boolean;
  role: Role;
  onDismiss: () => void;
  onSubmit: (role: Role) => void;
};

export const OrganizationRolesModal: React.FC<Props> = ({
  isVisible,
  role,
  onSubmit,
  onDismiss,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} onClose={onDismiss} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {role.uid !== "-1"
            ? t("screens.roles.editTitle")
            : t("screens.roles.newTitle")}
        </ModalHeader>
        <ModalBody>
          <RoleForm
            key={role.uid}
            role={role}
            onSubmit={onSubmit}
            onCancel={onDismiss}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
