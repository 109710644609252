import { makeAutoObservable } from "mobx";
import { ProjectLinks } from "../types";
import api from "../services/api";

export class ProjectLinksStore {
    projectLinks: ProjectLinks[] = [];
    loading = false;
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    fetchProjectLinks = async (projectId: string) => {
        this.loading = true;
        this.error = null;
        try {
            const data = await api.fetchProjectLinks(projectId);
            this.projectLinks = data;
        } catch (error) {
            throw error;
        } finally {
            this.loading = false;
            this.error = null;
        }
    };

    refresh = async (projectId: string) => {
        const data = await api.fetchProjectLinks(projectId);
        this.projectLinks = data;
    }

    submitLinks = async (
        project_id: string,
        projectLinks: ProjectLinks
    ) => {
        this.loading = true;
        try {
            if (projectLinks.linkId) {
                const expense = await api.updateProjectLinks(
                    project_id,
                    projectLinks
                );

                for (let i = 0; i < this.projectLinks.length; i++) {
                    if (this.projectLinks[i].linkId === projectLinks.linkId) {
                        this.projectLinks[i] = { ...projectLinks };
                    }
                }
            } else {
                const proLinks = await api.addProjectLinks(
                    project_id,
                    projectLinks
                );

                this.projectLinks.push(proLinks);
            }
        } catch (err) {
            throw err;
        } finally {
            this.loading = false;
            if (project_id) {
                this.refresh(project_id);
            }
        }
    };

    deleteProjectLinks = async (project_id: string, link_id: string) => {
        this.loading = true;
        try {
            await api.deleteProjectLinks(project_id, link_id);

            this.projectLinks = this.projectLinks.filter((exp) => exp.linkId !== link_id);
        } catch (err) {
            throw err;
        } finally {
            this.loading = false;;
        }
    }
}


export const projectLinksStore = new ProjectLinksStore();