import styled from "styled-components";
export const CalnderContainer = styled.section`
width: 100%;
height: 100%;
flex: 1 1 auto;

flex-direction: column;
background-color: #fbfbfb;
margin-left : 0px;
position: relative;
`;

export const RightSiderContaier = styled.section`
position: relative;
    width: 100%;
`;

export const CloseIcon = styled.button`
position: absolute;
top: 10px;
right: 10px;
`;

export const TeamPlanHeader = styled.section`
display: flex;
width: 100%;
justify-content: space-between;
`;

export const HeaderGridContianer = styled.section<{ isToday: boolean }>`
//   background-color: ${(props) => (props.isToday ? "#EDF1FB" : "transparent")};
//   border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  min-width: 80%;
  text-align: left;
`;

export const DateNumberText = styled.h1`
    font-size: 24px;
    font-weight: 600;
`;

export const DateText = styled.h1`
    font-size: 14px;
    font-weight: 400;
`;

export const DisplayDateCurrent = styled.section`
min-height: 60px;
padding: 2px;
display: flex;
flex-direction: column;
// width: 100%;
// background-color: gray;
padding: 0px 0px 10px 50px;
`;

export const MainContainer = styled.section`
display: flex;
width: 100%;
height: 100%;
background-color: #fbfbfb;
flex-direction: column;
`;

export const Weekview = styled.section`
display: flex;
width: 100%;
height: 100%;
flex-direction: column;
margin-top: 20px;

// padding: 0px 10px 0px 10px;
padding: 0px 20px 0px 20px;
`;

export const HeaderOfChart = styled.section`
display: flex;
width: 100%;
align-items: center;
// margin-top: 35px;
`;

export const TitleLeftText = styled.section`
width: 200px;
font-weight: 600;
font-size: 18px;
`;

export const TitleLeftTextUser = styled.section`
width: 200px;
font-weight: 500;
font-size: 14px;
display: flex;
align-items:center;
column-gap: 10px;
`;

export const WeekDayHeaders = styled.section`
display: flex;
flex: 1;
gap: 10px;
`;

export const TextOfDay = styled.section`
width: 100%;
flex: 1;
display: flex;
flex-direction: column;
`;

export const UserWrokContainer = styled.div`
margin-top: 10px;
display: flex;
flex-direction: column;
row-gap: 10px;
`;

export const UserTaskDetailsContainer = styled.section`
display: flex;
// gap: 10px;
`;
export const AvtarIcon = styled.section`
display: flex;
justify-content: center;
align-items: center;
height: 40px;
width: 40px;
background-color: #319795;
color: white;
border-radius: 20px;
`;

export const UserText = styled.span`

`;

export const ShowTimeBox = styled.section<{ isSelected?: boolean }>`
height: 70px;
 background-color: #DFDFDF;
 border-radius: 4px;
 border: ${(props) => props.isSelected ? "1px solid #acacad" : ""};
  width: 100%;
  flex: 1;
 align-content: end;
`;

export const ShowFillTimeBox = styled.section<{ c1: string, h1: number }>`
  height: ${(props) => props.h1}%;
  width: 100%;
   border-radius: 4px;
 background-color: ${(props) => props.c1};
`;

export const NoDataFound = styled.section`
text-align: center;
font-size: 14px;
font-weight: 500;
`;

// for the change control for calnders
export const NPIconContainer = styled.button`
width: 38px;
height: 38px;
border-radius: 8px;
border-width: 2px;
border-color: #DBDBDC;
display: flex;
justify-content: center;
align-items:center;
`;

export const TodayButton = styled.button`
width: auto;
height: 38px;
padding: 5px 15px 5px 15px;
border-radius: 8px;
border-width: 2px;
border-color: #DBDBDC;
display: flex;
justify-content: center;
align-items:center;
 background-color: ${({ theme }) => theme.white};
`;

export const SettingsButton = styled.button`
  background-color: ${({ theme }) => theme.white};
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  height: 2.5rem;
  width: 2.5rem;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  transition: all 250ms;
  color: ${(props) => props.theme.text1} !important;
  border: 1px solid #DBDBDC;
  border-radius: 6px; 
  // box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
`;