import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Avatar,
  Text,
  Badge,
  Stack,
} from "@chakra-ui/react";
import { User } from "../../../types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon } from "../../Icon";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PopoverHeader,
} from "@chakra-ui/popover";
import { Checkbox } from "@chakra-ui/checkbox";
import { useCallback, useState } from "react";
import { IconButton } from "../../Button";
import styled from "styled-components";

type Props = {
  users: User[];
  stateFilter: string,
  handleToggleStateFilter: (state: string, value: boolean) => void;
};

export const UsersTable: React.FC<Props> = ({ users, handleToggleStateFilter, stateFilter }) => {
  const { t } = useTranslation();

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="gray.400">{t<string>("screens.users.nominative")}</Th>
          <Th color="gray.400">{t<string>("screens.roles.title")}</Th>
          <Th color="gray.400" display={'flex'} gap={"10px"}>
            {t<string>("screens.users.status")}
            <Popover>
              <PopoverTrigger >
                <IconButton margin={0}>
                  <Icon iconName={"HiOutlineFilter"} size="2xsmall" />
                </IconButton>
              </PopoverTrigger>
              <PopoverContent maxWidth={"150px"}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>{t<string>("screens.users.status")}</PopoverHeader>
                <PopoverBody>
                  <Stack>
                    <Checkbox
                      isChecked={stateFilter === "active"}
                      onChange={(event) =>
                        handleToggleStateFilter("active", event.target.checked)
                      }
                    >
                      <FilterText>{t<string>('filterStatus.active')}</FilterText>
                    </Checkbox>
                    <Checkbox
                      isChecked={stateFilter === "inactive"}
                      onChange={(event) =>
                        handleToggleStateFilter("inactive", event.target.checked)
                      }
                    >
                      <FilterText>{t<string>('filterStatus.inActive')}</FilterText>
                    </Checkbox>
                    <Checkbox
                      isChecked={stateFilter === "invited"}
                      onChange={(event) =>
                        handleToggleStateFilter("invited", event.target.checked)
                      }
                    >
                      <FilterText>{t<string>('filterStatus.invited')}</FilterText>
                    </Checkbox>
                    <Checkbox
                      isChecked={stateFilter === "all"}
                      onChange={(event) =>
                        handleToggleStateFilter("all", event.target.checked)
                      }
                    >
                      <FilterText>{t<string>('filterStatus.all')}</FilterText>
                    </Checkbox>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {users.map((user) => (
          <Tr key={user.uid}>
            <Td minWidth={{ sm: "250px" }} pl="0px">
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Avatar
                  src={user.imageUrl}
                  borderRadius="12px"
                  me="18px"
                  size="sm"
                />
                <Flex direction="column">
                  <Link to={`./${user.uid}`}>
                    <Text fontSize="md" fontWeight="medium" minWidth="100%">
                      {user.nominative}
                    </Text>
                  </Link>
                  <Text fontSize="sm" color="gray.400" fontWeight="normal">
                    {user.email}
                  </Text>
                </Flex>
              </Flex>
            </Td>
            <Td>{user.role?.name}</Td>
            <Td>
              <Badge
                bg={
                  user.status === "active"
                    ? "green.300"
                    : user.status === "invited"
                      ? "yellow.300"
                      : "gray.200"
                }
              >
                {user.status}
              </Badge>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const FilterText = styled.span`
  font-size: 12px;
`