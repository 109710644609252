import { Checkbox } from "@chakra-ui/checkbox";
import { Heading, Stack } from "@chakra-ui/layout";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { IconButton } from "../../components/Button";
import { Column } from "../../components/Column";
import { Icon } from "../../components/Icon";
import integrations from "../../config/integrations";
import { useStores } from "../../hooks/useStores";
import { ColumnHeader } from "./styled";

export const GCalendarView: React.FC = observer(() => {
  const { board } = useStores();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    board.fetchGoogleCalendars();
  }, [board]);

  const handleToggleGCalendar = useCallback(
    (calendarId: string, toggle: boolean) => {
      board.toggleGoogleCalendar(calendarId, toggle);
    },
    [board]
  );

  return (
    <Column>
      <ColumnHeader>
        <Heading as={"h4"} size={"md"} fontWeight={500}>
          {integrations["gcalendar"].title}
        </Heading>
        <IconButton onClick={fetchData}>
          <Icon iconName={"HiRefresh"} />
        </IconButton>
      </ColumnHeader>
      {board.isFetchingGCalendars && <div>Loading...</div>}
      <Stack spacing={1} direction={"column"} overflowX={"hidden"}>
        {board.gCalendars &&
          board.gCalendars.map((gCalendar) => (
            <Checkbox
              key={gCalendar.id}
              isChecked={gCalendar.enabled}
              onChange={(e) =>
                handleToggleGCalendar(gCalendar.id, e.target.checked)
              }
            >
              {gCalendar.summary}
            </Checkbox>
          ))}
      </Stack>
    </Column>
  );
});
