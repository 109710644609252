import dayjs from "dayjs";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
type Props = {
    onClick: () => void;
    userId: string;
    day: dayjs.Dayjs;
    percentage: number;
    isSelected: boolean;
    hours: number;
}
export const FilterContainer: React.FC<Props> = observer(({
    onClick,
    day,
    userId,
    percentage,
    isSelected = false,
    hours = 0
}) => {
    return (
        <ShowTimeBox
            onClick={onClick}
            isSelected={isSelected}
        >
            <ShowFillTimeBox c1={percentage ? percentage === 100 ? '#67ABE1' : "#A0CEF3" : "#A0CEF3"} h1={percentage ? percentage <= 100 && percentage >= 0 ? percentage : 100 : 0} />
            <ShowWrokLogHours>{hours}</ShowWrokLogHours>
        </ShowTimeBox>
    );
});


export const ShowTimeBox = styled.section<{ isSelected?: boolean }>`
position: relative;
height: 70px;
 background-color: #DFDFDF;
 border-radius: 4px;
 border: ${(props) => props.isSelected ? "3px solid #319795" : ""};
  width: 100%;
  flex: 1;
 align-content: end;
 `;

const ShowWrokLogHours = styled.span`
 position: absolute;
 bottom: 2px;
 right: 5px;
 font-size: 11px;
 font-weight: 500;
 color: #4B4B4B;
 `

export const ShowFillTimeBox = styled.section<{ c1: string, h1: number }>`
  height: ${(props) => props.h1}%;
  width: 100%;
   border-radius: 4px;
 background-color: ${(props) => props.c1};
`;
