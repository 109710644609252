import { Button } from "@chakra-ui/button";
import { Heading } from "@chakra-ui/layout";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { observer } from "mobx-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ActionGroup } from "../../components/ActionGroup";
import { CustomerModal } from "../../components/Customers/CutomerModal";
import { Icon } from "../../components/Icon";
import { PageHeader } from "../../components/PageHeader";
import { ExpenseModal } from "../../components/ProjectDetailsScreen/ExpenseModal/ExpenseModal";
import { HeaderGrid } from "../../components/ProjectDetailsScreen/HeaderGrid/HeaderGrid";
import { ProjectModal } from "../../components/ProjectDetailsScreen/ProjectModal/ProjectModal";
import { FinalExpenses } from "../../components/ProjectDetailsScreen/Tabs/FinalExpenses/FinalExpenses";
import { ProjectDetailsBeta } from "../../components/ProjectDetailsScreen/Tabs/ProjectDescriptionBeta/ProjectDescriptionBeta";
import { ProjectInsightsBeta } from "../../components/ProjectDetailsScreen/Tabs/ProjectInsightsBeta/ProjectInsightsBeta";
import { ProjectReport } from "../../components/ProjectDetailsScreen/Tabs/ProjectReport/ProjectReport";
import { ProjectTasks } from "../../components/ProjectDetailsScreen/Tabs/ProjectTasks/ProjectTasks";
import keys from "../../config/keys";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useStores } from "../../hooks/useStores";
import Expense from "../../models/Expense";

export interface Action {
  type: string;
  payload?: {
    expense: Expense | null;
  };
}

const initialExpenseState = {
  expense: null,
  isModalVisible: false,
};

const expenseReducer = (state, action: Action) => {
  switch (action.type) {
    case "new-expense":
      return {
        expense: null,
        isModalVisible: true,
      };
    case "edit-expense":
      return {
        expense: action.payload ? action.payload.expense : null,
        isModalVisible: true,
      };
    case "close-expense":
      return {
        ...initialExpenseState,
      };
    default:
      throw new Error();
  }
};

export const ProjectDetails: React.FC = observer(() => {
  const { t } = useTranslation();
  const { organization, session } = useStores();
  const [isModalProjectVisible, setIsModalProjectVisible] = useState(false);
  const { projectId, userId } = useParams();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const organizationId = queryParams.get("organizationId") || "";
  const [expenseState, expenseDispatch] = useReducer(
    expenseReducer,
    initialExpenseState
  );
  const toast = useToast();

  const {
    isOpen: isCustomerModalOpen,
    onOpen: onCustomerModalOpen,
    onClose: onCustomerModalClose,
  } = useDisclosure();

  const handleCloseCustomerModal = useCallback(() => {
    onCustomerModalClose();
  }, [onCustomerModalClose]);

  const currentProject = useMemo(() => {
    const data = organization.projects.find(
      (project) => project.uid === projectId
    );

    return data;
  }, [organization.projects, projectId]);

  const toggleIsProjectModalVisibility = useCallback(() => {
    setIsModalProjectVisible(!isModalProjectVisible);
  }, [isModalProjectVisible]);

  useEffect(() => {
    if (organizationId && organization.projects.length === 0) {
      organization.fetchProjects(organizationId);
    }
  }, [organization.projects, organizationId]);

  useEffect(() => {
    if (currentProject) {
      Promise.all([
        currentProject.fetchExpenses(),
        currentProject.fetchNotes(),
        currentProject.fetchProjectStatstics(projectId, organizationId, userId),
      ]);
    }
  }, [currentProject, organization, organizationId, projectId, userId]);

  // const getHoursConstDetails = async () => {
  //   if(currentProject){
  //     const details = currentProject?.fetchProjectStatstics2(projectId, organizationId, userId);
  //   }
  // }

  // useEffect(() => {
  //   if (userId && projectId && organizationId && currentProject) {
  //     currentProject.fetchProjectStatstics(projectId, organizationId, userId);
  //   }
  // }, [currentProject, organization])

  const handleCustomerSubmit = useCallback(
    async (customer) => {
      try {
        if (organizationId) {
          await organization.createCustomer(organizationId, customer);

          toast({
            title: t("screens.customers.actions.created"),
            status: "success",
            position: "bottom-left",
          });
        }

        onCustomerModalClose();
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [onCustomerModalClose, organization, organizationId, toast, t]
  );

  if (organization.isFetchingProjects) {
    return <div>Loading...</div>;
  } else if (!currentProject) {
    return <div>{t<string>("sceens.projects.notFound")}</div>;
  } else {
    console.log("currentProject -> ", currentProject);
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        backgroundColor: "white",
      }}
    >
      <PageHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button variant={"ghost"} onClick={() => window.history.back()}>
            <Icon iconName={"HiOutlineArrowLeft"} />
          </Button>
          <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
            {currentProject.title}
          </Heading>
        </div>
        <ActionGroup>
          {/* <Button
            colorScheme={"teal"}
            variant={"ghost"}
            onClick={() => expenseDispatch({ type: "new-expense" })}
          >
            {t<string>("screens.expenses.addExpense")}
          </Button> */}
          <Button
            colorScheme={"teal"}
            variant={"ghost"}
            onClick={toggleIsProjectModalVisibility}
          >
            {t<string>("screens.projects.editProject")}
          </Button>
        </ActionGroup>
      </PageHeader>

      <HeaderGrid currentProject={currentProject} t={t} />
      {/* @ts-ignore */}
      <Tabs colorScheme={"teal"} size="md" mt={"10px"} pl={"0.75rem"}>
        <TabList>
          <Tab
            _selected={{
              fontWeight: "600",
              color: "#319795",
              borderBlockEndWidth: 2.5,
              borderBottomColor: "#319795",
            }}
          >
            {t<string>("screens.projects.insights.title")}
          </Tab>
          <Tab
            _selected={{
              fontWeight: "600",
              color: "#319795",
              borderBlockEndWidth: 2.5,
              borderBottomColor: "#319795",
            }}
          >
            {t<string>("screens.projects.details")}
          </Tab>
          {/* <Tab>{t<string>("screens.expenses.estimateExpenses")}</Tab> */}
          <Tab
            _selected={{
              fontWeight: "600",
              color: "#319795",
              borderBlockEndWidth: 2.5,
              borderBottomColor: "#319795",
            }}
          >
            {" "}
            {t<string>("screens.expenses.finalBalanceExpenses")}
          </Tab>
          {keys.SHOW_TASKS && (
            <Tab
              _selected={{
                fontWeight: "600",
                color: "#319795",
                borderBlockEndWidth: 2.5,
                borderBottomColor: "#319795",
              }}
            >
              {t<string>("tasks.title")}
            </Tab>
          )}
          <Tab
            _selected={{
              fontWeight: "600",
              color: "#319795",
              borderBlockEndWidth: 2.5,
              borderBottomColor: "#319795",
            }}
          >
            Activities
          </Tab>
          {/* <Tab>{t<string>("screens.projects.note")}</Tab> */}
        </TabList>

        {/* <Divider orientation='horizontal' my={"10px"} /> */}
        <TabPanels>
          <TabPanel>
            {/* <ProjectInsights project={currentProject} chartDetails={currentProject.projectStatstics ? currentProject.projectStatstics : null} /> */}
            <ProjectInsightsBeta project={currentProject} />
          </TabPanel>
          <TabPanel>
            {/* <ProjectDescription currentProject={currentProject} /> */}
            <ProjectDetailsBeta currentProject={currentProject} />
          </TabPanel>
          {/* <TabPanel>
            <EstimatedExpenses
              currentProject={currentProject}
              t={t}
              toast={toast}
              expenseDispatch={expenseDispatch}
            />
          </TabPanel> */}
          <TabPanel>
            <FinalExpenses
              currentProject={currentProject}
              expenseDispatch={expenseDispatch}
              t={t}
            />
          </TabPanel>
          {keys.SHOW_TASKS && (
            <TabPanel>
              {projectId && (
                <ProjectTasks
                  organizationId={organizationId}
                  projectId={projectId}
                />
              )}
            </TabPanel>
          )}

          <TabPanel>
            {/* from 0.24.19 called the projectActivities */}
            <ProjectReport
              report={organization.projectReport}
              isFetching={organization.isFetchingProjectReport}
              onLoad={() =>
                organization.fetchProjectReport(
                  organizationId,
                  currentProject.uid
                )
              }
            />
          </TabPanel>
          {/* <TabPanel>
            <ProjectNotes currentProject={currentProject} session={session} />
          </TabPanel> */}
        </TabPanels>
      </Tabs>

      {isModalProjectVisible && (
        <ProjectModal
          isModalProjectVisible={isModalProjectVisible}
          organization={organization}
          currentProject={currentProject}
          toast={toast}
          organizationId={organizationId}
          toggleIsProjectModalVisibility={toggleIsProjectModalVisibility}
          t={t}
          onCreateCustomer={onCustomerModalOpen}
          userId={userId}
        />
      )}

      {projectId && expenseState.isModalVisible && (
        <ExpenseModal
          currentProject={currentProject}
          toast={toast}
          organizationId={organizationId}
          expenseState={expenseState}
          projectId={projectId}
          expenseDispatch={expenseDispatch}
          t={t}
        />
      )}

      {isCustomerModalOpen && (
        <CustomerModal
          customer={null}
          onSubmit={handleCustomerSubmit}
          onDismiss={handleCloseCustomerModal}
          isVisible={isCustomerModalOpen}
          onDelete={() => {}}
        />
      )}
    </div>
  );
});
