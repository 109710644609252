import styled, { css } from "styled-components";

const cellStyles = css<{ onClick?: (any) => any }>`
  text-align: left;
  vertical-align: middle;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.2rem;
  min-width: 5em;
  ${(props) => props.onClick && "cursor: pointer;"}
`;

export const Th = styled.div`
  ${cellStyles}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  text-transform: uppercase;
  font-size: 0.833em;
  font-weight: 500;
  opacity: 0.6;

  & span {
    cursor: pointer;
    display: flex;
  }
`;

export const Td = styled.div`
  ${cellStyles};
  word-break: break-all;
`;
