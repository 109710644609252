import { Checkbox } from "@chakra-ui/checkbox";
import { FormLabel } from "@chakra-ui/form-control";
import styled from "styled-components";

export const StyledCheckbox = styled(Checkbox) <{ isChecked: boolean }>`
  & {
    .chakra-checkbox__control {
      width: 2.5em;
      height: 2.5em;
      border-radius: 1.25em;
      background-color: ${props => props.isChecked ? '#319795' : 'transparent'} !important;
      background-image : ${props => props.isChecked ? "none" : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23A0AEC0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>')`};
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 60% !important;
    }
}
  }
`;

export const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
