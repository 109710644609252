import React, { useCallback } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react";
import keys from "../../config/keys";
import { useTranslation } from "react-i18next";

import { AiOutlineGoogle } from "react-icons/ai";
import LogoImg from "../../assets/icons/timetrap-logo.svg";
import { BrandContainer, Container, BrandIcon, Card } from "./styled";
import { LoginForm } from "../../components/LoginForm";
import { Text, Center } from "@chakra-ui/layout";
import { useToast, Button } from "@chakra-ui/react";

export const Login: React.FC = observer(() => {
  const { session } = useStores();
  const { t } = useTranslation();
  const toast = useToast();
  const location = useLocation();

  const handleSuccess = useCallback(
    async (res) => {
      const { googleId, imageUrl, email, name } = res.profileObj;
      session.signUser(
        res.accessToken,
        res.tokenId,
        googleId,
        name,
        email,
        imageUrl
      );
      // session.sessionToken = res.access_token;
      session.refreshTokenSetup(res);
    },
    [session]
  );

  const handleFailure = useCallback((res) => {
    console.log("[Login failed] res:", res);
  }, []);

  const handleSubmit = useCallback(
    async (email: string, password: string) => {
      try {
        await session.login(email, password);
      } catch (err) {
        console.log(err);
        toast({
          title: t("screens.login.loginError"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [session, toast, t]
  );

  if (session.isLogged) {
    return <Navigate to={(location.state as { from?: string })?.from || "/"} />;
  }

  return (
    <Container>
      <BrandContainer>
        <BrandIcon>
          <img src={LogoImg} alt={"TimeTrap"} />
        </BrandIcon>
      </BrandContainer>
      <Card>
        <Text fontSize={"3xl"} fontWeight={600}>
          {t<string>("screens.login.login")}
        </Text>

        <LoginForm onSubmit={handleSubmit} />

        <GoogleLogin
          clientId={keys.GOOGLE_CLIENT_ID}
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
          uxMode={"popup"}
          // scope={
          //   "profile email https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events.readonly"
          // }
          scope={
            "profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
          }
          render={(renderProps) => (
            <Center>
              <Button
                style={{ marginTop: "1.5rem", width: "60%" }}
                colorScheme={"red"}
                leftIcon={<AiOutlineGoogle />}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                size={"md"}
              >
                {t<string>("screens.login.ctaLogin")}
              </Button>
            </Center>
          )}
        />

        <Text style={{ marginTop: "2rem" }}>
          {t<string>("screens.login.notSignup")}{" "}
          <Link color="teal.500" to={"/signup"}>
            {t<string>("screens.login.ctaSignup")}
          </Link>
        </Text>

        <Text style={{ marginTop: "1rem" }}>
          <Link color="teal.500" to={"/lost-password"}>
            {t<string>("screens.login.lostPassword")}
          </Link>
        </Text>
      </Card>
    </Container>
  );
});
