import { Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Overhead } from "../../types";
import { toCurrency } from "../../utils/string";

type Props = {
  overheads: Overhead[];
  onEdit: (overhead: Overhead) => void;
};

export const OverheadsTable: React.FC<Props> = ({ overheads, onEdit }) => {
  const { t } = useTranslation();
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="gray.400">
            {t<string>("components.overheadsTable.fromData")}
          </Th>
          <Th color="gray.400">
            {t<string>("components.overheadsTable.toDate")}
          </Th>
          <Th color="gray.400">
            {t<string>("components.overheadsTable.amount")}
          </Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {overheads.map((overhead) => (
          <Tr key={overhead.uid}>
            <Td>{dayjs(overhead.fromDate).format("DD/MM/YYYY")}</Td>
            <Td>{dayjs(overhead.toDate).format("DD/MM/YYYY")}</Td>
            <Td>{overhead.amount ? toCurrency(overhead.amount) : "-"}</Td>
            <Td>
              <Button
                colorScheme="teal"
                variant="ghost"
                onClick={() => onEdit(overhead)}
                size="sm"
              >
                {t<string>("common.edit")}
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
