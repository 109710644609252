import { Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Avatar } from "../../components/Avatar";
import { Box } from "../../components/Box";
import { PageHeader } from "../../components/PageHeader";
import { Select } from "../../components/Select";
import langConfig from "../../config/languages";
import { useStores } from "../../hooks/useStores";

export const UserProfile: React.FC = observer(() => {
  const { t, i18n } = useTranslation();
  const { session } = useStores();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  const languages = useMemo(() => {
    return langConfig.LANGUAGES.map((lang) => ({
      value: lang,
      label: t(`languages.${lang}`),
    }));
  }, [t]);

  const getSelectedLanguage = useCallback(() => {
    return {
      value: selectedLanguage,
      label: t(`languages.${selectedLanguage}`),
    };
  }, [selectedLanguage, t]);

  const handleConditions = async (language: string) => {
    setSelectedLanguage(language);
    await session.updateUser({
      language,
    })
    await i18n.changeLanguage(language);
  };


  return (
    <StyledStack w="100%" h="100%" bg={'white'}>
      <PageHeader>
        <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
          {t<string>("screens.profile.title")}
        </Heading>
      </PageHeader>
      <Stack>
        <Box>
          <HStack>
            <Avatar
              src={session.user?.imageUrl}
              size="lg"
              name={session.user?.nominative}
            />
            <Stack paddingLeft="1rem">
              <Text fontSize="lg" fontWeight={600}>
                {session.user?.nominative}
              </Text>
              <Text color="gray.500">{session.user?.email}</Text>
            </Stack>
          </HStack>
          <HStack>
            <Stack paddingLeft="5.3rem">
              <Text fontSize="lg" fontWeight={600}>
                {t<string>("screens.profile.language")}
              </Text>
              <HStack>
                <div
                  style={{
                    minWidth: "250px",
                  }}
                >
                  <Select
                    options={languages}
                    value={getSelectedLanguage()}
                    onChange={(option: any) => setSelectedLanguage(option.value)}
                  />
                </div>
                <Button bg={"#319795"} color={"white"} _hover={{
                  backgroundColor: "#319795",
                  color: "white"
                }}
                  onClick={() => {
                    handleConditions(selectedLanguage)
                  }}
                >
                  {t<string>("screens.profile.saveBtnText")}
                </Button>
              </HStack>
            </Stack>
          </HStack>
        </Box>

      </Stack>
    </StyledStack>
  );
});

// @ts-ignore
const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
`;
