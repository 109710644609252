import {
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "../Icon";

type Props = {
  value: string | null;
  placeholder?: string;
  onChange: (value: string) => void;
};

export const SearchBar: React.FC<Props> = ({
  value,
  placeholder,
  onChange,
}) => {
  const { t } = useTranslation();
  const mainTeal = useColorModeValue("teal.300", "teal.300");
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("white", "gray.800");

  const handleChange = useCallback((event) => onChange(event.target.value), [
    onChange,
  ]);

  return (
    <InputGroup
      bg={inputBg}
      borderRadius="0.75rem"
      w="250px"
      _focus={{
        borderColor: { mainTeal },
      }}
      _active={{
        borderColor: { mainTeal },
      }}
    >
      <InputLeftElement
        children={
          <Icon size="sm" iconName="HiOutlineSearch" color={searchIconColor} />
        }
      />
      <Input
        fontSize="sm"
        py="11px"
        placeholder={placeholder || t("components.searchBar.placeholder")}
        borderRadius="inherit"
        onChange={handleChange}
        value={value || ""}
      />
    </InputGroup>
  );
};
