import { Modal, ModalOverlay } from "@chakra-ui/modal";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useStores } from "../../hooks/useStores";
import { ConditionsForm } from "../ConditionsForm";
import { OrganizationModal } from "./OrganizationModal";
import i18n from "../../services/i18n";
import { useTranslation } from "react-i18next";

enum OnboardingViews {
  CONDITIONS_FORM = "CONDITIONS_FORM",
  ORGANIZATION_FORM = "ORGANIZATION_FORM",
}

export const OnboardingModal: React.FC = observer(() => {
  const { session, organization } = useStores();
  const { t } = useTranslation();
  const [view, setView] = useState<OnboardingViews | null>(
    !!!session.user?.termsOfUse || !!!session.user?.privacyPolicy
      ? OnboardingViews.CONDITIONS_FORM
      : !!!session.user?.organization
      ? OnboardingViews.ORGANIZATION_FORM
      : null
  );

  useEffect(() => {
    const view =
      !!!session.user?.termsOfUse || !!!session.user?.privacyPolicy
        ? OnboardingViews.CONDITIONS_FORM
        : !!!session.user?.organization
        ? OnboardingViews.ORGANIZATION_FORM
        : null;

    setView(view);
  }, [session]);

  const handleConditions = useCallback(
    async (language: string, checkedConditions: boolean) => {
      await session.updateUser({
        termsOfUse: checkedConditions,
        privacyPolicy: checkedConditions,
        language,
      });
      i18n.changeLanguage(language);
      if (!session.user?.organization) {
        setView(OnboardingViews.ORGANIZATION_FORM);
      } else {
        setView(null);
      }
    },
    [session]
  );

  const handleSubmitOrganization = useCallback(
    async (data) => {
      try {
        await organization.createOrganization(data, session.user?.language);
        setView(null);
      } catch (err: any) {
        if (err.message.includes("Invalid Domains")) {
          setView(null);
          alert(
            `${t("screens.organization.createErrorInvalidDomains")}${
              err.message.split(":")[1]
            }. ${t("screens.organization.createErrorInvalidDomains2")}`
          );
        } else {
          alert(
            `${JSON.stringify(err)} ${t("screens.organization.createError")}`
          );
        }
      }
    },
    [organization, session.user?.language, t]
  );

  return Boolean(view) ? (
    <Modal
      isOpen={Boolean(view)}
      onClose={() => null}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      {view === OnboardingViews.CONDITIONS_FORM && session.user ? (
        <ConditionsForm
          onSubmit={handleConditions}
          user={session.user}
          isSubmitting={session.isSubmitting}
        />
      ) : view === OnboardingViews.ORGANIZATION_FORM ? (
        <OrganizationModal
          onSubmit={handleSubmitOrganization}
          isSubmitting={organization.isSubmitting}
        />
      ) : null}
    </Modal>
  ) : null;
});
