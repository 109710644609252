import React from "react";

import * as Hi from "react-icons/hi";

type IconProps = {
  iconName: string;
  size?: string;
  color?: string;
  extraProps?: any;
};

export const Icon: React.FC<IconProps> = ({ iconName, size, color, extraProps }) => {
  let iconComponent = React.createElement(Hi[iconName]);
  const iconSize =
    size === "xsmall"
      ? "0.65rem" :
      size === "2xsmall"
        ? "1rem"
        : size === "small2"
          ? "20px"
          : size === "small"
            ? "1rem"
            : size === "large"
              ? "2.5rem"
              : size === "xlarge"
                ? "3rem"
                : size === "xxlarge"
                  ? "3.8rem"
                  : "1.4rem";

  return (
    <div style={{ fontSize: iconSize, color: color }} {...extraProps}>{iconComponent}</div>
  );
};
