import { Checkbox } from "@chakra-ui/checkbox";
import { Heading, Stack } from "@chakra-ui/layout";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton } from "../../components/Button";
import { Column } from "../../components/Column";
import { Icon } from "../../components/Icon";
import { IntegrationIcon } from "../../components/IntegrationIcon";
import integrations from "../../config/integrations";
import api from "../../services/api";
import { ColumnHeader } from "./styled";
import { Draggable } from "../../libs/@fullcalendar/interaction";
import { ExternalEvent } from "../../components/ExternalEvent";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";

export const PaymoView: React.FC = observer(() => {
  const SORUCE = "paymo";

  const [projects, setProjects] = useState<any[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);

  const filteredTask = useMemo(() => {
    if (!activeFilter) {
      return tasks;
    } else if (activeFilter === "today") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return tasks.filter((task) => {
        const taskDueDate = task.due ? new Date(task.due.date) : null;
        if (taskDueDate) {
          taskDueDate.setHours(0, 0, 0, 0);
        }
        return taskDueDate ? taskDueDate.getTime() === today.getTime() : false;
      });
    } else {
      return tasks.filter((task) => task.projectId === activeFilter);
    }
  }, [tasks, activeFilter]);

  useEffect(() => {
    fetchData();

    let draggableEl = document.getElementById("external-events");
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("data-title");
          let eventSourceId = eventEl.getAttribute("data-source-id");
          let source = eventEl.getAttribute("data-source");
          return {
            title,
            eventSourceId,
            source,
          };
        },
      });
    }
  }, []);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    const paymoData = await api.getPaymoTasks();
    setProjects(
      paymoData.projects.map((paymoProject) => ({
        id: paymoProject.id,
        name: paymoProject.name,
      }))
    );

    setTasks(
      paymoData.tasks.map((paymoTask) => ({
        id: paymoTask.id,
        title: paymoTask.name,
        elapsedTime: 0,
        due: null,
        projectId: paymoTask.project_id,
        description: paymoTask.description,
        icon: <IntegrationIcon service={SORUCE} />,
        //extendedProps: event.extendedProps || {},
      }))
    );
    setIsFetching(false);
  }, [setProjects, setTasks, setIsFetching]);

  const handleToggleFilter = useCallback(
    (key, checked) => {
      setActiveFilter(checked ? key : null);
    },
    [setActiveFilter]
  );

  return (
    <Column>
      <ColumnHeader>
        <Heading as={"h4"} size={"md"} fontWeight={500}>
          {integrations[SORUCE].title}
        </Heading>
        <div>
          <Popover>
            <PopoverTrigger>
              <IconButton style={{ marginLeft: "1rem" }}>
                <Icon iconName={"HiOutlineFilter"} />
              </IconButton>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack>
                  {projects.map((project) => (
                    <div>
                      <Checkbox
                        isChecked={activeFilter === project.id}
                        onChange={(event) =>
                          handleToggleFilter(project.id, event.target.checked)
                        }
                      >
                        {project.name}
                      </Checkbox>
                    </div>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <IconButton onClick={fetchData}>
            <Icon iconName={"HiRefresh"} />
          </IconButton>
        </div>
      </ColumnHeader>
      {isFetching && <div>Loading...</div>}

      <div
        id={"external-events"}
        style={{
          height: "100%",
          flex: "1 1 auto",
          position: "relative",
          overflowY: "auto",
          marginLeft: "0.2rem",
          overflowX: "hidden",
        }}
      >
        {filteredTask.map((task) => (
          <ExternalEvent
            className={"fc-event"}
            title={task.title}
            sourceId={task.id}
            key={task.id}
            source={SORUCE}
            description={task.description}
            icon={task.icon}
          >
            {task.title}
          </ExternalEvent>
        ))}
      </div>
    </Column>
  );
});
