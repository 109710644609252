import { Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TimeRadialChart } from "../../components/TimeRadialChart";
import { useStores } from "../../hooks/useStores";
import { useUserInsights } from "../../hooks/useUserInsights";
import { useUserProjects } from "../../hooks/useUserProjects";
import { useTaskCategories } from "../../hooks/useTaskCategories";
import { TimeBlockingNotAssigned } from "./TimeBlockingNotAssigned";
import {
  getUserBillableHoursHint,
  getUserWorkLoggetHint,
  toUserBillableHoursChartData,
  toUserWorkLoggedChartData,
} from "./utils";

type Props = {
  period: {
    fromDate: Date;
    toDate: Date;
  };
};

export const UserDashboard: React.FC<Props> = observer(({ period }) => {
  const { t } = useTranslation();
  const { session } = useStores();

  const {
    data: userReportData,
    refetch: refetchUserInsights,
  } = useUserInsights(
    session.user?.organization?.uid || "",
    session.user?.uid || "",
    period.fromDate,
    period.toDate
  );

  useEffect(() => {
    refetchUserInsights();
  }, [period, refetchUserInsights]);

  const {
    isLoading: isFetchingUserProjects,
    data: userProjects = [],
  } = useUserProjects(
    session.user?.organization?.uid || "",
    session.user?.uid || ""
  );

  const {
    isLoading: isFetchingTaskCategories,
    data: taskCategories = [],
  } = useTaskCategories(session.user?.organization?.uid || "");

  const workLoggedHint = useMemo(() => {
    return userReportData ? getUserWorkLoggetHint(userReportData) : null;
  }, [userReportData]);

  const workLogged = useMemo(() => {
    return userReportData ? toUserWorkLoggedChartData(userReportData) : [];
  }, [userReportData]);

  const billableHours = useMemo(() => {
    return userReportData ? toUserBillableHoursChartData(userReportData) : [];
  }, [userReportData]);

  const billableHoursHint = useMemo(() => {
    return userReportData ? getUserBillableHoursHint(userReportData) : null;
  }, [userReportData]);

  return (
    <Stack gridGap="1rem">
      <HStack>
        <HStack backgroundColor="#fff" borderRadius="0.5rem" padding="1rem">
          <TimeRadialChart
            data={workLogged}
            defaultHint={workLoggedHint}
            width={100}
            height={100}
            radius={48}
            innerRadius={36}
            colorType="literal"
            padAngle={0.04}
            animation
            enableOnValueMouseOver
          />
          <Text>{t<string>("screens.dashboard.totalWorkLogged")}</Text>
        </HStack>

        <HStack backgroundColor="#fff" borderRadius="0.5rem" padding="1rem">
          <TimeRadialChart
            data={billableHours}
            defaultHint={billableHoursHint}
            width={100}
            height={100}
            radius={48}
            innerRadius={36}
            colorType="literal"
            padAngle={0.04}
            animation
          />
          <Text>{t<string>("screens.dashboard.totalBillableHours")}</Text>
        </HStack>
      </HStack>
      <Stack>
        <Heading as="h5" size="sm" marginBottom="0.5rem">
          {t<string>("screens.dashboard.timeEntryNotAssigned")}
        </Heading>
        <TimeBlockingNotAssigned
          taskCategories={taskCategories}
          isFetchingTaskCategories={isFetchingTaskCategories}
          userProjects={userProjects}
          isFetchingUserProjects={isFetchingUserProjects}
        />
      </Stack>
    </Stack>
  );
});
