import { makeApiRequest } from ".";
import { Organization, User, UserContract } from "../../types";
import { toUserContract } from "../utils";

export const fetchUserContracts = async (
  organizationId: Organization["uid"],
  userId: User["uid"]
): Promise<UserContract[]> => {
  const response = await makeApiRequest(
    "GET",
    `organization/${organizationId}/user/${userId}/contract`
  );

  return response.map((item: any) => toUserContract(item));
};

export const addUserContract = async (
  organizationId: Organization["uid"],
  userId: User["uid"],
  data: Partial<UserContract>
): Promise<UserContract> => {
  const payload = {
    contract_type: data.contractType,
    contractual_hours: data.contractualHours,
    contractual_hours_by_day: data.contractualHoursByDay,
    from_date: data.fromDate,
    hourly_cost: data.hourlyCost,
    productivity_target: data.productivityTarget,
    to_date: data.toDate,
  };

  const response = await makeApiRequest(
    "POST",
    `organization/${organizationId}/user/${userId}/contract`,
    payload
  );

  return toUserContract(response);
};

export const updateUserContract = async (
  organizationId: Organization["uid"],
  userId: User["uid"],
  contractId: UserContract["uid"],
  data: Partial<UserContract>
): Promise<UserContract> => {
  const payload = {
    contract_type: data.contractType,
    contractual_hours: data.contractualHours,
    contractual_hours_by_day: data.contractualHoursByDay,
    from_date: data.fromDate,
    hourly_cost: data.hourlyCost,
    productivity_target: data.productivityTarget,
    to_date: data.toDate,
  };

  const response = await makeApiRequest(
    "PATCH",
    `organization/${organizationId}/user/${userId}/contract/${contractId}`,
    payload
  );

  return toUserContract(response);
};

export const deleteUserContract = async (
  organizationId: Organization["uid"],
  userId: User["uid"],
  contractId: UserContract["uid"]
): Promise<UserContract> => {
  const response = await makeApiRequest(
    "DELETE",
    `organization/${organizationId}/user/${userId}/contract/${contractId}`
  );

  return toUserContract(response);
};
