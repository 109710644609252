import { Input } from "@chakra-ui/input";
import { Link, Text } from "@chakra-ui/layout";
import { Field, Formik, Form, FormikHelpers } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalFooterForm } from "./ModalFooterForm";

type PaymoAPIKeyFormProps = {
  onCancel: () => any;
  onSubmit: (data: PaymoAPIKeyFormValues) => any;
};

export type PaymoAPIKeyFormValues = {
  apiKey: string;
};

export const PaymoAPIKeyForm: React.FC<PaymoAPIKeyFormProps> = ({
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        apiKey: "",
      }}
      onSubmit={(
        values: PaymoAPIKeyFormValues,
        { setSubmitting }: FormikHelpers<PaymoAPIKeyFormValues>
      ) => {
        setSubmitting(false);

        onSubmit(values);
      }}
    >
      <>
        <Text>
          {t<string>("screens.integrations.tips.paymo")}
          <Link
            href={"https://app.paymoapp.com/#Paymo.module.myaccount/"}
            isExternal
          >
            MyAccount
          </Link>
        </Text>

        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor={"apiKey"}>{"API Key"}</label>
          <Field id={"apiKey"} name={"apiKey"}>
            {({ field, form: { setFieldValue }, meta }) => (
              <div>
                <Input
                  name={field.name}
                  value={field.value}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                  }}
                />
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </div>
            )}
          </Field>

          <ModalFooterForm onCancel={onCancel} />
        </Form>
      </>
    </Formik>
  );
};

type JiraAPIKeyFormProps = {
  onCancel: () => any;
  onSubmit: (data: JiraAPIKeyFormValues) => any;
};

export type JiraAPIKeyFormValues = {
  email: string;
  apiKey: string;
  domain: string;
};

export const JiraAPIKeyForm: React.FC<JiraAPIKeyFormProps> = ({
  onCancel,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        email: "",
        apiKey: "",
        domain: "",
      }}
      onSubmit={(
        values: JiraAPIKeyFormValues,
        { setSubmitting }: FormikHelpers<JiraAPIKeyFormValues>
      ) => {
        setSubmitting(false);

        onSubmit(values);
      }}
    >
      <>
        <Text>
          Per abilitare l'integrazione con Jira, genera l'API Key dal tuo{" "}
          <Link href={"https://id.atlassian.com/manage/api-tokens"} isExternal>
            Account Atlassian
          </Link>
        </Text>
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor={"email"}>{"Atlassian account email"}</label>
          <Field id={"email"} name={"email"}>
            {({ field, form: { setFieldValue }, meta }) => (
              <div>
                <Input
                  name={field.name}
                  value={field.value}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                  }}
                />
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </div>
            )}
          </Field>

          <label htmlFor={"domain"}>{"Atlassian domain"}</label>
          <Field id={"domain"} name={"domain"}>
            {({ field, form: { setFieldValue }, meta }) => (
              <div>
                <Input
                  name={field.name}
                  value={field.value}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                  }}
                />
                <div>https://YOUR-DOMAIN.atlassian.net</div>
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </div>
            )}
          </Field>

          <label htmlFor={"apiKey"}>{"API Key"}</label>
          <Field id={"apiKey"} name={"apiKey"}>
            {({ field, form: { setFieldValue }, meta }) => (
              <div>
                <Input
                  name={field.name}
                  value={field.value}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                  }}
                />
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </div>
            )}
          </Field>

          <ModalFooterForm onCancel={onCancel} />
        </Form>
      </>
    </Formik>
  );
};
