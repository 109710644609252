import { observer } from "mobx-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Stack,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  Button,
  VStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useStores } from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { colors } from "../../theme";
import LogoImg from "../../assets/icons/timetrap-logo.svg";
import { Icon } from "../../components/Icon";
import { ReactComponent as TeamPlanIconSVG } from "../../assets/icons/team-plan.svg";
import { ReactComponent as TaskIcon } from "../../assets/icons/task.svg";
import { IntegrationIcon } from "../../components/IntegrationIcon";
import integrations from "../../config/integrations";
import { GoogleLogout, useGoogleLogout } from "react-google-login";
import keys from "../../config/keys";
import { ChangelogModal } from "../../components/ChangelogModal/ChangelogModal";
import { useCookies } from "react-cookie";
import { AbilityContext } from "../../components/Can";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { MdTaskAlt } from "react-icons/md";
import { IconType } from "react-icons";
import LogoutModal from "../../components/LogoutModal";
const { version } = require("../../../package.json");

type Props = {};

// const navStrucutre = {
//     planning: {

//     }
// }

export enum MenuItemKeys {
  board,
  tasks,
  projects,
  dashboard,
  integrations,
  organization,
  organizationDetail,
  customers,
  users,
  roles,
  timesheet,
  subscription,
  workflows,
  webhooks,
  teamplan,
  teamplan2,
  teamplan3,
  overheads,
}
// const TeamPlanIcon = () => (React.createElement(TeamPlanIconSVG, {
//     style: { width: 25, height: 25 }
// }))
export const SiderBarBeta: React.FC<Props> = observer(() => {
  const { t } = useTranslation();
  const { session, ui, organization } = useStores();
  const navigate = useNavigate();
  const ability: any = useContext(AbilityContext);

  const {
    isOpen: isOpenLoagoutModal,
    onOpen: onOpenLogoutModal,
    onClose: onCloseSLogoutModal
  } = useDisclosure();

  const handleNavigateTo = useCallback(
    (key) => {
      ui.setActiveScreen(key);
      ui.setIsSidebarCollapsed(false);
      ui.setIsSecondarySidebarVisible(false);
      ui.setDataSource(null);
      organization.toggleRightSideTimeGrapView(false);

      switch (key) {
        case MenuItemKeys.board:
          navigate(`/user/${session.user?.uid}/board`);
          break;
        case MenuItemKeys.tasks:
          navigate(`/user/${session.user?.uid}/board`);
          break;
        case MenuItemKeys.projects:
          navigate(
            `/user/${session.user?.uid}/projects?organizationId=${session.user?.organization?.uid}`
          );
          break;
        case MenuItemKeys.organization:
          ui.setIsSidebarCollapsed(false);
          navigate(`/organization/${session.user?.organization?.uid}`);

          break;
        case MenuItemKeys.dashboard:
          navigate(`/user/${session.user?.uid}/dashboard`);
          break;
        case MenuItemKeys.integrations:
          navigate(`/user/${session.user?.uid}/integrations`);
          break;
        case MenuItemKeys.organizationDetail:
          navigate(`/organization/${session.user?.organization?.uid}`);
          break;
        case MenuItemKeys.customers:
          navigate(
            `/organization/${session.user?.organization?.uid}/customers`
          );
          break;
        case MenuItemKeys.workflows:
          navigate(
            `/organization/${session.user?.organization?.uid}/workflows`
          );
          break;
        case MenuItemKeys.subscription:
          navigate(
            `/organization/${session.user?.organization?.uid}/subscription`
          );
          break;
        case MenuItemKeys.webhooks:
          navigate(`/organization/${session.user?.organization?.uid}/webhooks`);
          break;
        case MenuItemKeys.roles:
          navigate(`/organization/${session.user?.organization?.uid}/roles`);
          break;
        case MenuItemKeys.users:
          navigate(`/organization/${session.user?.organization?.uid}/users`);
          break;
        case MenuItemKeys.overheads:
          navigate(
            `/organization/${session.user?.organization?.uid}/overheads`
          );
          break;
        case MenuItemKeys.timesheet:
          navigate(
            `/organization/${session.user?.organization?.uid}/reports/timesheet`
          );
          break;
        case MenuItemKeys.teamplan:
          navigate(`/user/${session.user?.uid}/teamplan`);
          break;
        case MenuItemKeys.teamplan2:
          navigate(`/user/${session.user?.uid}/teamplan2`);
          break;
        case MenuItemKeys.teamplan3:
          navigate(`/user/${session.user?.uid}/teamplan3`);
          break;
      }
    },
    [navigate, session, ui]
  );

  const handleOpenSourcedata = useCallback(
    (sourcedata) => {
      ui.setIsSidebarCollapsed(false);
      ui.setIsSecondarySidebarVisible(true);
      ui.setDataSource(sourcedata);
      navigate(`/user/${session.user?.uid}/board`);
    },
    [ui]
  );
  const colorsTheme = colors(false);

  return (
    <>
      <SidebarContainer>
        <section
          style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          <BrandContainer>
            <BrandIcon>
              <img src={LogoImg} alt={"TimeTrap"} />
            </BrandIcon>
          </BrandContainer>
          <NavBarContainer>
            {/* @ts-ignore */}
            <VStack spacing={"10px"} align="stretch">
              <NavBarHeadingText>{t<string>("nav.planning")}</NavBarHeadingText>
              <Stack>
                <NavItemButton
                  onClick={() => handleNavigateTo(MenuItemKeys.board)}
                >
                  {/* <TaskIcon style={{ width: 18, height: 18, }} className="svg-task" /> */}
                  {/* <Icon iconName={"HiOutlineTemplate"} size="small2" /> */}
                  {t<string>("nav.board")}
                </NavItemButton>
                <NavItemButton
                  onClick={() => {
                    if (ui.activeScreen !== MenuItemKeys.tasks) {
                      handleNavigateTo(MenuItemKeys.tasks);
                    }
                    handleOpenSourcedata("tasks");
                  }}
                >
                  {/* <TaskIcon style={{ width: 18, height: 18, }} className="svg-task" /> */}
                  {/* <Icon2 iconName={MdTaskAlt} size="small2" /> */}
                  {t<string>("nav.tasks")}
                </NavItemButton>
                <NavItemButton
                  onClick={() => handleNavigateTo(MenuItemKeys.teamplan3)}
                >
                  {/* <Icon2 iconName={HiOutlineUserGroup} size="small2" /> */}
                  {t<string>("nav.teamplan")}
                </NavItemButton>
              </Stack>

              <NavBarHeadingText>
                {t<string>("nav.workspace")}
              </NavBarHeadingText>
              <Stack>
                <NavItemButton
                  onClick={() => handleNavigateTo(MenuItemKeys.dashboard)}
                >
                  {/* <Icon iconName={"HiOutlineChartBar"} size="small2" /> */}
                  {t<string>("nav.dashboard")}
                </NavItemButton>
                {ability.can("read", "project") && (
                  <NavItemButton
                    onClick={() => handleNavigateTo(MenuItemKeys.projects)}
                  >
                    {/* <Icon iconName={"HiOutlineLightningBolt"} size="small2" /> */}
                    {t<string>("nav.projects")}
                  </NavItemButton>
                )}
                {(ability.can("read", "project") ||
                  ability.can("read", "organization") ||
                  ability.can("read", "user") ||
                  ability.can("read", "role") ||
                  ability.can("read", "timesheet")) && (
                    <Accordion allowToggle>
                      <AccordionItem id="organiaztion-menu" borderStyle={"none"}>
                        <AccordionButton
                          _hover={{
                            backgroundColor: "#E6E6E6",
                            color: colorsTheme.black,
                            borderRadius: "5px",
                          }}
                          _focus={{
                            backgroundColor: "#319795",
                            color: colorsTheme.white,
                            borderRadius: "5px",
                          }}
                          gap={2}
                          paddingLeft={"10px"}
                        >
                          {/* <Icon
                        iconName={"HiOutlineOfficeBuilding"}
                        size="small2"
                      /> */}
                          <Box as="span" flex="1" textAlign="left">
                            {t<string>("nav.organization")}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel paddingLeft={0}>
                          {ability.can("edit", "organization") && (
                            <NavItemButton
                              onClick={() =>
                                handleNavigateTo(MenuItemKeys.organizationDetail)
                              }
                              extraPadding="20px"
                            >
                              {t<string>(
                                "screens.organization.nav.organizationDetail"
                              )}
                            </NavItemButton>
                          )}
                          {ability.can("read", "organization") && (
                            <NavItemButton
                              onClick={() =>
                                handleNavigateTo(MenuItemKeys.customers)
                              }
                              extraPadding="20px"
                            >
                              {t<string>("screens.customers.titleList")}
                            </NavItemButton>
                          )}
                          <Accordion allowToggle>
                            {ability.can("read", "project") && (
                              <AccordionItem id="orgenization-menu-project">
                                <AccordionButton
                                  _hover={{
                                    backgroundColor: "#E6E6E6",
                                    color: colorsTheme.black,
                                    borderRadius: "5px",
                                  }}
                                  _focus={{
                                    backgroundColor: "#319795",
                                    color: colorsTheme.white,
                                    borderRadius: "5px",
                                  }}
                                  paddingLeft={"20px"}
                                >
                                  <Box as="span" flex="1" textAlign="left">
                                    {t<string>(
                                      "screens.organization.nav.projectSetup"
                                    )}
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>

                                <AccordionPanel paddingLeft={0}>
                                  <NavItemButton
                                    onClick={() =>
                                      handleNavigateTo(MenuItemKeys.workflows)
                                    }
                                    extraPadding="30px"
                                  >
                                    {t<string>("screens.workflows.title")}
                                  </NavItemButton>
                                </AccordionPanel>
                              </AccordionItem>
                            )}
                            {ability.can("read", "user") && (
                              <AccordionItem id="orgenization-menu-user">
                                <AccordionButton
                                  _hover={{
                                    backgroundColor: "#E6E6E6",
                                    color: colorsTheme.black,
                                    borderRadius: "5px",
                                  }}
                                  _focus={{
                                    backgroundColor: "#319795",
                                    color: colorsTheme.white,
                                    borderRadius: "5px",
                                  }}
                                  paddingLeft={"20px"}
                                >
                                  <Box as="span" flex="1" textAlign="left">
                                    {t<string>(
                                      "screens.organization.nav.userManagement"
                                    )}
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>

                                <AccordionPanel paddingLeft={0}>
                                  <NavItemButton
                                    onClick={() =>
                                      handleNavigateTo(MenuItemKeys.roles)
                                    }
                                    extraPadding="30px"
                                  >
                                    {t<string>("screens.roles.titleList")}
                                  </NavItemButton>
                                  <NavItemButton
                                    onClick={() =>
                                      handleNavigateTo(MenuItemKeys.users)
                                    }
                                    extraPadding="30px"
                                  >
                                    {t<string>("nav.list")}
                                  </NavItemButton>
                                  <NavItemButton
                                    onClick={() =>
                                      handleNavigateTo(MenuItemKeys.overheads)
                                    }
                                    extraPadding="30px"
                                  >
                                    {t<string>("screens.overheads.titleList")}
                                  </NavItemButton>
                                  <NavItemButton
                                    onClick={() =>
                                      handleNavigateTo(MenuItemKeys.timesheet)
                                    }
                                    extraPadding="30px"
                                  >
                                    {t<string>("screens.timesheet.title")}
                                  </NavItemButton>
                                </AccordionPanel>
                              </AccordionItem>
                            )}

                            {ability.can("edit", "organization") && (
                              <NavItemButton
                                onClick={() =>
                                  handleNavigateTo(MenuItemKeys.subscription)
                                }
                                extraPadding="20px"
                              >
                                {t<string>(
                                  "screens.organization.nav.subscription"
                                )}
                              </NavItemButton>
                            )}

                            {ability.can("edit", "organization") && (
                              <AccordionItem id="orgenization-menu-advances">
                                <AccordionButton
                                  _hover={{
                                    backgroundColor: "#E6E6E6",
                                    color: colorsTheme.black,
                                    borderRadius: "5px",
                                  }}
                                  _focus={{
                                    backgroundColor: "#319795",
                                    color: colorsTheme.white,
                                    borderRadius: "5px",
                                  }}
                                  paddingLeft={"20px"}
                                >
                                  <Box as="span" flex="1" textAlign="left">
                                    {t<string>(
                                      "screens.organization.nav.advanced"
                                    )}
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel paddingLeft={0}>
                                  <NavItemButton
                                    onClick={() =>
                                      handleNavigateTo(MenuItemKeys.webhooks)
                                    }
                                    extraPadding="30px"
                                  >
                                    {t<string>("screens.webhooks.title")}
                                  </NavItemButton>
                                </AccordionPanel>
                              </AccordionItem>
                            )}
                          </Accordion>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  )}
              </Stack>

              <NavBarHeadingText>
                {t<string>("nav.integrations")}
              </NavBarHeadingText>
              <Stack>
                <NavItemButton
                  onClick={() => handleNavigateTo(MenuItemKeys.integrations)}
                // extraPadding="10px"
                >
                  {/* <Icon iconName={"HiSwitchHorizontal"} size="small2" /> */}
                  {t<string>("nav.integrations")}
                </NavItemButton>
                {session.accessToken && (
                  <NavItemButton
                    onClick={() => handleOpenSourcedata("gcalendar")}
                  // extraPadding="10px"
                  >
                    <IntegrationIcon service={"gcalendar"} />
                    {"Google Calendar"}
                  </NavItemButton>
                )}
                {session.user?.integrations.map((integration, ind) => (
                  <NavItemButton
                    onClick={() => handleOpenSourcedata(integration.service)}
                  // extraPadding="10px"
                  >
                    <IntegrationIcon service={integration.service} />
                    {integrations[integration.service].title}
                  </NavItemButton>
                ))}
              </Stack>
            </VStack>
          </NavBarContainer>
        </section>
        {/* @ts-ignore */}
        <VStack pb={"10px"} pl={"10px"} alignItems={"baseline"}>
          <HStack justifyContent={"center"} gap={"5px"}>
            <Avatar
              src={session.user?.imageUrl}
              // @ts-ignore
              values={session.user?.nominative.split(" ") || []}
              alt={session.user?.nominative}
              size={"sm"}
              _hover={{
                cursor: "pointer",
              }}
            />
            <Box as="section" display={"flex"} flexDirection={"column"}>
              <Text
                onClick={() => {
                  navigate(`/user/${session.user?.uid}/profile`);
                }}
                fontWeight={"600"}
                fontSize={"14px"}
              >
                {session.user?.nominative}
              </Text>
              <Text textDecoration={"underline"}
                onClick={onOpenLogoutModal}
              >
                {t<string>("screens.board.logoutButton")}
              </Text>
            </Box>
          </HStack>
          <HStack alignSelf={"flex-end"} pr="10px">
            <a
              target={"_blank"}
              href={keys.CHANGELOG_URL}
              rel={"noopener noreferrer"}
              title={"TimeTrap Knowledgebase"}
              style={{ color: "#878787" }}
            >
              v{version}
            </a>
            <a
              target={"_blank"}
              href={keys.KNOWLEDGEBASE_URL}
              rel={"noopener noreferrer"}
              title={"TimeTrap Knowledgebase"}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4982 5.27665 12.8128 3.62441 11.5942 2.40582C10.3756 1.18722 8.72335 0.50182 7 0.5ZM7 12.5C5.91221 12.5 4.84884 12.1774 3.94437 11.5731C3.0399 10.9687 2.33495 10.1098 1.91867 9.10476C1.50238 8.09977 1.39347 6.9939 1.60568 5.927C1.8179 4.86011 2.34173 3.8801 3.11092 3.11091C3.8801 2.34172 4.86011 1.8179 5.92701 1.60568C6.9939 1.39346 8.09977 1.50238 9.10476 1.91866C10.1098 2.33494 10.9687 3.03989 11.5731 3.94436C12.1774 4.84883 12.5 5.9122 12.5 7C12.4983 8.45818 11.9184 9.85617 10.8873 10.8873C9.85617 11.9184 8.45819 12.4983 7 12.5ZM8 10C8 10.1326 7.94732 10.2598 7.85356 10.3536C7.75979 10.4473 7.63261 10.5 7.5 10.5C7.23479 10.5 6.98043 10.3946 6.7929 10.2071C6.60536 10.0196 6.5 9.76522 6.5 9.5V7C6.36739 7 6.24022 6.94732 6.14645 6.85355C6.05268 6.75979 6 6.63261 6 6.5C6 6.36739 6.05268 6.24021 6.14645 6.14645C6.24022 6.05268 6.36739 6 6.5 6C6.76522 6 7.01957 6.10536 7.20711 6.29289C7.39465 6.48043 7.5 6.73478 7.5 7V9.5C7.63261 9.5 7.75979 9.55268 7.85356 9.64645C7.94732 9.74021 8 9.86739 8 10ZM6 4.25C6 4.10166 6.04399 3.95666 6.1264 3.83332C6.20881 3.70999 6.32595 3.61386 6.46299 3.55709C6.60003 3.50032 6.75083 3.48547 6.89632 3.51441C7.04181 3.54335 7.17544 3.61478 7.28033 3.71967C7.38522 3.82456 7.45665 3.9582 7.48559 4.10368C7.51453 4.24917 7.49968 4.39997 7.44291 4.53701C7.38615 4.67406 7.29002 4.79119 7.16668 4.8736C7.04334 4.95601 6.89834 5 6.75 5C6.55109 5 6.36032 4.92098 6.21967 4.78033C6.07902 4.63968 6 4.44891 6 4.25Z"
                  fill="#878787"
                />
              </svg>
            </a>
          </HStack>
        </VStack>
      </SidebarContainer>
      {isOpenLoagoutModal && <LogoutModal isOpen={isOpenLoagoutModal} onClose={onCloseSLogoutModal} t={t} />}
    </>
  );
});

const SidebarContainer = styled.aside`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  // background: ${(props) => props.theme.bg1};
  background: #fcfcfc;
  width: 200px;
  padding-top: 1rem;

  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 3px;
  }

  &:hover {
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

const BrandContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease;
  padding-top: 1rem;

  &:hover {
    opacity: 0.75;
  }
`;

const BrandIcon = styled.div`
  display: block;
  width: 52px;
  height: 52px;

  img {
    width: 100%;
  }
`;

const NavItemButton = styled.button<{ extraPadding?: string | undefined }>`
  height: 40px;
  width: 100%;
  font-size: 16px;
  font-wight: 400;
  text-align: left;
  color: #101010;
  padding-left: ${({ extraPadding }) => (extraPadding ? extraPadding : "10px")};
  display: flex;
  gap: 5px;
  align-items: center;
  &:hover {
    background: #e6e6e6;
    border-radius: 5px;
  }
  &:focus {
    background: #319795;
    color: white;
    border-radius: 5px;
  }
`;

const FooterView = styled.section`
  height: 80px;
  width: 100%;
`;

const NavBarContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const NavBarHeadingText = styled.h6`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  text-transform: uppercase;
`;

type IconProps = {
  iconName: IconType;
  size?: string;
  color?: string;
  extraProps?: any;
};

const Icon2: React.FC<IconProps> = ({ iconName, size, color, extraProps }) => {
  let iconComponent = React.createElement(iconName);
  const iconSize =
    size === "xsmall"
      ? "0.65rem"
      : size === "small2"
        ? "20px"
        : size === "small"
          ? "1rem"
          : size === "large"
            ? "2.5rem"
            : size === "xlarge"
              ? "3rem"
              : size === "xxlarge"
                ? "3.8rem"
                : "1.4rem";

  return (
    <div style={{ fontSize: iconSize, color: color }} {...extraProps}>
      {iconComponent}
    </div>
  );
};
