import { useDisclosure } from "@chakra-ui/hooks";
import {
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { Portal } from "@chakra-ui/portal";
import React, { useCallback, useState } from "react";
import {
  AutoSizer,
  Column,
  SortDirectionType,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import { IconButton } from "../Button";
import { FilterIndicator } from "./FilterIndicator";
import SortIndicator from "./SortIndicator";
import { Td, Th } from "./styled";

type ColumnData = {
  dataKey: string;
  label: string;
  numeric?: boolean;
  width: number;
  render?: (value: any, row: any, dataKey: string) => any;
  flexGrow?: number;
  sorter?: any;
  filterDropdown?: () => React.ReactNode;
};
type Row = {
  index: number;
};

type VirtualizedTableProps = {
  columns: ColumnData[];
  headerHeight?: number;
  rowHeight?: number;
  sortBy?: string;
  sortDirection?: SortDirectionType;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => any;
  onHeaderCellClick?: (dataKey: string) => void;
  cellDataGetter?: ({
    columnData,
    dataKey,
    rowData,
  }: {
    columnData: any;
    dataKey: string;
    rowData: any;
  }) => any;
  activeFilters?: { [dataKey: string]: string | string[] };
  rowStyle?: (row: Row) => React.CSSProperties;
  headerStyle?: React.CSSProperties;
};

export const VirtualizedTable: React.FC<VirtualizedTableProps> = ({
  columns,
  headerHeight = 48,
  rowHeight = 48,
  cellDataGetter,
  sortBy,
  sortDirection,
  onHeaderCellClick,
  activeFilters,
  headerStyle = {},
  ...tableProps
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  const handleSelectFilter = useCallback(
    (dataKey: string) => setSelectedFilter(dataKey),
    [setSelectedFilter]
  );
  const handleCloseFilter = useCallback(() => setSelectedFilter(null), [
    setSelectedFilter,
  ]);

  const headerRenderer = ({
    label,
    columnIndex,
    dataKey,
    sortBy,
    sortDirection,
    filterDropdown,
    onHeaderCellClick,
  }: TableHeaderProps & {
    columnIndex: number;
    filterDropdown?: () => React.ReactNode;
    onHeaderCellClick?: (dataKey: string) => void;
  }) => {
    return (
      <Th style={{
        display: "flex",
        justifyContent: "flex-start",
      }}>
        <span onClick={() => onHeaderCellClick && onHeaderCellClick(dataKey)} style={{ fontSize: '0.75rem', color: "#718096", fontWeight: 'bold' }}>
          {label}
          {sortBy === dataKey && (
            <SortIndicator sortDirection={sortDirection} />
          )}
        </span>
        {filterDropdown && (
          <Popover
            isOpen={selectedFilter === dataKey}
            onOpen={() => handleSelectFilter(dataKey)}
            onClose={handleCloseFilter}
            placement={"bottom"}
            closeOnBlur
          >
            <PopoverTrigger>
              <IconButton>
                <FilterIndicator
                  isActive={activeFilters && Boolean(activeFilters[dataKey])}
                />
              </IconButton>
            </PopoverTrigger>
            <Portal>
              <PopoverContent p={0}>
                <PopoverArrow />
                {/* <PopoverCloseButton /> */}
                {filterDropdown()}
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </Th>
    );
  };

  const cellRenderer: TableCellRenderer = ({
    cellData,
    columnIndex,
    rowData,
    dataKey,
    columnData,
  }) => {
    return (
      <Td
        style={{ height: rowHeight }}
      // align={
      //   (columnIndex != null && columns[columnIndex].numeric) || false
      //     ? "right"
      //     : "left"
      // }
      >
        {columnIndex != null && columns[columnIndex].render
          ? // @ts-ignore
          columns[columnIndex].render(cellData, rowData, dataKey)
          : cellData}
      </Td>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          height={height}
          width={width}
          rowHeight={rowHeight!}
          gridStyle={{
            direction: "inherit",
          }}
          headerHeight={headerHeight!}
          sortBy={sortBy}
          sortDirection={sortDirection}
          headerStyle={headerStyle}
          //className={classes.table}
          {...tableProps}
        //rowClassName={this.getRowClassName}
        >
          {columns.map(
            ({ dataKey, sorter, filterDropdown, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                      dataKey,
                      sortBy,
                      sortDirection,
                      filterDropdown,
                      onHeaderCellClick: sorter && onHeaderCellClick,
                    })
                  }
                  //className={classes.flexContainer}
                  // @ts-ignore
                  cellDataGetter={cellDataGetter}
                  cellRenderer={cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            }
          )}
        </Table>
      )}
    </AutoSizer>
  );
};
