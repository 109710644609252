import { useTranslation } from "react-i18next";
import { UserPreferences } from "../../types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { BoardSettingsFrom } from "../BoardSettingsForm";

type Props = {
  preferences: UserPreferences | undefined;
  isVisible: boolean;
  onClose: () => void;
  onPreferecensChange: (userPreferences: UserPreferences) => void;
  hideCalnderViewControls?: boolean;
};

export const BoardSettingsModal: React.FC<Props> = ({
  isVisible,
  preferences,
  onClose,
  onPreferecensChange,
  hideCalnderViewControls = true
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isVisible} size={"xl"} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody style={{ padding: "2rem" }}>
          <Tabs variant={"soft-rounded"} colorScheme={"teal"}>
            <TabList>
              <Tab>{t<string>("screens.board.board")}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <BoardSettingsFrom
                  preferences={preferences}
                  onChange={onPreferecensChange}
                  hideCalnderViewControls={hideCalnderViewControls}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
