import { Button, IconButton } from "@chakra-ui/button";
import { Heading, HStack, Stack, Text, Flex } from "@chakra-ui/layout";
import {
  Avatar,
  FormControl,
  Switch,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { ActionGroup } from "../../components/ActionGroup";
import { Box } from "../../components/Box";
import { Icon } from "../../components/Icon";
import { PageHeader } from "../../components/PageHeader";
import { Select } from "../../components/Select";
import { UserContractModal } from "../../components/UserDetails/UserContractModal";
import { UserContractsTables } from "../../components/UserDetails/UserContractsTables";
import {
  useCreateUserContract,
  useDeleteUserContract,
  useUpdateUserContract,
  useUserContracts,
} from "../../hooks/userContracts.hooks";
import { useStores } from "../../hooks/useStores";
import { Role, UserContract } from "../../types";
import { toRoleOptions } from "../../utils/role";

export const UserDetails: React.FC = observer(() => {
  const { t } = useTranslation();
  const { organizationId, userId } = useParams();
  const {
    isOpen: isUserContractModalOpen,
    onOpen: onUserContractModalOpen,
    onClose: onUserContractModalClose,
  } = useDisclosure();
  const { organization } = useStores();
  const toast = useToast();
  const navigate = useNavigate();
  const [activeContract, setActiveContract] = useState<UserContract | null>(
    null
  );

  useEffect(() => {
    if (organizationId) {
      organization.fetchRoles(organizationId);
    }
  }, [organizationId]);

  const {
    isFetching: isFetchingUserContracts,
    data: contracts = [],
  } = useUserContracts(organizationId!, userId!);

  const {
    isLoading: isSubmittingContract,
    mutateAsync: createUserContract,
  } = useCreateUserContract(organizationId!, userId!);

  const {
    isLoading: isUpdatingContract,
    mutateAsync: updateUserContract,
  } = useUpdateUserContract(organizationId!, userId!);

  const {
    isLoading: isDeletingContract,
    mutateAsync: deleteUserContract,
  } = useDeleteUserContract(organizationId!, userId!);

  const user = useMemo(() => {
    return organization.users.find((user) => user.uid === userId);
  }, [organization.users, userId]);

  const rolesOptions = useMemo(() => {
    return toRoleOptions(organization.roles);
  }, [organization.roles]);

  const handleChangeRole = useCallback(
    async (roleId: Role["uid"]) => {
      try {
        if (organizationId && userId) {
          await organization.updateUser(organizationId, userId, roleId);

          toast({
            title: t("screens.users.actions.userUpdated"),
            status: "success",
            position: "bottom-left",
          });
        } else {
          throw new Error("Missing organization or user");
        }
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
        });
      }
    },
    [organization, organizationId, userId, toast, t]
  );

  const handleChangeState = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (userId) {
          await organization.updateUserData(userId, {
            status: event.target.checked ? "active" : "inactive",
          });

          toast({
            title: t("screens.users.actions.userUpdated"),
            status: "success",
            position: "bottom-left",
          });
        } else {
          throw new Error("Missing user");
        }
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
        });
      }
    },
    [organization, t, toast, userId]
  );

  const handleEditContract = (userContract: UserContract) => {
    setActiveContract(userContract);
    onUserContractModalOpen();
  };

  const handleSubmitContract = useCallback(
    async (userContract: Partial<UserContract>) => {
      try {
        if (userContract.uid) {
          await updateUserContract(userContract);
          toast({
            title: t("screens.userDetails.contractUpdated"),
            status: "success",
            position: "bottom-left",
          });
        } else {
          await createUserContract(userContract);

          toast({
            title: t("screens.userDetails.contractCreated"),
            status: "success",
            position: "bottom-left",
          });
        }
        onUserContractModalClose();
        setActiveContract(null);
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
        });
      }
    },
    [createUserContract, onUserContractModalClose, t, toast, updateUserContract]
  );

  const handleDismissUserContractModal = () => {
    onUserContractModalClose();
    setActiveContract(null);
  };

  const handleDeleteContract = useCallback(
    async (contractId: UserContract["uid"]) => {
      if (window.confirm(t("screens.userDetails.deleteContract"))) {
        try {
          await deleteUserContract(contractId);
          handleDismissUserContractModal();
          toast({
            title: t("screens.userDetails.contractDeleted"),
            status: "success",
            position: "bottom-left",
          });
        } catch (err) {
          console.log(err);
          toast({
            title: t("common.error"),
            status: "error",
          });
        }
      }
    },
    [deleteUserContract, handleDismissUserContractModal, t, toast]
  );

  return (
    <StyledStack w="100%" h="100%">
      <PageHeader>
        <Flex alignItems="center">
          <IconButton
            variant={"ghost"}
            onClick={() => navigate(-1)}
            icon={<Icon iconName="HiOutlineArrowLeft" />}
            aria-label="Go back"
          />
          <Heading as="h4" size="md" fontWeight="semibold">
            {t<string>("screens.userDetails.title")}
          </Heading>
        </Flex>
        <ActionGroup>
          {(isFetchingUserContracts ||
            isSubmittingContract ||
            isUpdatingContract ||
            isDeletingContract) && <Spinner />}
        </ActionGroup>
      </PageHeader>
      {user && (
        <Stack>
          <Box>
            <HStack>
              <Flex align="center" flexWrap="nowrap" flexGrow={2}>
                <Avatar
                  src={user.imageUrl}
                  borderRadius="12px"
                  me="18px"
                  size="lg"
                />
                <Flex direction="column">
                  <Text fontSize="md" fontWeight="medium" minWidth="100%">
                    {user.nominative}
                  </Text>
                  <Text fontSize="sm" color="gray.400" fontWeight="normal">
                    {user.email}
                  </Text>
                </Flex>
              </Flex>

              <Stack w="200px">
                <FormControl display="flex" alignItems="center">
                  <Text w="100%">
                    {user.status === "active"
                      ? t("screens.userDetails.activeUser")
                      : t("screens.userDetails.inactiveUser")}
                  </Text>
                  <Switch
                    id="user-status"
                    isChecked={user.status === "active"}
                    onChange={handleChangeState}
                  />
                </FormControl>

                <Select
                  options={rolesOptions}
                  value={{
                    value: user.role?.uid || "",
                    label: user.role?.name || "",
                  }}
                  isLoading={organization.isFetchingRoles}
                  onChange={(option) =>
                    option && handleChangeRole(option.value)
                  }
                  placeholder={t<string>("common.selectPlaceholder")}
                />
              </Stack>
            </HStack>
          </Box>

          <Box>
            <HStack>
              <Heading flexGrow={2} as="h5" size="sm" fontWeight="semibold">
                {t<string>("screens.userDetails.contracts")}
              </Heading>
              <Button size="sm" onClick={onUserContractModalOpen}>
                {t<string>("screens.userDetails.addContract")}
              </Button>
            </HStack>
            <UserContractsTables
              contracts={contracts}
              onEdit={handleEditContract}
            />
          </Box>
        </Stack>
      )}
      {isUserContractModalOpen && (
        <UserContractModal
          contract={activeContract}
          isVisible={isUserContractModalOpen}
          onDismiss={handleDismissUserContractModal}
          onSubmit={handleSubmitContract}
          onDelete={handleDeleteContract}
        />
      )}
    </StyledStack>
  );
});

const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
`;
