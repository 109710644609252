import { DateProfile } from "../DateProfileGenerator";
import { diffWholeDays } from "../datelib/marker";
import { createFormatter, FormatterInput } from "../datelib/formatting";
import { DateRange } from "../datelib/date-range";
import { DateEnv } from "../datelib/env";
import { BaseOptions } from "../options";
import i18Service from "../../../../services/i18n";
// Computes what the title at the top of the calendarApi should be for this view
export function buildTitle(
  dateProfile: DateProfile,
  viewOptions: BaseOptions,
  dateEnv: DateEnv
) {
  let range: DateRange;
  // for views that span a large unit of time, show the proper interval, ignoring stray days before and after
  if (/^(year|month)$/.test(dateProfile.currentRangeUnit)) {
    range = dateProfile.currentRange;
  } else {
    // for day units or smaller, use the actual day range
    // @ts-ignore
    range = dateProfile.activeRange;
  }

  // HACK ================================
  if(dateProfile.currentRangeUnit === 'week' && dateProfile.activeRange) {

    if(dateProfile.activeRange.start.getMonth() ===dateProfile.activeRange.end.getMonth() ) {
      return i18Service.t(`months.${dateProfile.activeRange.start.getMonth()}`)
    } else {
      return `${i18Service.t(`months.${dateProfile.activeRange.start.getMonth()}`)} - ${i18Service.t(`months.${dateProfile.activeRange.end.getMonth()}`)}`
    }

  }
  // HACK ================================

  return dateEnv.formatRange(
    range.start,
    range.end,
    createFormatter(viewOptions.titleFormat || buildTitleFormat(dateProfile)),
    {
      isEndExclusive: dateProfile.isRangeAllDay,
      defaultSeparator: viewOptions.titleRangeSeparator,
    }
  );
}

// Generates the format string that should be used to generate the title for the current date range.
// Attempts to compute the most appropriate format if not explicitly specified with `titleFormat`.
function buildTitleFormat(dateProfile: DateProfile): FormatterInput {
  let { currentRangeUnit } = dateProfile;

  if (currentRangeUnit === "year") {
    return { year: "numeric" };
  }

  if (currentRangeUnit === "month") {
    return { year: "numeric", month: "long" }; // like "September 2014"
  }

  let days = diffWholeDays(
    dateProfile.currentRange.start,
    dateProfile.currentRange.end
  );

  if (days !== null && days > 1) {
    // multi-day range. shorter, like "Sep 9 - 10 2014"
    return { year: "numeric", month: "short", day: "numeric" };
  }

  // one day. longer, like "September 9 2014"
  return { year: "numeric", month: "long", day: "numeric" };
}
