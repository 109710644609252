import { Button, IconButton } from "@chakra-ui/button";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { HStack, Stack, StackProps } from "@chakra-ui/layout";
import { Field, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Project from "../../models/Project";
import { Task, User } from "../../types";
import { Select } from "../Select";
import { StyledFormLabel } from "./styled";
import styled from "styled-components";
import { Icon } from "../Icon";

type Props = {
  parentId: Task["uid"];
  projectId: Project["uid"];
  users: User[];
  isFetchingUsers: boolean;
  onSubmit: (newTask: any) => void;
  onCancel: () => void;
};

export const InlineTaskForm: React.FC<Props> = ({
  parentId,
  projectId,
  users,
  isFetchingUsers,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [userId, setUserId] = useState("");

  const usersOptions = useMemo(
    () =>
      users.map((user) => ({
        label: user.nominative,
        value: user.uid,
      })),
    [users]
  );

  const getSelectedUser: any = (userId) => {
    if (!userId) return "";

    const user = users.find((user) => user.uid === userId);

    return user
      ? {
          value: user.uid,
          label: user.nominative,
        }
      : "";
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      height: "var(--chakra-sizes-10)",
      borderRadius: "var(--chakra-radii-md)",
      borderColor: "inherit",
    }),
  };

  const handleReset = useCallback(() => {
    setName("");
    setUserId("");
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit({ name, userId, projectId, parentId });
    handleReset();
  }, [onSubmit, name, userId, projectId, parentId, handleReset]);

  return (
    <StyledStack padding={5}>
      <HStack alignItems={"flex-end"}>
        <FormControl>
          <StyledFormLabel htmlFor="name">
            {t<string>("tasks.name")}
          </StyledFormLabel>
          <Input
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </FormControl>

        <FormControl>
          <StyledFormLabel htmlFor={"userId"}>
            {t<string>("tasks.user")}
          </StyledFormLabel>
          <Select
            options={usersOptions}
            value={getSelectedUser(userId)}
            isLoading={isFetchingUsers}
            onChange={(option) => setUserId(option?.value || "")}
            styles={selectStyles}
          />
        </FormControl>
        <IconButton
          aria-label="Create task"
          colorScheme="teal"
          icon={<Icon iconName={"HiCheck"} />}
          onClick={handleSubmit}
          disabled={name.length === 0 || userId.length === 0}
        />
        <IconButton
          aria-label="Cancel"
          variant="ghost"
          colorScheme="teal"
          onClick={() => {
            onCancel();
            handleReset();
          }}
          icon={<Icon iconName={"HiOutlineX"} />}
        />
      </HStack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)<StackProps>`
  border: 2px solid #fbfbfb;
  border-radius: 0.5rem;
`;
