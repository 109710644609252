import { Button, IconButton } from "@chakra-ui/button";
import { Heading, Stack, Flex } from "@chakra-ui/layout";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { ActionGroup } from "../../components/ActionGroup";
import { Icon } from "../../components/Icon";
import { PageHeader } from "../../components/PageHeader";
import { Overhead } from "../../types";
import {
  useCreateOverhead,
  useDeleteOverhead,
  useOverheads,
  useUpdateOverhead,
} from "../../hooks/userOverheads.hooks";
import { OverheadsTable } from "../../components/OverheadsTable";
import { OverheadModal } from "../../components/OverheadModal";

export const Overheads: React.FC = observer(() => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const {
    isOpen: isOverheadModalOpen,
    onOpen: onOverheadModalOpen,
    onClose: onOverheadModalClose,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const [activeOverhead, setActiveOverhead] = useState<Overhead | null>(null);
  const { isFetching, data: overheads = [] } = useOverheads(organizationId!!);

  const {
    isLoading: isSubmitting,
    mutateAsync: createOverhead,
  } = useCreateOverhead(organizationId!);

  const {
    isLoading: isUpdating,
    mutateAsync: updateOverhead,
  } = useUpdateOverhead(organizationId!);

  const {
    isLoading: isDeleting,
    mutateAsync: deleteOverhead,
  } = useDeleteOverhead(organizationId!);

  const handleEdit = (overhead: Overhead) => {
    setActiveOverhead(overhead);
    onOverheadModalOpen();
  };

  const handleSubmit = useCallback(
    async (overhead: Partial<Overhead>) => {
      try {
        if (overhead.uid) {
          await updateOverhead(overhead);
          toast({
            title: t("screens.overheads.overheadUpdated"),
            status: "success",
            position: "bottom-left",
          });
        } else {
          await createOverhead(overhead);

          toast({
            title: t("screens.overheads.overheadCreated"),
            status: "success",
            position: "bottom-left",
          });
        }
        onOverheadModalClose();
        setActiveOverhead(null);
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
        });
      }
    },
    [createOverhead, onOverheadModalClose, t, toast, updateOverhead]
  );

  const handleDismissModal = () => {
    onOverheadModalClose();
    setActiveOverhead(null);
  };

  const handleDelete = useCallback(
    async (overheadId: Overhead["uid"]) => {
      if (window.confirm(t("screens.overheads.deleteOverheadConfirm"))) {
        try {
          await deleteOverhead(overheadId);
          handleDismissModal();
          toast({
            title: t("screens.overheads.contractDeleted"),
            status: "success",
            position: "bottom-left",
          });
        } catch (err) {
          console.log(err);
          toast({
            title: t("common.error"),
            status: "error",
          });
        }
      }
    },
    [deleteOverhead, t, toast]
  );

  return (
    <StyledStack w="100%" h="100%">
      <PageHeader>
        <Flex alignItems="center">
          <IconButton
            variant={"ghost"}
            onClick={() => navigate(-1)}
            icon={<Icon iconName="HiOutlineArrowLeft" />}
            aria-label="Go back"
          />
          <Heading as="h4" size="md" fontWeight="semibold">
            {t<string>("screens.overheads.title")}
          </Heading>
        </Flex>
        <ActionGroup>
          {(isFetching || isSubmitting || isUpdating || isDeleting) && (
            <Spinner />
          )}
          <Button size="sm" onClick={onOverheadModalOpen}>
            {t<string>("screens.overheads.addOverhead")}
          </Button>
        </ActionGroup>
      </PageHeader>
      <OverheadsTable overheads={overheads} onEdit={handleEdit} />
      {isOverheadModalOpen && (
        <OverheadModal
          overhead={activeOverhead}
          onDismiss={handleDismissModal}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          isVisible={isOverheadModalOpen}
        />
      )}
    </StyledStack>
  );
});

const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
`;
