import { useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationForm } from "../components/OrganizationForm";
import { useStores } from "../hooks/useStores";

export const OrganizationDetails: React.FC = observer(() => {
  const { session, organization } = useStores();
  const { t } = useTranslation();
  const toast = useToast();

  const handleSubmitOrganization = useCallback(
    async (data) => {
      try {
        await organization.updateOrganization(data);
        toast({
          title: t("screens.organization.organizationUpdateSuccess"),
          status: "success",
          position: "bottom-left",
        });
      } catch (err: any) {
        if (err.message.includes("Invalid Domains")) {
          alert(
            `${t("screens.organization.updateErrorInvalidDomains")}${
              err.message.split(":")[1]
            }`
          );
        } else {
          alert(
            `${JSON.stringify(err)} ${t("screens.organization.updateError")}`
          );
        }
      }
    },
    [organization, t, toast]
  );

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "3rem",
      }}
    >
      <OrganizationForm
        organization={session.user?.organization || null}
        onSubmit={handleSubmitOrganization}
        isSubmitting={organization.isSubmitting}
      />
    </div>
  );
});
