import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { InputGroup, InputLeftAddon } from "@chakra-ui/input";
import { HStack, Stack } from "@chakra-ui/layout";
import { UserContract } from "../../../types";
import { ModalFooterForm } from "../../ModalFooterForm";
import {
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { DatePicker } from "../../DatePicker";
import { DAYS } from "../../../config/const";

type Props = {
  contract: UserContract | null;
  onCancel: () => void;
  onSubmit: (userContract: Partial<UserContract>) => void;
  onDelete: (id: UserContract["uid"]) => void;
};

const NOW = new Date();

export const UserContractForm: React.FC<Props> = ({
  contract,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uid: contract?.uid || undefined,
      hourlyCost: contract?.hourlyCost || 0,
      contractType: contract?.contractType || "full-time",
      contractualHours: contract?.contractualHours || 0,
      contractualHoursByDay: contract?.contractualHoursByDay || {},
      productivityTarget: contract?.productivityTarget || 0,
      fromDate: contract?.fromDate || NOW,
      toDate: contract?.toDate || NOW,
    },
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1rem"} direction={"column"}>
        <HStack>
          <FormControl
            isInvalid={
              Boolean(formik.errors.fromDate) &&
              formik.getFieldMeta("fromDate").touched
            }
          >
            <StyledFormLabel htmlFor={"fromDate"}>
              {t<string>("components.userContractForm.fromDate")}
            </StyledFormLabel>
            <DatePicker
              // @ts-ignore
              dateFormat={"dd/MM/yyyy"}
              selectedDate={formik.values.fromDate}
              onChange={(date: any) => {
                formik.setFieldValue("fromDate", date);
                if (
                  formik.values.toDate?.getTime() < (date as Date).getTime()
                ) {
                  formik.setFieldValue("toDate", date);
                }
              }}
            />
            <FormErrorMessage>{`${formik.errors.fromDate}`}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              Boolean(formik.errors.fromDate) &&
              formik.getFieldMeta("toDate").touched
            }
          >
            <StyledFormLabel htmlFor={"toDate"}>
              {t<string>("components.userContractForm.toDate")}
            </StyledFormLabel>
            <DatePicker
              // @ts-ignore
              dateFormat={"dd/MM/yyyy"}
              selectedDate={formik.values.toDate}
              onChange={(date) => {
                formik.setFieldValue("toDate", date);
              }}
              minDate={formik.values.fromDate}
            />
            <FormErrorMessage>{`${formik.errors.toDate}`}</FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack alignItems={"flex-start"}>
          <FormControl
            isInvalid={
              Boolean(formik.errors.contractType) &&
              formik.getFieldMeta("contractType").touched
            }
          >
            <StyledFormLabel htmlFor={"contractType"}>
              {t<string>("screens.users.contractType")}
            </StyledFormLabel>
            <RadioGroup
              onChange={(newValue) => {
                formik.setFieldValue("contractType", newValue);

                switch (true) {
                  case newValue === "full-time":
                    formik.setFieldValue("contractualHoursByDay", {});
                    formik.setFieldValue("contractualHours", 0);
                    break;
                  case newValue === "part-time":
                    let days = {};
                    Object.keys(DAYS).map((dayId) => (days[dayId] = 0));
                    formik.setFieldValue("contractualHours", 0);
                    formik.setFieldValue("contractualHoursByDay", {
                      ...days,
                    });
                    break;
                }
              }}
              value={formik.values.contractType}
            >
              <Stack direction="column">
                <Radio value={"full-time"}>full-time</Radio>
                <Radio value={"part-time"}>part-time</Radio>
              </Stack>
            </RadioGroup>
            <FormErrorMessage>{formik.errors.contractType}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              Boolean(formik.errors.hourlyCost) &&
              formik.getFieldMeta("hourlyCost").touched
            }
          >
            <StyledFormLabel htmlFor={"hourlyCost"}>
              {t<string>("screens.users.hourlyCost")}
            </StyledFormLabel>
            <InputGroup>
              <InputLeftAddon children="€" />
              <NumberInput
                precision={2}
                name="hourlyCost"
                value={formik.values.hourlyCost || undefined}
                w="6rem"
              >
                <NumberInputField
                  name="hourlyCost"
                  value={formik.values.hourlyCost || undefined}
                  onChange={formik.handleChange}
                />
              </NumberInput>
            </InputGroup>
            <FormErrorMessage>{formik.errors.hourlyCost}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              Boolean(formik.errors.productivityTarget) &&
              formik.getFieldMeta("productivityTarget").touched
            }
          >
            <StyledFormLabel htmlFor={"productivityTarget"}>
              {t<string>("screens.users.productivityTarget")}
            </StyledFormLabel>
            <InputGroup>
              <InputLeftAddon children="%" />
              <NumberInput
                precision={1}
                name="productivityTarget"
                value={formik.values.productivityTarget || undefined}
                w="6rem"
              >
                <NumberInputField
                  name="productivityTarget"
                  value={formik.values.productivityTarget || undefined}
                  onChange={formik.handleChange}
                />
              </NumberInput>
            </InputGroup>
            <FormErrorMessage>
              {formik.errors.productivityTarget}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack>
          {formik.values.contractType === "full-time" ? (
            <FormControl
              isInvalid={
                Boolean(formik.errors.contractualHours) &&
                formik.getFieldMeta("contractualHours").touched
              }
            >
              <StyledFormLabel htmlFor={"contractualHours"}>
                {t<string>("screens.users.contractualHours")}
              </StyledFormLabel>
              <NumberInput
                precision={0}
                name="contractualHours"
                value={formik.values.contractualHours || 0}
                w="6rem"
              >
                <NumberInputField
                  name="contractualHours"
                  value={formik.values.contractualHours || 0}
                  onChange={formik.handleChange}
                />
              </NumberInput>
              <FormErrorMessage>
                {formik.errors.contractualHours}
              </FormErrorMessage>
            </FormControl>
          ) : (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th color="gray.400">{t<string>("eventTile.DAILY")}</Th>
                  <Th color="gray.400">
                    {t<string>("screens.users.contractualHours")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(DAYS).map((dayId) => (
                  <Tr key={dayId}>
                    <Td>{t<string>(`days.${DAYS[dayId]}`)}</Td>
                    <Td>
                      <FormControl>
                        <NumberInput
                          precision={0}
                          name={`contractualHoursByDay-${[dayId]}`}
                          value={
                            formik.values.contractualHoursByDay?.[dayId] || 0
                          }
                          w="6rem"
                        >
                          <NumberInputField
                            name={`contractualHoursByDay-${[dayId]}`}
                            value={
                              formik.values.contractualHoursByDay?.[dayId] || 0
                            }
                            onChange={(newValue) => {
                              formik.setFieldValue("contractualHoursByDay", {
                                ...formik.values.contractualHoursByDay,
                                [dayId]: newValue.target.value,
                              });
                            }}
                          />
                        </NumberInput>
                      </FormControl>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </HStack>

        <ModalFooterForm
          onCancel={onCancel}
          onDelete={contract?.uid ? () => onDelete(contract.uid) : undefined}
        />
      </Stack>
    </form>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
