import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TaskCategories } from "./TaskCategories";
import { ProjectCategories } from "./ProjectCategories";
import { useQueryParams } from "../../../hooks/useQueryParams";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CategoriesDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const organizationId = queryParams.get("organizationId") || "";

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {t<string>("screens.projects.categories")}
          </DrawerHeader>

          <DrawerBody>
            <Tabs variant="soft-rounded" colorScheme="teal">
              <TabList>
                <Tab>{t<string>("screens.projects.projects")}</Tab>
                <Tab>{t<string>("screens.projects.tasks")}</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <ProjectCategories organizationId={organizationId} />
                </TabPanel>
                <TabPanel>
                  <TaskCategories organizationId={organizationId} />
                </TabPanel>
              </TabPanels>
            </Tabs>

            {/* {categories.map((category) => (
              <Category
                key={category.uid}
                id={category.uid}
                label={category.name}
                onChange={onUpdate}
              />
            ))} */}
          </DrawerBody>

          {/* <DrawerFooter style={{ justifyContent: "flex-start" }}>
            <Button colorScheme={"teal"} variant={"ghost"} onClick={onCreate}>
              {t<string>("screens.projects.addCategory")}
            </Button>
          </DrawerFooter> */}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
