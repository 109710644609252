import { HStack } from "@chakra-ui/layout";
import { Plan } from "../../types";
import { PlanCard } from "./PlanCard";

type Props = {
  currentPlan: Plan;
  plans: Plan[];
  onSelected: (plan: Plan) => void;
};

export const Plans: React.FC<Props> = ({ currentPlan, plans, onSelected }) => {
  return (
    <HStack paddingTop="1.5rem">
      {plans.map((plan) => (
        <PlanCard
          key={plan.uid}
          currentPlan={currentPlan}
          plan={plan}
          onSelected={onSelected}
          plain={false}
        />
      ))}
    </HStack>
  );
};
