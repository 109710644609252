import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addWebhook,
  deleteWebhook,
  fetchWebhooks,
  updateWebhook,
} from "../services/api/webhooks";
import { Organization, Webhook } from "../types";

export const useWebhooks = (organizationId: Organization["uid"]) =>
  useQuery([`webhooks-${organizationId}`, organizationId], () =>
    fetchWebhooks(organizationId)
  );

export const useCreateWebhook = (organizationId: Organization["uid"]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (webhook: Pick<Webhook, "url" | "description" | "events">) =>
      addWebhook(organizationId, webhook),
    {
      onSettled: () => {
        queryClient.invalidateQueries(`webhooks-${organizationId}` as any);
      },
    }
  );
};

export const useUpdateWebhook = (organizationId: Organization["uid"]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (webhook: Webhook) =>
      updateWebhook(organizationId, webhook.uid || "", webhook),
    {
      onSettled: () => {
        queryClient.invalidateQueries(`webhooks-${organizationId}` as any);
      },
    }
  );
};

export const useDeleteWebhook = (organizationId: Organization["uid"]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (webhook: Webhook) => deleteWebhook(organizationId, webhook.uid),
    {
      onSettled: () => {
        queryClient.invalidateQueries(`webhooks-${organizationId}` as any);
      },
    }
  );
};
