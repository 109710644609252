import Project from "../models/Project";
import i18n from "../services/i18n";

export const useGroupedProjects = (projects: Project[]) => {
  const groupedProjects: any = [
    { id: "", label: i18n.t("eventTile.noProject") },
  ];

  for (let project of projects) {
    if (project.category) {
      if (
        !groupedProjects.find((group) => group.label === project.category?.name)
      ) {
        groupedProjects.push({ label: project.category?.name, options: [] });
      }
    } else {
      if (
        !groupedProjects.find(
          (group) =>
            group.label === i18n.t("screens.board.event.noProjectCategory")
        )
      ) {
        groupedProjects.push({
          label: i18n.t("screens.board.event.noProjectCategory"),
          options: [],
        });
      }
    }

    for (let i = 0; i < groupedProjects.length; i++) {
      if (
        groupedProjects[i].label ===
        (project.category?.name ||
          i18n.t("screens.board.event.noProjectCategory"))
      ) {
        groupedProjects[i].options.push({
          value: project.uid,
          label: `${project.title} (${project.customer?.nominative})`,
        });
      }
    }
  }

  return groupedProjects;
};
