import { Button } from "@chakra-ui/button";
import { FormLabel } from "@chakra-ui/form-control";
import { Link, Stack, Text } from "@chakra-ui/layout";
import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/modal";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { User } from "../../types";
import langConfig from "../../config/languages";
import { Select } from "../Select";
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from "../../config/const";

type Props = {
  user: User;
  isSubmitting: boolean;
  onSubmit: (language: string, checkedTermsOfUse: boolean) => void;
};

export const ConditionsForm: React.FC<Props> = ({
  user,
  isSubmitting,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [checkedTerms, setCheckedTerms] = useState(user.termsOfUse);
  const [checkedPrivacy, setCheckedPrivacy] = useState(user.privacyPolicy);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  const languages = useMemo(() => {
    return langConfig.LANGUAGES.map((lang) => ({
      value: lang,
      label: t(`languages.${lang}`),
    }));
  }, [t]);

  const getSelectedLanguage = useCallback(() => {
    return {
      value: selectedLanguage,
      label: t(`languages.${selectedLanguage}`),
    };
  }, [selectedLanguage, t]);

  const handleSubmit = useCallback(() => {
    onSubmit(selectedLanguage, checkedTerms && checkedPrivacy);
  }, [checkedTerms, checkedPrivacy, selectedLanguage, onSubmit]);

  return (
    <ModalContent>
      <ModalHeader>
        {t<string>("termsOfUse.welcome", { nominative: user.nominative })}
      </ModalHeader>
      <ModalBody>
        <Text>{t<string>("termsOfUse.message")}</Text>

        <Stack paddingY={"0.5rem"}>
          <StyledFormLabel>{t<string>("common.language")}</StyledFormLabel>

          <Select
            options={languages}
            value={getSelectedLanguage()}
            onChange={(option: any) => setSelectedLanguage(option.value)}
          />
        </Stack>

        {/* Using plain checkbox to avoid flushSync React warning occurs on render()
          https://github.com/chakra-ui/chakra-ui/issues/3357 */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type={"checkbox"}
            id={"termsOfUse"}
            name={"termsOfUse"}
            value={checkedTerms.toString()}
            checked={checkedTerms}
            onChange={(e) => setCheckedTerms(e.target.checked)}
          />
          <label htmlFor={"termsOfUse"} style={{ marginLeft: "0.5rem" }}>
            <Text fontSize="md">
              {t<string>("termsOfUse.agree")}
              <Link href={TERMS_OF_USE_URL} isExternal>
                {t<string>("termsOfUse.termsOfUse")}
              </Link>
            </Text>
          </label>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type={"checkbox"}
            id={"privacyPolicy"}
            name={"privacyPolicy"}
            value={checkedPrivacy.toString()}
            checked={checkedPrivacy}
            onChange={(e) => setCheckedPrivacy(e.target.checked)}
          />
          <label htmlFor={"privacyPolicy"} style={{ marginLeft: "0.5rem" }}>
            <Text fontSize="md">
              {t<string>("termsOfUse.agree")}
              <Link href={PRIVACY_POLICY_URL} isExternal>
                {t<string>("termsOfUse.privacyPolicy")}
              </Link>
            </Text>
          </label>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!checkedTerms || !checkedPrivacy}
          isLoading={isSubmitting}
          colorScheme={"teal"}
          onClick={handleSubmit}
          style={{ width: "100%" }}
        >
          {t<string>("common.confirm")}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
