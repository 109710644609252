import React from "react";
import { ServiceCode } from "../../types";
import { observer } from "mobx-react";
import { Column } from "../../components/Column";
import { GCalendarView } from "./GCalendarView";
import { TodoistView } from "./TodoistView";
import { PaymoView } from "./PaymoView";
import { JiraView } from "./JiraView";
import { TasksView } from "./TasksView";
import { AsanaView } from "./AsanaView";
import { useToast } from "@chakra-ui/react";

type Props = {
  source: "tasks" | ServiceCode;
};

enum Views {
  TASKS = "tasks",
  GCALENDAR = "gcalendar",
  TODOIST = "todoist",
  PAYMO = "paymo",
  JIRA = "jira",
  ASANA = "asana",
}

export const EventsSource: React.FC<Props> = observer(({ source }) => {
  const toast = useToast();
  return (
    <Column>
      {source === Views.GCALENDAR ? (
        <GCalendarView />
      ) : source === Views.TODOIST ? (
        <TodoistView />
      ) : source === Views.PAYMO ? (
        <PaymoView />
      ) : source === Views.JIRA ? (
        <JiraView />
      ) : source === Views.TASKS ? (
        <TasksView />
      ) : source === Views.ASANA ? (
        <AsanaView toast={toast} />
      ) : null}
    </Column>
  );
});
