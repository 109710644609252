import React, { useCallback } from "react";
import {
  CookieConsent as CookieConsentBanner,
  getCookieConsentValue,
} from "react-cookie-consent";
import { useStores } from "../../hooks/useStores";
import i18n from "../../services/i18n";

export const CookieConsent: React.FunctionComponent = () => {
  const { session } = useStores();

  const handleConsent = useCallback(
    async (accepted: boolean) => {
      await session.updateUser({
        cookieConsent: accepted,
      });
    },
    [session]
  );

  return !session.user?.cookieConsent ||
    getCookieConsentValue("TimeTrap") === undefined ? (
    <CookieConsentBanner
      location="bottom"
      buttonText={i18n.t<string>("cookiesConsent.accept")}
      cookieName={"TimeTrap"}
      style={{
        background: "#2B373B",
        width: "80%",
        left: "10%",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      }}
      buttonStyle={{ color: "#4e503b" }}
      expires={150}
      onAccept={() => handleConsent(true)}
      enableDeclineButton
      onDecline={() => handleConsent(false)}
      declineButtonText={i18n.t<string>("cookiesConsent.decline")}
      acceptOnScroll
    >
      {i18n.t<string>("cookiesConsent.message")}
    </CookieConsentBanner>
  ) : (
    <></>
  );
};
