import { DayTableModel, DateRange, Slicer } from "../common";
import { TableSeg } from "./TableSeg";
import React from "react";
// @ts-ignore
export class DayTableSlicer extends Slicer<TableSeg, [DayTableModel]> {
  forceDayIfListItem = true;

  sliceRange(dateRange: DateRange, dayTableModel: DayTableModel): TableSeg[] {
    return dayTableModel.sliceRange(dateRange);
  }
}
