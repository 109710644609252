import { HStack, Stack } from "@chakra-ui/layout";
import { useEffect } from "react";
import { Plan, User } from "../../types";
import keys from "../../config/keys";
import { Box } from "../Box";
import { PlanCard } from "./PlanCard";
import { Button } from "@chakra-ui/button";
import { Icon } from "../Icon";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/toast";

type Props = {
  plan: Plan;
  user: User;
  goBack: () => void;
  onCheckoutCompleted: (plan: Plan) => void;
};

export const Checkout: React.FC<Props> = ({
  user,
  plan,
  goBack,
  onCheckoutCompleted,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  // Paddle integration: https://github.com/ronaldlangeveld/paddlejs-react
  // @ts-ignore
  const Paddle = window.Paddle;

  if (keys.NODE_ENV !== "production") {
    Paddle.Environment.set("sandbox");
  }
  Paddle.Setup({ vendor: parseInt(keys.PADDLE_VENDOR) });

  const checkoutComplete = (data: any) => {
    toast({
      title: t("plans.paymentSuccess"),
      status: "success",
      position: "bottom-left",
    });
    onCheckoutCompleted(plan);
  };

  const checkoutClosed = (data) => {
    console.log(data);
    //alert("Your purchase has been cancelled, we hope to see you again soon!");
    toast({
      title: t("plans.paymentError"),
      status: "error",
      position: "bottom-left",
    });
  };

  useEffect(() => {
    if (plan.subscription) {
      Paddle.Checkout.open({
        method: "inline",
        product: plan.subscription?.id,
        email: user.email,
        passthrough: JSON.stringify({
          userId: user.uid,
          organizationId: user.organization?.uid,
        }),
        allowQuantity: false,
        disableLogout: true,
        frameTarget: "checkout-container", // The className of your checkout <div>
        successCallback: checkoutComplete,
        closeCallback: checkoutClosed,
        frameInitialHeight: 416,
        frameStyle:
          "width:100%; min-width:312px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
      });
    }
  }, [plan, user, Paddle]);

  return (
    <HStack paddingTop="1.5rem">
      <Stack>
        <PlanCard plan={plan} />

        {!plan.subscription && (
          <Button
            colorScheme="teal"
            onClick={() => onCheckoutCompleted(plan)}
            marginBottom="1rem"
          >
            {t<string>("plans.downgradeToFreePlan")}
          </Button>
        )}

        <Button
          leftIcon={<Icon iconName="HiOutlineArrowLeft" />}
          colorScheme="teal"
          variant="link"
          onClick={goBack}
        >
          {t<string>("common.goBack")}
        </Button>
      </Stack>

      {plan.subscription && <Box className="checkout-container" />}
    </HStack>
  );
};
