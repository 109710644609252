import { makeApiRequest } from ".";

export const closeTodoistTask = async (
  taskId: string
): Promise<{ err: string; success: boolean }> => {
  const response = await makeApiRequest(
    "POST",
    `connect/todoist/tasks/${taskId}/close`
  );

  return response;
};
