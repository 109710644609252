import { User } from "../types";
import { toGoogleCalendar, toGoogleCalendarEvent } from "./utils";

let accessToken: string | undefined;

const setAccessToken = (token: string) => {
  accessToken = token;
};

const clearAccessToken = () => {
  accessToken = undefined;
};

const fetchCalendars = async () => {
  if (!accessToken) {
    return [];
  }
  const response = await fetch(
    `https://www.googleapis.com/calendar/v3/users/me/calendarList`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  const calendarsList = await response.json();
  console.log("calendarId -> ", calendarsList);
  return calendarsList && calendarsList.items
    ? calendarsList.items.map((calendar) => toGoogleCalendar(calendar))
    : [];
};

const fetchEvents = async (
  calendarId,
  startISODate,
  endISODate,
  userEmail?: User["email"]
) => {
  if (!accessToken) {
    return [];
  }

  const response = await fetch(
    `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?singleEvents=True&timeMin=${startISODate}&timeMax=${endISODate}&showDeleted=false `,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  const eventsList = await response.json();
  console.log("event list -> ", eventsList);
  const data = eventsList && eventsList.items
    ? eventsList.items
      .filter((event) =>
        event.status === "confirmed" && userEmail && event.attendees
          ? event.attendees?.find(
            (attendee) =>
              attendee.email === userEmail &&
              attendee.responseStatus !== "declined"
          )
          : true
      )
      .map((event) => toGoogleCalendarEvent(event))
    : []
  console.log("data -> ", data);
  return data

};

const updateEventGCal = async (
  calendarId,
  startISODate,
  endISODate,
  eventId?,
  summary?,
) => {
  if (!accessToken) {
    return [];
  }

  const res = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  const eventGet = await res.json();
  console.log("get respose -> ", eventGet);


  const response = await fetch(
    `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}?key=AIzaSyDewHrbfJ9vWjaC2JzHQZUROzRD4RSRvOI`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      method: "PUT",
      body: JSON.stringify({
        ...eventGet,
        summary: summary || "",
        "end": { "dateTime": endISODate?.toISOString() },
        "start": { "dateTime": startISODate?.toISOString() }
      })
    }
  );
  const eventsList = await response.json();
  console.log("update respose -> ", eventsList);

  // return eventsList && eventsList.items
  //   ? eventsList.items
  //     .filter((event) =>
  //       event.status === "confirmed" && userEmail && event.attendees
  //         ? event.attendees?.find(
  //           (attendee) =>
  //             attendee.email === userEmail &&
  //             attendee.responseStatus !== "declined"
  //         )
  //         : true
  //     )
  //     .map((event) => toGoogleCalendarEvent(event))
  //   : [];
};

const deleteGCalendarEvent = async (
  calendarId,
  eventId?,
) => {
  if (!accessToken) {
    return [];
  }

  const response = await fetch(
    `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}?key=AIzaSyDewHrbfJ9vWjaC2JzHQZUROzRD4RSRvOI`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      method: "DELETE",
    }
  );
  const eventsList = await response.json();

}

export default {
  setAccessToken,
  clearAccessToken,
  fetchCalendars,
  fetchEvents,
  updateEventGCal,
  deleteGCalendarEvent,
};
