import { makeAutoObservable } from "mobx";
import gapi from "../services/gapi";
import { User } from "../types";

export default class GoogleCalendar {
  id: string;
  summary: string;
  backgroundColor: string;
  enabled: boolean;
  events: any[];

  constructor(id: string, summary: string, backgroundColor: string) {
    this.id = id;
    this.summary = summary;
    this.backgroundColor = backgroundColor;
    this.enabled = false;
    this.events = [];

    makeAutoObservable(this);
  }

  setEnabled = (enabled: boolean) => {
    this.enabled = enabled;
  };

  fetchEvents = async (startISODate, endISODate, userEmail?: User["email"]) => {
    // console.log(
    //   "[Google Calendar] Fetching events,",
    //   this.id,
    //   startISODate,
    //   endISODate
    // );
    const eventList = await gapi.fetchEvents(
      this.id,
      startISODate,
      endISODate,
      userEmail
    );

    this.events = eventList;
  };
}
