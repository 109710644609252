import { Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { RadialChart, RadialChartProps } from "react-vis";

type Props = {
  defaultHint?: { value: string; valuePerc: string };
  enableOnValueMouseOver?: boolean;
} & RadialChartProps &
  any;

export const TimeRadialChart: React.FC<Props> = ({
  defaultHint,
  enableOnValueMouseOver = false,
  children,
  ...otherProps
}) => {
  const [hint, setHint] = useState(defaultHint);

  useEffect(() => {
    setHint(defaultHint);
  }, [defaultHint]);

  const handleSeriesMouseOut = useCallback(() => setHint(defaultHint), [
    defaultHint,
  ]);

  return (
    <RadialChart
      {...otherProps}
      onValueMouseOver={(v) => {
        enableOnValueMouseOver &&
          setHint({ value: v.value, valuePerc: v.valuePerc });
      }}
      onSeriesMouseOut={handleSeriesMouseOut}
      key={JSON.stringify(defaultHint)}
    >
      {hint && (
        <Stack
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          marginLeft="0"
          marginRight="0"
          marginTop="0"
          marginBottom="0"
          alignItems="center"
          paddingTop="0.5rem"
          key={JSON.stringify(hint)}
        >
          <Text color="#000" marginTop="0" fontSize="md">
            {hint.valuePerc}
          </Text>
          <Text
            color="#000"
            marginTop="0 !important"
            fontSize="xs"
            fontWeight={300}
          >
            {hint.value}
          </Text>
        </Stack>
      )}
    </RadialChart>
  );
};
