import { makeAutoObservable } from "mobx";
import { Organization, User } from "../types";
import Project from "./Project";

export default class Expense {
  uid: string;
  organizationId: Organization["uid"];
  projectId: Project["uid"];
  isFinalBalance: boolean;
  supplier: string | null;
  title: string;
  description: string | null;
  amount: number;
  estimated_amount: number | null;
  confirmed_amount: number | null;
  transacation_date: Date | string | null;
  isBillable: boolean;
  approver: User | null;

  constructor(
    uid: string,
    organizationId: Organization["uid"],
    projectId: Project["uid"],
    isFinalBalance: boolean,
    supplier: string,
    title: string,
    description: string | null,
    amount: number,
    estimated_amount: number | null,
    confirmed_amount: number | null,
    transacation_date: Date | string | null,
    isBillable: boolean,
    approver: User | null
  ) {
    this.uid = uid;
    this.organizationId = organizationId;
    this.projectId = projectId;
    this.isFinalBalance = isFinalBalance;
    this.supplier = supplier;
    this.title = title;
    this.description = description;
    this.amount = amount;
    this.estimated_amount = estimated_amount;
    this.confirmed_amount = confirmed_amount;
    this.transacation_date = transacation_date;
    this.isBillable = isBillable;
    this.approver = approver;

    makeAutoObservable(this);
  }
}
