export default {
  NODE_ENV: process.env.NODE_ENV || "development",
  PORT: process.env.PORT || 1337,
  API_BASE_URL:
    process.env.REACT_APP_API_BASE_URL || "http://localhost:4000/api/v1/",
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
  INTERCOM_APP_ID: "yxqtem8i",
  TYPEFORM_POOL_ID: "ZzVLzj4D",
  PADDLE_VENDOR: process.env.REACT_APP_PADDLE_VENDOR || "3665",
  TIDIO_URL: "//code.tidio.co/p7ya9auxgtkhlco8c7hmyvtwigtidqc4.js",
  SHOW_TASKS: process.env.REACT_APP_SHOW_TASKS
    ? process.env.REACT_APP_SHOW_TASKS === "true"
    : true,
  KNOWLEDGEBASE_URL:
    "https://larin-group.notion.site/TimeTrap-1147dcacd329800abe3cc0ac496d8ad7",
  CHANGELOG_URL:
    "https://larin-group.notion.site/Changelog-28fa2954f5674be4b09a4c2131196f08",
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || "",
};
