import { Table, Thead, Tbody, Tr, Th, Td, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTimeBlockingNotAssigned } from "../../hooks/useTimeBlockingEventsNotAssigned";
import { useStores } from "../../hooks/useStores";
import { Select } from "../../components/Select";
import moment from "moment";
import { sec2time } from "../../utils/dateTimeFormat";
import { useCallback, useMemo } from "react";
import Project from "../../models/Project";
import { TaskCategory, TimeBlockingEvent } from "../../types";
import { useGroupedProjects } from "../../hooks/useGroupedProjects";
import { useUpdateTimeBlockingEvent } from "../../hooks/useMutationTimeBlockingEvent";
import { CategorySelect } from "../../components/CategorySelect";
import {
  getSelectedProject,
  getSelectedTaskCategory,
} from "../../utils/select";

type Props = {
  taskCategories: TaskCategory[];
  isFetchingTaskCategories: boolean;
  userProjects: Project[];
  isFetchingUserProjects: boolean;
};

export const TimeBlockingNotAssigned: React.FC<Props> = ({
  taskCategories,
  isFetchingTaskCategories,
  userProjects,
  isFetchingUserProjects,
}) => {
  const { session } = useStores();
  const { t } = useTranslation();
  const toast = useToast();

  const projects = useGroupedProjects(userProjects || []);
  const {
    isLoading,
    data: timeBlockingNotAssigned,
    error,
  } = useTimeBlockingNotAssigned(session.user?.uid || "");

  const updateTimeBlockingEvent = useUpdateTimeBlockingEvent(
    session.user?.uid || ""
  );

  const taskCategoriesOpts = useMemo(() => {
    const categories: any = [
      { id: "", label: t("screens.board.event.noProjectCategory") },
    ];

    for (let taskCategory of taskCategories) {
      categories.push({
        value: taskCategory.uid,
        label: taskCategory.name,
        color: taskCategory.color,
      });
    }

    return categories;
  }, [t, taskCategories]);

  const handleChangeProject = useCallback(
    (timeBlock: TimeBlockingEvent, projectId: Project["uid"]) => {
      updateTimeBlockingEvent.mutate({ ...timeBlock, projectId });
      toast({
        title: t("common.actionExecutedSuccessfully"),
        status: "success",
        position: "bottom-left",
      });
    },
    [t, toast, updateTimeBlockingEvent]
  );

  const handleChangeCategory = useCallback(
    (timeBlock: TimeBlockingEvent, categoryId: TaskCategory["uid"]) => {
      updateTimeBlockingEvent.mutate({ ...timeBlock, categoryId });
      toast({
        title: t("common.actionExecutedSuccessfully"),
        status: "success",
        position: "bottom-left",
      });
    },
    [t, toast, updateTimeBlockingEvent]
  );

  return (
    <Table variant="striped" size="sm">
      <Thead>
        <Tr>
          <Th>{t<string>("screens.board.formTitle")}</Th>
          <Th>{t<string>("eventTile.date")}</Th>
          <Th>{t<string>("screens.dashboard.workLogged")}</Th>
          <Th>{t<string>("screens.board.project")}</Th>
          <Th>{t<string>("tasks.color")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {timeBlockingNotAssigned &&
          timeBlockingNotAssigned.map((timeBlock) => (
            <Tr key={timeBlock.uid}>
              <Td>{timeBlock.title}</Td>
              <Td>{moment(timeBlock.startTime).format("YYYY-MM-DD HH:MM")}</Td>
              <Td>{sec2time(timeBlock.elapsedTime, "HH:MM")}</Td>
              <Td>
                <Select
                  options={projects}
                  value={
                    timeBlock.projectId
                      ? getSelectedProject(timeBlock.projectId, userProjects)
                      : null
                  }
                  isLoading={isFetchingUserProjects}
                  onChange={(option: any) =>
                    handleChangeProject(timeBlock, option.value)
                  }
                />
              </Td>
              <Td>
                <CategorySelect
                  options={taskCategoriesOpts}
                  value={getSelectedTaskCategory(
                    timeBlock.categoryId || "",
                    taskCategories
                  )}
                  isLoading={isFetchingTaskCategories}
                  onChange={(option: any) =>
                    handleChangeCategory(timeBlock, option.value)
                  }
                />
              </Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
};
