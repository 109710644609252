import { Plan } from "../types";

export const isLimited = (
  plan: Plan,
  unitType: string,
  currentOccurrence?: number
): boolean => {
  let result = false;
  // alert(plan.config);
  if (plan.config) {
    const unit = plan.config.find(
      (configUnit) => configUnit.unitType === unitType
    );

    if (!unit) {
      result = true;
    } else if (typeof unit.enabled === "boolean") {
      result = !unit.enabled;
    } else if (!unit.tierLimited) {
      result = !!unit.tierLimited;
    } else if ((currentOccurrence || 0) >= (unit.tierLimit || 0)) {
      result = true;
    }
  } else {
    result = false
  }

  return result;
};
