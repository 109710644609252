import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Select,
    VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import Project from '../models/Project';
import { Customer, FilterProjectFormValues, ProjectCategory, ProjectStatus, User } from '../types';
import { Select as SelectrReact } from "./Select";
import ds from "../config/projects";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IFilterProjectForm {
    projects: Project[];
    onCloseModal: () => void;
    setFilterBayData: (x: FilterProjectFormValues | null) => void;
    filterByData: FilterProjectFormValues | null
    projectsWorkflows: ProjectStatus[];
    categories: ProjectCategory[];
    users: User[];
    customers: Customer[];
}

// Mock data for select fields
const projectLeaders = ['John Doe', 'Jane Smith', 'Mike Johnson'];
const statuses = ['Active', 'On Hold', 'Completed'];
const projectCategories = ['Web Development', 'Mobile App', 'Desktop Software'];
const projectTypes = ['New Development', 'Maintenance', 'Upgrade'];

const FilterProjectForm: React.FC<IFilterProjectForm> = ({ projects, onCloseModal, setFilterBayData, filterByData, projectsWorkflows, categories, users, customers }) => {

    const { t } = useTranslation();

    // const [filteredProjects, setFilteredProjects] = useState<Project[]>(projects);

    const formikRef = useRef<FormikProps<FilterProjectFormValues>>(null);

    const customersOptions = useMemo(
        () =>
            customers.map((customer) => ({
                label: customer.nominative || "",
                value: customer.uid,
            })),
        [customers]
    );

    const usersOptions = useMemo(
        () =>
            users.filter(e => e.status === 'active').map((user) => ({
                label: user.nominative,
                value: user.uid,
            })),
        [users]
    );

    const statusOptions = useMemo(
        () =>
            projectsWorkflows.map((status) => ({
                label: status.name,
                value: status.uid,
            })),
        [projectsWorkflows]
    );

    const projectTypeOptions = useMemo(
        () =>
            ds.project_type.map((e, ind) => {
                return {
                    label: t(`screens.projects.projectTypes.${ind}`),
                    value: e,
                };
            }),
        [ds]
    );

    const categoriesOptions = useMemo(() => {
        const options = categories.map((category) => ({
            label: category.name,
            value: category.uid,
        }));
        options.push({ label: "Nessuna categoria", value: "" });
        return options;
    }, [categories]);

    const getSelectedCustomer: any = (members: Customer["uid"][]) => {
        if (!members) return [];

        const selectedCustomer = customers
            .filter((user) => members.indexOf(user.uid) > -1)
            .map((user) => ({
                value: user.uid,
                label: user.nominative,
            }));

        return selectedCustomer;
    };

    const getSelectedStatus: any = (statusIds: ProjectStatus["uid"][] | null) => {
        if (!statusIds) {
            return null;
        }

        const selectedStatus = projectsWorkflows
            .filter((status) => statusIds.indexOf(status.uid) > -1)
            .map((status) => ({
                value: status.uid,
                label: status.name,
            }));

        return selectedStatus;
    };

    const getSelectedProjectTyps: any = (pType: string[] | null) => {
        if (!pType) {
            return null;
        }

        const selectedTypes = ds.project_type
            .filter((type) => pType.indexOf(type) > -1)
            .map((type) => {
                const index = ds.project_type.findIndex(
                    (workflow) => workflow === type
                );
                return {
                    value: type,
                    label: t(`screens.projects.projectTypes.${index}`),
                }
            });
        return selectedTypes;
    };

    const getSelectedCategory: any = (categoryId: string[]) => {
        if (!categoryId || (categoryId && categoryId.length === 0))
            return null;

        const selectedCategory = categories
            .filter((cat) => categoryId.indexOf(cat.uid) > -1)
            .map((cat) => ({
                value: cat.uid,
                label: cat.name,
            }));

        return selectedCategory;
    };

    const getSelectedUser: any = (members: User['uid'][]) => {
        if (!members) return [];

        const selectedMembers = users
            .filter((user) => members.indexOf(user.uid) > -1)
            .map((user) => ({
                value: user.uid,
                label: user.nominative,
            }));

        return selectedMembers;
    };

    const initialValues: FilterProjectFormValues = {
        projectTitle: filterByData?.projectTitle || '',
        customerName: filterByData?.customerName || [],
        referenceQuote: filterByData?.referenceQuote || '',
        projectLeader: filterByData?.projectLeader || [],
        status: filterByData?.status || [],
        projectCategory: filterByData?.projectCategory || [],
        projectType: filterByData?.projectType || [],
        budget: filterByData?.budget,
        marginality: filterByData?.marginality
    };


    const filterProjects = (values: FilterProjectFormValues) => {
        console.log("valeue -> \n", values);
        // return
        setFilterBayData(values);
        onCloseModal();
    };

    const handleSubmit = (values: FilterProjectFormValues) => {
        filterProjects(values);
    };

    const clearFilter = () => {
        if (formikRef.current) {
            formikRef.current.resetForm({
                values: {
                    projectTitle: '',
                    customerName: [],
                    referenceQuote: '',
                    projectLeader: [],
                    status: [],
                    projectCategory: [],
                    projectType: [],
                    budget: 0,
                    marginality: 0
                }
            });
        }
        setFilterBayData(null);
    };



    // const filterProjects = (values: FilterProjectFormValues) => {
    //     setFilterBayData(values)
    //     // const filtered = projects.filter(project => {
    //     //     let match = true;
    //     //     if (project) {
    //     //         if (values.projectTitle) {
    //     //             const titleMatch = project.title.toLowerCase().includes(values.projectTitle.toLowerCase());
    //     //             const customerMatch = project.customer?.nominative?.toLowerCase().includes(values.projectTitle.toLowerCase()) || false;
    //     //             match = match && (titleMatch || customerMatch);
    //     //         }

    //     //         if (values.referenceQuote && project.externalReference) {
    //     //             match = match && project.externalReference.toLowerCase().includes(values.referenceQuote.toLowerCase());
    //     //         }

    //     //         if (values.projectLeader) {
    //     //             match = match && project.projectManager ? project.projectManager.nominative === values.projectLeader : false;
    //     //         }

    //     //         if (values.status) {
    //     //             match = match && project.status.name === values.status;
    //     //         }

    //     //         if (values.projectCategory && project.category) {
    //     //             match = match && project.category.name === values.projectCategory;
    //     //         }

    //     //         if (values.projectType && project.projectType) {
    //     //             match = match && project.projectType === values.projectType;
    //     //         }

    //     //         if (values.budget !== '') {
    //     //             match = match && project.budget ? project.budget <= values.budget : false;
    //     //         }

    //     //         if (values.marginality !== '') {
    //     //             match = match && project.marginabilityPercentage ? project.marginabilityPercentage >= values.marginality : false;
    //     //         }

    //     //     }
    //     //     return match;
    //     // });
    //     // console.log("filteredProjects ->\n", filtered);
    //     // setFilteredProjects(filtered);
    //     onCloseModal();
    // };

    // const handleSubmit = (values: FilterProjectFormValues) => {
    //     filterProjects(values);
    // };

    return (
        <Box maxWidth="500px" margin="auto">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formikRef}>
                {({ resetForm, submitForm, setFieldValue }) => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <Field name="projectTitle">
                                {({ field }) => (
                                    <FormControl>
                                        <StyledFormLabel>Project Title</StyledFormLabel>
                                        <Input {...field} />
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="customerName">
                                {({ field }) => (
                                    <FormControl>
                                        <StyledFormLabel>Customer Name</StyledFormLabel>
                                        <SelectrReact
                                            options={customersOptions}
                                            // @ts-ignore
                                            isMulti
                                            value={getSelectedCustomer(field.value)}
                                            onChange={(option) =>
                                                setFieldValue(field.name, // @ts-ignore
                                                    option?.map(
                                                        (selectedOption) => selectedOption.value
                                                    ) || null)
                                            }
                                            onBlur={field.onBlur}
                                        />
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="referenceQuote">
                                {({ field }) => (
                                    <FormControl>
                                        <StyledFormLabel>Reference Quote</StyledFormLabel>
                                        <Input {...field} />
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="projectLeader">
                                {({ field }) => (
                                    <FormControl>
                                        <StyledFormLabel>Project Leader</StyledFormLabel>
                                        <SelectrReact
                                            options={usersOptions}
                                            // @ts-ignore
                                            isMulti
                                            value={getSelectedUser(field.value)}
                                            onChange={(option) =>
                                                setFieldValue(field.name,
                                                    // @ts-ignore
                                                    option?.map(
                                                        (selectedOption) => selectedOption.value
                                                    ) || null)
                                            }
                                            onBlur={field.onBlur}
                                        />
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="status">
                                {({ field }) => (
                                    <FormControl>
                                        <StyledFormLabel>Status</StyledFormLabel>
                                        <SelectrReact
                                            options={statusOptions}
                                            // @ts-ignore
                                            isMulti
                                            value={getSelectedStatus(field.value)}
                                            onChange={(option) =>
                                                setFieldValue(field.name,
                                                    // @ts-ignore
                                                    option?.map(
                                                        (selectedOption) => selectedOption.value
                                                    ) || null)
                                            }
                                            onBlur={field.onBlur}
                                        />

                                        {/* <Select {...field} placeholder="Select status">
                                            {statuses.map((status) => (
                                                <option key={status} value={status}>
                                                    {status}
                                                </option>
                                            ))}
                                        </Select> */}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="projectCategory">
                                {({ field }) => (
                                    <FormControl>
                                        <StyledFormLabel>Project Category</StyledFormLabel>
                                        <SelectrReact
                                            options={categoriesOptions}
                                            // @ts-ignore
                                            isMulti
                                            value={getSelectedCategory(field.value)}
                                            onChange={(option) =>
                                                setFieldValue(field.name,
                                                    // @ts-ignore
                                                    option?.map(
                                                        (selectedOption) => selectedOption.value
                                                    ) || null)
                                            }
                                            onBlur={field.onBlur}
                                        />
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="projectType">
                                {({ field }) => (
                                    <FormControl>
                                        <StyledFormLabel>Project Type</StyledFormLabel>
                                        <SelectrReact
                                            options={projectTypeOptions}
                                            // @ts-ignore
                                            isMulti
                                            value={getSelectedProjectTyps(field.value)}
                                            onChange={(option) =>
                                                setFieldValue(field.name,
                                                    // @ts-ignore
                                                    option?.map(
                                                        (selectedOption) => selectedOption.value
                                                    ) || null)
                                            }
                                            onBlur={field.onBlur}
                                        />
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="budget">
                                {({ field }) => (
                                    <FormControl>
                                        <StyledFormLabel>Budget</StyledFormLabel>
                                        <Input {...field} type="number" />
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="marginality">
                                {({ field }) => (
                                    <FormControl>
                                        <StyledFormLabel>Marginality</StyledFormLabel>
                                        <Input {...field} type="number"
                                        // placeholder="Enter minimum marginality" 
                                        />
                                    </FormControl>
                                )}
                            </Field>

                            <HStack justifyContent="space-between">
                                <Button onClick={() => {
                                    resetForm();
                                    clearFilter();
                                    setFilterBayData(null)
                                    // setFilteredProjects(projects);
                                }} colorScheme="gray">
                                    Clear Filter
                                </Button>
                                <Button onClick={submitForm} colorScheme="blue">
                                    Search
                                </Button>
                            </HStack>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default FilterProjectForm;

// @ts-ignore
const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;