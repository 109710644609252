import { useQuery } from "react-query";
import api from "../services/api";
import { Organization } from "../types";
import moment from "moment";

export const useTeamReport = (
  organizationId: Organization["uid"],
  fromDate: Date,
  toDate: Date
) =>
  useQuery(
    ["teamReport", organizationId, fromDate.toISOString, toDate.toISOString],
    () => api.getTeamReport(organizationId, moment(fromDate), moment(toDate)),
    {
      enabled: false,
    }
  );
