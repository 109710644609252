import React from "react";
import { Tooltip } from "@chakra-ui/tooltip";
import { Avatar as CIAvatar, AvatarProps, HStack } from "@chakra-ui/react";

type Props = {
  values?: string[];
  src?: string;
  extendend?: boolean;
  alt?: string;
  className?: string;
} & AvatarProps;

export const Avatar: React.FC<Props> = ({
  values,
  src,
  extendend = false,
  alt,
  size = "sm",
  ...otherProps
}) => {
  const letters =
    (values &&
      values.map((value, idx) =>
        value ? value.charAt(0).toUpperCase() : ""
      )) ||
    [];

  const avatarComponent = (
    <CIAvatar
      src={src || undefined}
      name={letters.join("")}
      size={size}
      {...otherProps}
    />
  );

  return extendend ? (
    <HStack>
       {avatarComponent}
      <span style={{ marginLeft: "0.5em" }}>
        {values && values.join(" ").length > 1 ? values.join(" ") : alt}
      </span>
    </HStack>
  ) : (
    <Tooltip
      label={values && values.join(" ").length > 1 ? values.join(" ") : alt}
      placement="top"
    >
      {avatarComponent}
    </Tooltip>
  );
};
