import { FullCalendarViews, UserPreferences } from "../types";

const DEFAULT_USER_PREFERENCES: UserPreferences = {
  allDaySlot: false,
  weekends: false,
  slotDuration: "00:30:00",
  slotMinTime: "06:00:00",
  slotMaxTime: "20:00:00",
  businessDaysOfWeek: [1, 2, 3, 4, 5],
  businessStartTime: "09:00",
  businessEndTime: "18:00",
  gcalendars: [],
  calendarView: FullCalendarViews.TIME_GRID_WEEK,
};

export default DEFAULT_USER_PREFERENCES;
