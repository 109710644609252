import { useMutation, useQueryClient } from "react-query";
import api from "../services/api";
import { TimeBlockingEvent, User } from "../types";

export const useUpdateTimeBlockingEvent = (userId: User["uid"]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (timeBlock: TimeBlockingEvent) =>
      api.setTimeBlockingEvent(userId, timeBlock.uid, timeBlock),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          `user-${userId}-time-blocking-events` as any
        );
        queryClient.invalidateQueries(
          `user-${userId}-time-blocking-not-assigned` as any
        );
      },
    }
  );
};
