import { makeAutoObservable } from "mobx";
import { ProjectUpdate } from "../types";
import api from "../services/api";

export class ProjectUpdateStore {
    projectUpdate: ProjectUpdate[] = [];
    loading = false;
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    fetchProjectUpdates = async (projectId: string) => {
        this.loading = true;
        this.error = null;
        try {
            const data = await api.fetchProjectUpdates(projectId);
            this.projectUpdate = data;
        } catch (error) {
            throw error;
        } finally {
            this.loading = false;
            this.error = null;
        }
    };

    refresh = async (projectId: string) => {
        const data = await api.fetchProjectUpdates(projectId);
        this.projectUpdate = data;
    }

    submitUpdate = async (
        project_id: string,
        proUpdates: ProjectUpdate
    ) => {
        this.loading = true;
        try {
            if (proUpdates.updateId) {
                const expense = await api.updateProjectUpdate(
                    project_id,
                    proUpdates
                );

                for (let i = 0; i < this.projectUpdate.length; i++) {
                    if (this.projectUpdate[i].updateId === proUpdates.updateId) {
                        this.projectUpdate[i] = { ...proUpdates };
                    }
                }
            } else {
                const proUpdate = await api.addProjectUpdate(
                    project_id,
                    proUpdates
                );

                this.projectUpdate.push(proUpdate);
            }
        } catch (err) {
            throw err;
        } finally {
            this.loading = false;
            if (project_id) {
                this.refresh(project_id);
            }
        }
    };


    deleteProjectUpdate = async (project_id: string, update_id: string) => {
        this.loading = true;
        try {
            await api.deleteProjectUpdate(project_id, update_id);

            this.projectUpdate = this.projectUpdate.filter((exp) => exp.updateId !== update_id);
        } catch (err) {
            throw err;
        } finally {
            this.loading = false;;
        }
    }
}

export const projectUpdateStore = new ProjectUpdateStore();