import { makeAutoObservable } from "mobx";
import api from "../services/api";
import { TimeBlockingEvent, User } from "../types";

export default class Timer {
  userId: User["uid"];
  event: TimeBlockingEvent;
  startTime: number;

  constructor(
    userId: User["uid"],
    startTime: number,
    event: TimeBlockingEvent
  ) {
    this.userId = userId;
    this.event = event;
    this.startTime = startTime;

    makeAutoObservable(this);
  }

  stop = async () => {
    const stopDateTime = new Date();
    const diff = Math.trunc((stopDateTime.getTime() - this.startTime) / 1000);

    this.event.elapsedTime += diff;
    await api.setTimeBlockingEvent(this.userId, this.event.uid, this.event);
    await api.deleteTimer(this.userId);
    // }
  };
}
