import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "../DatePicker";
import { Icon } from "../Icon";
import { DatePeriodPickerContainer } from "./styled";

type Period = {
  from: Date;
  to: Date;
};

type Props = {
  value: Period;
  fromCaption: string;
  toCaption: string;
  onChange: (period: Period) => void;
};

export const TimePeriodPicker: React.FC<Props> = ({
  value,
  fromCaption,
  toCaption,
  onChange,
}) => {
  //const inputEl = useRef(null);
  const handleFromDateChange = useCallback(
    (date) => {
      if (date) {
        onChange({ ...value, from: date });
      }
    },
    [onChange, value]
  );

  const handleToDateChange = useCallback(
    (date) => {
      if (date) {
        onChange({ ...value, to: date });
      }
    },
    [onChange, value]
  );

  return (
    <DatePeriodPickerContainer>
      <DatePicker
        // @ts-ignore
        showTimeSelect
        showTimeSelectOnly
        dateFormat={"HH:mm"}
        timeFormat={"HH:mm"}
        selectedDate={value.from}
        onChange={(date) => handleFromDateChange(date)}
        timeCaption={fromCaption}
      />
      <Icon iconName={"HiOutlineArrowNarrowRight"} />
      <DatePicker
        //ref={inputEl}
        // @ts-ignore
        showTimeSelect
        showTimeSelectOnly
        dateFormat={"HH:mm"}
        timeFormat={"HH:mm"}
        selectedDate={value.to}
        onChange={(date) => handleToDateChange(date)}
        popperPlacement={"bottom-end"}
        timeCaption={toCaption}
        // autoFocus
      />
    </DatePeriodPickerContainer>
  );
};
