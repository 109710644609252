import React, { useCallback, useEffect, useRef, useState } from "react";
import { Motion, spring } from "react-motion";
import styled from "styled-components";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

export const Menu = styled.div`
  background-color: #fff;
  // border: 1px solid black;
  width: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 0px 5px 0px;
`;

const MenuItemContainer = styled.div`
  cursor: pointer;
  color: black;
  padding: 0.5rem;
   &:hover {
    background-color: #e2e8f0;
  }
`;

export const MenuItem = ({ onMouseDown, children }) => {
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      onMouseDown(event);
    },
    [onMouseDown]
  );

  return (
    <MenuItemContainer onMouseDown={handleClick}>{children}</MenuItemContainer>
  );
};

export default ({ parentRef, children }) => {
  const contextMenuRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const parent = parentRef.current;
    if (!parent) return;

    const showMenu = (event) => {
      event.preventDefault();
      setIsVisible(true);
      setX(event.offsetX);
      setY(event.offsetY);
    };

    const closeMenu = () => {
      setIsVisible(false);
    };

    parent.addEventListener("contextmenu", showMenu);
    window.addEventListener("click", closeMenu);

    return () => {
      parent.removeEventListener("contexmenu", showMenu);
      window.removeEventListener("click", closeMenu);
    };
  });

  useOnClickOutside(contextMenuRef, () => setIsVisible(false));

  return (
    <Motion
      defaultStyle={{ opacity: 0 }}
      style={{ opacity: !isVisible ? spring(0) : spring(1) }}
    >
      {(interpolatedStyle) => (
        <>
          {isVisible ? (
            <div
              ref={contextMenuRef}
              className="menu-container"
              style={{
                position: "absolute",
                top: y,
                left: x,
                opacity: interpolatedStyle.opacity,
                zIndex: 1000,
              }}
            >
              {children}
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </Motion>
  );
};
