export const getUsetifulScript = (userId: string, lng: string) => `
window.usetifulTags = { userId: '${userId}', language: '${lng}'};
(function (w, d, s) {
    var a = d.getElementsByTagName('head')[0];
    var r = d.createElement('script');
    r.async = 1;
    r.src = s;
    r.setAttribute('id', 'usetifulScript');
    r.dataset.token = "5592050f07d939173ef5c2499fdbed37";
                        a.appendChild(r);
  })(window, document, "https://www.usetiful.com/dist/usetiful.js");
`;
