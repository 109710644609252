import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { OverheadForm } from "../OverheadForm";
import { Overhead } from "../../types";

type Props = {
  overhead: Overhead | null;
  isVisible: boolean;
  onDismiss: () => void;
  onSubmit: (overhead: Partial<Overhead>) => void;
  onDelete: (id: Overhead["uid"]) => void;
};

export const OverheadModal: React.FC<Props> = ({
  overhead,
  isVisible,
  onSubmit,
  onDismiss,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} onClose={onDismiss} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {overhead
            ? t("screens.overheads.editOverhead")
            : t("screens.overheads.newOverhead")}
        </ModalHeader>
        <ModalBody>
          <OverheadForm
            overhead={overhead}
            onCancel={onDismiss}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
