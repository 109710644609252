import { Button } from "@chakra-ui/button";
import { Checkbox, CheckboxGroup } from "@chakra-ui/checkbox";
import { HStack, Stack } from "@chakra-ui/layout";
import { NumberInput, NumberInputField } from "@chakra-ui/number-input";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecurrenceRule } from "../types";
import { DatePicker } from "./DatePicker";
import { Select } from "./Select";

type Props = {
  rule: RecurrenceRule | null;
  onCancel: () => void;
  onSubmit: (rule: RecurrenceRule) => void;
};

const FREQUENCIES = ["DAILY", "WEEKLY", "MONTHLY", "YEARLY"];

export const RecurrenceForm: React.FC<Props> = ({
  rule,
  onCancel,
  onSubmit,
}) => {
  const [every, setEvery] = useState<number>(rule?.every || 1);
  const [frequency, setFrequency] = useState<RecurrenceRule["frequency"]>(
    rule?.frequency || "DAILY"
  );
  const [dayOfWeeks, setDayOfWeeks] = useState(rule?.dayOfWeeks || []);
  const [until, setUntil] = useState(rule?.until || null);
  const [repeat, setRepeat] = useState(rule?.repeat || null);
  const [endCondition, setEndCondition] = useState<string>(
    rule?.until ? "date" : rule?.repeat ? "after" : "never"
  );

  const { t } = useTranslation();

  const frequencyOptions = useMemo(
    () =>
      FREQUENCIES.map((frequency) => ({
        label: t(`eventTile.${frequency}`),
        value: frequency,
      })),
    [t]
  );

  const getSelectedFrequency: any = (frequency: string) => {
    return {
      value: frequency,
      label: t(`eventTile.${frequency}`),
    };
  };

  const dayOfWeekOptions = ["0", "1", "2", "3", "4", "5", "6"].map(
    (dayOfWeek) => ({
      label: t(`dayOfWeekShort.${dayOfWeek}`),
      value: dayOfWeek,
    })
  );

  const handleSubmit = useCallback(() => {
    onSubmit({
      every,
      frequency,
      until,
      repeat,
      dayOfWeeks,
    });
  }, [every, frequency, until, repeat, dayOfWeeks, onSubmit]);

  return (
    <Stack>
      <HStack>
        <span style={{ marginRight: "8px" }}>
          {t<string>("eventTile.repeatEvery")}
        </span>
        <NumberInput
          precision={0}
          min={1}
          max={99}
          defaultValue={1}
          value={every}
          style={{ width: "5rem" }}
        >
          <NumberInputField
            value={every}
            onChange={(e) => setEvery(parseInt(e.target.value))}
          />
        </NumberInput>
        <div style={{ flexGrow: 1 }}>
          <Select
            options={frequencyOptions}
            value={getSelectedFrequency(frequency)}
            onChange={(option) => {
              setFrequency(
                (option?.value as RecurrenceRule["frequency"]) || "DAILY"
              );
              setDayOfWeeks([]);
            }}
          />
        </div>
      </HStack>

      {frequency === "WEEKLY" && (
        <div style={{ padding: "8px 0" }}>
          <div style={{ marginBottom: "6px" }}>
            {t<string>("eventTile.repeatOn")}
          </div>
          <CheckboxGroup
            value={dayOfWeeks.map((item) => item.toString())}
            colorScheme={"teal"}
            onChange={(list) => {
              console.log(list);
              setDayOfWeeks(list.map((item) => parseInt(item as string)));
            }}
          >
            <HStack spacing={4}>
              {dayOfWeekOptions.map((option) => (
                <Checkbox key={option.value} value={option.value}>
                  {option.label}
                </Checkbox>
              ))}
            </HStack>
          </CheckboxGroup>
        </div>
      )}

      <Stack>
        <div style={{ marginBottom: "6px" }}>{t<string>("eventTile.ends")}</div>
        <RadioGroup
          onChange={(value) => {
            setEndCondition(value);
            setRepeat(null);
            setUntil(null);
          }}
          value={endCondition}
        >
          <Stack spacing={2}>
            <HStack>
              <Radio value={"never"}>{t<string>("eventTile.never")}</Radio>
            </HStack>

            <HStack>
              <Radio value={"date"}>{t<string>("eventTile.date")}</Radio>
              <DatePicker
                // @ts-ignore
                dateFormat={"dd/MM/yyyy"}
                selectedDate={until || undefined}
                onChange={(date) => {
                  setUntil(date || null);
                }}
              />
            </HStack>
            <HStack alignItems={"center"} alignContent={"center"}>
              <Radio value={"after"}>{t<string>("eventTile.after")}</Radio>
              <NumberInput
                precision={0}
                min={0}
                max={99}
                defaultValue={0}
                value={repeat || 0}
              >
                <NumberInputField
                  value={repeat || 0}
                  onChange={(e) => setRepeat(parseInt(e.target.value))}
                />
              </NumberInput>
              <span>{t<string>("eventTile.occurrences")}</span>
            </HStack>
          </Stack>
        </RadioGroup>
      </Stack>

      <HStack justifyContent={"flex-end"} paddingTop={4} marginBottom={3}>
        <HStack>
          <Button variant={"ghost"} onClick={onCancel}>
            {t<string>("screens.board.cancelButton")}
          </Button>

          <Button colorScheme={"teal"} onClick={handleSubmit}>
            {t<string>("screens.board.confirmButton")}
          </Button>
        </HStack>
      </HStack>
    </Stack>
  );
};
