import React, { useEffect, useMemo, useState } from "react";
import Project from "../../../../models/Project";
import styled from "styled-components";
import { Box, Text } from "@chakra-ui/react";
import { BarBudgetConsumptionChart } from "../../../ChartComponents/BarBudgetConsumptionChart";
import LineChartDates from "../../../ChartComponents/LineChartDates";
import { toCurrency } from "../../../../utils/string";
import { observer } from "mobx-react";

interface ProjectInsightsProps {
  project: Project;
}

export const ProjectInsightsBeta = observer(
  ({ project }: ProjectInsightsProps) => {
    const [budgetData, setBudgetData] = useState<{
      totalBudget: number;
      confirmedHours: number;
      unconfirmedHours: number;
      plannedHours: number;
      // externalCosts: number;
      totalExpenses: number;
    } | null>(null);

    const totalExpenses = useMemo(() => {
      return (project.expenses || []).reduce((total, expense) => {
        if (expense.confirmed_amount) {
          if (expense.confirmed_amount === 0) {
            return (
              total +
              (expense?.estimated_amount
                ? parseFloat(expense.estimated_amount.toString())
                : 0)
            );
          } else {
            return (
              total +
              (expense?.confirmed_amount
                ? parseFloat(expense.confirmed_amount.toString())
                : 0)
            );
          }
        }
        return total;
      }, 0);
    }, [project.expenses]);

    useEffect(() => {
      if (project.projectStatstics && project.budget) {
        const budgetData = {
          totalBudget: project.budget ? Number(project.budget) : 0,
          confirmedHours: project.projectStatstics.totalConfirmedHoursCost,
          unconfirmedHours: project.projectStatstics.totalUnconfirmedHoursCost,
          plannedHours: project.projectStatstics.totalPlannedHoursCost,
          // externalCosts: 0,
          totalExpenses,
        };
        setBudgetData(budgetData);
      } else {
        setBudgetData(null);
      }
    }, [project.projectStatstics, project, totalExpenses]);

    if (!project.projectStatstics) {
      return <div />;
    }

    return (
      <InsightContainer>
        {/* @ts-ignore */}
        <Box
          display="flex"
          gap="20px"
          flexDirection={{ sm: "column", lg: "row" }}
        >
          <DashboardContainer>
            <DashboardItem>
              <ItemTitle>Total budget</ItemTitle>
              <ItemValue>
                {toCurrency(Number(project.projectStatstics.budget))}
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Target budget</ItemTitle>
              <ItemValue>
                {toCurrency(Number(project.projectStatstics.targetBudget))}
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Current budget used</ItemTitle>
              <ItemValue>
                {toCurrency(project.projectStatstics.currentBudgetUsed)}
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Available budget</ItemTitle>
              <ItemValue>
                {toCurrency(Number(project.projectStatstics.availableBudget))}
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Target margin</ItemTitle>
              <ItemValue>
                {project.projectStatstics.targetMarginabilityPercentage || "--"}
                %
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Current margin</ItemTitle>
              <ItemValue>
                {project.projectStatstics.currentMarginalityPercentage || "--"}%
              </ItemValue>
            </DashboardItem>
          </DashboardContainer>
          <GraphContainer>
            {/* @ts-ignore */}
            <Text fontSize="13px" fontWeight="400" color="#475467">
              Project budget consumption
            </Text>
            <Box w="100%" h="100%">
              {project.projectStatstics === null ||
              project.projectStatstics?.cumulativeTotalCosts.length === 0 ||
              budgetData === null ? (
                <Box
                  as="section"
                  w={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  h={"300px"}
                >
                  <WarrningContainer>
                    <WarrningText>
                      {"Can't Show graph wihout proper infromations!"}
                    </WarrningText>
                  </WarrningContainer>
                </Box>
              ) : (
                <BarBudgetConsumptionChart data={project.projectStatstics} />
              )}
            </Box>
          </GraphContainer>
        </Box>
        <Box
          display="flex"
          gap="20px"
          flexDirection={{ sm: "column", lg: "row" }}
        >
          <DashboardContainer>
            <DashboardItem>
              <ItemTitle>Confirmed activity hours</ItemTitle>
              <ItemValue>
                {Number(project.projectStatstics.totalConfirmedHours).toFixed(
                  1
                )}
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Cost of confirmed hours</ItemTitle>
              <ItemValue>
                {toCurrency(project.projectStatstics.totalConfirmedHoursCost)}
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Unconfirmed activity hours</ItemTitle>
              <ItemValue>
                {Number(
                  project.projectStatstics?.totalUnconfirmedHours
                ).toFixed(1)}
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Cost of unconfirmed hours</ItemTitle>
              <ItemValue>
                {toCurrency(project.projectStatstics.totalUnconfirmedHoursCost)}
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Planned activity hours</ItemTitle>
              <ItemValue>
                {Number(project.projectStatstics?.totalPlannedHours).toFixed(1)}
              </ItemValue>
            </DashboardItem>
            <DashboardItem>
              <ItemTitle>Cost of planned hours</ItemTitle>
              <ItemValue>
                {toCurrency(project.projectStatstics.totalPlannedHoursCost)}
              </ItemValue>
            </DashboardItem>
          </DashboardContainer>
          {/* <section style={{ flex: 0.5 }}> */}
          <GraphContainer style={{ flex: 1 }}>
            {/* @ts-ignore */}
            <Text fontSize="13px" fontWeight="400" color="#475467">
              Project budget forecast
            </Text>
            <Box w="100%" h="100%">
              {project.projectStatstics === null ||
              project.projectStatstics?.costsForecast.length === 0 ? (
                <Box
                  as="section"
                  w={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  h={"300px"}
                >
                  <WarrningContainer>
                    <WarrningText>
                      {"Can't Show graph wihout proper infromations!"}
                    </WarrningText>
                  </WarrningContainer>
                </Box>
              ) : (
                <LineChartDates
                  totalBudget={Number(project.projectStatstics.targetBudget)}
                  chartValues={project.projectStatstics.costsForecast}
                  budgets={budgetData}
                />
              )}
            </Box>
          </GraphContainer>
        </Box>
      </InsightContainer>
    );
  }
);

const InsightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
  background-color: #fdfdfd;
  border-radius: 8px;
  border-width: 1px;
  border-color: #e3e3e3;
  //  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 800px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;
const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  background-color: #fdfdfd;
  border-radius: 8px;
  border-width: 1px;
  border-color: #e3e3e3;
  //  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  //   max-width: 800px;
  width: 100%;
`;

const DashboardItem = styled.div`
  padding: 1rem;
`;

const ItemTitle = styled.h3`
  font-size: 0.9rem;
  color: #666;
  margin: 0 0 0.5rem 0;
  color: #475467;
`;

const ItemValue = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  color: #101828;
`;

const WarrningContainer = styled.section`
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  background-color: #e3e3e3;
`;

const WarrningText = styled.span`
  color: black;
  font-size: 14px;
  font-weight: 500;
`;
