import styled from "styled-components";

export const Card = styled.div<{ color?: string }>`
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  position: relative;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
  background: #fff;

  ${(props) => props.color && `border-left: 3px solid ${props.color}`}
`;
