import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Category } from "../../../components/Category";
import { ProjectCategoryForm } from "../../../components/ProjectCategoryForm";
import api from "../../../services/api";
import { Organization, ProjectCategory } from "../../../types";

type Props = {
  organizationId: Organization["uid"];
};

export const ProjectCategories: React.FC<Props> = ({ organizationId }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const { isLoading, isError, data: projectCategories, error } = useQuery(
    ["project-categories", organizationId],
    // @ts-ignore
    () => api.fetchProjectCategories(organizationId)
  );

  const selectedCategory = useMemo(() => {
    return selectedId
      ? (projectCategories || []).find(
          (category) => category.uid === selectedId
        )
      : null;
  }, [projectCategories, selectedId]);

  const addCategoryMutation = useMutation(
    // @ts-ignore
    (category: ProjectCategory) =>
      api.createProjectCategory(organizationId, category),
    {
      onSuccess: (category) => {
        queryClient.setQueryData("project-categories" as any, (categories) => [
          ...((categories as ProjectCategory[]) || []),
          category,
        ]);
      },
      onSettled: () => {
        queryClient.invalidateQueries("project-categories" as any);
      },
    }
  );

  const updateCategoryMutation = useMutation(
    // @ts-ignore
    (category: ProjectCategory) =>
      api.updateProjectCategory(organizationId, category),
    {
      onSettled: () => {
        queryClient.invalidateQueries("project-categories" as any);
      },
    }
  );

  const deleteCategoryMutation = useMutation(
    // @ts-ignore
    (categoryId: ProjectCategory["uid"]) =>
      api.deleteProjectCategory(organizationId, categoryId),
    {
      onSettled: () => {
        queryClient.invalidateQueries("project-categories" as any);
      },
    }
  );

  const handleModalClose = useCallback(() => {
    onModalClose();
    setSelectedId(null);
  }, [onModalClose]);

  const handleSubmitCategory = useCallback(
    async (category: ProjectCategory) => {
      try {
        console.log(category);

        if (category.uid.length === 0) {
          await addCategoryMutation.mutateAsync(category);

          toast({
            title: t("screens.projects.actions.categorySuccess"),
            status: "success",
            position: "bottom-left",
          });
        } else {
          await updateCategoryMutation.mutateAsync(category);
          toast({
            title: t("screens.projects.actions.categoryUpdateSuccess"),
            status: "success",
            position: "bottom-left",
          });
        }

        handleModalClose();
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [handleModalClose, addCategoryMutation, toast, t, updateCategoryMutation]
  );

  const handleCategoryClick = useCallback(
    (categoryId: string) => {
      setSelectedId(categoryId);
      onModalOpen();
    },
    [onModalOpen]
  );

  const handleDeleteCategory = useCallback(
    async (categoryId: ProjectCategory["uid"]) => {
      await deleteCategoryMutation.mutateAsync(categoryId);
      handleModalClose();
    },
    [deleteCategoryMutation, handleModalClose]
  );

  return (
    <Stack>
      <Stack>
        {projectCategories &&
          projectCategories.map((category) => (
            <Category
              key={category.uid}
              id={category.uid}
              label={category.name}
              color={category.color}
              onClick={handleCategoryClick}
            />
          ))}
      </Stack>
      <Button colorScheme={"teal"} variant={"ghost"} onClick={onModalOpen}>
        {t<string>("screens.projects.addCategory")}
      </Button>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {t<string>("screens.projects.addCategory")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ProjectCategoryForm
                uid={selectedCategory?.uid}
                name={selectedCategory?.name}
                color={selectedCategory?.color}
                onSubmit={handleSubmitCategory}
                onCancel={handleModalClose}
                onDelete={handleDeleteCategory}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Stack>
  );
};
