import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Box, HStack, Stack } from "@chakra-ui/react";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { Observer, observer } from "mobx-react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { BoardSettingsModal } from "../../components/BoardSettingsModal";
import { Menu, MenuItem } from "../../components/ContextMenu";
import { DeleteEventDialog } from "../../components/DeleteEventDialog";
import { EventForm } from "../../components/EventForm";
import { EventTile } from "../../components/EventTile";
import { Icon } from "../../components/Icon";
import { Portal } from "../../components/Portal/Portal";
import { useStores } from "../../hooks/useStores";
import { DateSelectArg } from "../../libs/@fullcalendar/common/calendar-utils";
import { EventClickArg } from "../../libs/@fullcalendar/common/interactions/EventClicking";
import daygridPlugin from "../../libs/@fullcalendar/daygrid";
import FullCalendar from "../../libs/@fullcalendar/react";
import { DeleteRecurrenceActions, FixMeLater } from "../../types";
import navigationUtils from "../../utils/navigation";
import { FilterContainer } from "../FilterContainer";
import {
  AvtarIcon,
  CloseIcon,
  DateNumberText,
  DateText,
  DisplayDateCurrent,
  NoDataFound,
  RightSiderContaier,
  SettingsButton,
  TextOfDay,
  TitleLeftText,
  TitleLeftTextUser,
  TodayButton,
  UserText,
  WeekDayHeaders,
} from "./styled";
import { SelectMulti } from "../../components/MultiCutomSelector";
import { useUserProjects } from "../../hooks/useUserProjects";
import { Select } from "../../components/Select";

type Props = {};

type UserDataView = {
  id: string;
  value: string;
  label: string;
  nextFiveDayValue: number[];
  isActive: boolean;
};
type PercetangeType = { day: dayjs.Dayjs; value: number };

const UserTeamViewStoreKey = 'userteamview';

export const TeamPlanTheta: React.FC<Props> = observer(() => {
  dayjs.extend(isToday);
  const { board, session, organization, ui } = useStores();
  const location = useLocation();
  const { t } = useTranslation();
  const { userId } = useParams();
  const calendarRef = useRef<FullCalendar | null>(null);

  const [projectList, setProjectList] = useState<{ label: string; value: string }[]>([])

  const [currentWeek, setCurrentWeek] = useState<dayjs.Dayjs>(
    dayjs().startOf("week")
  );

  const [selectedCharBox, setSelectedChartBox] = useState<{
    key: number;
    id: string;
    day: dayjs.Dayjs;
  } | null>(null);

  // for clanders
  const [eventData, setEventData] = useState<FixMeLater>(null);
  const [isModalEventVisible, setIsModalEventVisible] = useState(false);

  // for the events delete
  const [eventToDelete, setEventToDelete] = useState<any>(null);
  const [isOpenDeleteEventDialog, setIsOpenDeleteEventDialog] = useState(false);

  // for setting model
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);

  const {
    // isLoading: isFetchingUserProjects,
    data: userProjects = [],
  } = useUserProjects(
    session.user?.organization?.uid || "",
    session.user?.uid || ""
  );

  if (!session.isLogged) {
    return <Navigate to={"/"} />;
  }

  const organizationUsers = useMemo(() => {
    return organization.users
      ?.filter((e) => e.status === "active")
      .map(
        (user) =>
        ({
          value: user.uid,
          label: user.nominative,
        } || null)
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [organization.users]);

  useEffect(() => {
    board.init();
    session.updateWeekDays(new Date());
    organization.toggleRightSideTimeGrapView(false);
  }, []);

  useEffect(() => {
    const userId = navigationUtils.fromRoutes.userId(location.pathname);
    if (userId) {
      // board.fetchTimeBlockingEventsByDate2(userId, currentWeek.toDate(), currentWeek.add(7, 'day').toDate());
    }
    if (session.user?.organization) {
      organization.fetchProjects(session.user.organization.uid);
      organization.fetchUsers(session.user.organization.uid, true);
    }
    if (userId && organization.users) {
      // return null;
      const userData = organization.users.find((user) => user.uid === userId);
      if (window !== undefined) {
        const getUser = window.localStorage.getItem(UserTeamViewStoreKey);
        if (getUser) {
          const getUserList = Array.isArray(JSON.parse(getUser)) ? JSON.parse(getUser) : [];
          if (getUserList?.length) {
            organization.updateSelectorValues(getUserList);
          } else {
            if (userData) {
              organization.updateSelectorValues([
                {
                  label: userData.nominative,
                  value: userData.uid,
                },
              ]);
            }
          }
        }
      } else {
        if (userData) {
          organization.updateSelectorValues([
            {
              label: userData.nominative,
              value: userData.uid,
            },
          ]);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (organization.projects.length) {
      const makeSelectorValue = organization.projects.map(e => ({ label: e.title, value: e.uid }));
      setProjectList([...makeSelectorValue]);
    }
  }, [organization.projects]);

  useEffect(() => {
    // if (currentWeek) {
    session.updateWeekDays(currentWeek.toDate());
    if (session.getWorkignDays) {
      board.fetchAllUserReport();
    }
    if (organization.selectorValues) {
      if (window !== undefined) {
        window.localStorage.setItem(UserTeamViewStoreKey, JSON.stringify(organization.selectorValues))
      }
    }
  }, [currentWeek, organization.selectorValues, session.user]);

  useEffect(() => {
    if (calendarRef.current && selectedCharBox?.day) {
      const api = calendarRef.current.getApi();
      api.changeView("timeGridDay", selectedCharBox.day.toDate());
    }
    if (selectedCharBox?.id) {
      board.fetchTimeBlockingEventsByDate3(
        selectedCharBox.id,
        selectedCharBox.day.toDate(),
        selectedCharBox.day.add(1, "day").toDate()
      );
    }
  }, [calendarRef, selectedCharBox]);

  const handleDateSelect = useCallback(
    (selectInfo: DateSelectArg) => {
      setEventData({
        startTime: selectInfo.start,
        endTime: selectInfo.end,
      });
      setIsModalEventVisible(true);
    },
    [setIsModalEventVisible, setEventData]
  );

  const handleReceiveEvent = useCallback(
    async (data) => {
      const { title, start, extendedProps } = data.event;

      const end = new Date();
      end.setTime(start.getTime() + 1 * 60 * 60 * 1000); // on D&D event, end time is null
      await board.submitEvent({
        title,
        startTime: start,
        endTime: end, // on D&D event, end time is null
        source: extendedProps.source,
        sourceId: extendedProps.eventSourceId,
        eventType: "activity",
        color: extendedProps.eventColor || null,
        projectId: extendedProps.projectId || null,
        categoryId: extendedProps.categoryId || null,
      });
      data.revert(); // Revert to delete the event create on calendar by the D&D
    },
    [board]
  );

  // event modal
  const handleCancelModal = useCallback(() => {
    setEventData(null);
    setIsModalEventVisible(false);
  }, [setEventData, setIsModalEventVisible]);

  const handleSubmitEvent = useCallback(
    async (data) => {
      try {
        await board.submitEvent(data);
      } catch (err) {
        alert(JSON.stringify(err));
      } finally {
        setIsModalEventVisible(false);
      }
    },
    [board, setIsModalEventVisible]
  );

  const handleConfirmDeleteEvent = useCallback(
    (event: any) => {
      setEventToDelete(event);
      setIsOpenDeleteEventDialog(true);
    },
    [setEventToDelete, setIsOpenDeleteEventDialog]
  );

  // delete task model
  const handleCancelDeleteEventDialog = useCallback(
    () => setIsOpenDeleteEventDialog(false),
    [setIsOpenDeleteEventDialog]
  );

  const handleDeleteEvent = useCallback(
    async (event: any, deleteRecurrence?: DeleteRecurrenceActions) => {
      try {
        if (event) {
          // Recurring event instance
          if (event.id === "null" && event.extendedProps.recurrenceId) {
            const recurrenceData: { skip?: number; end_date?: number } = {};

            if (
              deleteRecurrence === DeleteRecurrenceActions.ONLY_CURRENT_EVENT
            ) {
              recurrenceData.skip = event.startTime.getTime();
            } else if (
              deleteRecurrence === DeleteRecurrenceActions.ALL_RECURRENT_EVENTS
            ) {
              recurrenceData.end_date = event.startTime - 1000;
            }

            await board.updateRecurrence(
              event.extendedProps.recurrenceId,
              recurrenceData
            );
          } else {
            await board.deleteEvent(event.id, !!deleteRecurrence);
          }
        }
      } catch (err) {
        alert(JSON.stringify(err));
      } finally {
        setEventData(null);
        setEventToDelete(null);
        setIsOpenDeleteEventDialog(false);
        setIsModalEventVisible(false);
      }
    },
    [board, setEventData, setIsModalEventVisible]
  );

  // all stuf belong to grid calnder

  const handleConfirmEventTime = useCallback(
    async (event) => {
      /*
       * If user click on GCalendar event and edit it, then create a new task
       * (so the Id must be null)
       */
      if (!event.extendedProps.isTask || event.id === "null") {
        await board.submitEvent({
          id: null, // Create new task
          title: event.title,
          startTime: event.start,
          endTime: event.end,
          elapsedTime: (event.end.getTime() - event.start.getTime()) / 1000,
          color: event.backgroundColor,
          extendedProps: event.extendedProps,
          source: event.extendedProps?.source,
          sourceId: event.extendedProps?.source ? event.id : null,
          recurrenceId: event.extendedProps?.recurrenceId || null,
        });
      } else {
        await board.submitEvent({
          id: event.id,
          elapsedTime: (event.end.getTime() - event.start.getTime()) / 1000,
        });
      }
    },
    [board]
  );

  const handleEventCompleted = useCallback(
    async (event) => {
      /*
       * If user click on GCalendar event and edit it, then create a new task
       * (so the Id must be null)
       */
      if (!event.extendedProps.isTask || event.id === "null") {
        await board.submitEvent({
          id: null, // Create new task
          title: event.title,
          startTime: event.start,
          endTime: event.end,
          elapsedTime: (event.end.getTime() - event.start.getTime()) / 1000,
          color: event.backgroundColor,
          completed: true,
          extendedProps: event.extendedProps,
          source: event.extendedProps?.source,
          sourceId: event.extendedProps?.source ? event.id : null,
          recurrenceId: event.extendedProps?.recurrenceId || null,
        });
      } else {
        await board.submitEvent({
          id: event.id,
          elapsedTime: (event.end.getTime() - event.start.getTime()) / 1000,
          completed: true,
        });
      }
    },
    [board]
  );

  const handleEditEvent = useCallback(
    (eventInfo) => {
      // TODO: Refactor
      const event = {
        title: eventInfo.event.title,
        startTime: eventInfo.event.start,
        endTime: eventInfo.event.end,
        color: eventInfo.event.backgroundColor,
        id: eventInfo.event.id,
        elapsedTime: eventInfo.event.extendedProps.elapsedTime,
        projectId: eventInfo.event.extendedProps.projectId,
      };
      setEventData(event);
      setIsModalEventVisible(true);
    },
    [setEventData, setIsModalEventVisible]
  );

  const handleCopyEvent = useCallback(
    (eventInfo) => {
      // TODO: Refactor
      const event = {
        title: eventInfo.event.title,
        startTime: eventInfo.event.start,
        endTime: eventInfo.event.end,
        color: eventInfo.event.backgroundColor,
        elapsedTime: eventInfo.event.extendedProps.elapsedTime,
        projectId: eventInfo.event.extendedProps.projectId,
        extendedProps: eventInfo.event.extendedProps,
      };
      setEventData(event);
      setIsModalEventVisible(true);
    },
    [setEventData, setIsModalEventVisible]
  );

  // for the timer handler
  const handleTimerStop = useCallback(async () => {
    try {
      await session.stopTimer();
      // await board.submitEvent({
      //   id,
      //   elapsedTime: currentElapsedTime,
      // });
    } catch (err) {
      console.log(err);
      alert(JSON.stringify(err));
    }
  }, [session]);

  const handleTimerStart = useCallback(
    async (eventId) => {
      if (session.timer !== null) {
        if (
          window.confirm(
            `Sei sicuro di voler avviare il time tracking su questo blocco e fermare gli altri attivi?`
          )
        ) {
          // const currentTimer = new Date().getTime();
          // if (board.eventTimerPlayingStart) {
          //   handleTimerStop(
          //     board.eventTimerPlayingId,
          //     (currentTimer - board.eventTimerPlayingStart.getTime()) / 1000 +
          //       (board.currentPlayingEvent?.elapsedTime || 0)
          //   );
          // }
          // board.eventTimerPlayingId = eventId;
          // board.eventTimerPlayingStart = new Date();
          await handleTimerStop();
        }
      }
      board.startTimer(eventId);
    },
    [board, session, handleTimerStop]
  );

  const handleEventClick = useCallback(
    (clickInfo: EventClickArg) => {
      /*
       * If user click on GCalendar event and edit it, then create a new task
       * (so the Id must be null)
       */
      setEventData({
        title: clickInfo.event.title,
        startTime: clickInfo.event.start,
        endTime: clickInfo.event.end,
        color: clickInfo.event.backgroundColor,
        id: clickInfo.event.extendedProps?.isTask ? clickInfo.event.id : null,
        extendedProps: clickInfo.event.extendedProps,
        source: clickInfo.event.extendedProps?.source,
        sourceId: clickInfo.event.extendedProps?.source
          ? clickInfo.event.id
          : null,
        recurrence: clickInfo.event.extendedProps?.recurrence || null,
      });

      setIsModalEventVisible(clickInfo?.event.id !== session.timer?.event.uid);
      // if (
      //   window.confirm(
      //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
      //   )
      // ) {
      //   clickInfo.event.remove();
      // }
    },
    [setEventData, setIsModalEventVisible, session, organization]
  );

  const handleResizeEvent = useCallback(
    async (eventDropInfo) => {
      let event: any = {
        title: eventDropInfo.event.title,
        startTime: eventDropInfo.event.start,
        endTime: eventDropInfo.event.end,
        color: eventDropInfo.event.backgroundColor,
        id: eventDropInfo.event.id,
      };

      if (
        (eventDropInfo.event.extendedProps.recurrenceId &&
          eventDropInfo.event.id === "null") ||
        (eventDropInfo.event.extendedProps?.source === "gcalendar" &&
          !eventDropInfo.event.extendedProps?.isTask)
      ) {
        // setEditEventInfo(eventDropInfo);
        // setIsModalEditRecurringEventVisible(true);
        event = {
          id: null, // Create new task
          title: eventDropInfo.event.title,
          startTime: eventDropInfo.event.start,
          endTime: eventDropInfo.event.end,
          elapsedTime: eventDropInfo.event.elapsedTime,
          color: eventDropInfo.event.backgroundColor,
          extendedProps: eventDropInfo.event.extendedProps,
          source: eventDropInfo.event.extendedProps?.source,
          eventType: eventDropInfo.event.extendedProps?.eventType,
          sourceId: eventDropInfo.event.extendedProps?.source ? event.id : null,
          recurrenceId: eventDropInfo.event.extendedProps?.recurrenceId || null,
        };
      }

      try {
        await board.submitEvent(event);
      } catch (err) {
        alert(JSON.stringify(err));
      }
    },
    [board]
  );

  const handleSubmitInlineEvent = useCallback((data) => console.log(data), []);

  const renderEventContent = (eventInfo) => {
    const menu = (
      <Portal className={"menu-portal"}>
        <Menu
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {!eventInfo.event.extendedProps.completed &&
            !(eventInfo.event.extendedProps.evenType === "planning") && (
              <MenuItem
                onMouseDown={() => handleConfirmEventTime(eventInfo.event)}
              >
                {t<string>("eventTile.actions.confirmTime")}
              </MenuItem>
            )}
          {!eventInfo.event.extendedProps.completed &&
            !(eventInfo.event.extendedProps.evenType === "planning") && (
              <MenuItem
                onMouseDown={() => handleEventCompleted(eventInfo.event)}
              >
                {t<string>("eventTile.actions.completed")}
              </MenuItem>
            )}
          <MenuItem onMouseDown={() => handleEditEvent(eventInfo)}>
            {t<string>("eventTile.actions.edit")}
          </MenuItem>
          <MenuItem onMouseDown={() => handleCopyEvent(eventInfo)}>
            {t<string>("eventTile.actions.copy")}
          </MenuItem>
        </Menu>
      </Portal>
    );

    const handleContextMenu = (e) => {
      const menuPortal = document.getElementsByClassName(
        "menu-portal"
      ) as HTMLCollectionOf<HTMLElement>;

      if (menuPortal && menuPortal[0]) {
        menuPortal[0].style.left = `${e.clientX}px`;
        menuPortal[0].style.top = `${e.clientY}px`;
      }
    };
    return (
      <Observer>
        {() => (
          <EventTile
            id={eventInfo.event.id}
            title={eventInfo.event.title}
            elapsedTime={parseInt(eventInfo.event.extendedProps.elapsedTime)}
            startDate={new Date(eventInfo.event.start)}
            endDate={new Date(eventInfo.event.end)}
            event={eventInfo}
            isTimerRunning={
              (eventInfo.event.id === session.timer?.event.uid &&
                !!session.timer) ||
              false
            }
            completed={eventInfo.event.extendedProps.completed}
            onTimerStop={handleTimerStop}
            onTimerStart={handleTimerStart}
            onEventClick={handleEventClick}
            // isNew={
            //   eventInfo.event.extendedProps &&
            //   eventInfo.event.extendedProps.isNew
            // }
            onSubmit={handleSubmitInlineEvent}
            menu={menu}
            eventType={eventInfo.event.extendedProps.eventType}
            source={eventInfo.event.extendedProps.source}
            onContextMenu={handleContextMenu}
          />
        )}
      </Observer>
    );
  };

  const handleUpdateUserPreferences = useCallback(
    (preferences) => {
      // alert(JSON.stringify(preferences))
      session.updateUser({ preferences });
    },
    [session]
  );
  // const handleUpdateOptions = useCallback(
  //     (option) => {
  //         let finalArray: UserDataView[] = [];
  //         // @ts-ignore
  //         if (option?.length) {
  //             // @ts-ignore
  //             finalArray = option?.map(e => {
  //                 return {
  //                     label: e?.label,
  //                     value: e?.value,
  //                 }
  //             })
  //         } else {
  //             finalArray = [];
  //         }
  //         // befor update the state delete the entry if it's deleted

  //         organization.updateSelectorValues(finalArray);
  //     },
  //     [organization]
  // );

  const showMonth = () => {
    if (currentWeek) {
      const startMomth = dayjs(currentWeek).startOf("week");
      const endMomth = dayjs(startMomth.add(7, "day"));
      if (startMomth.month() === endMomth.month()) {
        return t(`months.${currentWeek.month()}`);
      } else {
        return (
          t(`months.${startMomth.month()}`) +
          "-" +
          t(`months.${endMomth.month()}`)
        );
      }
    }
  };

  return (
    // @ts-ignore
    <Stack w="100%" h="100vh" bg={"white"}>
      <HStack w="100%" h="100%">
        <Stack w="100%" h="100%">
          <HStack w="100%" h="9%" justifyContent={"space-between"} pr="10px">
            <HStack>
              <ControlsContainer>
                <TodayButton
                  onClick={() => {
                    setCurrentWeek(dayjs().startOf("week"));
                  }}
                >
                  {t("calendar.today")}
                </TodayButton>
                <ActionButtonConitaner
                  onClick={() => {
                    setCurrentWeek(
                      dayjs(currentWeek).startOf("week").subtract(1, "week")
                    );
                  }}
                >
                  <Icon iconName="HiChevronLeft" />
                </ActionButtonConitaner>
                <ActionButtonConitaner
                  onClick={() => {
                    setCurrentWeek(
                      dayjs(currentWeek).startOf("week").add(1, "week")
                    );
                  }}
                >
                  <Icon iconName="HiChevronRight" />
                </ActionButtonConitaner>
              </ControlsContainer>
              <MonthText>{showMonth()}</MonthText>
            </HStack>
            <HStack>
              <div style={{
                minWidth: "250px",
              }}>
                <Select
                  options={projectList}
                  placeholder={t('screens.integrations.selectProject')}
                  // defaultValue={{ label: 'No project!', value: 'null' }}
                  isClearable

                  onChange={(options) => {
                    if (options?.value !== 'null' && options?.value) {
                      const findProject = organization.projects.find(e => e.uid === options.value);
                      if (findProject) {
                        const users = findProject.member?.map(e => ({ label: e.nominative, value: e.uid })) || [];
                        organization.updateSelectorValues(users);
                      }
                    } else {
                      const userData = organization.users.find((user) => user.uid === userId);
                      if (userData) {
                        organization.updateSelectorValues([
                          {
                            label: userData.nominative,
                            value: userData.uid,
                          },
                        ]);
                      }
                    }
                  }}
                // value={}
                />
              </div>
              <div
                style={{
                  minWidth: "350px",
                }}
              >
                {/* <CustomSelect /> */}
                <SelectMulti
                  // @ts-ignore
                  isMulti
                  options={organizationUsers}
                  isLoading={organization.isFetchingUsers}
                  placeholder="Select User"
                  // @ts-ignore
                  value={organization.selectorValues.map((e) => e)}
                  closeMenuOnSelect={false}
                  getValue={(option) => {
                    // handleUpdateOptions(option)
                    // handlerUserChange(option);
                    let finalArray: UserDataView[] = [];
                    // @ts-ignore
                    if (option?.length) {
                      // @ts-ignore
                      finalArray = option?.map((e) => {
                        return {
                          label: e?.label,
                          value: e?.value,
                        };
                      });
                    } else {
                      finalArray = [];
                    }
                    // befor update the state delete the entry if it's deleted
                    organization.updateSelectorValues(finalArray);
                  }}
                />
              </div>
              <SettingsButton
                aria-label={"Settings"}
                onClick={() => {
                  // board.setIsRightGridOpen(false)
                  setIsSettingsModalVisible(true);
                }}
              >
                <Icon iconName={"HiOutlineCog"} />
              </SettingsButton>
            </HStack>
          </HStack>
          <Stack
            pt={"10px"}
            px={"10px"}
            bg="white"
            flex={"1"}
            h="91%"
            pb={"10px"}
          >
            {/* <HStack>
                            {session.getWorkignDays && session.getWorkignDays.map((e, _ind) => (
                                <WeekDayHeader isCurrent={e.isToday()} key={_ind}>{e.format('DD/MM/YY')}</WeekDayHeader>
                            ))} */}
            <Container>
              <LeftContainer>
                <TitleLeftText>
                  {t("screens.teamplan.chartTitle")}
                </TitleLeftText>
              </LeftContainer>
              <RightContainer>
                <WeekDayHeaders>
                  {session.getWorkignDays &&
                    session.getWorkignDays.map((date, ind) => (
                      <TextOfDay key={date.toString() + ind}>
                        {dayjs().isSame(date, "day") ? (
                          <Box
                            as="section"
                            h={"45px"}
                            w={"45px"}
                            bg="#319795"
                            borderRadius={23}
                            justifyContent={"center"}
                            alignItems={"center"}
                            display={"flex"}
                            color={"white"}
                          >
                            <DateNumberText>{date.format("D")}</DateNumberText>
                          </Box>
                        ) : (
                          <DateNumberText>{date.format("D")}</DateNumberText>
                        )}
                        <DateText>{t(`dayOfWeek.${date.day()}`)}</DateText>
                      </TextOfDay>
                    ))}
                </WeekDayHeaders>
              </RightContainer>
            </Container>
            <ScrollableContent>
              {organization.selectorValues &&
                organization.selectorValues.length === 0 ? (
                <NoDataFound>{t("screens.teamplan.noDataFound")}</NoDataFound>
              ) : (
                organization.selectorValues.map((e) => {
                  return (
                    <>
                      <LeftContainer>
                        <TitleLeftTextUser>
                          <button
                            onClick={() => {
                              const filterData = organization.selectorValues.filter(
                                (es) => es.value !== e.value
                              );
                              organization.updateSelectorValues(filterData);
                            }}
                          >
                            <Icon iconName="HiOutlineX" size="small" />
                          </button>
                          <section
                            style={{
                              width: 40,
                              height: 40,
                            }}
                          >
                            <AvtarIcon>
                              {e?.label.charAt(0).toUpperCase() || ""}
                            </AvtarIcon>
                          </section>
                          <section style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <UserText>{e?.label || ""}</UserText>
                            <span>{board.userPrecantageInfo
                              .find((e1) => e1.userId === e.value)
                              ?.report.reduce((prev, cur) => prev += cur.hours, 0) || 0} hours</span>
                          </section>
                        </TitleLeftTextUser>
                      </LeftContainer>
                      <RightContainer>
                        <Box
                          as="section"
                          w="100%"
                          display={"flex"}
                          gap={"10px"}
                        >
                          {session.getWorkignDays &&
                            session.getWorkignDays.map((en, key) => {
                              return (
                                <FilterContainer
                                  key={en.toString() + key}
                                  onClick={() => {
                                    setSelectedChartBox({
                                      id: e.value,
                                      key: key,
                                      day: en,
                                    });
                                    organization.toggleRightSideTimeGrapView(
                                      true
                                    );
                                  }}
                                  day={en}
                                  userId={e.value}
                                  percentage={
                                    board.userPrecantageInfo
                                      .find((e1) => e1.userId === e.value)
                                      ?.report.find((e) =>
                                        e.day.isSame(en, "day")
                                      )?.percetange || 0
                                  }
                                  isSelected={
                                    selectedCharBox
                                      ? selectedCharBox.id === e.value &&
                                        selectedCharBox.key === key
                                        ? true
                                        : false
                                      : false
                                  }
                                  hours={board.userPrecantageInfo
                                    .find((e1) => e1.userId === e.value)
                                    ?.report.find((e) =>
                                      e.day.isSame(en, "day")
                                    )?.hours || 0}
                                />
                              );
                            })}
                        </Box>
                      </RightContainer>
                    </>
                  );
                })
              )}
            </ScrollableContent>
            {/* </HStack> */}
          </Stack>
        </Stack>
        {organization.isRightSideTimeGrapOpen && (
          <Stack w="25%" h="100%">
            <RightSiderContaier>
              <CloseIcon
                onClick={() => {
                  organization.toggleRightSideTimeGrapView(false);
                  setSelectedChartBox(null);
                }}
              >
                <Icon iconName="HiOutlineX" size="small2" />
              </CloseIcon>
              <DisplayDateCurrent>
                <DateNumberText>
                  {selectedCharBox ? selectedCharBox.day.format("D") : ""}
                </DateNumberText>
                <DateText>
                  {" "}
                  {selectedCharBox
                    ? t(`dayOfWeek.${selectedCharBox.day.day()}`)
                    : ""}
                </DateText>
              </DisplayDateCurrent>
              <FullCalendar
                ref={calendarRef}
                key={ui.isSecondarySidebarVisible ? "resized" : "full-width"}
                plugins={[daygridPlugin]}
                initialView="timeGridDay"
                height={"100vh"}
                headerToolbar={false}
                selectable={true}
                selectMirror={true}
                editable={true}
                droppable={true}
                eventSources={board.events}
                eventContent={renderEventContent}
                // @ts-ignore
                eventReceive={handleReceiveEvent}
                select={handleDateSelect}
                weekends={session.user?.preferences.weekends}
                slotMinTime={session.user?.preferences.slotMinTime}
                slotMaxTime={session.user?.preferences.slotMaxTime}
                slotDuration={session.user?.preferences.slotDuration}
                scrollTime={moment().format("HH:mm:ss")}
                businessHours={{
                  // days of week. an array of zero-based day of week integers (0=Sunday)
                  daysOfWeek: session.user?.preferences.businessDaysOfWeek,
                  startTime: session.user?.preferences.businessStartTime, // a start time
                  endTime: session.user?.preferences.businessEndTime, // an end time
                }}
                // @ts-ignore
                allDaySlot={false}
                nowIndicator
                dayHeaders={false}
                eventDrop={handleResizeEvent}
              />
            </RightSiderContaier>
          </Stack>
        )}
      </HStack>
      {/* EventModal */}
      {isModalEventVisible && (
        <Modal
          isOpen={isModalEventVisible}
          size={"xl"}
          onClose={handleCancelModal}
        >
          <ModalOverlay />
          {/* @ts-ignore */}
          <ModalContent maxW={"820px"}>
            <ModalCloseButton />
            <ModalBody style={{ padding: "2rem" }}>
              <EventForm
                data={eventData}
                projects={userProjects}
                isFetchingProjects={organization.isFetchingProjects}
                organizationId={session.user?.organization?.uid || ""}
                onSubmit={handleSubmitEvent}
                onCancel={handleCancelModal}
                onDelete={handleConfirmDeleteEvent}
                isSubmitting={board.isSubmitting}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {/* Settings Modal */}
      {isSettingsModalVisible && (
        <BoardSettingsModal
          preferences={session.user?.preferences}
          isVisible={isSettingsModalVisible}
          onClose={() => setIsSettingsModalVisible(false)}
          onPreferecensChange={handleUpdateUserPreferences}
          hideCalnderViewControls={false}
        />
      )}
      {/* Delete Event Alert Dialog */}
      {isOpenDeleteEventDialog && (
        <DeleteEventDialog
          event={eventToDelete}
          isOpen={isOpenDeleteEventDialog}
          onDismiss={handleCancelDeleteEventDialog}
          onConfirm={handleDeleteEvent}
        />
      )}
    </Stack>
  );
});

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
`;

const ScrollableContent = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
  overflow-y: auto;
`;

const LeftContainer = styled.section``;
const RightContainer = styled.section``;

const ControlsContainer = styled.section`
  display: flex;
  gap: 10px;
`;

const ActionButtonConitaner = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 8px;
  border-width: 2px;
  border-color: #dbdbdc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
`;

const MonthText = styled.span`
  font-size: 16px;
  font-weight: 400;
`;
