import React, { useCallback } from "react";
import { Icon } from "../Icon";
import { HStack, Text } from "@chakra-ui/react";

type Props = {
  id: string;
  label: string;
  color?: string | null;
  onClick?: (id: string) => any;
};

export const Category: React.FC<Props> = ({ color, id, label, onClick }) => {
  const handleClick = useCallback(
    (e) => {
      onClick && onClick(id);
    },
    [onClick, id]
  );

  return (
    <HStack cursor="pointer" marginBottom={"0.5rem"} onClick={handleClick}>
      <Icon iconName={"HiTag"} color={color || undefined} />
      <Text>{label}</Text>
    </HStack>
  );
};
