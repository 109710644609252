import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FullCalendarViews, UserPreferences } from "../types";
import { Formik, Form, Field, FieldProps } from "formik";
import moment from "moment";
import styled from "styled-components";
import { Checkbox } from "@chakra-ui/checkbox";
import { Stack } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { DatePicker } from "./DatePicker";
import { TimePeriodPicker } from "./TimePeriodPicker";
import { Select } from "./Select";
import { FormErrorMessage, Radio, RadioGroup } from "@chakra-ui/react";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { DAYS } from "../config/const";

type BoardSettingsFromProps = {
  preferences: UserPreferences | undefined;
  onChange: (preferences: UserPreferences) => void;
  hideCalnderViewControls: boolean;
};

export const BoardSettingsFrom: React.FC<BoardSettingsFromProps> = ({
  preferences,
  onChange,
  hideCalnderViewControls
}) => {
  const { t } = useTranslation();

  const daysOptions = useMemo(
    () =>
      Object.keys(DAYS).map((dayId) => ({
        label: t(`days.${DAYS[dayId]}`),
        value: dayId,
      })),
    []
  );
  const getSelectedBusinessDays: any = () => {
    const selectedBusinessDays = Object.keys(DAYS)
      .filter((day) => {
        if (preferences?.businessDaysOfWeek)
          return preferences?.businessDaysOfWeek.indexOf(+day) > -1;
      })
      .map((day) => ({
        label: t(`days.${DAYS[day]}`),
        value: day,
      }));
    return selectedBusinessDays;
  };

  const handleFieldChange = useCallback(
    (key, value) => {
      const userPreferences = preferences || {};

      if (key === "slotTime") {
        userPreferences["slotMinTime"] = value[0].format("HH:mm:ss");
        userPreferences["slotMaxTime"] = value[1].format("HH:mm:ss");
      } else if (key === "businessTime") {
        userPreferences["businessStartTime"] = value[0].format("HH:mm");
        userPreferences["businessEndTime"] = value[1].format("HH:mm");
      } else {
        userPreferences[key] = value;
      }

      onChange(userPreferences as UserPreferences);
    },
    [preferences, onChange]
  );

  return (
    <Container>
      <Formik initialValues={{}} onSubmit={(values, { setSubmitting }) => { }}>
        {(props) => (
          <Form>
            <Stack spacing={"1.5rem"}>
              <Field name={"slotTime"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error)}>
                    <Checkbox
                      isChecked={preferences?.allDaySlot}
                      onChange={(e) =>
                        handleFieldChange("allDaySlot", e.target.checked)
                      }
                    >
                      {t<string>("screens.board.preferences.allDaySlot")}
                    </Checkbox>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"weekends"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error)}>
                    <Checkbox
                      isChecked={preferences?.weekends}
                      onChange={(e) =>
                        handleFieldChange("weekends", e.target.checked)
                      }
                    >
                      {t<string>("screens.board.preferences.weekends")}
                    </Checkbox>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"startTime"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <FormLabel htmlFor={"startTime"}>
                      {t<string>("screens.board.preferences.slotDuration")}
                    </FormLabel>

                    <DatePicker
                      // @ts-ignore
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat={"HH:mm:ss"}
                      timeFormat={"HH:mm:ss"}
                      selectedDate={
                        moment(
                          preferences?.slotDuration,
                          "HH:mm:ss"
                        ).toDate() || undefined
                      }
                      timeCaption={""}
                      onChange={(e) => {
                        if (moment(e).get('h') === 0 && moment(e).get('m') === 0 && moment(e).get('s') === 0) {

                        } else {
                          e &&
                            handleFieldChange(
                              "slotDuration",
                              moment(e).format("HH:mm:ss")
                            );
                        }
                      }}
                      minTime={setHours(setMinutes(new Date(), 15), 0)}
                      maxTime={setHours(setMinutes(new Date(), 45), 23)}
                    // readOnly
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"slotTime"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <FormLabel htmlFor={"slotTime"}>
                      {t<string>("screens.board.preferences.slotRangeTime")}
                    </FormLabel>

                    <TimePeriodPicker
                      value={{
                        from: moment(
                          preferences?.slotMinTime,
                          "HH:mm:ss"
                        ).toDate(),
                        to: moment(
                          preferences?.slotMaxTime,
                          "HH:mm:ss"
                        ).toDate(),
                      }}
                      fromCaption={t<string>("eventTile.fromTime")}
                      toCaption={t<string>("eventTile.toTime")}
                      onChange={(e) =>
                        handleFieldChange("slotTime", [
                          moment(e.from),
                          moment(e.to),
                        ])
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"businessDaysOfWeek"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <FormLabel htmlFor={"businessDaysOfWeek"}>
                      {t<string>(
                        "screens.board.preferences.businessDaysOfWeek"
                      )}
                    </FormLabel>
                    <Select
                      options={daysOptions}
                      // @ts-ignore
                      isMulti
                      value={getSelectedBusinessDays()}
                      onChange={(e) => {
                        handleFieldChange(
                          "businessDaysOfWeek",
                          // @ts-ignore
                          e?.map((selectedOption) => +selectedOption.value) ||
                          null
                        );
                      }}
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"businessTime"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <FormLabel htmlFor={"businessTime"}>
                      {t<string>("screens.board.preferences.businessRangeTime")}
                    </FormLabel>
                    <TimePeriodPicker
                      value={{
                        from: moment(
                          preferences?.businessStartTime,
                          "HH:mm:ss"
                        ).toDate(),
                        to: moment(
                          preferences?.businessEndTime,
                          "HH:mm:ss"
                        ).toDate(),
                      }}
                      fromCaption={t<string>("eventTile.fromTime")}
                      toCaption={t<string>("eventTile.toTime")}
                      onChange={(e) =>
                        handleFieldChange("businessTime", [
                          moment(e.from),
                          moment(e.to),
                        ])
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {hideCalnderViewControls && <Field name={"calendarView"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <FormLabel htmlFor={"calendarView"}>
                      {t<string>("screens.board.preferences.calendarView")}
                    </FormLabel>
                    <RadioGroup
                      onChange={(value) =>
                        handleFieldChange("calendarView", value)
                      }
                      value={
                        preferences?.calendarView ||
                        FullCalendarViews.TIME_GRID_WEEK
                      }
                    >
                      <Stack direction="row">
                        <Radio value={FullCalendarViews.TIME_GRID_DAY}>
                          {t<string>("screens.board.preferences.timeGridDay")}
                        </Radio>
                        <Radio value={FullCalendarViews.TIME_GRID_WEEK}>
                          {t<string>("screens.board.preferences.timeGridWeek")}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>}
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
