import styled from "styled-components";
import { Button as RButton } from "rebass";

export const ActionsContainer = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 80px;
  z-index: 50;
`;

export const SettingsButton = styled.button<{ isClick?: boolean }>`
 background-color: ${({ theme, isClick }) => isClick !== undefined ? isClick ? theme.black : theme.primary : theme.white};
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  height: 2.5rem;
  width: 3rem;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  transition: all 250ms;
  color: ${(props) => props.isClick !== undefined ? props.isClick ? props.theme.white : props.theme.white : props.theme.text1} !important;
  border: 1px solid #DBDBDC;
  border-radius: 6px; 
  // box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
`;
