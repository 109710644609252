import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Heading, Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import styled from "styled-components";
import { ActionGroup } from "../../components/ActionGroup";
import { Box } from "../../components/Box";
import { PageHeader } from "../../components/PageHeader";
import { ProjectsWorkflowsTable } from "../../components/ProjectsWorkflowsTable";
import { ProjectWorkflowModal } from "../../components/ProjectWorkflowModal";
import {
  useCreateProjectWorkflow,
  useDeleteProjectWorkflow,
  useUpdateProjectWorkflow,
} from "../../hooks/useMutationProjectsWorkflows";
import { useProjectsWorkflows } from "../../hooks/useProjectsWorkflows";
import { ProjectStatus } from "../../types";

export const ProjectsWorkflows: React.FC = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const [activeItem, setActiveItem] = useState<ProjectStatus | null>(null);
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const {
    isFetching: isFetchingProjectsWorkflows,
    data: projectsWorkflows = [],
  } = useProjectsWorkflows(organizationId!);

  const {
    isLoading: isSubmitting,
    mutateAsync: addProjectWorkflow,
  } = useCreateProjectWorkflow(organizationId!);

  const {
    isLoading: isUpdating,
    mutateAsync: updateProjectWorkflow,
  } = useUpdateProjectWorkflow(organizationId!);

  const {
    isLoading: isDeleting,
    mutateAsync: deleteProjectWorkflow,
  } = useDeleteProjectWorkflow(organizationId!);

  const handleSubmit = useCallback(
    async (status: Partial<ProjectStatus>) => {
      if (status.uid) {
        await updateProjectWorkflow(status);
        setActiveItem(null);
      } else {
        await addProjectWorkflow(status);
      }
      onModalClose();
    },
    [addProjectWorkflow, onModalClose, updateProjectWorkflow]
  );

  const handleItemClick = useCallback(
    (projectStatus: ProjectStatus) => {
      setActiveItem(projectStatus);
      onModalOpen();
    },
    [onModalOpen]
  );

  const handleDeleteProjectWorkflow = useCallback(async () => {
    if (activeItem && window.confirm(t("screens.workflows.deleteWorkflow"))) {
      await deleteProjectWorkflow(activeItem);
      onModalClose();
      setActiveItem(null);
    }
  }, [activeItem, deleteProjectWorkflow, onModalClose, t]);

  return (
    <StyledStack w="100%" h="100%">
      <PageHeader>
        <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
          {t<string>("screens.workflows.title")}
        </Heading>
        <ActionGroup>
          {(isFetchingProjectsWorkflows ||
            isSubmitting ||
            isUpdating ||
            isDeleting) && <Spinner />}
          <Button colorScheme={"teal"} onClick={onModalOpen}>
            {t<string>("screens.workflows.bttAdd")}
          </Button>
        </ActionGroup>
      </PageHeader>
      <Stack>
        <Box>
          <ProjectsWorkflowsTable
            workflows={projectsWorkflows}
            onItemClick={handleItemClick}
          />
        </Box>
      </Stack>

      {isModalOpen && (
        <ProjectWorkflowModal
          isVisible={isModalOpen}
          onDismiss={() => {
            setActiveItem(null);
            onModalClose();
          }}
          onDelete={handleDeleteProjectWorkflow}
          onSubmit={handleSubmit}
          status={activeItem}
        />
      )}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
`;
