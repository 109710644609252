import styled from "styled-components";

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1.25rem;
  width: 100%;
  margin-bottom: 0.8rem;
  align-items: center;
`;
