import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addUserContract,
  deleteUserContract,
  fetchUserContracts,
  updateUserContract,
} from "../services/api";
import { Organization, User, UserContract } from "../types";

export const useUserContracts = (
  organizationId: Organization["uid"],
  userId: User["uid"]
) =>
  useQuery(
    [`user-contracts-${organizationId}-${userId}`, organizationId, userId],
    () => fetchUserContracts(organizationId, userId)
  );

export const useCreateUserContract = (
  organizationId: Organization["uid"],
  userId: User["uid"]
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Partial<UserContract>) =>
      addUserContract(organizationId, userId, data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          `user-contracts-${organizationId}-${userId}` as any
        );
      },
    }
  );
};

export const useUpdateUserContract = (
  organizationId: Organization["uid"],
  userId: User["uid"]
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Partial<UserContract>) =>
      updateUserContract(organizationId, userId, data.uid || "", data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          `user-contracts-${organizationId}-${userId}` as any
        );
      },
    }
  );
};

export const useDeleteUserContract = (
  organizationId: Organization["uid"],
  userId: User["uid"]
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (contractId: UserContract["uid"]) =>
      deleteUserContract(organizationId, userId, contractId),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          `user-contracts-${organizationId}-${userId}` as any
        );
      },
    }
  );
};
