import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RSelect, { Props, components } from "react-select";
import styled from "styled-components";
import LoadingOverly from "./LoadingOverly";

export const Option = components.Option;
export const SingleValue = components.SingleValue;

export const SelectMulti: React.FC<Props & { getValue?: (x: any) => void }> = (props) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { t } = useTranslation();
    const [isSelectAllPress, setIsSelectAllPress] = useState(false)

    useEffect(() => {
        if (props.getValue) {
            props.getValue(selectedOptions)
        }
    }, [selectedOptions])
    // if (props.isMulti) {

    const selectAllOption = {
        value: '<SELECT_ALL>',
        label: t('screens.teamplan.selectAll'),
    };


    // @ts-ignore
    const isAllSelected = selectedOptions.length === props.options.length;

    const handleSelectAll = (selected) => {
        if (selected.some(option => option.value === '<SELECT_ALL>')) {
            setIsSelectAllPress(true);
            if (isAllSelected) {
                setIsSelectAllPress(false)
                setSelectedOptions([]);
            } else {
                setTimeout(() => {
                    setIsSelectAllPress(false);
                }, 2500);
                // @ts-ignore
                setSelectedOptions(props.options);
            }
        } else {
            setSelectedOptions(selected);
        }
    };

    const selectStyles = {
        control: (styles) => ({
            ...styles,
            minHeight: "var(--chakra-sizes-10)",
            borderRadius: "var(--chakra-radii-md)",
            borderColor: "inherit",
            width: "100%",
        }),
        indicatorSeparator: () => ({ display: "none" }),
        container: (styles) => ({
            ...styles,
            flex: 1,
        }),
        ...props.styles
    };
    return (
        <>
            {/*  @ts-ignore */}
            <StyledSelect styles={selectStyles} {...props}
                // @ts-ignore
                options={isAllSelected ? [...props.options] : [selectAllOption, ...props.options]}
                onChange={handleSelectAll}
                components={{
                    Option: CustomOption,
                    // @ts-ignore
                    MultiValue: CustomMultiValue,
                    ValueContainer: CustomValueContainerComponent
                }}
            />
            <LoadingOverly isShown={isSelectAllPress} />
        </>
    );



};

const CustomOption = ({ children, ...props }) => {
    if (props.data.value === '<SELECT_ALL>') {
        return (
            // @ts-ignore
            <components.Option {...props}>
                <SelectBtnContainer >{children}</SelectBtnContainer>
            </components.Option>
        );
    }
    return (
        // @ts-ignore
        <components.Option {...props}>{children}</components.Option>
    );
};

const CustomMultiValue = (props) => {
    const { index, getValue } = props;
    const values = getValue();
    if (index > 1) return null;
    return (
        <components.MultiValue {...props}>
            {values[index].label}
        </components.MultiValue>
    );
};

const CustomValueContainerComponent = ({ children, getValue }) => {
    const values = getValue();
    const selectedCount = values.length;
    return (
        <CustomValueContainer>
            {children}
            {selectedCount > 2 && (
                <MoreIndicator>
                    +{selectedCount - 2} more
                </MoreIndicator>
            )}
        </CustomValueContainer>
    );
};

const SelectBtnContainer = styled.section`
display: flex;
justify-content: flex-end;
color: #319795;
text-decoration: underline;
`;

const MoreIndicator = styled.div`
  color: #319795;
  margin-left: 8px;
`;

const CustomValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledSelect = styled(RSelect)`
  .react-select__control {
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .react-select__value-container {
    padding: 2px 8px;
  }
`;
