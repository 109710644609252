import { Table, Thead, Tbody, Tr, Th, Td, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Webhook } from "../../types";

type Props = {
  webhooks: Webhook[];
  onItemClick: (webhook: Webhook) => any;
};

export const WebhooksTable: React.FC<Props> = ({ webhooks, onItemClick }) => {
  const { t } = useTranslation();
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="gray.400">{t<string>("screens.webhooks.url")}</Th>
          <Th color="gray.400">{t<string>("screens.webhooks.description")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {webhooks.map((webhook) => (
          <Tr key={webhook.uid}>
            <Td>
              <Link
                href={"#"}
                style={{
                  color: "var(--chakra-colors-teal-600)",
                  fontWeight: 400,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick(webhook);
                }}
              >
                {webhook.url}
              </Link>
            </Td>
            <Td>{webhook.description}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
