import { Button } from "@chakra-ui/button";
import { HStack, Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router";
import { Avatar } from "../../../Avatar";
import { PriorityIcon } from "../../../PriorityIcon";
import { VirtualizedTable } from "../../../VirtualizedTable";
import api from "../../../../services/api";
import { Task, User } from "../../../../types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box
} from '@chakra-ui/react'
import styled from "styled-components";
import { DownloadCSV } from "../../../DownloadCSV";
import { TASK_PRIORITIES } from "../../../../config/tasks";
import { useStores } from "../../../../hooks/useStores";

interface ProjectTasksProps {
  organizationId: string;
  projectId: string;
}

export const ProjectTasks: React.FC<ProjectTasksProps> = ({
  organizationId,
  projectId,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = useStores()

  // showing the task details models
  const {
    isOpen: isOpenSubTaskModal,
    onOpen: onOpenSubTaskModal,
    onClose: onCloseSubTaskModal
  } = useDisclosure();
  const [subtaskList, setSubTaskList] = useState<Task[]>([])
  const handleCloseSubtTaksModal = () => {
    onCloseSubTaskModal();
    setSubTaskList([]);
  }

  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<
    "ASC" | "DESC" | undefined
  >(undefined);

  const { isLoading, isError, data: tasksData, error } = useQuery(
    ["project-tasks"],
    // @ts-ignore
    () => api.fetchTasks(organizationId, projectId)
  );

  const handleEditTask = useCallback(
    (taskId: Task["uid"]) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("modal", "task");
      searchParams.set("taskId", taskId);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [location, navigate]
  );

  const handleOpenTaskModal = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("modal", "task");
    searchParams.set("projectId", projectId);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [navigate, location, projectId]);

  const handleSort = useCallback(
    (dataKey: string) => {
      if (dataKey === sortBy && sortDirection === "ASC") {
        setSortDirection("DESC");
      } else if (dataKey === sortBy && sortDirection === "DESC") {
        setSortBy(undefined);
        setSortDirection(undefined);
      } else {
        setSortBy(dataKey);
        setSortDirection("ASC");
      }
    },
    [sortBy, sortDirection, setSortBy, setSortDirection]
  );

  const columns = useMemo(
    () => [
      {
        width: 80,
        flexGrow: 2,
        label: t("tasks.name"),
        dataKey: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (name: Task["name"], task: Task) => (
          <div
            onClick={() => handleEditTask(task.uid)}
            style={{
              textDecoration: task.complete ? "line-through" : "none",
              cursor: "pointer",
            }}
          >
            {name}
          </div>
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("tasks.dueDate"),
        dataKey: "dueDate",
        render: (dueDate: Task["dueDate"]) =>
          dueDate ? moment(dueDate).format("DD/MM/yyyy") : null,
      },
      {
        width: 80,
        flexGrow: 1,
        label: "Activity hours",
        dataKey: "activityHours",
        render: (activityHours: Task["activityHours"]) => {
          console.log(" amount -> ", activityHours);
          return activityHours ? (activityHours / 3600).toFixed(1) : 0;
        }
      },

      {
        width: 80,
        flexGrow: 1,
        label: t("tasks.priority"),
        dataKey: "priority",
        sorter: (a, b) => a["priority"] - b["priority"],
        render: (priority: Task["priority"]) => (
          // <PriorityIcon priority={priority} extendend />
          TASK_PRIORITIES[priority].name
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("tasks.user"),
        dataKey: "user",
        sorter: (a, b) =>
          a["user"]?.nominative.localeCompare(b["user"]?.nominative),
        render: (user: User) => (
          // <Avatar values={[user.nominative]} extendend src={user.imageUrl} />
          user.nominative
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("tasks.statusName"),
        dataKey: "complete",
        render: (isCompleted: Task["complete"]) =>
          isCompleted ? "Completed" : "Open",
      },
      // {
      //   width: 50,
      //   flexGrow: 1,
      //   label: "subtask",
      //   dataKey: "subTasks",
      //   render: (subtask: Task["subTasks"]) => {
      //     if (subtask) {
      //       setSubTaskList(subtask)
      //     }
      //     return <button onClick={() => {
      //       onOpenSubTaskModal();
      //     }}>subtask</button>
      //   }
      // },
    ],
    [handleEditTask, t]
  );

  const tasks = useMemo(() => {
    let taskList = tasksData ?? [];
    if (sortBy) {
      const columnData = columns.find((column) => column.dataKey === sortBy);

      if (columnData?.sorter) {
        taskList = taskList.sort(columnData.sorter);

        if (sortDirection === "DESC") {
          taskList = taskList.reverse();
        }
      }
    }
    console.log("takslinkst -> ", taskList);

    return taskList;
  }, [columns, sortBy, sortDirection, tasksData]);

  const csvData = useMemo(() => {
    const rows = [
      [
        t("tasks.name").toUpperCase(),
        t("tasks.description").toUpperCase(),
        t("tasks.project").toUpperCase(),
        t("tasks.category").toUpperCase(),
        t("nav.organization").toUpperCase(),
        t("tasks.dueDate").toUpperCase(),
        "Activity Hours".toUpperCase(),
        t("tasks.priority").toUpperCase(),
        t("screens.projects.reports.user").toUpperCase(),
        t("tasks.statusName").toUpperCase(),
      ],
    ];

    if (tasksData) {
      for (let data of tasksData) {
        rows.push([
          data.name,
          data.description || '',
          data.project?.title || '',
          data.category?.name || '',
          session.user?.organization?.name || '',
          moment(data.dueDate).format("DD/MM/YY HH:mm"),
          data.activityHours ? (data.activityHours / 3600).toFixed(1) : "0",
          TASK_PRIORITIES[data.priority].name?.toString(),
          data.user.nominative,
          data.complete ? "Completed" : "Open",
        ]);
      }
    }

    return rows;
  }, [tasksData, t]);

  if (isLoading || !tasksData) {
    return <Spinner />;
  }

  // csv
  const downloadPDF = () => {

  };



  return (
    <Stack>
      <HStack justifyContent={"flex-end"} gap={2}>
        {/* <DownloadCSV data={csvData} target={"_blank"} separator={";"} /> */}
        {/* <Button
          colorScheme={"teal"}
          variant={"ghost"}
          size={"sm"}
          leftIcon={(<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 1.25H14C14.1989 1.25 14.3897 1.32902 14.5303 1.46967C14.671 1.61032 14.75 1.80109 14.75 2V3.1895C14.75 3.3884 14.6709 3.57913 14.5303 3.71975L9.71975 8.53025C9.57909 8.67087 9.50004 8.8616 9.5 9.0605V13.7892C9.5 13.9033 9.474 14.0158 9.424 14.1182C9.37399 14.2207 9.30129 14.3104 9.21141 14.3805C9.12153 14.4506 9.01685 14.4994 8.90532 14.523C8.79378 14.5466 8.67834 14.5445 8.56775 14.5167L7.06775 14.1417C6.90557 14.1011 6.76161 14.0075 6.65874 13.8757C6.55588 13.7438 6.5 13.5814 6.5 13.4142V9.0605C6.49996 8.8616 6.42091 8.67087 6.28025 8.53025L1.46975 3.71975C1.32909 3.57913 1.25004 3.3884 1.25 3.1895V2C1.25 1.80109 1.32902 1.61032 1.46967 1.46967C1.61032 1.32902 1.80109 1.25 2 1.25Z" stroke="#319795" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          )}
          onClick={() => handleOpenTaskModal()}
        >
          {"Filter"}
        </Button> */}
        <DownloadCSV data={csvData} target={"_blank"} separator={";"} />
        <Button
          colorScheme={"teal"}
          size={"sm"}
          onClick={() => handleOpenTaskModal()}
        >
          {t<string>("tasks.addTask")}
        </Button>
      </HStack>
      <div
        style={{
          flex: "1 1 auto",
          width: "100%",
          height: `${(tasks.length + 1) * 50}px`,
          display: "flex",
        }}
      >
        <VirtualizedTable
          rowCount={tasks.length}
          rowGetter={({ index }) => tasks[index]}
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onHeaderCellClick={handleSort}
          rowStyle={(row) =>
            row.index === 0 ? { borderTop: '2px solid rgb(238, 243, 245)', } : {}
          }
        />
        {isOpenSubTaskModal &&
          <Modal isOpen={isOpenSubTaskModal} onClose={handleCloseSubtTaksModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Sub task Details</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {subtaskList && subtaskList.map(e => {
                  return (
                    <Box as='section' display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <TaskName>{e.name}</TaskName>
                      <TaskName>{e.complete ? "Completed" : "Open"}</TaskName>
                    </Box>
                  )
                })}
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={handleCloseSubtTaksModal}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>}
      </div>
    </Stack>
  );
};

const TaskName = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: black;
`