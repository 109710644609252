import { useQuery } from "react-query";
import api from "../services/api";
import { Organization, User } from "../types";

export const useUserProjects = (
  organizationId: Organization["uid"],
  userId: User["uid"]
) =>
  useQuery(["user-projects", { organizationId, userId }], () =>
    api.fetchUserProjects(organizationId, userId)
  );
