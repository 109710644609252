import React from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Button,
    VStack,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
} from "@chakra-ui/react";
import { ModalFooterForm } from '../../../ModalFooterForm';
import { ProjectLinksStore } from '../../../../models/ProjectLInks';
import { ProjectLinks } from '../../../../types';

interface NewLinkFormValues {
    title: string;
    url: string;
}

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Name is required'),
    // url: Yup.string().url('Invalid URL').required('Link is required'),
});

interface NewLinkFormProps {
    isOpen: boolean;
    onClose: () => void;
    onDelete?: (value: ProjectLinks) => void;
    onSubmit?: (value: ProjectLinks) => void;
    pLInks: ProjectLinks | null
}

const ProjectDetailsLinkForm: React.FC<NewLinkFormProps> = ({ isOpen, onClose, onDelete, onSubmit, pLInks }) => {
    // const initialValues: ProjectLinks = { title: '', link: '' };

    // const handleSubmit = (
    //     values: ProjectLinks,
    //     { setSubmitting }: FormikHelpers<ProjectLinks>
    // ) => {
    //     // Handle form submission here
    //     console.log(values);
    //     // setSubmitting(false);
    //     if (onSubmit) {
    //         onSubmit(values)
    //     }
    //     // projectLinksStore.submitLinks
    //     onClose();
    // };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                {/* @ts-ignore */}
                <ModalHeader>{pLInks?.linkId ? "Edit Link" : "Add Link"}</ModalHeader>
                <ModalCloseButton />
                <Formik
                    initialValues={{
                        linkId: pLInks?.linkId ? pLInks.linkId : null,
                        title: pLInks?.title ? pLInks.title : '',
                        url: pLInks?.url ? pLInks.url : ''
                    }}
                    validationSchema={validationSchema}
                    // @ts-ignore
                    onSubmit={(
                        values: ProjectLinks,
                        { setSubmitting }: FormikHelpers<ProjectLinks>
                    ) => {
                        setSubmitting(false);
                        if (onSubmit) {
                            onSubmit(values);
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <Field type={"hidden"} name={"linkId"} />
                            <ModalBody>
                                <VStack spacing={4}>
                                    <Field name="title">
                                        {({ field }) => (
                                            <FormControl isInvalid={!!errors.title && touched.title}>
                                                <FormLabel htmlFor="title">Name</FormLabel>
                                                <Input {...field} id="title" placeholder="Name" />
                                                <FormErrorMessage>{errors.title}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="url">
                                        {({ field }) => (
                                            <FormControl isInvalid={!!errors.url && touched.url}>
                                                <FormLabel htmlFor="url">Link</FormLabel>
                                                <Input {...field} id="url" placeholder="Link" />
                                                <FormErrorMessage>{errors.url}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </VStack>
                            </ModalBody>
                            <Box px="24px">
                                <ModalFooterForm
                                    onCancel={onClose}
                                    onDelete={onDelete && pLInks ? () => onDelete(pLInks) : undefined}
                                />
                            </Box>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default ProjectDetailsLinkForm;