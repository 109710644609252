import { useQuery } from "react-query";
import api from "../services/api";
import { Organization, User } from "../types";
import moment from "moment";

export const useUserInsights = (
  organizationId: Organization["uid"],
  userId: User["uid"],
  fromDate: Date,
  toDate: Date
) =>
  useQuery(
    [
      "userInsights",
      organizationId,
      userId,
      fromDate.toISOString,
      toDate.toISOString,
    ],
    () =>
      api.fetchUserInsights(
        organizationId,
        userId,
        moment(fromDate),
        moment(toDate)
      ),
    {
      enabled: false,
    }
  );
