import Project from "../models/Project";
import { TaskCategory } from "../types";

export const getSelectedTaskCategory = (
  categoryId: TaskCategory["uid"] | null,
  categories: TaskCategory[]
): { value: string; label: string; color?: string | null } | null => {
  if (!categoryId) return null;

  const taskCategory = categories.find(
    (categoriy) => categoriy.uid === categoryId
  );

  return taskCategory
    ? {
        value: taskCategory.uid,
        label: taskCategory.name,
        color: taskCategory.color,
      }
    : null;
};

export const getSelectedProject = (
  projectId: Project["uid"],
  projects: Project[]
): { value: string; label: string } | null => {
  if (!projectId) return null;

  const project = projects.find((project) => project.uid === projectId);

  return project
    ? {
        value: project.uid,
        label: project.title,
      }
    : null;
};
