import { Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import { UserContract } from "../../../types";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { toCurrency } from "../../../utils/string";
import { DAYS } from "../../../config/const";

type Props = {
  contracts: UserContract[];
  onEdit: (userContract: UserContract) => void;
};

export const UserContractsTables: React.FC<Props> = ({ contracts, onEdit }) => {
  const { t } = useTranslation();
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="gray.400">
            {t<string>("components.userContractsTable.fromData")}
          </Th>
          <Th color="gray.400">
            {t<string>("components.userContractsTable.toDate")}
          </Th>
          <Th color="gray.400">
            {t<string>("components.userContractsTable.hourlyCost")}
          </Th>
          <Th color="gray.400">
            {t<string>("components.userContractsTable.contractualHours")}
          </Th>
          <Th color="gray.400">
            {t<string>("components.userContractsTable.contractType")}
          </Th>
          <Th color="gray.400">
            {t<string>("components.userContractsTable.productivityTarget")}
          </Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {contracts.map((contract) => (
          <Tr key={contract.uid}>
            <Td>{dayjs(contract.fromDate).format("DD/MM/YYYY")}</Td>
            <Td>{dayjs(contract.toDate).format("DD/MM/YYYY")}</Td>
            <Td>
              {contract.hourlyCost ? toCurrency(contract.hourlyCost) : "-"}
            </Td>
            <Td whiteSpace={"pre"}>
              {contract.contractType === "full-time" &&
              contract.contractualHours
                ? contract.contractualHours
                : contract.contractType === "part-time" &&
                  contract.contractualHoursByDay
                ? Object.keys(contract.contractualHoursByDay)
                    .map((el, dayId) =>
                      contract.contractualHoursByDay?.[el] !== 0
                        ? `${
                            contract.contractualHoursByDay?.[el]
                          } - ${t<string>(`days.${DAYS[dayId]}`)}\r\n`
                        : ""
                    )
                    .join("") || "-"
                : "-"}
            </Td>
            <Td>{contract.contractType || "-"}</Td>
            <Td>
              {contract.productivityTarget
                ? `${contract.productivityTarget} %`
                : "-"}
            </Td>
            <Td>
              <Button
                colorScheme="teal"
                variant="ghost"
                onClick={() => onEdit(contract)}
                size="sm"
              >
                {t<string>("common.edit")}
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
