import { Checkbox } from "@chakra-ui/checkbox";
import { Heading, Stack } from "@chakra-ui/layout";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton } from "../../components/Button";
import { Column } from "../../components/Column";
import { Icon } from "../../components/Icon";
import { IntegrationIcon } from "../../components/IntegrationIcon";
import integrations from "../../config/integrations";
import api from "../../services/api";
import { ColumnHeader } from "./styled";
import { Draggable } from "../../libs/@fullcalendar/interaction";
import { ExternalEvent } from "../../components/ExternalEvent";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";

export const JiraView: React.FC = observer(() => {
  const SORUCE = "jira";

  const [types, setTypes] = useState<any[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);

  const filteredTask = useMemo(() => {
    if (!activeFilter) {
      return tasks;
    } else {
      return tasks.filter((task) => task.type === activeFilter);
    }
  }, [tasks, activeFilter]);

  useEffect(() => {
    fetchData();

    let draggableEl = document.getElementById("external-events");
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("data-title");
          let eventSourceId = eventEl.getAttribute("data-source-id");
          let source = eventEl.getAttribute("data-source");
          return {
            title,
            eventSourceId,
            source,
          };
        },
      });
    }
  }, []);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    const jiraData = await api.getJiraTasks();

    const issueTypes = [];

    for (let task of jiraData.tasks) {
      // @ts-ignore
      if (issueTypes.indexOf(task.fields.issuetype.name) === -1) {
        // @ts-ignore
        issueTypes.push(task.fields.issuetype.name);
      }
    }
    setTypes(issueTypes);
    setTasks(
      jiraData.tasks.map((task) => ({
        id: task.key,
        title: task.fields.summary,
        elapsedTime: 0,
        due: null,
        projectId: null,
        description: task.fields.description,
        type: task.fields.issuetype.name,
        icon: (
          <img
            src={task.fields.issuetype.iconUrl}
            alt={task.fields.issuetype.name}
          />
        ),
        //extendedProps: event.extendedProps || {},
      }))
    );
    setIsFetching(false);
  }, [setTasks, setIsFetching, setTypes]);

  const handleToggleFilter = useCallback(
    (key, checked) => {
      setActiveFilter(checked ? key : null);
    },
    [setActiveFilter]
  );

  return (
    <Column>
      <ColumnHeader>
        <Heading as={"h4"} size={"md"} fontWeight={500}>
          {integrations[SORUCE].title}
        </Heading>
        <div>
          <Popover>
            <PopoverTrigger>
              <IconButton style={{ marginLeft: "1rem" }}>
                <Icon iconName={"HiOutlineFilter"} />
              </IconButton>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack>
                  {types.map((type) => (
                    <Checkbox
                      isChecked={activeFilter === type}
                      onChange={(event) =>
                        handleToggleFilter(type, event.target.checked)
                      }
                    >
                      {type}
                    </Checkbox>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <IconButton onClick={fetchData}>
            <Icon iconName={"HiRefresh"} />
          </IconButton>
        </div>
      </ColumnHeader>
      {isFetching && <div>Loading...</div>}

      <div
        id={"external-events"}
        style={{
          height: "100%",
          flex: "1 1 auto",
          position: "relative",
          overflowY: "auto",
          marginLeft: "0.2rem",
          overflowX: "hidden",
        }}
      >
        {filteredTask.map((task) => (
          <ExternalEvent
            className={"fc-event"}
            title={task.title}
            sourceId={task.id}
            key={task.id}
            source={SORUCE}
            description={task.description}
            icon={task.icon}
          >
            {task.title}
          </ExternalEvent>
        ))}
      </div>
    </Column>
  );
});
