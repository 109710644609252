export const TASK_PRIORITIES = {
  10: {
    name: "lowest",
  },
  20: {
    name: "low",
  },
  30: {
    name: "medium",
  },
  40: {
    name: "high",
  },
  50: {
    name: "highest",
  },
};
