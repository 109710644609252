import React, { useMemo } from "react";
import styled from "styled-components";
import { Button as RebassButton } from "rebass/styled-components";
import { transparentize } from "polished";
import { Icon } from "../Icon";
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import dayjs from "dayjs";
import { useCallback } from "react";
import { Period } from "../../types";

type CalendarNavButtonsProps = {
  initialDate?: Date;
  type?: "week";
  disabled?: boolean;
  onChange?: (period: Period) => void;
};

export const CalendarNavButtons: React.FC<CalendarNavButtonsProps> = ({
  initialDate = new Date(),
  type = "week",
  disabled = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(dayjs(initialDate));

  const isDisabledTodayButton = useMemo(() => {
    return (
      (type === "week" &&
        dayjs().startOf("week").isSame(date.startOf("week"))) ||
      disabled
    );
  }, [date, disabled, type]);

  const emitOnChange = useCallback(
    (newDate: dayjs.Dayjs) => {
      let period: Period;
      switch (type) {
        case "week":
          period = {
            fromDate: newDate.startOf("week").toDate(),
            toDate: newDate.endOf("week").toDate(),
          };
          break;
        default:
          period = {
            fromDate: newDate.toDate(),
            toDate: newDate.toDate(),
          };
      }
      onChange && onChange(period);
    },
    [onChange, type]
  );

  const onPrevClick = useCallback(() => {
    let newDate: dayjs.Dayjs;
    switch (type) {
      case "week":
        newDate = date.subtract(1, "week");
        break;
      default:
        newDate = date;
    }
    setDate(newDate);
    emitOnChange(newDate);
  }, [date, emitOnChange, type]);

  const onTodayClick = useCallback(() => {
    let newDate: dayjs.Dayjs;
    switch (type) {
      case "week":
        newDate = dayjs();
        break;
      default:
        newDate = date;
    }
    setDate(newDate);
    emitOnChange(newDate);
  }, [date, emitOnChange, type]);

  const onNextClick = useCallback(() => {
    let newDate: dayjs.Dayjs;
    switch (type) {
      case "week":
        newDate = date.add(1, "week");
        break;
      default:
        newDate = date;
    }
    setDate(newDate);
    emitOnChange(newDate);
  }, [date, emitOnChange, type]);

  return (
    // @ts-ignore
    <Flex gap={"10px"}>
      <TodayButton disabled={isDisabledTodayButton} onClick={onTodayClick}>
        {t<string>("calendar.today")}
      </TodayButton>
      <ActionButtonConitaner onClick={onPrevClick} disabled={disabled}>
        <Icon iconName={"HiChevronLeft"} />
      </ActionButtonConitaner>
      <ActionButtonConitaner onClick={onNextClick} disabled={disabled}>
        <Icon iconName={"HiChevronRight"} />
      </ActionButtonConitaner>
    </Flex>
  );
};

const TodayButton = styled.button`
  width: auto;
  height: 38px;
  padding: 5px 15px 5px 15px;
  border-radius: 8px;
  border-width: 2px;
  border-color: #dbdbdc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.white};

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: ${({ theme }) => theme.text1};
`;

const ActionButtonConitaner = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 8px;
  border-width: 2px;
  border-color: #dbdbdc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.white};
   &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
`;