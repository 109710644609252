import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ChartDataValueT, ProjectStatstics } from "../../types";
import { toCurrency } from "../../utils/string";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface BudgetData {
  totalBudget: number;
  confirmedHours: number;
  unconfirmedHours: number;
  plannedHours: number;
  // externalCosts: number;
  totalExpenses: number;
}

const generateDates = (startDate, count) => {
  const dates = [];
  let currentDate = new Date(startDate);
  for (let i = 0; i < count; i++) {
    // @ts-ignore
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

const formatDate = (date: Date | string | null | undefined) => {
  if (!date) return "";

  const d = date instanceof Date ? date : new Date(date);

  if (isNaN(d.getTime())) return ""; // Invalid date

  return d.toLocaleDateString();
};

const BudgetLineChart = ({
  totalBudget,
  chartValues,
  budgets,
}: {
  totalBudget: number;
  chartValues: ProjectStatstics["costsForecast"];
  budgets?: BudgetData | null;
}) => {
  const generateBudgetData = () => {
    // return Array.from({ length: count }, () => Math.floor(Math.random() * 15000) + 5000);
    return chartValues
      .filter((e) => {
        if (new Date(e.date) <= new Date()) {
          return true;
        }
        return false;
      })
      .map((e) => e.total_cost)
      .map((e) => e + Number(budgets?.totalExpenses || 0));
  };

  const dataPointCount = 10;
  const budgetLimit = totalBudget || 120;
  const currentDate = new Date(); // Set a fixed date for demonstration

  const budgetData = generateBudgetData();

  const data = {
    labels: chartValues.map((e) => new Date(e.date)),
    datasets: [
      {
        label: "Target Budget",
        data: budgetData,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
        borderWidth: 4.5,
      },
      {
        label: "Budget Limit",
        data: Array(dataPointCount).fill(budgetLimit),
        borderColor: "rgb(255, 99, 132)",
        // borderDash: [5, 5],
        fill: false,
        borderWidth: 4.5,
        pointRadius: 0,
      },
      {
        label: "Forecast",
        data: chartValues.map(
          (e) => e.total_cost + Number(budgets?.totalExpenses || 0)
        ),
        borderColor: "rgb(75, 192, 192, 0.5)",
        borderDash: [5, 5],
        fill: false,
        pointRadius: 0,
        borderWidth: 2.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Daily Budget Over Time (Actual and Planned)",
        font: {
          size: 18,
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: (context) => {
            const date = new Date(context[0].label);
            return `Date: ${formatDate(date)}`;
          },
          label: (context) => {
            const value = context.raw;
            const label = context.dataset.label;
            if (label === "Budget") {
              const date = new Date(context.label);
              const status = date <= currentDate ? "Actual" : "Planned";
              return `${status} Budget: ${toCurrency(value)}`;
            }
            return `${label}: ${toCurrency(value)}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
          font: {
            size: 14,
          },
        },
        ticks: {
          callback: function (value, index) {
            // console.log("value -< ", value, " index => ", index);

            // // @ts-ignore
            // console.log(this.getLabelForValue(value));
            // @ts-ignore
            const date = new Date(this.getLabelForValue(value));
            // if (date.getDate() % 5 === 1) {
            //     return formatDate(date);
            // }
            // const data = [
            //     "2024-09-10T05:00:00.000Z".split("T")[0],
            //     "2024-09-15T23:40:00.000Z".split("T")[0],
            //     "2024-09-21T18:20:00.000Z".split("T")[0],
            //     "2024-09-27T13:00:00.000Z".split("T")[0],
            //     "2024-10-03T07:40:00.000Z".split("T")[0],
            //     "2024-10-09T02:20:00.000Z".split("T")[0],
            //     "2024-10-14T21:00:00.000Z".split("T")[0],
            //     "2024-10-20T15:40:00.000Z".split("T")[0],
            //     "2024-10-26T10:20:00.000Z".split("T")[0],
            //     "2024-11-01T05:00:00.000Z".split("T")[0]
            // ]
            return date.toISOString().split("T")[0];
            // return formatDate(date);
          },
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 12,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Budget",
          font: {
            size: 14,
          },
        },
        beginAtZero: true,
        suggestedMax: Math.max(...budgetData, budgetLimit) * 1.1,
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "400px", margin: "auto" }}>
      {/* @ts-ignore */}
      <Line data={data} options={options} />
    </div>
  );
};

export default BudgetLineChart;
