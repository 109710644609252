import React from "react";
import FilterActiveIcon from "./assets/filter-active.svg";
import FilterEmptyIcon from "./assets/filter-empty.svg";

type Props = {
  isActive?: boolean;
};

export const FilterIndicator: React.FC<Props> = ({ isActive }) => {
  return (
    <img
      src={isActive ? FilterActiveIcon : FilterEmptyIcon}
      alt={"filter"}
      style={{ width: "14px", height: "14px" }}
    />
  );
};
