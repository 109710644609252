import { BaseComponent, RefMap, RenderHook } from "../common";
import { TimeColsAxisCell } from "./TimeColsAxisCell";
import { TimeSlatMeta } from "./time-slat-meta";
import React from "react";
import { SlotLaneContentArg } from "../common/render-hook-misc";
import styled from "styled-components";

export interface TimeColsSlatsBodyProps {
  axis: boolean;
  slatMetas: TimeSlatMeta[];
  slatElRefs: RefMap<HTMLTableRowElement>;
}

export class TimeColsSlatsBody extends BaseComponent<TimeColsSlatsBodyProps> {
  render() {
    let { props, context } = this;
    let { options } = context;
    let { slatElRefs } = props;

    return (
      <tbody>
        {props.slatMetas.map((slatMeta, i) => {
          let hookProps: SlotLaneContentArg = {
            time: slatMeta.time,
            date: context.dateEnv.toDate(slatMeta.date),
            view: context.viewApi,
          };

          let classNames: string[] = ["fc-timegrid-slot"];
          // [
          //   "fc-timegrid-slot",
          //   "fc-timegrid-slot-lane",
          //   slatMeta.isLabeled ? "" : "fc-timegrid-slot-minor",
          // ];

          return (
            <tr key={slatMeta.key} ref={slatElRefs.createRef(slatMeta.key)}>
              {props.axis && <TimeColsAxisCell {...slatMeta} />}
              {/* @ts-ignore */}
              <RenderHook
                hookProps={hookProps}
                classNames={options.slotLaneClassNames}
                content={options.slotLaneContent}
                didMount={options.slotLaneDidMount}
                willUnmount={options.slotLaneWillUnmount}
              >
                {(rootElRef, customClassNames, innerElRef, innerContent) => (
                  <Cell
                    isLabeled={slatMeta.isLabeled}
                    ref={rootElRef}
                    className={classNames.concat(customClassNames).join(" ")}
                    data-time={slatMeta.isoTimeStr}
                  >
                    {innerContent}
                  </Cell>
                )}
              </RenderHook>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

const Cell = styled.td<{ isLabeled?: boolean }>`
  border: 1px solid #ebebeb;
  border-top-color: ${(props) => (props.isLabeled ? "#EBEBEB" : "#FAFAFA")};
`;
