import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { Input } from "@chakra-ui/input";
import * as Yup from "yup";
import { ModalFooterForm } from "../ModalFooterForm";
import { ProjectStatus } from "../../types";
import { Stack } from "@chakra-ui/layout";
import { Checkbox } from "@chakra-ui/react";

interface Values {
  uid: string | undefined;
  name: string;
  selectedByDefault: boolean;
}

type Props = {
  status: ProjectStatus | null;
  onCancel: () => any;
  onSubmit: (status: Values) => any;
  onDelete?: () => any;
};

export const ProjectWorkflowForm: React.FC<Props> = ({
  status,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uid: status?.uid || undefined,
      name: status?.name || "",
      selectedByDefault:
        status?.selectedByDefault !== undefined
          ? status?.selectedByDefault
          : true,
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(
          t("messages.formValidations.projectWorkflowForm.nameRequired")
        )
        .min(2, t("messages.formValidations.projectWorkflowForm.nameRequired")),
    }),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1rem"} direction={"column"}>
        <FormControl
          isInvalid={
            Boolean(formik.errors.name) && formik.getFieldMeta("name").touched
          }
        >
          <StyledFormLabel>
            {t<string>("screens.workflows.name")}
          </StyledFormLabel>
          <Input
            name={"name"}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(formik.errors.selectedByDefault)}>
          <Checkbox
            name={"selectedByDefault"}
            isChecked={formik.values.selectedByDefault}
            onChange={(e) => {
              formik.setFieldValue("selectedByDefault", e.target.checked);
            }}
          >
            {t<string>("screens.workflows.selectedByDefault")}
          </Checkbox>
          <FormErrorMessage>{formik.errors.selectedByDefault}</FormErrorMessage>
        </FormControl>

        <ModalFooterForm
          onCancel={onCancel}
          onDelete={(status && status.editable && onDelete) || undefined}
        />
      </Stack>
    </form>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
