import { makeApiRequest } from ".";
import { Organization, Webhook } from "../../types";
import { toWebhook } from "../utils";

export const fetchWebhooks = async (
  organizationId: Organization["uid"]
): Promise<Webhook[]> => {
  const response = await makeApiRequest(
    "GET",
    `organization/${organizationId}/webhooks`
  );

  return response.map((item: any) => toWebhook(item));
};

export const addWebhook = async (
  organizationId: Organization["uid"],
  webhook: Pick<Webhook, "url" | "description" | "events">
): Promise<Webhook> => {
  const response = await makeApiRequest(
    "POST",
    `organization/${organizationId}/webhooks`,
    { ...webhook, events: webhook.events.join(",") }
  );

  return toWebhook(response);
};

export const updateWebhook = async (
  organizationId: Organization["uid"],
  webhookId: Webhook["uid"],
  webhook: Webhook
): Promise<Webhook> => {
  const response = await makeApiRequest(
    "PATCH",
    `organization/${organizationId}/webhooks/${webhookId}`,
    { ...webhook, events: webhook.events.join(",") }
  );

  return toWebhook(response);
};

export const deleteWebhook = async (
  organizationId: Organization["uid"],
  webhookId: Webhook["uid"]
): Promise<Webhook> => {
  const response = await makeApiRequest(
    "DELETE",
    `organization/${organizationId}/webhooks/${webhookId}`
  );

  return toWebhook(response);
};
