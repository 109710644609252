import { Table, Thead, Tbody, Tr, Th, Td, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ProjectStatus } from "../../types";

type Props = {
  workflows: ProjectStatus[];
  onItemClick: (projectStatus: ProjectStatus) => any;
};

export const ProjectsWorkflowsTable: React.FC<Props> = ({
  workflows,
  onItemClick,
}) => {
  const { t } = useTranslation();
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="gray.400">{t<string>("screens.workflows.name")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {workflows.map((status) => (
          <Tr key={status.uid}>
            <Td>
              <Link
                href={"#"}
                style={{
                  color: "var(--chakra-colors-teal-600)",
                  fontWeight: 400,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick(status);
                }}
              >
                {status.name}
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
