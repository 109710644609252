import it from "./it";
import en from "./en";

export default {
  it: {
    translation: it,
  },
  en: {
    translation: en,
  },
};
