import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { InputGroup, InputLeftAddon } from "@chakra-ui/input";
import { HStack, Stack } from "@chakra-ui/layout";
import { NumberInput, NumberInputField } from "@chakra-ui/react";
import { Overhead } from "../../types";
import { DatePicker } from "../DatePicker";
import { ModalFooterForm } from "../ModalFooterForm";

type Props = {
  overhead: Overhead | null;
  onCancel: () => void;
  onSubmit: (overhead: Partial<Overhead>) => void;
  onDelete: (id: Overhead["uid"]) => void;
};

const NOW = new Date();

export const OverheadForm: React.FC<Props> = ({
  overhead,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uid: overhead?.uid || undefined,
      amount: overhead?.amount || 0,
      fromDate: overhead?.fromDate || NOW,
      toDate: overhead?.toDate || NOW,
    },
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1rem"} direction={"column"}>
        <HStack>
          <FormControl
            isInvalid={
              Boolean(formik.errors.fromDate) &&
              formik.getFieldMeta("fromDate").touched
            }
          >
            <StyledFormLabel htmlFor={"fromDate"}>
              {t<string>("components.overheadForm.fromDate")}
            </StyledFormLabel>
            <DatePicker
              // @ts-ignore
              dateFormat={"dd/MM/yyyy"}
              selectedDate={formik.values.fromDate}
              onChange={(date: any) => {
                formik.setFieldValue("fromDate", date);
                if (
                  formik.values.toDate?.getTime() < (date as Date).getTime()
                ) {
                  formik.setFieldValue("toDate", date);
                }
              }}
            />
            <FormErrorMessage>{`${formik.errors.fromDate}`}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              Boolean(formik.errors.fromDate) &&
              formik.getFieldMeta("toDate").touched
            }
          >
            <StyledFormLabel htmlFor={"toDate"}>
              {t<string>("components.overheadForm.toDate")}
            </StyledFormLabel>
            <DatePicker
              // @ts-ignore
              dateFormat={"dd/MM/yyyy"}
              selectedDate={formik.values.toDate}
              onChange={(date) => {
                formik.setFieldValue("toDate", date);
              }}
              minDate={formik.values.fromDate}
            />
            <FormErrorMessage>{`${formik.errors.toDate}`}</FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack alignItems={"flex-start"}>
          <FormControl
            isInvalid={
              Boolean(formik.errors.amount) &&
              formik.getFieldMeta("amount").touched
            }
          >
            <StyledFormLabel htmlFor={"amount"}>
              {t<string>("components.overheadForm.amount")}
            </StyledFormLabel>
            <InputGroup>
              <InputLeftAddon children="€" />
              <NumberInput
                precision={2}
                name="amount"
                value={formik.values.amount || undefined}
                w="6rem"
              >
                <NumberInputField
                  name="amount"
                  value={formik.values.amount || undefined}
                  onChange={formik.handleChange}
                />
              </NumberInput>
            </InputGroup>
            <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
          </FormControl>
        </HStack>

        <ModalFooterForm
          onCancel={onCancel}
          onDelete={overhead?.uid ? () => onDelete(overhead.uid) : undefined}
        />
      </Stack>
    </form>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
