import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { toCurrency } from "../../utils/string";
import Project from "../../models/Project";

interface BudgetConsumptionChartProps {
  data: Project["projectStatstics"];
}

export const BarBudgetConsumptionChart: React.FC<BudgetConsumptionChartProps> = ({
  data,
}) => {
  const chartData = [
    {
      name: "Total budget",
      ...data,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout="vertical"
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis axisLine={false} type="number" hide />
        <YAxis dataKey={"name"} yAxisId={0} type="category" hide />
        <Tooltip
          formatter={(value, name, props) => [toCurrency(Number(value)), name]}
        />
        <Legend />
        <Bar
          dataKey="targetBudget"
          stackId="total"
          fill="rgb(255, 99, 132)"
          name="Target Budget"
        />
        <Bar
          dataKey="totalConfirmedHoursCost"
          stackId="consumption"
          fill="rgba(22, 160, 133, 1)"
          name="Confirmed Hours"
        />
        <Bar
          dataKey="totalUnconfirmedHoursCost"
          stackId="consumption"
          fill="rgba(241, 196, 15, 1)"
          name="Unconfirmed Hours"
        />
        <Bar
          dataKey="totalPlannedHoursCost"
          stackId="consumption"
          fill="rgba(142, 202, 230, 1)"
          name="Planned Hours"
        />
        {/* <Bar
          dataKey="externalCosts"
          stackId="consumption"
          fill="rgba(52, 152, 219, 1)"
          name="External Costs"
        /> */}
        <Bar
          dataKey="totalExpenses"
          stackId="consumption"
          fill="rgb(33,125,187)"
          name="Total Expenses"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
