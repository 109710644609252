import { StatLabel, StatNumber } from "@chakra-ui/stat";
import styled from "styled-components";

export const StyledStatLabel = styled(StatLabel)`
  color: #475467;
  font-size: 0.85rem !important;
  font-weight: 400 !important;
`;

export const StyledStatNumber = styled(StatNumber)`
  font-weight: 400 !important;
  font-size: 1.25rem !important;
`;
