import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Alert,
  AlertIcon,
  Button,
  Container,
  FormControl,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Customer } from "../../types";
import { slugify } from "../../utils/string";

type Props = {
  customer: Customer | null;
  isVisible: boolean;
  onDismiss: () => void;
  onSubmit: (customerId: any) => void;
};

export const DeleteConfirmModal: React.FC<Props> = ({
  customer,
  isVisible,
  onDismiss,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uid: customer?.uid || null,
      secureText: "",
    },
    onSubmit,
  });

  const secureText = `${slugify(
    `${t("screens.board.deleteButton")} ${customer?.nominative}` ||
      t("screens.board.deleteButton")
  )}`;

  return (
    <Modal isOpen={isVisible} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("screens.customers.actions.delete.title")}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Alert status="warning">
            <AlertIcon />
            {t("screens.customers.actions.delete.alert")}
          </Alert>
          <Container paddingX="0" marginY="2">
            {t("screens.customers.actions.delete.message")}
          </Container>

          <Container paddingX="0" marginY="2">
            <Trans i18nKey="screens.customers.actions.delete.confirm">
              Please type
              {/* @ts-ignore */}
              <b>{{ secureText }}</b> to confirm.
            </Trans>
          </Container>

          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={"1rem"} direction={"column"}>
              <FormControl>
                <Input
                  size="lg"
                  name={"secureText"}
                  value={formik.values.secureText}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Stack>
            <Stack marginY="3" direction={"column"}>
              <Button
                variant={"outline"}
                colorScheme={"red"}
                type={"submit"}
                disabled={formik.values.secureText !== secureText}
              >
                {t<string>("screens.board.deleteButton")}
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
