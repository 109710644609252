import { type } from "os";
import "./main.css";

// exports
// --------------------------------------------------------------------------------------------------

export const version: string = "<%= version %>"; // important to type it, so .d.ts has generic string

// explicit API
export { EventSourceApi } from "./api/EventSourceApi";
export { EventApi, buildEventApis } from "./api/EventApi";
export { CalendarApi } from "./CalendarApi";
export { formatDate, formatRange } from "./formatting-api";
export type { FormatDateOptions, FormatRangeOptions } from "./formatting-api";
export { ViewApi } from "./ViewApi";
// export * from "./api-type-deps";

export type {
  // things for plugins. everything else is exported in api-type-deps
  Identity,
  Dictionary,
  BaseOptionRefiners,
  BaseOptionsRefined,
  CalendarOptionRefiners,
  CalendarOptionsRefined,
  ViewOptionRefiners,
  ViewOptionsRefined,
  RawOptionsFromRefiners,
  RefinedOptionsFromRefiners,
  CalendarListenerRefiners,
} from "./options";

export {
  BASE_OPTION_DEFAULTS,
  BASE_OPTION_REFINERS,
  identity,
  refineProps,
} from "./options";

export type { EventDef, EventDefHash } from "./structs/event-def";
export type {
  EventInstance,
  EventInstanceHash,
} from "./structs/event-instance";

export { createEventInstance } from "./structs/event-instance";
export type {
  EventInput,
  EventRefined,
  EventTuple,
  EventRefiners,
} from "./structs/event-parse";

export { refineEventDef, parseEventDef } from "./structs/event-parse";

export type { BusinessHoursInput } from "./structs/business-hours";

export { parseBusinessHours } from "./structs/business-hours";

export {
  padStart,
  isInt,
  parseFieldSpecs,
  compareByFieldSpecs,
  compareByFieldSpec,
  flexibleCompare,
  preventSelection,
  allowSelection,
  preventContextMenu,
  allowContextMenu,
  compareNumbers,
  enableCursor,
  disableCursor,
  guid,
  computeSmallestCellWidth,
} from "./util/misc";

export type { OrderSpec } from "./util/misc";

export {
  computeVisibleDayRange,
  isMultiDayRange,
  diffDates,
} from "./util/date";

export { removeExact, isArraysEqual } from "./util/array";

export {
  memoize,
  memoizeObjArg,
  memoizeArraylike,
  memoizeHashlike,
} from "./util/memoize";

export {
  intersectRects,
  pointInsideRect,
  constrainPoint,
  getRectCenter,
  diffPoints,
  translateRect,
} from "./util/geom";

export type { Rect, Point } from "./util/geom";

export {
  mapHash,
  filterHash,
  isPropsEqual,
  compareObjs,
  buildHashFromArray,
  collectFromHash,
  getUnequalProps,
} from "./util/object";

export {
  findElements,
  findDirectChildren,
  removeElement,
  applyStyle,
  applyStyleProp,
  elementMatches,
  elementClosest,
} from "./util/dom-manip";
export { parseClassNames } from "./util/html";

export { getCanVGrowWithinCell } from "./util/table-styling";

export type { EventStore } from "./structs/event-store";
export {
  filterEventStoreDefs,
  createEmptyEventStore,
  mergeEventStores,
  getRelevantEvents,
  eventTupleToStore,
} from "./structs/event-store";
export { combineEventUis, createEventUi } from "./component/event-ui";
export type { EventUiHash, EventUi } from "./component/event-ui";
export { Splitter } from "./component/event-splitting";
export type { SplittableProps } from "./component/event-splitting";
export {
  getDayClassNames,
  getDateMeta,
  getSlotClassNames,
} from "./component/date-rendering";
export type { DateMeta } from "./component/date-rendering";
export { buildNavLinkData } from "./common/nav-link";

export {
  preventDefault,
  listenBySelector,
  whenTransitionDone,
} from "./util/dom-event";

export {
  computeInnerRect,
  computeEdges,
  computeHeightAndMargins,
  getClippingParents,
  computeRect,
} from "./util/dom-geom";

export { unpromisify } from "./util/promise";

export { Emitter } from "./common/Emitter";
export {
  rangeContainsMarker,
  intersectRanges,
  rangesEqual,
  rangesIntersect,
  rangeContainsRange,
} from "./datelib/date-range";
export type { DateRange } from "./datelib/date-range";
export { PositionCache } from "./common/PositionCache";
export {
  ScrollController,
  ElementScrollController,
  WindowScrollController,
} from "./common/scroll-controller";
export { Theme } from "./theme/Theme";
export { ViewContextType } from "./ViewContext";
export type { ViewContext } from "./ViewContext";
export { DateComponent } from "./component/DateComponent";
export type {
  Seg,
  EventSegUiInteractionState,
} from "./component/DateComponent";
export type { CalendarData } from "./reducers/data-types";
export { CalendarDataManager } from "./reducers/CalendarDataManager";
export { CalendarDataProvider } from "./component/CalendarDataProvider";
export type { CalendarDataProviderProps } from "./component/CalendarDataProvider";
export { sliceEvents } from "./View";
export type { ViewProps } from "./View";

export { DateProfileGenerator } from "./DateProfileGenerator";
export type { DateProfile } from "./DateProfileGenerator";
export type { ViewDef } from "./structs/view-def";
export type { ViewSpec } from "./structs/view-spec";
export type { DateSpan, DateSpanApi, DatePointApi } from "./structs/date-span";
export { isDateSpansEqual } from "./structs/date-span";

export {
  addDays,
  startOfDay,
  addMs,
  addWeeks,
  diffWeeks,
  diffWholeWeeks,
  diffWholeDays,
  diffDayAndTime,
  diffDays,
  isValidDate,
} from "./datelib/marker";
export type { DateMarker } from "./datelib/marker";
export {
  createDuration,
  asCleanDays,
  multiplyDuration,
  addDurations,
  asRoughMinutes,
  asRoughSeconds,
  asRoughMs,
  wholeDivideDurations,
  greatestDurationDenominator,
} from "./datelib/duration";
export type { Duration } from "./datelib/duration";
export { DateEnv } from "./datelib/env";
export type { DateMarkerMeta } from "./datelib/env";

export { createFormatter } from "./datelib/formatting";
export type { FormatterInput } from "./datelib/formatting";
export type {
  DateFormatter,
  VerboseFormattingArg,
} from "./datelib/DateFormatter";
export {
  formatIsoTimeString,
  formatDayString,
} from "./datelib/formatting-utils";
export { NamedTimeZoneImpl } from "./datelib/timezone";
export { parse as parseMarker } from "./datelib/parsing";
export type { LocaleInput } from "./datelib/locale";

export type { EventSourceDef } from "./structs/event-source-def";
export type { EventSource, EventSourceHash } from "./structs/event-source";
export type {
  EventSourceRefiners,
  EventSourceRefined,
} from "./structs/event-source-parse";

export {
  Interaction,
  interactionSettingsToStore,
  interactionSettingsStore,
} from "./interactions/interaction";
export type {
  InteractionSettings,
  InteractionSettingsStore,
} from "./interactions/interaction";
export type { PointerDragEvent } from "./interactions/pointer";
export type { Hit } from "./interactions/hit";
export type { dateSelectionJoinTransformer } from "./interactions/date-selecting";
export type {
  eventDragMutationMassager,
  EventDropTransformers,
} from "./interactions/event-dragging";
export type { EventResizeJoinTransforms } from "./interactions/event-resizing";
export { ElementDragging } from "./interactions/ElementDragging";

export { config } from "./global-config";
export { globalLocales } from "./global-locales";

export type { RecurringType, ParsedRecurring } from "./structs/recurring-event";

export { parseDragMeta } from "./structs/drag-meta";
export type { DragMetaInput, DragMeta } from "./structs/drag-meta";

export type {
  PluginDef,
  PluginDefInput,
  ViewPropsTransformer,
  ViewContainerAppend,
} from "./plugin-system-struct";
export { createPlugin } from "./plugin-system";
export type { Action } from "./reducers/Action";
export type { CalendarContext } from "./CalendarContext";
export { CalendarContent } from "./CalendarContent";
export type { CalendarContentProps } from "./CalendarContent";
export { CalendarRoot } from "./CalendarRoot";

export { DayHeader } from "./common/DayHeader";
export { computeFallbackHeaderFormat } from "./common/table-utils";
export { TableDateCell } from "./common/TableDateCell";
export { TableDowCell } from "./common/TableDowCell";

export { DaySeriesModel } from "./common/DaySeriesModel";

export type { EventInteractionState } from "./interactions/event-interaction-state";
export {
  sliceEventStore,
  hasBgRendering,
  setElSeg,
  getElSeg,
  computeSegDraggable,
  computeSegStartResizable,
  computeSegEndResizable,
  getEventClassNames,
  buildSegTimeText,
  buildSegCompareObj,
  sortEventSegs,
  getSegMeta,
  buildEventRangeKey,
} from "./component/event-rendering";

export type {
  EventRenderRange,
  EventContentArg,
} from "./component/event-rendering";

export type { DayTableSeg, DayTableCell } from "./common/DayTableModel";
export { DayTableModel } from "./common/DayTableModel";

export { Slicer } from "./common/slicing-utils";
export type { SlicedProps } from "./common/slicing-utils";

export type { EventMutation } from "./structs/event-mutation";
export { applyMutationToEventStore } from "./structs/event-mutation";
export type {
  Constraint,
  ConstraintInput,
  AllowFunc,
} from "./structs/constraint";
export { isPropsValid, isInteractionValid } from "./validation";

export { requestJson } from "./util/requestJson";

export {
  Component,
  Fragment,
  createContext,
  createElement,
  createRef,
} from "./vdom";
export type {
  ComponentType,
  ComponentChildren,
  ComponentChild,
  Ref,
  RefObject,
  VNode,
  VUIEvent,
  Context,
} from "./vdom";
export { BaseComponent, setRef } from "./vdom-util";
export { DelayedRunner } from "./util/DelayedRunner";

export type {
  ScrollGridProps,
  ScrollGridSectionConfig,
  ColGroupConfig,
  ScrollGridImpl,
  ScrollGridChunkConfig,
} from "./scrollgrid/ScrollGridImpl";
export type { SimpleScrollGridSection } from "./scrollgrid/SimpleScrollGrid";
export { SimpleScrollGrid } from "./scrollgrid/SimpleScrollGrid";
export {
  hasShrinkWidth,
  renderMicroColGroup,
  getScrollGridClassNames,
  getSectionClassNames,
  getSectionHasLiquidHeight,
  getAllowYScrolling,
  renderChunkContent,
  computeShrinkWidth,
  sanitizeShrinkWidth,
  isColPropsEqual,
  renderScrollShim,
  getStickyFooterScrollbar,
  getStickyHeaderDates,
} from "./scrollgrid/util";
export type {
  CssDimValue,
  ScrollerLike,
  SectionConfig,
  ColProps,
  ChunkConfig,
  ChunkContentCallbackArgs,
  ChunkConfigRowContent,
  ChunkConfigContent,
} from "./scrollgrid/util";
export type { ScrollerProps, OverflowValue } from "./scrollgrid/Scroller";
export { Scroller } from "./scrollgrid/Scroller";
export { getScrollbarWidths } from "./util/scrollbar-width";
export { RefMap } from "./util/RefMap";
export { getIsRtlScrollbarOnLeft } from "./util/scrollbar-side";

export { NowTimer } from "./NowTimer";
export { ScrollResponder } from "./ScrollResponder";
export type { ScrollRequest } from "./ScrollResponder";
export { globalPlugins } from "./global-plugins";
export {
  RenderHook,
  MountHook,
  buildClassNameNormalizer,
  ContentHook,
  CustomContentRenderContext,
} from "./common/render-hook";
export type {
  RenderHookProps,
  RenderHookPropsChildren,
  MountHookProps,
  ClassNamesGenerator,
  CustomContentGenerator,
  DidMountHandler,
  WillUnmountHandler,
  MountArg,
} from "./common/render-hook";
export type { StandardEventProps } from "./common/StandardEvent";
export { StandardEvent } from "./common/StandardEvent";
export { NowIndicatorRoot } from "./common/NowIndicatorRoot";
export type { NowIndicatorRootProps } from "./common/NowIndicatorRoot";

export type { DayCellRootProps, DayCellContentArg } from "./common/DayCellRoot";
export { DayCellRoot } from "./common/DayCellRoot";
export type { DayCellContentProps } from "./common/DayCellContent";
export { DayCellContent } from "./common/DayCellContent";
export type { MinimalEventProps } from "./common/EventRoot";
export { EventRoot } from "./common/EventRoot";
export type { BgEventProps } from "./common/bg-fill";
export { renderFill, BgEvent } from "./common/bg-fill";
export type { WeekNumberRootProps } from "./common/WeekNumberRoot";
export { WeekNumberRoot } from "./common/WeekNumberRoot";

export { ViewRoot } from "./common/ViewRoot";
export type { ViewRootProps } from "./common/ViewRoot";
export { triggerDateSelect, getDefaultEventEnd } from "./calendar-utils";
export type {
  DatePointTransform,
  DateSpanTransform,
  DateSelectionApi,
} from "./calendar-utils";
