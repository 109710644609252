import React, { useState } from "react";
import Project from "../../../../models/Project";
import styled from "styled-components";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Avatar,
    AvatarGroup,
    Text,
    Link,
    SimpleGrid,
    Tooltip,
    HStack,
    IconButton,
    Button,
    VStack,
    Flex,
    Badge,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { BsThreeDotsVertical, BsArrowsAngleExpand, } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import { ProjectUpdate } from "../../../../types";
import { ActionUpdate } from "./ProjectDescriptionBeta";

interface ProjectUpdateSectionProps {
    currentProject: Project;
    setIsupdateModel?: () => void;
    projectUpdate: ProjectUpdate[];
    updatesDispatchUpdate: React.Dispatch<ActionUpdate>;
}

interface Update {
    date: string;
    status: "Halted" | "Potential risk" | "In Progress";
    details: {
        update: string;
        priorities: string;
        roadblocks: string;
    };
}

const MotionBox = motion(Box);

export const ProjectUpdateSection = ({
    currentProject, setIsupdateModel,
    projectUpdate, updatesDispatchUpdate,
}: ProjectUpdateSectionProps) => {

    const UpdateCard: React.FC<{ update: ProjectUpdate }> = ({ update }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        const getStatusColor = (status: ProjectUpdate['status']) => {
            switch (status) {
                case "Halted": return { bg: "#FEF3F2", bc: "#B42318" };
                case "Potential risk": return { bg: "#FEF0C7", bc: "#B54708" };
                case "In progress": return { bg: "#ECFDF3", bc: "#027A48" };
                case "Delivered": return { bg: "#EEF4FF", bc: "#3538CD" };
                default: return { bg: "#FEF0C7", bc: "#B54708" };
            }
        };

        return (
            <Box borderWidth="1px" borderRadius="md" p={4} mb={2}>
                <Flex justifyContent="space-between" alignItems="center">
                    <HStack>
                        <Text fontWeight="bold">{update.date ? update.date.toLocaleDateString() : "--"}</Text>
                        <Badge backgroundColor={getStatusColor(update.status).bg} variant='outline'
                            borderColor={getStatusColor(update.status).bc} borderRadius={'full'} borderWidth={"1px"}
                            color={getStatusColor(update.status).bc} px={"3"} py={"1"} textTransform={'none'}>{update.status}</Badge>
                    </HStack>
                    <HStack>
                        <IconButton
                            aria-label={isExpanded ? "Collapse" : "Expand"}
                            icon={isExpanded ? <BsArrowsAngleExpand /> : <BsArrowsAngleExpand />}
                            onClick={() => setIsExpanded(!isExpanded)}
                            variant="ghost"
                        />
                        <Menu>
                            <MenuButton
                                as={IconButton}
                                aria-label='Options'
                                icon={<BsThreeDotsVertical />}
                                variant='ghost'
                            />
                            <MenuList>
                                <MenuItem onClick={(e) => {
                                    e.preventDefault();
                                    updatesDispatchUpdate({
                                        type: "edit-update",
                                        payload: { update },
                                    })
                                }}>Edit</MenuItem>
                                {/* <MenuItem color="#C90A0A" onClick={(e) => {
                                    e.preventDefault();
                                    if (onDelete && update) {
                                        onDelete(update)
                                    }
                                }}>Delete</MenuItem> */}
                            </MenuList>
                        </Menu>
                    </HStack>
                </Flex>
                {/* @ts-ignore */}
                <AnimatePresence>
                    {isExpanded && (
                        <MotionBox
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            style={{ overflow: "hidden" }}
                        >
                            <VStack align="stretch" mt={4} spacing={3}>
                                <Box>
                                    <Text fontWeight="bold">Update:</Text>
                                    <Box dangerouslySetInnerHTML={{ __html: update.data.update || "" }}></Box>
                                </Box>
                                <Box>
                                    <Text fontWeight="bold">Priorities:</Text>
                                    <Box dangerouslySetInnerHTML={{ __html: update.data.priorities || "" }}></Box>
                                </Box>
                                <Box>
                                    <Text fontWeight="bold">Roadblocks:</Text>
                                    <Box dangerouslySetInnerHTML={{ __html: update.data.roadblocks || "" }}></Box>
                                </Box>
                            </VStack>
                        </MotionBox>
                    )}
                </AnimatePresence>
            </Box>
        );
    };


    return (
        <Box w="100%">
            {/* @ts-ignore */}
            <Flex justifyContent="flex-end" alignItems="center" mb={4}>
                {/* <Text fontSize="xl" fontWeight="bold">Updates</Text> */}
                <Button leftIcon={<IoMdAdd />} colorScheme="teal" size="sm"
                    onClick={(e) => {
                        // setIsupdateModel && setIsupdateModel()
                        e.preventDefault();
                        updatesDispatchUpdate({
                            type: "new-update",
                        })
                    }}
                >
                    Add update
                </Button>
            </Flex>
            <VStack spacing={2} align="stretch">
                {projectUpdate.length ? projectUpdate.map((update, index) => (
                    <UpdateCard key={index} update={update} />
                )) :
                    <Text>No Found updates.</Text>
                }
            </VStack>
            {/* {projectUpdate.length > 0 && <Box w="100%" display={"flex"} justifyContent={"flex-end"}>
                <Button variant="link" colorScheme="blue" mt={2} >
                    Load more
                </Button>
            </Box>} */}
        </Box>
    );
};
