import { EventSource, EventSourceHash } from "../structs/event-source";
import {
  parseEventSource,
  buildEventSourceRefiners,
} from "../structs/event-source-parse";
import { arrayToHash, filterHash } from "../util/object";
import { DateRange } from "../datelib/date-range";
import { DateProfile } from "../DateProfileGenerator";
import { Action } from "./Action";
import { guid } from "../util/misc";
import { CalendarContext } from "../CalendarContext";
import { CalendarOptions } from "../options";

export function initEventSources(
  calendarOptions,
  dateProfile: DateProfile,
  context: CalendarContext
) {
  let activeRange = dateProfile ? dateProfile.activeRange : null;

  return addSources(
    {},
    parseInitialSources(calendarOptions, context),
    activeRange,
    context
  );
}

export function reduceEventSources(
  eventSources: EventSourceHash,
  action: Action,
  dateProfile: DateProfile,
  context: CalendarContext
): EventSourceHash {
  let activeRange = dateProfile ? dateProfile.activeRange : null; // need this check?

  switch (action.type) {
    case "ADD_EVENT_SOURCES": // already parsed
      return addSources(eventSources, action.sources, activeRange, context);

    case "REMOVE_EVENT_SOURCE":
      return removeSource(eventSources, action.sourceId);

    case "PREV": // TODO: how do we track all actions that affect dateProfile :(
    case "NEXT":
    case "CHANGE_DATE":
    case "CHANGE_VIEW_TYPE":
      if (dateProfile) {
        // @ts-ignore
        return fetchDirtySources(eventSources, activeRange, context);
      }
      return eventSources;

    case "FETCH_EVENT_SOURCES":
      return fetchSourcesByIds(
        eventSources,
        (action as any).sourceIds // why no type?
          ? arrayToHash((action as any).sourceIds)
          : excludeStaticSources(eventSources, context),
        // @ts-ignore
        activeRange,
        context
      );

    case "RECEIVE_EVENTS":
    case "RECEIVE_EVENT_ERROR":
      return receiveResponse(
        eventSources,
        action.sourceId,
        action.fetchId,
        // @ts-ignore
        action.fetchRange
      );

    case "REMOVE_ALL_EVENT_SOURCES":
      return {};

    default:
      return eventSources;
  }
}

export function reduceEventSourcesNewTimeZone(
  eventSources: EventSourceHash,
  dateProfile: DateProfile,
  context: CalendarContext
) {
  let activeRange = dateProfile ? dateProfile.activeRange : null; // need this check?

  return fetchSourcesByIds(
    eventSources,
    excludeStaticSources(eventSources, context),
    // @ts-ignore
    activeRange,
    context
  );
}

export function computeEventSourcesLoading(
  eventSources: EventSourceHash
): boolean {
  for (let sourceId in eventSources) {
    if (eventSources[sourceId].isFetching) {
      return true;
    }
  }

  return false;
}

function addSources(
  eventSourceHash: EventSourceHash,
  sources: EventSource<any>[],
  fetchRange: DateRange | null,
  context: CalendarContext
): EventSourceHash {
  let hash: EventSourceHash = {};

  for (let source of sources) {
    hash[source.sourceId] = source;
  }

  if (fetchRange) {
    hash = fetchDirtySources(hash, fetchRange, context);
  }

  return { ...eventSourceHash, ...hash };
}

function removeSource(
  eventSourceHash: EventSourceHash,
  sourceId: string
): EventSourceHash {
  return filterHash(
    eventSourceHash,
    (eventSource: EventSource<any>) => eventSource.sourceId !== sourceId
  );
}

function fetchDirtySources(
  sourceHash: EventSourceHash,
  fetchRange: DateRange,
  context: CalendarContext
): EventSourceHash {
  return fetchSourcesByIds(
    sourceHash,
    filterHash(sourceHash, (eventSource) =>
      isSourceDirty(eventSource, fetchRange, context)
    ),
    fetchRange,
    context
  );
}

function isSourceDirty(
  eventSource: EventSource<any>,
  fetchRange: DateRange,
  context: CalendarContext
) {
  if (!doesSourceNeedRange(eventSource, context)) {
    return !eventSource.latestFetchId;
  }
  return (
    !context.options.lazyFetching ||
    !eventSource.fetchRange ||
    eventSource.isFetching || // always cancel outdated in-progress fetches
    fetchRange.start < eventSource.fetchRange.start ||
    fetchRange.end > eventSource.fetchRange.end
  );
}

function fetchSourcesByIds(
  prevSources: EventSourceHash,
  sourceIdHash: { [sourceId: string]: any },
  fetchRange: DateRange,
  context: CalendarContext
): EventSourceHash {
  let nextSources: EventSourceHash = {};

  for (let sourceId in prevSources) {
    let source = prevSources[sourceId];

    if (sourceIdHash[sourceId]) {
      nextSources[sourceId] = fetchSource(source, fetchRange, context);
    } else {
      nextSources[sourceId] = source;
    }
  }

  return nextSources;
}

function fetchSource(
  eventSource: EventSource<any>,
  fetchRange: DateRange,
  context: CalendarContext
) {
  let { options, calendarApi } = context;
  let sourceDef = context.pluginHooks.eventSourceDefs[eventSource.sourceDefId];
  let fetchId = guid();

  sourceDef.fetch(
    {
      eventSource,
      range: fetchRange,
      context,
    },
    (res) => {
      // success callback
      let { rawEvents } = res;

      if (options.eventSourceSuccess) {
        rawEvents =
          options.eventSourceSuccess.call(calendarApi, rawEvents, res.xhr) ||
          rawEvents;
      }

      if (eventSource.success) {
        rawEvents =
          eventSource.success.call(calendarApi, rawEvents, res.xhr) ||
          rawEvents;
      }

      context.dispatch({
        type: "RECEIVE_EVENTS",
        sourceId: eventSource.sourceId,
        fetchId,
        fetchRange,
        rawEvents,
      });
    },
    (error) => {
      // failure callback
      console.warn(error.message, error);

      if (options.eventSourceFailure) {
        options.eventSourceFailure.call(calendarApi, error);
      }

      if (eventSource.failure) {
        eventSource.failure(error);
      }

      context.dispatch({
        type: "RECEIVE_EVENT_ERROR",
        sourceId: eventSource.sourceId,
        fetchId,
        fetchRange,
        error,
      });
    }
  );

  return {
    ...eventSource,
    isFetching: true,
    latestFetchId: fetchId,
  };
}

function receiveResponse(
  sourceHash: EventSourceHash,
  sourceId: string,
  fetchId: string,
  fetchRange: DateRange
) {
  let eventSource: EventSource<any> = sourceHash[sourceId];

  if (
    eventSource && // not already removed
    fetchId === eventSource.latestFetchId
  ) {
    return {
      ...sourceHash,
      [sourceId]: {
        ...eventSource,
        isFetching: false,
        fetchRange, // also serves as a marker that at least one fetch has completed
      },
    };
  }

  return sourceHash;
}

function excludeStaticSources(
  eventSources: EventSourceHash,
  context: CalendarContext
): EventSourceHash {
  return filterHash(eventSources, (eventSource) =>
    doesSourceNeedRange(eventSource, context)
  );
}

function parseInitialSources(
  rawOptions: CalendarOptions,
  context: CalendarContext
) {
  let refiners = buildEventSourceRefiners(context);
  // @ts-ignore
  let rawSources = [].concat(rawOptions.eventSources || []);
  let sources = []; // parsed

  if (rawOptions.initialEvents) {
    // @ts-ignore
    rawSources.unshift(rawOptions.initialEvents);
  }

  if (rawOptions.events) {
    // @ts-ignore
    rawSources.unshift(rawOptions.events);
  }

  for (let rawSource of rawSources) {
    let source = parseEventSource(rawSource, context, refiners);
    if (source) {
      // @ts-ignore
      sources.push(source);
    }
  }

  return sources;
}

function doesSourceNeedRange(
  eventSource: EventSource<any>,
  context: CalendarContext
) {
  let defs = context.pluginHooks.eventSourceDefs;

  return !defs[eventSource.sourceDefId].ignoreRange;
}
