import React from "react";
import { ReactComponent as TodoistIcon } from "../assets/icons/todoist-icon.svg";
import { ReactComponent as GogoleCalendarIcon } from "../assets/icons/google-calendar.svg";
import { ReactComponent as PaymoIcon } from "../assets/icons/paymo-icon.svg";
import { ReactComponent as JiraIcon } from "../assets/icons/jira-icon.svg";
import { ReactComponent as TimeTrapIcon } from "../assets/icons/timetrap-logo.svg";
import { ReactComponent as AsanaIcon } from "../assets/icons/asana-icon.svg";
import { ServiceCode } from "../types";

export const INTEGRATION_ICONS = {
  timetrap: TimeTrapIcon,
  todoist: TodoistIcon,
  gcalendar: GogoleCalendarIcon,
  paymo: PaymoIcon,
  jira: JiraIcon,
  asana: AsanaIcon,
};

type IntegrationIconProps = {
  service: ServiceCode;
  size?: string;
};

export const IntegrationIcon: React.FC<IntegrationIconProps> = ({
  service,
  size,
}) => {
  const iconSize =
    size === "xsmall"
      ? "0.65rem"
      : size === "small"
        ? "1rem"
        : size === "large"
          ? "2.0rem"
          : size === "xlarge"
            ? "3rem"
            : size === "xxlarge"
              ? "3.8rem"
              : "1.4rem";

  return React.createElement(INTEGRATION_ICONS[service], {
    style: { width: iconSize, height: iconSize },
  });
};
