import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../hooks/useStores";
import { Navigate, useNavigate } from "react-router-dom";

export const RedirectToUserBoard: React.FC = observer(() => {
  const { session } = useStores();

  console.log(session.isLogged, session.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (session.isLogged && session.user) {
      console.log("Redirect to board");
      navigate(`/user/${session.user.uid}/board`);
    }
  }, [session, navigate]);

  if (session.isLogged && session.user) {
    return <Navigate to={`/user/${session.user.uid}/board`} />;
  }

  return <Navigate to={`/login`} />;
});
