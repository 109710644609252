import React from "react";
import {
  createElement,
  createRef,
  VNode,
  DateComponent,
  DateProfile,
  EventStore,
  EventUiHash,
  EventInteractionState,
  DateSpan,
  memoize,
  DateRange,
  DayTableModel,
  DateEnv,
  DateMarker,
  Hit,
  NowTimer,
  CssDimValue,
  Duration,
} from "../common";
import { TimeCols } from "./TimeCols";
import { TimeSlatMeta } from "./time-slat-meta";
import { TimeColsSlatsCoords } from "./TimeColsSlatsCoords";
import { DayTimeColsSlicer } from "./DayTimeColsSlicer";

export interface DayTimeColsProps {
  dateProfile: DateProfile;
  dayTableModel: DayTableModel;
  axis: boolean;
  slotDuration: Duration;
  slatMetas: TimeSlatMeta[];
  businessHours: EventStore;
  eventStore: EventStore;
  eventUiBases: EventUiHash;
  dateSelection: DateSpan | null;
  eventSelection: string;
  eventDrag: EventInteractionState | null;
  eventResize: EventInteractionState | null;
  // @ts-ignore
  tableColGroupNode: VNode;
  tableMinWidth: CssDimValue;
  clientWidth: number | null;
  clientHeight: number | null;
  expandRows: boolean;
  onScrollTopRequest?: (scrollTop: number) => void;
  forPrint: boolean;
  onSlatCoords?: (slatCoords: TimeColsSlatsCoords) => void;
}

export class DayTimeCols extends DateComponent<DayTimeColsProps> {
  private buildDayRanges = memoize(buildDayRanges);
  private slicer = new DayTimeColsSlicer();
  private timeColsRef = createRef<TimeCols>();

  render() {
    let { props, context } = this;
    let { dateProfile, dayTableModel } = props;

    let isNowIndicator = context.options.nowIndicator;
    let dayRanges = this.buildDayRanges(
      dayTableModel,
      dateProfile,
      context.dateEnv
    );

    // give it the first row of cells
    // TODO: would move this further down hierarchy, but sliceNowDate needs it
    return (
      <NowTimer unit={isNowIndicator ? "minute" : "day"}>
        {(nowDate: DateMarker, todayRange: DateRange) => (
          <TimeCols
            ref={this.timeColsRef}
            rootElRef={this.handleRootEl}
            {...this.slicer.sliceProps(
              // @ts-ignore
              props,
              dateProfile,
              null,
              context,
              dayRanges
            )}
            forPrint={props.forPrint}
            axis={props.axis}
            dateProfile={dateProfile}
            slatMetas={props.slatMetas}
            slotDuration={props.slotDuration}
            cells={dayTableModel.cells[0]}
            tableColGroupNode={props.tableColGroupNode}
            tableMinWidth={props.tableMinWidth}
            clientWidth={props.clientWidth}
            clientHeight={props.clientHeight}
            expandRows={props.expandRows}
            nowDate={nowDate}
            // @ts-ignore
            nowIndicatorSegs={
              isNowIndicator &&
              this.slicer.sliceNowDate(nowDate, context, dayRanges)
            }
            todayRange={todayRange}
            onScrollTopRequest={props.onScrollTopRequest}
            onSlatCoords={props.onSlatCoords}
          />
        )}
      </NowTimer>
    );
  }

  handleRootEl = (rootEl: HTMLDivElement | null) => {
    if (rootEl) {
      this.context.registerInteractiveComponent(this, { el: rootEl });
    } else {
      this.context.unregisterInteractiveComponent(this);
    }
  };

  queryHit(positionLeft: number, positionTop: number): Hit {
    // @ts-ignore
    let rawHit = this.timeColsRef.current.positionToHit(
      positionLeft,
      positionTop
    );

    if (rawHit) {
      return {
        component: this,
        dateSpan: rawHit.dateSpan,
        dayEl: rawHit.dayEl,
        rect: {
          left: rawHit.relativeRect.left,
          right: rawHit.relativeRect.right,
          top: rawHit.relativeRect.top,
          bottom: rawHit.relativeRect.bottom,
        },
        layer: 0,
      };
    }
    // @ts-ignore
    return null;
  }
}

export function buildDayRanges(
  dayTableModel: DayTableModel,
  dateProfile: DateProfile,
  dateEnv: DateEnv
): DateRange[] {
  let ranges: DateRange[] = [];

  for (let date of dayTableModel.headerDates) {
    ranges.push({
      start: dateEnv.add(date, dateProfile.slotMinTime),
      end: dateEnv.add(date, dateProfile.slotMaxTime),
    });
  }

  return ranges;
}
