import { Spinner } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

type Props = {
    isShown: boolean
}

const LoadingOverly: React.FC<Props> = ({
    isShown = false
}) => {
    return isShown ? (
        <OverLyContainer>
            {/* @ts-ignore */}
            {/* <SpinnerComponent /> */}
            <Spinner
                color="#319795"
                size="xl"
                thickness="4px"
                speed="0.65s"
            />
        </OverLyContainer>
    ) : null;
};

export default LoadingOverly;

const SpinnerComponent = styled(Spinner)`
    color: #319795;
    thickness: 4px;
    size: xl;
`;

const OverLyContainer = styled.section`
position: absolute;
// padding: 0px;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0,0,0,0.5);
z-index: 9999;
`
