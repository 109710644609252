import { observer } from "mobx-react";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Route, useNavigate, useLocation, Routes } from "react-router-dom";
import { HStack } from "@chakra-ui/react";
import { OrganizationRoles } from "../OrganizationRoles";
import { ProjectDetails } from "../ProjectDetails";
import { Projects } from "../Projects";
import { Customers } from "../Customers";
import { Timesheet } from "../Timesheet";
import { OrganizationDetails } from "../OrganizationDetails";
import { useStores } from "../../hooks/useStores";
import { SecondarySidebarContainer } from "../Sidebar/styled";
import { NavGroup, NavItem, NavItemText } from "../../components/Nav";
import { AbilityContext } from "../../components/Can";
import { SubscriptionDetails } from "../SubscriptionDetails";
import { ProjectsWorkflows } from "../ProjectsWorkflows";
import { Webhooks } from "../Webhooks";
import { Users } from "../Users";
import { UserDetails } from "../UserDetails";
import { ScrollBar } from "../../components/Common/Scrollbar";
import { Overheads } from "../Overheads";

export enum MenuItemKeys {
  organizationDetail,
  customers,
  users,
  roles,
  timesheet,
  subscription,
  workflows,
  webhooks,
}

export const OrganizationLayout: React.FC = observer(() => {
  const { session } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const ability = useContext(AbilityContext);

  const activeItemKey = useMemo(() => {
    let key: MenuItemKeys | null = null;

    if (location.pathname.indexOf("customers") > -1) {
      key = MenuItemKeys.customers;
    } else if (location.pathname.indexOf("roles") > -1) {
      key = MenuItemKeys.roles;
    } else if (location.pathname.indexOf("users") > -1) {
      key = MenuItemKeys.users;
    } else if (location.pathname.indexOf("timesheet") > -1) {
      key = MenuItemKeys.timesheet;
    } else if (location.pathname.indexOf("subscription") > -1) {
      key = MenuItemKeys.subscription;
    } else if (location.pathname.indexOf("workflows") > -1) {
      key = MenuItemKeys.workflows;
    } else if (location.pathname.indexOf("webhooks") > -1) {
      key = MenuItemKeys.webhooks;
    } else {
      key = MenuItemKeys.organizationDetail;
    }
    return key;
  }, [location]);

  const handleNavigateTo = useCallback(
    (key: MenuItemKeys) => {
      let url = "";
      if (key === MenuItemKeys.organizationDetail) {
        url = `/organization/${session.user?.organization?.uid}`;
      } else if (key === MenuItemKeys.roles) {
        url = `/organization/${session.user?.organization?.uid}/roles`;
      } else if (key === MenuItemKeys.users) {
        url = `/organization/${session.user?.organization?.uid}/users`;
      } else if (key === MenuItemKeys.timesheet) {
        url = `/organization/${session.user?.organization?.uid}/reports/timesheet`;
      } else if (key === MenuItemKeys.customers) {
        url = `/organization/${session.user?.organization?.uid}/customers`;
      } else if (key === MenuItemKeys.workflows) {
        url = `/organization/${session.user?.organization?.uid}/workflows`;
      } else if (key === MenuItemKeys.webhooks) {
        url = `/organization/${session.user?.organization?.uid}/webhooks`;
      } else if (key === MenuItemKeys.subscription) {
        url = `/organization/${session.user?.organization?.uid}/subscription`;
      }

      navigate(url);
    },
    [navigate, session]
  );

  return (
    <HStack w="100%" bg={'white'}>
      {/* <SecondarySidebarContainer>
        <ScrollBar>
          <NavGroup title={t<string>("screens.organization.nav.organization")}>
            {
              // @ts-ignore
              ability.can("read", "organization") && (
                <NavItem
                  isActive={activeItemKey === MenuItemKeys.organizationDetail}
                  onClick={() =>
                    handleNavigateTo(MenuItemKeys.organizationDetail)
                  }
                >
                  <NavItemText>
                    {t<string>("screens.organization.nav.organizationDetail")}
                  </NavItemText>
                </NavItem>
              )
            }
            {
              // @ts-ignore
              ability.can("read", "project") && (
                <NavItem
                  isActive={activeItemKey === MenuItemKeys.customers}
                  onClick={() => handleNavigateTo(MenuItemKeys.customers)}
                >
                  <NavItemText>
                    {t<string>("screens.customers.titleList")}
                  </NavItemText>
                </NavItem>
              )
            }
          </NavGroup>

          {
            // @ts-ignore
            ability.can("read", "project") && (
              <NavGroup
                title={t<string>("screens.organization.nav.projectSetup")}
              >
                <NavItem
                  isActive={activeItemKey === MenuItemKeys.workflows}
                  onClick={() => handleNavigateTo(MenuItemKeys.workflows)}
                >
                  <NavItemText>
                    {t<string>("screens.workflows.title")}
                  </NavItemText>
                </NavItem>
              </NavGroup>
            )
          }

          <NavGroup
            title={t<string>("screens.organization.nav.userManagement")}
          >
            {
              // @ts-ignore
              ability.can("read", "role") && (
                <NavItem
                  isActive={activeItemKey === MenuItemKeys.roles}
                  onClick={() => handleNavigateTo(MenuItemKeys.roles)}
                >
                  <NavItemText>
                    {t<string>("screens.roles.titleList")}
                  </NavItemText>
                </NavItem>
              )
            }
            {
              // @ts-ignore
              ability.can("read", "user") && (
                <NavItem
                  isActive={activeItemKey === MenuItemKeys.users}
                  onClick={() => handleNavigateTo(MenuItemKeys.users)}
                  id={"usetiful-users"}
                >
                  <NavItemText>
                    {t<string>("screens.users.titleList")}
                  </NavItemText>
                </NavItem>
              )
            }
            {
              // @ts-ignore
              ability.can("read", "timesheet") && (
                <NavItem
                  isActive={activeItemKey === MenuItemKeys.timesheet}
                  onClick={() => handleNavigateTo(MenuItemKeys.timesheet)}
                >
                  <NavItemText>
                    {t<string>("screens.timesheet.title")}
                  </NavItemText>
                </NavItem>
              )
            }
          </NavGroup>

          {
            // @ts-ignore
            ability.can("read", "organization") && (
              <NavGroup
                title={t<string>("screens.organization.nav.subscription")}
              >
                <NavItem
                  isActive={activeItemKey === MenuItemKeys.subscription}
                  onClick={() => handleNavigateTo(MenuItemKeys.subscription)}
                >
                  <NavItemText>
                    {t<string>("screens.subscription.title")}
                  </NavItemText>
                </NavItem>
              </NavGroup>
            )
          }

          {
            // @ts-ignore
            ability.can("read", "webhooks") && (
              <NavGroup title={t<string>("screens.organization.nav.advanced")}>
                <NavItem
                  isActive={activeItemKey === MenuItemKeys.webhooks}
                  onClick={() => handleNavigateTo(MenuItemKeys.webhooks)}
                >
                  <NavItemText>
                    {t<string>("screens.webhooks.title")}
                  </NavItemText>
                </NavItem>
              </NavGroup>
            )
          }
        </ScrollBar>
      </SecondarySidebarContainer> */}

      <div
        style={{
          flexGrow: 1,
          display: "flex",
          height: "100vh",
          width: "100%",
          overflow: "auto",
          marginLeft: "0px",
        }}
      >
        <Routes>
          <Route
            path={"/:organizationId/roles"}
            element={<OrganizationRoles />}
          />
          <Route path={"/:organizationId/users"} element={<Users />} />
          <Route
            path={"/:organizationId/users/:userId"}
            element={<UserDetails />}
          />
          <Route
            path={"/:organizationId/workflows"}
            element={<ProjectsWorkflows />}
          />
          <Route
            path={"/:organizationId/projects/:projectId"}
            element={<ProjectDetails />}
          />
          <Route path={"/:organizationId/projects"} element={<Projects />} />
          <Route path={"/:organizationId/customers"} element={<Customers />} />
          <Route
            path={"/:organizationId/subscription"}
            element={<SubscriptionDetails />}
          />
          <Route path={"/:organizationId/webhooks"} element={<Webhooks />} />
          <Route
            path={"/:organizationId/reports/timesheet"}
            element={<Timesheet />}
          />
          <Route path={"/:organizationId/overheads"} element={<Overheads />} />
          <Route path={"/:organizationId"} element={<OrganizationDetails />} />
        </Routes>
      </div>
    </HStack>
  );
});
