import { intersectRanges, DateRange, Slicer } from "../common";
import { TimeColsSeg } from "./TimeColsSeg";

export class DayTimeColsSlicer extends Slicer<TimeColsSeg, [DateRange[]]> {
  sliceRange(range: DateRange, dayRanges: DateRange[]): TimeColsSeg[] {
    let segs: TimeColsSeg[] = [];

    for (let col = 0; col < dayRanges.length; col += 1) {
      let segRange = intersectRanges(range, dayRanges[col]);

      if (segRange) {
        segs.push({
          // @ts-ignore
          start: segRange.start,
          // @ts-ignore
          end: segRange.end,
          // @ts-ignore
          isStart: segRange.start.valueOf() === range.start.valueOf(),
          // @ts-ignore
          isEnd: segRange.end.valueOf() === range.end.valueOf(),
          col,
        });
      }
    }

    return segs;
  }
}
