import { RawAbility, Role } from "../types";
import { Ability } from "@casl/ability";

export const subjects = [
  "organization",
  "role",
  "user",
  "project",
  "timesheet",
  "team",
];

export const actions = ["read", "create", "edit", "delete"];

export const getBaseRole = (): Role => {
  return {
    uid: "-1",
    name: "New role",
    acls: subjects.map((subject) => ({
      subject,
      action: [],
    })) as RawAbility[],
  };
};
