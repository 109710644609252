import { Role } from "../types";

export const toRoleOptions = (roles: Role[]) =>
  roles.map((role) => ({ value: role.uid, label: role.name }));

export const getSelectedRoleOption = (roles: Role[], roleId: string | null) => {
  if (!roleId) return null;

  const role = roles.find((role) => role.uid === roleId);

  return role
    ? {
        value: role.uid,
        label: role.name,
      }
    : null;
};
