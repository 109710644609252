import it from "./it";

const en: typeof it = {
  screens: {
    board: {
      formTitle: "Title",
      formColor: "Category",
      formTaskType: "Type",
      formTaskTypePlanning: "Planning",
      formTaskTypeActivity: "Activity",
      formEventUntitledTitle: "(Untitled)",
      cancelButton: "Cancel",
      confirmLogout: "Confirm Logout",
      confrimLogoutMsg: "Are you sure you want to logout?",
      deleteButton: "Delete",
      confirmButton: "Save",
      logoutButton: "Logout",
      settings: "Settings",
      elapsedTime: "Duration",
      project: "Project",
      toolbar: {
        integrations: "Integrations",
        plugins: {
          googleCalendar: "Google calendar",
        },
      },
      board: "Board",
      preferences: {
        allDaySlot: "All day events slot",
        weekends: "Weekends",
        slotDuration: "Slot duration",
        slotRangeTime: "Slot range time",
        slotMinTime: "Min slot time",
        slotMaxTime: "Max slot time",
        businessDaysOfWeek: "Business days of week",
        businessRangeTime: "Business time range",
        businessStartTime: "Business day start time",
        businessEndTime: "Business day end time",
        calendarView: "Calendar view",
        timeGridWeek: "Weekly",
        timeGridDay: "Daily",
      },
      recurrence: {
        editRecurringEvent: "Edit periodic event",
        onlyThis: "This event",
        thisAndFollowingOnes: "This event and the following ones",
      },
      event: {
        addEventTitle: "Add ",
        editEventTitle: "Edit ",
        activity: "activity",
        planning: "planning",
        noProjectCategory: "Not categorized",
        deleteTitle: "Delete activity",
        deleteRecurrenceTitle: "Delete recurrent event",
        onlyThisEvent: "Only this event",
        thisEventAndFollowing: "This event and the following ones",
        deleteMessage: "Are you sure to delete this activity?",
        noTask: "No Task",
      },
    },
    login: {
      title: "Time Trap",
      login: "Login",
      signUp: "Sign Up",
      ctaLogin: "Sign in with Google",
      notSignup: "Don't have an account?",
      alreadySignup: "Already have an account?",
      ctaSignup: "Sign Up",
      nominative: "Nominative",
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm password",
      loginError: "Wrong email or password",
      signUpError: "Error",
      lostPassword: "I forgot the password",
      desktopOnly:
        "We are working to bring Timetrap to smartphones, please access from your laptop for now",
    },
    lostPassword: {
      title: "Lost password",
      sendRecoveryLink: "Send recovery link",
      requestSuccess:
        "Request successful. You will receive an email with the instructions to follow!",
    },
    changePassword: {
      title: "Change password",
      newPassword: "New password",
      confirmNewPassword: "Confirm password",
    },
    organization: {
      name: "Corporation",
      domains: "Associated email domains",
      domainsPlaceholder: "your-company.com",
      addDomain: "Add",
      roleName: "Role",
      addProject: "Add project",
      createTitle: "Enter your company name",
      createError: "Error while creating the organization",
      createErrorInvalidDomains:
        "The organization was created but the following domains could not be added because they are invalid or already used by other organizations: ",
      createErrorInvalidDomains2:
        "You can change the email domains from the Organization/Details section",
      updateError: "Error while updating the company data",
      updateErrorInvalidDomains:
        "The following domains are invalid or already used by other organizations: ",
      organizationUpdateSuccess: "Organization information updated correctly",
      nav: {
        organization: "Organization",
        organizationDetail: "Details",
        userManagement: "Users",
        subscription: "Billing",
        projectSetup: "Projects",
        advanced: "Advanced",
      },
    },
    projects: {
      title: "Project",
      titleList: "Other projects",
      titleProjectLeader: "Your projects as Project Leader",
      titleTeamMember: "Your projects as team member",
      notFound: "Project not found",
      customer: "Customer",
      externalReference: "Reference quote",
      notBillable: "Not billable",
      billable: "Billable",
      budget: "Budget",
      marginabilityPercentage: "Marginality target %",
      toleranceDays: "Tolerance days",
      projectManager: "Project Leader",
      salesManager: "Sales Account",
      category: "Category",
      categories: "Categories",
      projects: "Projects",
      projectType: "Project Type",
      type: "Type",
      tasks: "Task",
      addCategory: "Add category",
      status: "Status",
      contactName: "Customer reference name",
      contactSurname: "Customer reference surname",
      contactEmail: "Customer reference email",
      cotnactRole: "Customer reference Role",
      editProject: "Edit project",
      startDate: "Start date",
      endDate: "End date",
      closingDate: "Closing date",
      team: "Team",
      addMember: "Add team member",
      details: "Project details",
      description: "Description",
      note: "Note",
      currentMarginability: "Current marginability",
      myProjects: "My projects",
      allProjects: "All projects",
      actions: {
        createSuccess: "Project created successfully",
        updateSuccess: "Project updated successfully",
        delete: "Are you sure you want to delete the project?",
        categorySuccess: "Category created successfully",
        categoryUpdateSuccess: "Category updated successfully",
        resetFilters: "Reset filters",
      },
      validations: {
        titleRequired: "Project title is required!",
        customerRequired: "Project customer is required!",
        projectManagerRequired: "Project Manager is required!",
      },
      notes: {
        actions: {
          addNote: "Add note",
          delete: "Are you sure you want to delete this note?",
        },
      },
      insights: {
        title: "Insights",
        workLog: "Work log",
        workLogCost: "Work log cost",
        currentMarginabilityPercentage: "Current marginability %",
        availableBudget: "Available budget",
      },
      reports: {
        user: "User",
        task: "Task",
        startTime: "Start date",
        endTime: "End date",
        elapsedTime: "Work log",
        title: "Title",
        date: "Date",
        discription: "discription",
        estimatedAmpunt: "Estimated amount",
        confirmedAmpunt: "Confirmed amount",
        transactionDate: "Transaction date",
        category: "Category",
        project: "Project"
      },
      projectCategory: {
        name: "Name",
        color: "Color",
        validations: {
          nameRequired: "Name is required",
          colorRequired: "Color is required",
        },
      },
      taskCategory: {
        name: "Name",
        color: "Color",
        isUsedForTasks: "Used for Activity",
        isUsedForPlanning: "Used for planning",
        validations: {
          nameRequired: "Name is required",
          colorRequired: "Color is required",
        },
      },
      roleTages: {
        resiposiable: "Responsible",
        accountable: "Accountable",
        consulted: "Consulted",
        informed: "Informed",
      },
      projectLinks: {
        actions: {
          created: "Link created successfully",
          update: "Link update successfully",
          delete: "Are you sure want to delete link?",
        },
      },
      projectUpdate: {
        actions: {
          created: "Updates created successfully",
          update: "Updates update successfully",
          delete: "Are you sure want to Updates link?",
        },
        title: "Add project update",
        titleEdit: "Edit project update"
      },
      projectTypes: {
        0: "One-shot",
        1: "Recurring",
        2: "Cost-based",
        3: "Hour pack",
        4: "Pre-sales",
      },
    },
    expenses: {
      modalTitle: "New expense",
      title: "Title",
      supplier: "Supplier",
      type: "Type",
      description: "Description",
      amount: "Amount",
      isBillable: "Billable",
      isFinalBalance: "Final balance",
      approver: "Approved by",
      totalAmount: "Total amount",
      estimateExpenses: "Expenses estimated",
      finalBalanceExpenses: "External costs",
      addExpense: "Add expense",
      toFinalBalance: "Final balance",
      actions: {
        delete: "Are you sure you want to delete the expense?",
      },
      validations: {
        titleRequired: "Insert the title of the expense",
        amountRequired: "Insert the amount of the expense",
      },
    },
    expenseTypes: {
      estimate: "Estimated",
      final_balance: "Final balance",
    },
    users: {
      titleList: "Users",
      title: "User",
      email: "Email",
      nominative: "Nominative",
      hourlyCost: "Hourly cost",
      status: "Status",
      contractualHours: "Contratual hours",
      contractType: "Contract type",
      productivityTarget: "Productivity target",
      actions: {
        userUpdated: "User updated successfully",
        userInvited: "User invited successfully",
      },
      inviteUser: "Invite user",
      role: "Role",
    },
    userDetails: {
      title: "User details",
      contracts: "Contracts",
      addContract: "New",
      activeUser: "Active user",
      inactiveUser: "Inactive user",
      editContract: "Edit contract",
      newContract: "New contract",
      contractCreated: "Contract created successfully",
      contractUpdated: "Contract updated successfully",
      contractDeleted: "Contract deleted successfully",
      deleteContract: "Are you sure you want to delete this contract?",
    },
    roles: {
      titleList: "Roles",
      title: "Role",
      subject: "Subject",
      editTitle: "Edit role",
      newTitle: "New role",
      actions: {
        newRole: "New role",
        createdSuccess: "Role created successfully",
        updatedSuccess: "Role update successfully",
      },
    },
    timesheet: {
      title: "Timesheet",
      generate: "Generate",
      workLogDate: "Date",
      workLogDay: "Day",
      workLog: "Hours",
      byDay: "By day",
      byProject: "By project",
      projectTitle: "Project",
      customerNominative: "Customer",
      byClient: "By client",
    },
    dashboard: {
      taskNotAssigned: "Task not assigned",
      weeklyReport: "Weekly report",
      targetHours: "Target hours",
      plannedHours: "Planned hours",
      workLogged: "Work logged",
      billableHours: "Billable hours",
      personal: "Personal",
      team: "Team",
      // totalPlannedHours: "Planned hours",
      totalWorkLogged: "Work logged",
      totalBillableHours: "Billable hours",
      timeEntryNotAssigned: "Time entry not assigned",
    },
    customers: {
      titleList: "Customers",
      title: "Customer",
      nominative: "Nominative",
      email: "Email",
      fiscalCode: "Fiscal code",
      vatNumber: "Vat Number",
      externalId: "External ID",
      actions: {
        new: "New",
        updated: "Customer updated successfully",
        created: "Customer created successfully",
        delete: {
          title: "Are you absolutely sure you want to proceed?",
          alert: "Unexpected bad things will happen if you don’t read this!",
          message:
            "This action cannot be undone. This will permanently delete the customer, all the projects related and all the time entries (time tracked for the projects).",
          confirm: "Please type  <1>{{secureText}}</1> to cofirm.",
          deleted: "Customer deleted successfully",
        },
      },
    },
    integrations: {
      title: "Integrations",
      plugins: {
        googleCalendar: "Google calendar",
      },
      enablePaymo: "Enable Paymo",
      enableJira: "Enable Jira",
      tips: {
        paymo:
          "To enable integration with Paymo, generate the API Key from the page ",
      },
      allProjects: "All projects",
      selectProject: "Select project",
      tasks: {
        markCompleted: "Mark as completed",
        viewOnasana: "View on Asana",
        viewOntodoist: "View on Todoist",
        showDetails: "Show details",
        hideDetails: "Hide details",
        viewLink: "View link",
      },
    },
    team: {
      title: "Team",
      plannedHours: "Planned hours",
      workLog: "Work log",
      billableWorkLog: "Billable work log",
    },
    subscription: {
      title: "Manage subscription",
    },
    profile: {
      title: "Profile",
      language: "Language:",
      saveBtnText: "Save",
      languageChangePlace: "Select language",
    },
    workflows: {
      title: "Workflows",
      bttAdd: "New",
      name: "Status",
      newTitle: "New status",
      editTitle: "Edit status",
      deleteWorkflow: "Are you sure you want to delete this status?",
      selectedByDefault: "Selected by default in projects list",
    },
    webhooks: {
      title: "Webhooks",
      editTitle: "Edit webhook",
      newTitle: "New webhook",
      url: "Endpoint URL",
      description: "Description",
      events: "Events",
      enabled: "Enabled",
      disabled: "Disabled",
      status: "Status",
      urlPlaceholder: "https://",
      descriptionPlaceholder:
        "An optional description of what this webhook endpoint is used for.",
      eventCategories: {
        project: "Project",
      },
      eventsDescriptions: {
        "project-status-change":
          "Occurs whenever a project status has changed.",
      },
      condition: "Condition",
      conditions: {
        always: "Always",
      },
      deleteWebhook: "Are you sure you want to delete this webhook?",
    },
    teamplan: {
      chartTitle: "TEAM PLAN",
      noDataFound: "Please select a team member",
      selectAll: "Select All",
    },
    overheads: {
      titleList: "Overheads",
      title: "Overheads",
      addOverhead: "Add",
      editOverhead: "Edit",
      newOverhead: "New",
      deleteOverheadConfirm: "Are you sure you want to delete this overhead?",
      overheadDeleted: "Overhead deleted successfully",
      overheadCreated: "Overhead created successfully",
      overheadUpdated: "Overhead updated successfully",
    },
  },
  nav: {
    board: "Calendar",
    projects: "Projects",
    dashboard: "Dashboard",
    integrations: "Integrations",
    tasks: "Task",
    organization: "Organization",
    teamplan: "Team Plan",
    workspace: "WORKSPACE",
    planning: "PLANNING",
    list: "List",
  },
  tasks: {
    title: "Task",
    addTask: "New task",
    category: "Category",
    editTask: "Edit task",
    name: "Title",
    description: "Description",
    dueDate: "Due date",
    color: "Category",
    user: "Assignee",
    project: "Project",
    subtasks: "Subtasks",
    addSubtask: "Create subtask",
    priority: "Priority",
    statusName: "status",
    downloadTask: "Download",
    validations: {
      nameRequired: "Task name is required!",
      projectRequired: "Task project is required!",
      userRequired: "Assignee is required!",
    },
    priorities: {
      lowest: "Lowest",
      low: "Low",
      medium: "Medium",
      high: "High",
      highest: "Highest",
    },
    status: {
      open: "Open",
      completed: "Completed",
    },
  },
  menu: {
    profile: "Profile",
  },
  eventTile: {
    day: "Day",
    fromTime: "From",
    toTime: "To",
    noProject: "No project",
    recurrence: "Recurrence",
    repeatEvery: "Repeat every",
    repeatOn: "Repeat on",
    ends: "Ends",
    DAILY: "Days",
    WEEKLY: "Weeks",
    MONTHLY: "Months",
    YEARLY: "Years",
    never: "Never",
    after: "After",
    occurrences: "occurrences",
    date: "Date",
    doesNotRepeat: "Does not repeat",
    on: "on",
    untilDate: "unti",
    repeatLabel: "repeat {{repeat}} times",
    actions: {
      completed: "Completed",
      confirmTime: "Confirm time",
      edit: "Edit",
      copy: "Duplicate",
      activityToPlanning: "Change into Planning",
      planningToActivty: "Change into Activty",
    },
    validations: {
      endTime: "End time has to be after start time!",
    },
  },
  days: {
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
  },
  dayOfWeek: {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  },
  dayOfWeekShort: {
    0: "S",
    1: "M",
    2: "T",
    3: "W",
    4: "T",
    5: "F",
    6: "S",
  },
  months: {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  },
  roles: {
    admin: "Admin",
    manager: "Manager",
    member: "Member",
  },
  acls: {
    subject: {
      organization: "Corporation",
      role: "Roles",
      user: "Users",
      project: "Projects",
      timesheet: "Timesheet",
      team: "Team",
    },
    action: {
      read: "Read",
      edit: "Edit",
      create: "Create",
      delete: "Delete",
    },
  },
  calendar: {
    today: "Today",
  },
  termsOfUse: {
    welcome: "Welcome {{nominative}}!",
    message: "Complete registration",
    agree: "I agree to the ",
    termsOfUse: "Terms and Conditions.",
    privacyPolicy: "Privacy Policy",
  },
  cookiesConsent: {
    message: "This website uses cookies to enhance the user experience!",
    accept: "Accept",
    decline: "Decline",
  },
  plans: {
    change: "Change",
    free: "Free plan",
    unlimited: "Unlimited plan",
    units: {
      USER: "Users",
      PROJECT: "Projects",
      INTEGRATION: "Integrations",
      TEAM_DASHBOARD: "Team dashboard",
    },
    tierNotLimited: "No limits",
    enabled: "Enabled",
    disabled: "Disabled",
    title: "Our plans",
    gratis: "Gratis",
    currentPlan: "Current plan",
    selectPlan: "Select {{name}}",
    labels: {
      UNLIMITED_USER: "Unlimited users",
      UNLIMITED_PROJECT: "Unlimited Projects",
      UNLIMITED_INTEGRATION: "Unlimited integrations",
      UNLIMITED_TEAM_DASHBOARD: "Team dashboard",
    },
    paymentSuccess:
      "Subscription activated successfully! The update may take a few minutes ",
    paymentError: "Something went wrong",
    downgradeToFreePlan: "Downgrade to Free Plan",
  },
  common: {
    action: "Action",
    delete: "Delete",
    edit: "Edit",
    cancel: "Cancel",
    confirm: "Confirm",
    save: "Save",
    yes: "Yes",
    no: "No",
    search: "Search",
    reset: "Reset",
    actionExecutedSuccessfully: "Action executed successfully",
    error: "Something went wrong. Please try again",
    requiredField: "Required field",
    cleanFilters: "Clean filters",
    filter: "Filter",
    filterBy: "Filter By",
    refresh: "Refresh",
    create: "Create",
    backToLogin: "Back to login",
    goBack: "Back",
    language: "Language",
    selectPlaceholder: "Seleziona...",
    newVersionAvailable: "New version available",
  },
  languages: {
    it: "Italiano",
    en: "English",
  },
  messages: {
    formValidations: {
      projectWorkflowForm: {
        nameRequired: "The name must be at least 2 characters long",
      },
      webhookForm: {
        urlRequired: "Endpoint is required",
      },
      inviteUserForm: {
        emailRequired: "Email is required",
        emailInvalid: "Must be a valid email",
        nominativeRequired: "Nominative is required",
        roleRequired: "Role is required",
      },
    },
  },
  components: {
    searchBar: {
      placeholder: "Search...",
    },
    userContractsTable: {
      fromData: "From",
      toDate: "To",
      hourlyCost: "Hourly cost",
      contractualHours: "Contractual hours",
      contractType: "Contract type",
      productivityTarget: "Productivity target",
    },
    userContractForm: {
      fromDate: "From",
      toDate: "To",
    },
    changelogModal: {
      title: "Changelog",
      error: "An error occurred while loading the changelog",
    },
    projectCard: {
      status: "Status",
      currentMarginability: "Current marginability",
      endDate: "End date",
      customer: "Customer"
    },
    overheadsTable: {
      fromData: "From",
      toDate: "To",
      amount: "Amount",
    },
    overheadForm: {
      fromDate: "From",
      toDate: "To",
      amount: "Amount",
    },
  },
  filterStatus: {
    active: "Active",
    inActive: "InActive",
    all: "All",
    invited: "Invited",
  }
};

export default en;
