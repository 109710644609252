// Function that convert seconds in the format HH:MM:SS
export const sec2time = (
  timeInSeconds: number,
  format: "HH:MM:SS" | "hm" | "HH:MM" = "HH:MM:SS"
) => {
  var pad = function (num, size) {
      return ("000" + num).slice(size * -1);
    },
    time: any = parseFloat(timeInSeconds.toString()).toFixed(3),
    hours: number = Math.floor(time / 60 / 60),
    minutes: number = Math.floor(time / 60) % 60,
    seconds: number = Math.floor(time - minutes * 60);

  return format === "HH:MM:SS"
    ? pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2)
    : format === "hm"
    ? `${pad(hours, hours < 10 ? 1 : 2)}h ${pad(minutes, 2)}m`
    : pad(hours, 2) + ":" + pad(minutes, 2);
};

// time => HH:MM:SS | HH:MM
export const time2sec = (time: string) => {
  const timeUnits = time.split(":");

  return +timeUnits[0] * 60 * 60 + +timeUnits[1] * 60 + (+timeUnits[2] || 0);
};

// HH:MM => xx.xx
export const time2Decimal = (t: string): number => {
  var arr = t.split(":");
  var dec = (parseInt(arr[1], 10) / 6) * 10;

  return parseFloat(parseInt(arr[0], 10) + "." + (dec < 10 ? "0" : "") + dec);
};

export const decimalTime2ApproximatedTime = (decimalTime: number): number => {
  const time = Math.round(decimalTime / 0.25) * 0.25;
  const hours = Math.floor(time / 60 / 60);
  let minutes = Math.floor(time / 60) % 60;

  //Approximate minutes to the nearest 15 minutes
  if (minutes % 15 === 0) {
    return time;
  } else if (minutes % 15 < 7.5) {
    minutes = minutes - (minutes % 15);
  } else {
    minutes = minutes + 15 - (minutes % 15);
  }

  return hours * 60 * 60 + minutes * 60;
};
