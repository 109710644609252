const integrations = {
  gcalendar: {
    title: "Google Calendar",
  },
  todoist: {
    title: "Todoist",
  },
  paymo: {
    title: "Paymo",
  },
  jira: {
    title: "Jira",
  },
  asana: {
    title: "Asana",
  },
};

export default integrations;
