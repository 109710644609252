import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { Observer, observer } from "mobx-react";
import { Customer } from "../../types";
import {
  Button,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { PageHeader } from "../../components/PageHeader";
import { ActionGroup } from "../../components/ActionGroup";
import { VirtualizedTable } from "../../components/VirtualizedTable";
import { Icon } from "../../components/Icon";
import useDebounce from "../../hooks/useDebounce";
import { fuzzyMatch } from "../../utils/string";
import styled from "styled-components";
import { CustomerModal } from "../../components/Customers/CutomerModal";
import { DeleteConfirmModal } from "../../components/DeleteConfirmModal/DeleteConfirmModal";

export const Customers: React.FC = observer(() => {
  const { organization } = useStores();
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const toast = useToast();

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const debouncedFilters = useDebounce(organization.customersFilters, 300);

  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<
    "ASC" | "DESC" | undefined
  >(undefined);

  const {
    isOpen: isCustomerModalOpen,
    onOpen: onCustomerModalOpen,
    onClose: onCustomerModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteConfirmModalOpen,
    onOpen: onDeleteConfirmModalOpen,
    onClose: onDeleteConfirmModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (organizationId) {
      organization.fetchCustomers(organizationId);
    }
  }, []);

  const changeFilter = useCallback(
    (dataKey, value) => {
      organization.updateCustomerFilters(dataKey, value);
    },
    [organization]
  );

  const handleCustomerClick = useCallback(
    (customer: Customer) => {
      setSelectedCustomer(customer);
      onCustomerModalOpen();
    },
    [onCustomerModalOpen]
  );

  const handleCloseCustomerModal = useCallback(() => {
    setSelectedCustomer(null);
    onCustomerModalClose();
  }, [onCustomerModalClose]);

  const handleCloseDeleteConfirmModal = useCallback(() => {
    onDeleteConfirmModalClose();
  }, [onDeleteConfirmModalClose]);

  const getTextSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <Input
                  id={dataIndex}
                  value={
                    (organization.customersFilters[dataIndex] as string) || ""
                  }
                  onChange={(e) => changeFilter(dataIndex, e.target.value)}
                  placeholder={`${t<string>("common.search")} ${t<string>(
                    `screens.customers.${dataIndex}`
                  )}`}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, organization.customersFilters, t]
  );

  const columns = useMemo(
    () => [
      {
        width: 80,
        flexGrow: 2,
        label: t("screens.customers.nominative"),
        dataKey: "nominative",
        sorter: (a, b) => a.nominative.localeCompare(b.nominative),
        ...getTextSearchProps("nominative"),
        render: (nominative: Customer["nominative"], record: Customer) => (
          <Link
            to={"#"}
            style={{
              color: "var(--chakra-colors-teal-600)",
              fontWeight: 500,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            onClick={(e) => {
              e.preventDefault();
              handleCustomerClick(record);
            }}
          >
            <Tooltip label={nominative} aria-label={nominative}>
              {nominative}
            </Tooltip>
          </Link>
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.customers.email"),
        dataKey: "email",
        //render: (email: Customer["email"]) => customer?.nominative,
        ...getTextSearchProps("email"),
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
    ],
    [getTextSearchProps, handleCustomerClick, t]
  );

  const handleSort = useCallback(
    (dataKey: string) => {
      if (dataKey === sortBy && sortDirection === "ASC") {
        setSortDirection("DESC");
      } else if (dataKey === sortBy && sortDirection === "DESC") {
        setSortBy(undefined);
        setSortDirection(undefined);
      } else {
        setSortBy(dataKey);
        setSortDirection("ASC");
      }
    },
    [sortBy, sortDirection, setSortBy, setSortDirection]
  );

  const handleCustomerSubmit = useCallback(
    async (customer) => {
      try {
        if (organizationId) {
          if (customer.uid) {
            await organization.updateCustomer(organizationId, customer);

            toast({
              title: t("screens.customers.actions.updated"),
              status: "success",
              position: "bottom-left",
            });
          } else {
            await organization.createCustomer(organizationId, customer);

            toast({
              title: t("screens.customers.actions.created"),
              status: "success",
              position: "bottom-left",
            });
          }
        }

        setSelectedCustomer(null);
        onCustomerModalClose();
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [onCustomerModalClose, organization, organizationId, toast, t]
  );

  const handleDeleteAction = useCallback(async () => {
    onCustomerModalClose();
    onDeleteConfirmModalOpen();
  }, [onCustomerModalClose, onDeleteConfirmModalOpen]);

  const onDelete = useCallback(
    async (customer) => {
      try {
        if (organizationId) {
          await organization.deleteCustomer(organizationId, customer.uid);
          toast({
            title: t("screens.customers.actions.delete.deleted"),
            status: "success",
            position: "bottom-left",
          });
        }
        setSelectedCustomer(null);
        onDeleteConfirmModalClose();
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [organizationId, onDeleteConfirmModalClose, organization, toast, t]
  );

  const customers = useMemo(() => {
    let customers = [...organization.customers];
    if (sortBy) {
      const columnData = columns.find((column) => column.dataKey === sortBy);

      if (columnData?.sorter) {
        customers = customers.sort(columnData.sorter);

        if (sortDirection === "DESC") {
          customers = customers.reverse();
        }
      }
    }

    for (let dataKey of Object.keys(debouncedFilters)) {
      customers = customers.filter((customer) =>
        Array.isArray(debouncedFilters[dataKey])
          ? (debouncedFilters[dataKey] as string[]).some((searchQuery) =>
              fuzzyMatch(JSON.stringify(customer[dataKey]), searchQuery)
            )
          : fuzzyMatch(
              JSON.stringify(customer[dataKey]),
              debouncedFilters[dataKey] as string
            )
      );
    }

    return customers;
  }, [
    organization.customers,
    sortBy,
    columns,
    sortDirection,
    debouncedFilters,
  ]);

  return (
    <StyledStack w="100%" h="100%">
      <PageHeader>
        <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
          {t<string>("screens.customers.titleList")}
        </Heading>
        <ActionGroup>
          {organization.isFetchingCustomers && <Spinner />}
          {Object.keys(organization.customersFilters).length > 0 && (
            <Button
              variant={"ghost"}
              colorScheme={"teal"}
              leftIcon={<Icon iconName={"HiOutlineX"} />}
              onClick={() => organization.cleanCustomerFilters()}
            >
              {t<string>("common.cleanFilters")}
            </Button>
          )}
          <Button colorScheme={"teal"} onClick={onCustomerModalOpen}>
            {t<string>("screens.customers.actions.new")}
          </Button>
        </ActionGroup>
      </PageHeader>

      <div style={{ flex: "1 1 auto", width: "100%", display: "flex" }}>
        <VirtualizedTable
          rowCount={customers.length}
          rowGetter={({ index }) => customers[index]}
          columns={columns}
          onHeaderCellClick={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          activeFilters={organization.customersFilters}
          rowStyle={(row) =>
            row.index % 2 === 0 ? { background: "#EDF1FB" } : {}
          }
        />
      </div>

      {isCustomerModalOpen && (
        <CustomerModal
          customer={selectedCustomer}
          onSubmit={handleCustomerSubmit}
          onDismiss={handleCloseCustomerModal}
          onDelete={handleDeleteAction}
          isVisible={isCustomerModalOpen}
        />
      )}
      {isDeleteConfirmModalOpen && (
        <DeleteConfirmModal
          customer={selectedCustomer}
          onSubmit={onDelete}
          onDismiss={handleCloseDeleteConfirmModal}
          isVisible={true}
        />
      )}
    </StyledStack>
  );
});
const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
`;
