import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { Observer, observer } from "mobx-react";
import { ProjectForm } from "../../components/ProjectForm";
import Project from "../../models/Project";
import { VirtualizedTable } from "../../components/VirtualizedTable";
import { PageHeader } from "../../components/PageHeader";
import { Heading, HStack, Stack, Text, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Icon } from "../../components/Icon";
import { ActionGroup } from "../../components/ActionGroup";
import { SearchBar } from "../../components/SearchBar";
import useDebounce from "../../hooks/useDebounce";
import { fuzzyMatch } from "../../utils/string";
import { Tooltip } from "@chakra-ui/tooltip";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  Spinner,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { CategoriesDrawer } from "./CategoriesDrawer";
import { Select } from "../../components/Select";
import { FilterProjectFormValues, ProjectStatus } from "../../types";
import { DownloadCSV } from "../../components/DownloadCSV";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useProjectsWorkflows } from "../../hooks/useProjectsWorkflows";
import { isLimited } from "../../utils/plan";
import { CustomerModal } from "../../components/Customers/CutomerModal";
import { useUserProjects } from "../../hooks/useUserProjects";
import { ProjectCard } from "../../components/ProjectCard";
import { useNavigate } from "react-router";
import { ProjectFormBeta } from "../../components/ProjectFormBeta";
import { Box, BoxWithoutShadow } from "../../components/Box";
import ds from "../../config/projects";
import FilterProjectForm from "../../components/FilterProjectForm";

export const Projects: React.FC = observer(() => {
  const [currentProject, setCurrentProject] = useState<null | Project>(null);
  const { organization, session } = useStores();
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const organizationId = queryParams.get("organizationId") || "";
  const viewMode = queryParams.get("v") || "my-projects";
  const toast = useToast();
  const navigate = useNavigate();
  // const [viewMode, setViewMode] = useState<"my-prjects" | "all-projects">(
  //   view === "all-projects" ? "all-projects" : "my-prjects"
  // );
  const [
    filterByData,
    setFilterBayData,
  ] = useState<FilterProjectFormValues | null>(null);

  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<
    "ASC" | "DESC" | undefined
  >(undefined);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [searchQueryProject, setSearchQueryProject] = useState<string | null>(
    null
  );
  const debouncedQuery = useDebounce(searchQuery, 300);
  const {
    isFetching: isFetchingProjectsWorkflows,
    data: projectsWorkflows = [],
  } = useProjectsWorkflows(organizationId);

  const {
    data: userProjects = [],
    refetch: refetchUserProjects,
  } = useUserProjects(organizationId, String(session.user?.uid));

  const debouncedFilters = useDebounce(organization.projectsFilters, 300);

  const {
    isOpen: isCategoriesDrawerOpen,
    onOpen: onCategoriesDrawerOpen,
    onClose: onCategoriesDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isProjectModalOpen,
    onOpen: onProjectModalOpen,
    onClose: onProjectModalClose,
  } = useDisclosure();

  const {
    isOpen: isFilterModal,
    onOpen: onFilterModalOpen,
    onClose: onFilterModalClose,
  } = useDisclosure();

  const {
    isOpen: isCustomerModalOpen,
    onOpen: onCustomerModalOpen,
    onClose: onCustomerModalClose,
  } = useDisclosure();

  const handleCloseCustomerModal = useCallback(() => {
    onCustomerModalClose();
  }, [onCustomerModalClose]);

  const teamLeaderProjects = useMemo(() => {
    return userProjects.filter((project) => {
      // if (searchQueryProject) {
      //   const serachBox = (project.title.toLowerCase().includes(searchQueryProject.toLowerCase()) || (project.customer?.nominative && project.customer?.nominative.toLowerCase().includes(searchQueryProject.toLowerCase()))) && project.projectManager?.uid === session.user?.uid;
      //   // const filterBySearch = filterByData ? (filterByData.projectTitle ? (project.title.toLowerCase().includes(filterByData.projectTitle) || (project.customer?.nominative && project.customer?.nominative.toLowerCase().includes(filterByData.projectTitle.toLowerCase()))) && project.projectManager?.uid === session.user?.uid : false) : false
      //   // console.log("serachBox -> ", serachBox, "\nfilterBySearch -> ", filterBySearch, "\nfilterByData -> ", filterByData);
      //   return serachBox
      // } else {
      //   return project.projectManager?.uid === session.user?.uid
      // }

      const isProjectManager =
        project.projectManager?.uid === session.user?.uid;

      const matchesSearchQuery = searchQueryProject
        ? project.title.toLowerCase().includes(searchQueryProject.toLowerCase())
        : true;

      const matchesFilterForm = filterByData?.projectTitle
        ? project.title
            .toLowerCase()
            .includes(filterByData.projectTitle.toLowerCase())
        : true;

      const matchRefenceQuta = filterByData?.referenceQuote
        ? project.externalReference
            ?.toLowerCase()
            .includes(filterByData.referenceQuote.toLowerCase())
        : true;
      const matchProjectCategory = filterByData?.projectCategory
        ? filterByData.projectCategory.length > 0
          ? filterByData.projectCategory.some(
              (e) => e === project.category?.uid
            )
          : true
        : true;
      const matchProjectType = filterByData?.projectType
        ? filterByData.projectType.length > 0
          ? filterByData.projectType.some((e) => e === project.projectType)
          : true
        : true;
      const matchProjectBudget = filterByData?.budget
        ? project.budget === filterByData.budget
        : true;
      const matchProjectMarginity = filterByData?.marginality
        ? project.marginabilityPercentage === filterByData.marginality
        : true;
      const matchProjectLeader = filterByData?.projectLeader
        ? filterByData.projectLeader.length > 0
          ? filterByData.projectLeader.some(
              (e) => e === project.projectManager?.uid
            )
          : true
        : true;
      const matchProjectStatus = filterByData?.status
        ? filterByData.status.length > 0
          ? filterByData.status.some((e) => e === project.status?.uid)
          : true
        : true;
      const matchProjectCustomer = filterByData?.customerName
        ? filterByData.customerName.length > 0
          ? filterByData.customerName.some((e) => e === project.customer?.uid)
          : true
        : true;

      return (
        isProjectManager &&
        matchesSearchQuery &&
        matchesFilterForm &&
        matchRefenceQuta &&
        matchProjectCategory &&
        matchProjectType &&
        matchProjectBudget &&
        matchProjectMarginity &&
        matchProjectLeader &&
        matchProjectCustomer &&
        matchProjectStatus
      );
    });
  }, [session.user?.uid, userProjects, searchQueryProject, filterByData]);

  const teamMemberProjects = useMemo(() => {
    return userProjects.filter((project) => {
      const isProjectManager =
        project.projectManager?.uid !== session.user?.uid;

      const matchesSearchQuery = searchQueryProject
        ? project.title.toLowerCase().includes(searchQueryProject.toLowerCase())
        : true;

      const matchesFilterForm = filterByData?.projectTitle
        ? project.title
            .toLowerCase()
            .includes(filterByData.projectTitle.toLowerCase())
        : true;

      const matchRefenceQuta = filterByData?.referenceQuote
        ? project.externalReference
            ?.toLowerCase()
            .includes(filterByData.referenceQuote.toLowerCase())
        : true;
      const matchProjectCategory = filterByData?.projectCategory
        ? filterByData.projectCategory.length > 0
          ? filterByData.projectCategory.some(
              (e) => e === project.category?.uid
            )
          : true
        : true;
      const matchProjectType = filterByData?.projectType
        ? filterByData.projectType.length > 0
          ? filterByData.projectType.some((e) => e === project.projectType)
          : true
        : true;
      const matchProjectBudget = filterByData?.budget
        ? project.budget === filterByData.budget
        : true;
      const matchProjectMarginity = filterByData?.marginality
        ? project.marginabilityPercentage === filterByData.marginality
        : true;
      const matchProjectLeader = filterByData?.projectLeader
        ? filterByData.projectLeader.length > 0
          ? filterByData.projectLeader.some(
              (e) => e === project.projectManager?.uid
            )
          : true
        : true;
      const matchProjectStatus = filterByData?.status
        ? filterByData.status.length > 0
          ? filterByData.status.some((e) => e === project.status?.uid)
          : true
        : true;
      const matchProjectCustomer = filterByData?.customerName
        ? filterByData.customerName.length > 0
          ? filterByData.customerName.some((e) => e === project.customer?.uid)
          : true
        : true;

      return (
        isProjectManager &&
        matchesSearchQuery &&
        matchesFilterForm &&
        matchRefenceQuta &&
        matchProjectCategory &&
        matchProjectType &&
        matchProjectBudget &&
        matchProjectMarginity &&
        matchProjectLeader &&
        matchProjectCustomer &&
        matchProjectStatus
      );
      // if (searchQueryProject) {
      //   return (project.title.toLowerCase().includes(searchQueryProject.toLowerCase()) || (project.customer?.nominative && project.customer?.nominative.toLowerCase().includes(searchQueryProject.toLowerCase()))) && project.projectManager?.uid !== session.user?.uid
      // } else {
      //   return project.projectManager?.uid !== session.user?.uid
      // }
    });
  }, [session.user?.uid, userProjects, searchQueryProject]);

  useEffect(() => {
    organization.fetchProjects(organizationId);
    organization.fetchProjectCategories(organizationId);
    organization.fetchCustomers(organizationId);
    organization.fetchUsers(organizationId);
  }, []);

  useEffect(() => {
    if (!isFetchingProjectsWorkflows && projectsWorkflows.length > 0) {
      organization.projectsFilters["status"] = projectsWorkflows.reduce(
        (acc, workflow): Array<string> =>
          workflow.selectedByDefault ? [...acc, workflow.uid] : acc,
        [] as Array<string>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingProjectsWorkflows]);

  const changeFilter = useCallback(
    (dataKey, value) => {
      organization.updateProjectFilters(dataKey, value);
    },
    [organization]
  );

  const getTextSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <Input
                  id={dataIndex}
                  value={
                    (organization.projectsFilters[dataIndex] as string) || ""
                  }
                  onChange={(e) => changeFilter(dataIndex, e.target.value)}
                  placeholder={`${t<string>("common.search")} ${t<string>(
                    `screens.projects.${dataIndex}`
                  )}`}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, organization, t]
  );

  const statusOptions = useMemo(
    () =>
      projectsWorkflows.map((status) => ({
        label: status.name,
        value: status.uid,
      })),
    [projectsWorkflows]
  );

  const getSelectedStatus: any = useCallback(
    (statusListIds: ProjectStatus["uid"][]) => {
      return (
        statusListIds &&
        statusListIds.map((statusId) => {
          const status = projectsWorkflows.find(
            (category) => category.uid === statusId
          );

          return (
            status && {
              value: status.uid,
              label: status.name,
            }
          );
        })
      );
    },
    [projectsWorkflows]
  );

  const getStatusSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <div style={{ width: "100%" }}>
                  <Select
                    id={dataIndex}
                    options={statusOptions}
                    // @ts-ignore
                    isMulti
                    value={
                      getSelectedStatus(
                        organization.projectsFilters[dataIndex]
                      ) || []
                    }
                    onChange={(options) => {
                      const selectedOptions = options as any;

                      changeFilter(
                        dataIndex,
                        options
                          ? (selectedOptions as {
                              label: string;
                              value: string;
                            }[]).map((option) => option.value)
                          : null || null
                      );
                    }}
                  />
                </div>
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, getSelectedStatus, organization, statusOptions]
  );

  const projectTypesOptions = useMemo(
    () =>
      ds.project_type.map((type, ind) => ({
        label: type,
        value: type,
      })),
    [ds.project_type]
  );

  const getSelectedProjectTypes: any = useCallback(
    (projectTypeValue: Project["projectType"][]) => {
      return (
        projectTypeValue &&
        projectTypeValue.map((ptValue) => {
          const ptype = ds.project_type.find((type) => type === ptValue);

          return (
            ptype && {
              value: ptype,
              label: ptype,
            }
          );
        })
      );
    },
    [ds.project_type]
  );

  const getProjectTypeSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <div style={{ width: "100%" }}>
                  <Select
                    id={dataIndex}
                    options={projectTypesOptions}
                    // @ts-ignore
                    isMulti
                    value={
                      getSelectedProjectTypes(
                        organization.projectsFilters[dataIndex]
                      ) || []
                    }
                    onChange={(options) => {
                      const selectedOptions = options as any;

                      changeFilter(
                        dataIndex,
                        options
                          ? (selectedOptions as {
                              label: string;
                              value: string;
                            }[]).map((option) => option.value)
                          : null || null
                      );
                    }}
                  />
                </div>
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, getSelectedProjectTypes, organization, projectTypesOptions]
  );

  const getSelectedUser: any = useCallback(
    (userIds: string[]) => {
      return (
        userIds &&
        userIds.map((userId) => {
          const user = organization.users.find((user) => user.uid === userId);
          return user
            ? {
                value: user.uid,
                label: user.nominative,
              }
            : "";
        })
      );
    },
    [organization]
  );

  const getProjectManagerSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <div style={{ width: "100%" }}>
                  <Select
                    key={organization.users.length}
                    id={dataIndex}
                    // @ts-ignore
                    isMulti
                    options={organization.users.map((user) => ({
                      label: user.nominative,
                      value: user.uid,
                    }))}
                    value={
                      getSelectedUser(
                        organization.projectsFilters[dataIndex]
                      ) || ""
                    }
                    onChange={(options) => {
                      const selectedOptions = options as any;

                      changeFilter(
                        dataIndex,
                        options
                          ? (selectedOptions as {
                              label: string;
                              value: string;
                            }[]).map((option) => option.value)
                          : null || null
                      );
                    }}
                  />
                </div>
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, organization, getSelectedUser]
  );

  const getSelectedCategory: any = useCallback(
    (categoryIds) => {
      return (
        categoryIds &&
        categoryIds.map((categoryId) => {
          const category = organization.projectCategories.find(
            (category) => category.uid === categoryId
          );

          return (
            category && {
              value: category.uid,
              label: category.name,
            }
          );
        })
      );
    },
    [organization]
  );

  const getProjectCategorySearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <div style={{ width: "100%" }}>
                  <Select
                    id={dataIndex}
                    options={organization.projectCategories.map((category) => ({
                      label: category.name,
                      value: category.uid,
                    }))}
                    // @ts-ignore
                    isMulti
                    value={
                      getSelectedCategory(
                        organization.projectsFilters[dataIndex]
                      ) || ""
                    }
                    onChange={(options) => {
                      const selectedOptions = options as any;

                      changeFilter(
                        dataIndex,
                        options
                          ? (selectedOptions as {
                              label: string;
                              value: string;
                            }[]).map((option) => option.value)
                          : null || null
                      );
                    }}
                  />
                </div>
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, getSelectedCategory, organization]
  );

  const columns = useMemo(
    () => [
      {
        width: 120,
        flexGrow: 2,
        label: t("screens.projects.title"),
        dataKey: "title",
        sorter: (a, b) => a.title.localeCompare(b.title),
        ...getTextSearchProps("title"),
        render: (title: Project["title"], record: Project) => (
          <Link
            to={`/user/${session.user?.uid}/projects/${record.uid}?organizationId=${organizationId}`}
            style={{
              color: "var(--chakra-colors-teal-600)",
              fontWeight: 500,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip label={title} aria-label={title}>
              {title}
            </Tooltip>
          </Link>
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.projects.customer"),
        dataKey: "customer",
        render: (customer: Project["customer"]) => customer?.nominative,
        ...getTextSearchProps("customer"),
        sorter: (a, b) =>
          a.customer?.nominative.localeCompare(b.customer?.nominative),
      },
      {
        width: 80,
        flexGrow: 2,
        label: t("screens.projects.externalReference"),
        dataKey: "externalReference",
        ...getTextSearchProps("externalReference"),
      },
      {
        width: 80,
        flexGrow: 2,
        label: t("screens.projects.projectManager"),
        dataKey: "projectManager",
        render: (projectManager: Project["projectManager"]) =>
          projectManager?.nominative,
        ...getProjectManagerSearchProps("projectManager"),
        sorter: (a, b) =>
          a["projectManager"]?.nominative.localeCompare(
            b["projectManager"]?.nominative
          ),
      },
      {
        width: 60,
        flexGrow: 1,
        label: t("screens.projects.status"),
        dataKey: "status",
        render: (status: Project["status"]) => status?.name || "",
        sorter: (a: Project, b: Project) =>
          (a.status?.name || "").localeCompare(b.status?.name || ""),
        ...getStatusSearchProps("status"),
      },
      {
        width: 60,
        flexGrow: 1,
        label: t("screens.projects.projectType"),
        dataKey: "projectType",
        render: (projectType: Project["projectType"]) => projectType || "",
        sorter: (a: Project, b: Project) =>
          (a.projectType || "").localeCompare(b.projectType || ""),
        ...getProjectTypeSearchProps("projectType"),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.projects.category"),
        dataKey: "category",
        render: (category: Project["category"]) => category?.name,
        ...getProjectCategorySearchProps("category"),
        sorter: (a, b) =>
          (a["category"]?.name || "").localeCompare(b["category"]?.name || ""),
      },
      {
        width: 70,
        flexGrow: 1,
        label: t("screens.projects.budget"),
        dataKey: "budget",
        sorter: (a, b) => a["budget"] - b["budget"],
        render: (budget: Project["budget"]) => (budget ? `${budget} €` : ""),
      },
      {
        width: 70,
        flexGrow: 1,
        label: t("screens.projects.currentMarginability"),
        dataKey: "currentMarginability",
        sorter: (a, b) => a["currentMarginability"] - b["currentMarginability"],
        render: (currentMarginability: Project["currentMarginability"]) =>
          currentMarginability ? `${currentMarginability} %` : "",
      },
    ],
    [
      organizationId,
      t,
      getStatusSearchProps,
      getProjectCategorySearchProps,
      getProjectManagerSearchProps,
      getTextSearchProps,
    ]
  );

  const projects = useMemo(() => {
    let projects = organization.projects;
    if (sortBy) {
      const columnData = columns.find((column) => column.dataKey === sortBy);

      if (columnData?.sorter) {
        projects = projects.slice().sort(columnData.sorter);

        if (sortDirection === "DESC") {
          projects = projects.reverse();
        }
      }
    }

    if (debouncedQuery) {
      projects = projects.filter(
        (project) =>
          !debouncedQuery || fuzzyMatch(JSON.stringify(project), debouncedQuery)
      );
    }

    for (let dataKey of Object.keys(debouncedFilters)) {
      projects = projects.filter((project) =>
        Array.isArray(debouncedFilters[dataKey])
          ? (debouncedFilters[dataKey] as string[]).some((searchQuery) =>
              fuzzyMatch(JSON.stringify(project[dataKey]), searchQuery)
            )
          : fuzzyMatch(
              JSON.stringify(project[dataKey]),
              debouncedFilters[dataKey] as string
            )
      );
    }

    return projects;
  }, [
    organization.projects,
    sortBy,
    sortDirection,
    columns,
    debouncedQuery,
    debouncedFilters,
  ]);

  const csvData = useMemo(() => {
    const rows = [
      [
        t("screens.projects.title").toUpperCase(),
        t("screens.projects.customer").toUpperCase(),
        t("screens.projects.externalReference").toUpperCase(),
        t("screens.projects.projectManager").toUpperCase(),
        t("screens.projects.status").toUpperCase(),
        t("screens.projects.projectType").toUpperCase(),
        t("screens.projects.category").toUpperCase(),
        t("screens.projects.budget").toUpperCase(),
        t("screens.projects.currentMarginability").toUpperCase(),
        t("screens.projects.startDate").toUpperCase(),
        t("screens.projects.endDate").toUpperCase(),
      ],
    ];

    if (projects) {
      for (let project of projects) {
        rows.push([
          project.title,
          project.customer?.nominative || "",
          project.externalReference || "",
          project.projectManager?.nominative || "",
          project.status?.name || "",
          project.projectType || "",
          project.category?.name || "",
          project.budget?.toString() || "",
          project.currentMarginability || "",
          project.startDate?.toLocaleDateString() || "",
          project.endDate?.toLocaleDateString() || "",
        ]);
      }
    }

    return rows;
  }, [projects, t]);

  const handleOpenProjectModal = useCallback(() => {
    organization.fetchUsers(organizationId);
    organization.fetchProjectCategories(organizationId);
    onProjectModalOpen();
  }, [onProjectModalOpen, organizationId, organization]);

  const handleSubmit = useCallback(
    async (project) => {
      try {
        await organization.createProject(organizationId, project);
        toast({
          title: t("screens.projects.actions.createSuccess"),
          status: "success",
          position: "bottom-left",
        });

        onProjectModalClose();
        setCurrentProject(null);
        refetchUserProjects();
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [organization, organizationId, toast, t, onProjectModalClose]
  );

  // const handleDeleteProject = useCallback(
  //   async (projectId: Project["uid"]) => {
  //     if (window.confirm(t("screens.projects.actions.delete"))) {
  //       try {
  //         await organization.deleteProject(organizationId, projectId);
  //         setCurrentProject(null);
  //         setIsModalProjectVisible(false);
  //       } catch (err) {
  //         alert(err);
  //       }
  //     }
  //   },
  //   [organization, organizationId, t]
  // );

  // const handleSubmitCategory = useCallback(
  //   async (category) => {
  //     try {
  //       await organization.createProjectCategory(organizationId, category.name);

  //       enqueueSnackbar(t("screens.projects.actions.categorySuccess"), {
  //         variant: "success",
  //       });
  //       onCategoryModalClose();
  //     } catch (err) {
  //       console.log(err);
  //       enqueueSnackbar(t("common.error"), {
  //         variant: "error",
  //       });
  //     }
  //   },
  //   [organization, organizationId, t, enqueueSnackbar, onCategoryModalClose]
  // );

  // const handleUpdateCategory = useCallback(
  //   async (categoryId, data) => {
  //     try {
  //       await organization.updateProjectCategory(
  //         organizationId,
  //         categoryId,
  //         data.label
  //       );
  //       enqueueSnackbar(t("screens.projects.actions.categoryUpdateSuccess"), {
  //         variant: "success",
  //       });
  //     } catch (err) {
  //       console.log(err);
  //       enqueueSnackbar(t("common.error"), {
  //         variant: "error",
  //       });
  //     }
  //   },
  //   [organizationId, organization, t, enqueueSnackbar]
  // );

  // const handleSearch = useCallback(
  //   (selectedKeys, confirm, dataIndex) => {
  //     confirm();
  //     setSearchText(selectedKeys[0]);
  //     setSearchedColumn(dataIndex);
  //   },
  //   [setSearchText, setSearchedColumn]
  // );

  // const handleReset = useCallback(
  //   (clearFilters) => {
  //     clearFilters();
  //     setSearchText("");
  //   },
  //   [setSearchText]
  // );

  const handleSort = useCallback(
    (dataKey: string) => {
      if (dataKey === sortBy && sortDirection === "ASC") {
        setSortDirection("DESC");
      } else if (dataKey === sortBy && sortDirection === "DESC") {
        setSortBy(undefined);
        setSortDirection(undefined);
      } else {
        setSortBy(dataKey);
        setSortDirection("ASC");
      }
    },
    [sortBy, sortDirection, setSortBy, setSortDirection]
  );

  const handleCustomerSubmit = useCallback(
    async (customer) => {
      try {
        if (organizationId) {
          await organization.createCustomer(organizationId, customer);

          toast({
            title: t("screens.customers.actions.created"),
            status: "success",
            position: "bottom-left",
          });
        }

        onCustomerModalClose();
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [onCustomerModalClose, organization, organizationId, toast, t]
  );

  const handleChangeViewMode = useCallback(() => {
    // setViewMode(viewMode === "all-projects" ? "my-prjects" : "all-projects");

    navigate(
      `/user/${session.user?.uid}/projects?organizationId=${organizationId}&v=${
        viewMode === "all-projects" ? "my-projects" : "all-projects"
      }`
    );
  }, [navigate, organizationId, session.user?.uid, viewMode]);

  const handleProjectCardClick = useCallback(
    (projectId: Project["uid"]) => {
      navigate(
        `/user/${session.user?.uid}/projects/${projectId}?organizationId=${organizationId}`
      );
    },
    [navigate, session.user?.uid, organizationId]
  );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
      }}
    >
      <PageHeader>
        <Heading
          as={"h4"}
          size={"sm"}
          fontWeight={"medium"}
          onClick={handleChangeViewMode}
          cursor={"pointer"}
          borderColor={"#319795"}
          border={"1px solid"}
          color={"#319795"}
          borderRadius={"0.5rem"}
          px={"1rem"}
          py={"0.5rem"}
        >
          {viewMode === "all-projects"
            ? t<string>("screens.projects.myProjects")
            : t<string>("screens.projects.allProjects")}
        </Heading>

        <ActionGroup>
          {organization.isFetching && <Spinner />}
          {viewMode === "all-projects" && (
            <>
              {Object.keys(organization.projectsFilters).length > 0 && (
                <Button
                  variant={"ghost"}
                  size={"sm"}
                  colorScheme={"teal"}
                  leftIcon={<Icon iconName={"HiOutlineX"} />}
                  onClick={() => organization.cleanProjectFilters()}
                >
                  {t<string>("common.cleanFilters")}
                </Button>
              )}
              <SearchBar
                value={searchQuery}
                onChange={(value) => setSearchQuery(value)}
              />
              <DownloadCSV data={csvData} target={"_blank"} separator={";"} />
            </>
          )}
          {viewMode === "my-projects" && (
            <HStack>
              <SearchBar
                value={searchQueryProject}
                onChange={(value) => setSearchQueryProject(value)}
              />
              <Button
                variant={"outline"}
                size={"sm"}
                fontSize={"15px"}
                fontWeight={"semibold"}
                borderRadius={"6px"}
                w="108px"
                h="100%"
                colorScheme={"teal"}
                onClick={() => onFilterModalOpen()}
              >
                {t<string>("common.filter")}
              </Button>
            </HStack>
          )}
          <HStack>
            <Button
              leftIcon={<Icon iconName={"HiOutlineTag"} />}
              colorScheme="teal"
              variant="ghost"
              fontSize={"15px"}
              fontWeight={"semibold"}
              h="100%"
              size={"sm"}
              onClick={onCategoriesDrawerOpen}
            >
              {t<string>("screens.projects.categories")}
            </Button>
            <Button
              colorScheme={"teal"}
              size={"sm"}
              h="100%"
              fontSize={"15px"}
              fontWeight={"semibold"}
              borderRadius={"6px"}
              onClick={handleOpenProjectModal}
              disabled={
                session.user && session.user.organization
                  ? isLimited(
                      session.user.organization.plan,
                      "PROJECT",
                      organization.projects.length
                    )
                  : false
              }
            >
              {t<string>("screens.organization.addProject")}
            </Button>
          </HStack>
        </ActionGroup>
      </PageHeader>

      {viewMode === "all-projects" ? (
        <div style={{ minHeight: "800px", width: "100%", display: "flex" }}>
          <VirtualizedTable
            rowCount={projects.length}
            rowGetter={({ index }) => projects[index]}
            columns={columns}
            onHeaderCellClick={handleSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            activeFilters={organization.projectsFilters}
            rowStyle={(row) =>
              row.index % 2 === 0 ? { background: "#EDF1FB" } : {}
            }
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
          }}
        >
          {teamLeaderProjects.length > 0 && (
            <>
              <PageHeader style={{ paddingBottom: 0 }}>
                <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
                  {t<string>("screens.projects.titleProjectLeader")}
                </Heading>
              </PageHeader>

              <div
                style={{
                  display: "grid",
                  paddingLeft: "0.75rem",
                  paddingRight: "0.75rem",
                }}
              >
                <BoxWithoutShadow
                  display={"grid"}
                  gridTemplateColumns={{
                    sm: "repeat(1, minmax(0, 1fr))",
                    md: "repeat(2, minmax(0, 1fr))",
                    lg: "repeat(3, minmax(0, 1fr))",
                  }}
                  gap={"1rem"}
                  spacing={0}
                >
                  {teamLeaderProjects.map((project) => (
                    <ProjectCard
                      key={project.uid}
                      project={project}
                      onClick={handleProjectCardClick}
                    />
                  ))}
                </BoxWithoutShadow>
              </div>
            </>
          )}

          {teamMemberProjects.length > 0 && (
            <>
              <PageHeader style={{ paddingBottom: 0 }}>
                <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
                  {t<string>("screens.projects.titleTeamMember")}
                </Heading>
              </PageHeader>
              <div
                style={{
                  display: "grid",
                  paddingLeft: "0.75rem",
                  paddingRight: "0.75rem",
                }}
              >
                <BoxWithoutShadow
                  display={"grid"}
                  gridTemplateColumns={{
                    sm: "repeat(1, minmax(0, 1fr))",
                    md: "repeat(2, minmax(0, 1fr))",
                    lg: "repeat(3, minmax(0, 1fr))",
                  }}
                  gap={"1rem"}
                >
                  {teamMemberProjects.map((project) => (
                    <ProjectCard
                      key={project.uid}
                      project={project}
                      onClick={handleProjectCardClick}
                    />
                  ))}
                </BoxWithoutShadow>
              </div>
            </>
          )}
        </div>
      )}

      {isProjectModalOpen && (
        <Modal
          isOpen={isProjectModalOpen}
          size={"xl"}
          onClose={onProjectModalClose}
        >
          <ModalOverlay />
          <ModalContent
            maxW={"820px"}
            maxH={"80%"}
            overflow={"hidden"}
            overflowY={"scroll"}
          >
            <ModalHeader>
              {t<string>("screens.organization.addProject")}
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <ProjectFormBeta
                customers={organization.customers}
                categories={organization.projectCategories}
                users={organization.users}
                projectsWorkflows={projectsWorkflows}
                onCreateCustomer={onCustomerModalOpen}
                onCancel={onProjectModalClose}
                project={currentProject}
                onSubmit={handleSubmit}
                isFetchingCategories={organization.isFetchingProjectCategories}
                isFetchingCustomers={organization.isFetchingCustomers}
                isFetchingProjectsWorkflows={isFetchingProjectsWorkflows}
              />
              {/* <ProjectForm
                project={currentProject}
                onCancel={onProjectModalClose}
                onSubmit={handleSubmit}
                users={organization.users}
                isFetchingUsers={organization.isFetchingUsers}
                categories={organization.projectCategories}
                customers={organization.customers}
                isFetchingCustomers={organization.isFetchingCustomers}
                isFetchingProjectsWorkflows={isFetchingProjectsWorkflows}
                projectsWorkflows={projectsWorkflows}
                onCreateCustomer={onCustomerModalOpen}
              /> */}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {isFilterModal && (
        <Modal isOpen={isFilterModal} size={"xl"} onClose={onFilterModalClose}>
          <ModalOverlay />
          <ModalContent
            maxW={"520px"}
            maxH={"80%"}
            overflow={"hidden"}
            overflowY={"scroll"}
          >
            <ModalHeader>{t<string>("common.filterBy")}</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <FilterProjectForm
                projects={projects}
                onCloseModal={onFilterModalClose}
                setFilterBayData={setFilterBayData}
                filterByData={filterByData}
                projectsWorkflows={projectsWorkflows}
                categories={organization.projectCategories}
                users={organization.users}
                customers={organization.customers}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {isCustomerModalOpen && (
        <CustomerModal
          customer={null}
          onSubmit={handleCustomerSubmit}
          onDismiss={handleCloseCustomerModal}
          isVisible={isCustomerModalOpen}
          onDelete={() => {}}
        />
      )}

      {/* <Drawer
        title="Gantt"
        width={"95%"}
        closable={true}
        onClose={() => setIsGanttVisible(false)}
        visible={isGanttVisible}
      >
        <FrappeGantt
          // @ts-ignore
          tasks={organization.projects
            .filter((project) => project.startDate && project.endDate)
            .map((project) => ({
              id: project.uid,
              name: project.title,
              progress: 100,
              start: moment(project.startDate).format("YYYY-MM-DD"),
              end: moment(project.endDate).format("YYYY-MM-DD"),
            }))}
        />
      </Drawer> */}

      {isCategoriesDrawerOpen && (
        <CategoriesDrawer
          isOpen={isCategoriesDrawerOpen}
          onClose={onCategoriesDrawerClose}
        />
      )}
    </div>
  );
});
