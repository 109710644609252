import { HStack, Text } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { PlanUnitType } from "../../types";
import { Icon } from "../Icon";

type Props = {
  unit: PlanUnitType;
};

export const InlinePlanUnit: React.FC<Props> = ({ unit }) => {
  const { t } = useTranslation();
  return (
    <HStack>
      {typeof unit.enabled === "boolean" && !unit.enabled ? (
        <Icon iconName="HiXCircle" />
      ) : (
        <Icon iconName="HiCheckCircle" color="#319795" />
      )}
      <Text>
        {!unit.tierLimited
          ? t(`plans.labels.UNLIMITED_${unit.unitType}`)
          : unit.tierLimit
          ? `${unit.tierLimit} ${t<string>(`plans.units.${unit.unitType}`)}`
          : unit.enabled
          ? t("plans.enabled")
          : t(`plans.units.${unit.unitType}`)}
      </Text>
    </HStack>
  );
};
