import styled from "styled-components";
import { Button as RebassButton } from "rebass/styled-components";

export const IconButton = styled(RebassButton)`
  padding: 0.2rem;
  background-color: transparent;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: ${(props) => props.theme.secondaryText1};
`;
