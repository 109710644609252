import { Stack, Text } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PlanUnitType } from "../../types";

type Props = {
  unit: PlanUnitType;
};

export const PlanUnit: React.FC<Props> = ({ unit }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <StyledUnitTitle>
        {t<string>(`plans.units.${unit.unitType}`)}
      </StyledUnitTitle>
      <Text>
        {!unit.tierLimited
          ? t("plans.tierNotLimited")
          : unit.tierLimit
          ? unit.tierLimit
          : unit.enabled
          ? t("plans.enabled")
          : t("plans.disabled")}
      </Text>
    </Stack>
  );
};

const StyledUnitTitle = styled(Text)`
  font-size: 0.833em;
  font-weight: 500;
`;
