import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProjectStatus } from "../../types";
import { ProjectWorkflowForm } from "../ProjectWorkflowForm";

type Props = {
  isVisible: boolean;
  status: ProjectStatus | null;
  onDismiss: () => void;
  onSubmit: (status: Partial<ProjectStatus>) => void;
  onDelete: () => void;
};

export const ProjectWorkflowModal: React.FC<Props> = ({
  isVisible,
  status,
  onSubmit,
  onDismiss,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {status
            ? t("screens.workflows.editTitle")
            : t("screens.workflows.newTitle")}
        </ModalHeader>
        <ModalBody>
          <ProjectWorkflowForm
            status={status}
            onSubmit={onSubmit}
            onDelete={onDelete}
            onCancel={onDismiss}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
