import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { transparentize } from "polished";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "../../components/Avatar";
import { TimeRadialChart } from "../../components/TimeRadialChart";
import { useStores } from "../../hooks/useStores";
import { useTeamReport } from "../../hooks/useTeamReport";
import { sec2time } from "../../utils/dateTimeFormat";
import {
  getUserBillableHoursHint,
  getUserWorkLoggetHint,
  toUserBillableHoursChartData,
  toUserWorkLoggedChartData,
} from "./utils";

type Props = {
  period: {
    fromDate: Date;
    toDate: Date;
  };
};

export const TeamDashboard: React.FC<Props> = observer(({ period }) => {
  const { session } = useStores();
  const BUSINESS_WORK_DAYS = 5;
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    data: teamReport = [],
    error,
    refetch: refetchTeamReport,
  } = useTeamReport(
    session.user?.organization?.uid || "",
    period.fromDate,
    period.toDate
  );

  useEffect(() => {
    refetchTeamReport();
  }, [period, refetchTeamReport]);

  const teamWorkLogged = useMemo(() => {
    if (!teamReport) {
      return [];
    }
    const series = teamReport.reduce((total: any[], userReport) => {
      for (let taskCategoryReport of userReport.taskCategoriesReport) {
        const chartSerie = total.findIndex(
          (serie) => serie.key === taskCategoryReport.categoryName
        );

        if (chartSerie >= 0) {
          total[chartSerie].angle =
            total[chartSerie].angle + taskCategoryReport.elapsedTime;
          total[chartSerie].value = sec2time(total[chartSerie].angle, "HH:MM");
          total[chartSerie].valuePerc =
            total[chartSerie].valuePerc +
            taskCategoryReport.elapsedTime /
              (userReport.contractualHours * BUSINESS_WORK_DAYS * 60 * 60);
        } else {
          total.push({
            angle: taskCategoryReport.elapsedTime,
            color: taskCategoryReport.color || transparentize(0.9, "#1B48BB"),
            key: taskCategoryReport.categoryName,
            value: sec2time(taskCategoryReport.elapsedTime, "HH:MM"),
            valuePerc:
              taskCategoryReport.elapsedTime /
              (userReport.contractualHours * BUSINESS_WORK_DAYS * 60 * 60),
          });
        }
      }
      return total;
    }, []);

    const totalWorkLoggedTime = series.reduce((total, serie) => {
      return serie.key !== "empty" ? total + serie.angle : total;
    }, 0);

    const totalContactualHours = teamReport.reduce((total, userReport) => {
      return total + userReport.contractualHours * 60 * 60;
    }, 0);

    series.push({
      key: "empty",
      angle: totalContactualHours - totalWorkLoggedTime,
      value: sec2time(totalContactualHours - totalWorkLoggedTime, "HH:MM"),
      valuePerc:
        (totalContactualHours - totalWorkLoggedTime) / totalContactualHours,
      color: transparentize(0.9, "#1B48BB"),
    });

    return series.map((serie) => ({
      ...serie,
      valuePerc: `${(serie.valuePerc * 100).toFixed(1)}%`,
    }));
  }, [teamReport]);

  const teamWorkLoggedHint = useMemo(() => {
    const totalWorkLoggedTime = teamWorkLogged.reduce((total, serie) => {
      return serie.key !== "empty" ? total + serie.angle : total;
    }, 0);

    const totalContactualHours = teamReport.reduce((total, userReport) => {
      return total + userReport.contractualHours;
    }, 0);

    return {
      value: sec2time(totalWorkLoggedTime, "HH:MM"),
      valuePerc: `${(
        (totalWorkLoggedTime /
          (totalContactualHours * BUSINESS_WORK_DAYS * 60 * 60)) *
        100
      ).toFixed(1)}%`,
    };
  }, [teamReport, teamWorkLogged]);

  const teamBillableHours = useMemo(() => {
    return [
      {
        angle: teamReport.reduce((total, userReport) => {
          return total + userReport.billableWorkLog;
        }, 0),
        color: "#1B48BB",
      },
      {
        angle: teamReport.reduce((total, userReport) => {
          return (
            total +
            userReport.contractualHours * BUSINESS_WORK_DAYS * 60 * 60 -
            userReport.billableWorkLog
          );
        }, 0),
        color: transparentize(0.9, "#1B48BB"),
      },
    ];
  }, [teamReport]);

  const teamBillableHoursHint = useMemo(() => {
    const totalBillableTime = teamReport.reduce((total, userReport) => {
      return total + userReport.billableWorkLog;
    }, 0);

    const totalContactualHours = teamReport.reduce((total, userReport) => {
      return total + userReport.contractualHours;
    }, 0);

    return {
      value: sec2time(totalBillableTime, "HH:MM"),
      valuePerc: `${(
        (totalBillableTime /
          (totalContactualHours * BUSINESS_WORK_DAYS * 60 * 60)) *
        100
      ).toFixed(1)}%`,
    };
  }, [teamReport]);

  return (
    <Stack>
      <HStack>
        <HStack backgroundColor="#fff" borderRadius="0.5rem" padding="1rem">
          <TimeRadialChart
            data={teamWorkLogged}
            defaultHint={teamWorkLoggedHint}
            width={100}
            height={100}
            radius={48}
            innerRadius={36}
            colorType="literal"
            padAngle={0.04}
            animation
            enableOnValueMouseOver
          />
          <Text>{t<string>("screens.dashboard.totalWorkLogged")}</Text>
        </HStack>

        <HStack backgroundColor="#fff" borderRadius="0.5rem" padding="1rem">
          <TimeRadialChart
            data={teamBillableHours}
            defaultHint={teamBillableHoursHint}
            width={100}
            height={100}
            radius={48}
            innerRadius={36}
            colorType="literal"
            padAngle={0.04}
            animation
          />
          <Text>{t<string>("screens.dashboard.totalBillableHours")}</Text>
        </HStack>
      </HStack>
      <Table variant="striped" colorScheme="teal" backgroundColor="#fff">
        <Thead>
          <Tr>
            <Th>{t<string>("screens.users.title")}</Th>
            <Th>{t<string>("screens.dashboard.workLogged")}</Th>
            <Th>{t<string>("screens.dashboard.billableHours")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {teamReport.map((userReport) => (
            <Tr>
              <Td>
                <HStack>
                  <Avatar src={userReport.imageUrl} />
                  <span>{userReport.nominative}</span>
                </HStack>
              </Td>

              <Td>
                <TimeRadialChart
                  data={toUserWorkLoggedChartData(userReport)}
                  defaultHint={getUserWorkLoggetHint(userReport)}
                  width={100}
                  height={100}
                  radius={48}
                  innerRadius={36}
                  colorType="literal"
                  padAngle={0.04}
                  enableOnValueMouseOver
                  animation
                />
              </Td>
              <Td>
                <TimeRadialChart
                  data={toUserBillableHoursChartData(userReport)}
                  defaultHint={getUserBillableHoursHint(userReport)}
                  width={100}
                  height={100}
                  radius={48}
                  innerRadius={36}
                  colorType="literal"
                  padAngle={0.04}
                  animation
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  );
});
