import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "../hooks/useStores";
import { Observer, observer } from "mobx-react";
import moment from "moment";
import { TimesheetRowByDay, TimesheetRowByProject } from "../types";
import {
  decimalTime2ApproximatedTime,
  sec2time,
  time2Decimal,
} from "../utils/dateTimeFormat";
import { Link, useParams } from "react-router-dom";
import { DownloadCSV } from "../components/DownloadCSV";
import styled from "styled-components";
import {
  Button,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { ActionGroup } from "../components/ActionGroup";
import {
  DatePeriodPicker,
  DatePeriodPickerPeriod,
} from "../components/DatePeriodPicker";
import { PageHeader } from "../components/PageHeader";
import { VirtualizedTable } from "../components/VirtualizedTable";
import useDebounce from "../hooks/useDebounce";
import { fuzzyMatch } from "../utils/string";
import { Select } from "../components/Select";
import {
  isTimesheetByDayRow,
  isTimesheetByProjectRow,
} from "../services/utils";

const SELECT_STYLES = {
  control: (styles) => ({
    ...styles,
    minWidth: "120px",
  }),
};

export const Timesheet: React.FC = observer(() => {
  const [period, setPeriod] = useState({} as DatePeriodPickerPeriod);
  const { session, organization } = useStores();
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const [timesheetType, setTimesheetType] = useState("0");
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<
    "ASC" | "DESC" | undefined
  >(undefined);

  const timesheetTypes = useMemo(
    () => [
      { name: t("screens.timesheet.byDay"), uid: "0" },
      { name: t("screens.timesheet.byProject"), uid: "1" },
      { name: t("screens.timesheet.byClient"), uid: "2" },
    ],
    [t]
  );

  const debouncedFilters = useDebounce(organization.timesheetFilters, 300);
  const changeFilter = useCallback(
    (dataKey, value) => {
      organization.updateTimesheetFilters(dataKey, value);
    },
    [organization]
  );

  const getColumnSearchProps = useCallback(
    (dataIndex, placeholder) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <Input
                  id={dataIndex}
                  value={
                    (organization.timesheetFilters[dataIndex] as string) || ""
                  }
                  onChange={(e) => changeFilter(dataIndex, e.target.value)}
                  placeholder={`${t<string>("common.search")} ${placeholder}`}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, organization.timesheetFilters, t]
  );

  const columns: Array<any> = useMemo(() => {
    let columnsData = [
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.users.title"),
        dataKey: "nominative",
        sorter: (a, b) => a.nominative.localeCompare(b.nominative),
        ...getColumnSearchProps(
          "nominative",
          t("screens.customers.nominative")
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.timesheet.workLogDate"),
        dataKey: "workLogDate",
        render: (_: any, record: TimesheetRowByDay | TimesheetRowByProject) => (
          <span>{moment(record.workLogDate).format("YYYY-MM-DD")}</span>
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.timesheet.workLogDay"),
        dataKey: "workLogDay",
        render: (_: any, record: TimesheetRowByDay | TimesheetRowByProject) => (
          <span>
            {t<string>(
              `dayOfWeekShort.${new Date(record.workLogDate).getDay()}`
            )}
          </span>
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.timesheet.workLog"),
        dataKey: "workLog",
        render: (_: any, record: TimesheetRowByDay | TimesheetRowByProject) => (
          <span>{sec2time(record.workLog, "HH:MM")}</span>
        ),
      },
    ];

    switch (true) {
      case timesheetType === timesheetTypes[0].uid:
        columnsData.push({
          width: 80,
          flexGrow: 1,
          label: t("screens.users.contractualHours"),
          dataKey: "contractualHours",
          render: (
            contractualHours: any,
            record: TimesheetRowByDay | TimesheetRowByProject
          ) =>
            isTimesheetByDayRow(record) ? (
              <span>
                {record.contractType === "full-time" && contractualHours
                  ? contractualHours
                  : record.contractType === "part-time" &&
                    record.contractualHoursByDay
                  ? record.contractualHoursByDay[record.workLogDate.getDay()]
                  : "0"}
              </span>
            ) : (
              <></>
            ),
        });
        break;
      case timesheetType === timesheetTypes[1].uid:
        columnsData.push({
          width: 80,
          flexGrow: 1,
          label: t("screens.timesheet.projectTitle"),
          dataKey: "projectTitle",
          //@ts-ignore
          sorter: (a, b) => a.projectTitle.localeCompare(b.projectTitle),
          render: (
            projectTitle: any,
            record: TimesheetRowByDay | TimesheetRowByProject
          ) =>
            isTimesheetByProjectRow(record) ? (
              <Link
                to={`/user/${session.user?.uid}/projects/${record.projectId}?organizationId=${organizationId}`}
                style={{
                  color: "var(--chakra-colors-teal-600)",
                  fontWeight: 500,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <Tooltip label={projectTitle} aria-label={projectTitle}>
                  {projectTitle}
                </Tooltip>
              </Link>
            ) : (
              <></>
            ),
          ...getColumnSearchProps(
            "projectTitle",
            t("screens.timesheet.projectTitle")
          ),
        });
        columnsData.push({
          width: 80,
          flexGrow: 1,
          label: t("screens.timesheet.customerNominative"),
          dataKey: "customerNominative",
          //@ts-ignore
          sorter: (a, b) =>
            a.customerNominative.localeCompare(b.customerNominative),
          ...getColumnSearchProps(
            "customerNominative",
            t("screens.timesheet.customerNominative")
          ),
        });
        break;
      case timesheetType === timesheetTypes[2].uid:
        columnsData.push({
          width: 80,
          flexGrow: 1,
          label: t("screens.timesheet.customerNominative"),
          dataKey: "customerNominative",
          //@ts-ignore
          sorter: (a, b) =>
            a.customerNominative.localeCompare(b.customerNominative),
          ...getColumnSearchProps(
            "customerNominative",
            t("screens.timesheet.customerNominative")
          ),
        });
        break;
    }
    return columnsData;
  }, [
    getColumnSearchProps,
    organizationId,
    session.user?.uid,
    t,
    timesheetType,
    timesheetTypes,
  ]);

  const handleChangePeriod = useCallback((values) => setPeriod(values), [
    setPeriod,
  ]);

  const handleGenerate = useCallback(
    () =>
      organization.fetchTimesheet(
        organizationId!,
        moment(period.from),
        moment(period.to),
        timesheetType === "0"
          ? "byDay"
          : timesheetType === "1"
          ? "byProject"
          : "byClient"
      ),
    [organization, organizationId, period.from, period.to, timesheetType]
  );

  const timesheet = useMemo(() => {
    let timesheet = [...organization.timesheet];
    if (sortBy) {
      const columnData = columns.find((column) => column.dataKey === sortBy);

      if (columnData?.sorter) {
        timesheet = timesheet.slice().sort(columnData.sorter);

        if (sortDirection === "DESC") {
          timesheet = timesheet.reverse();
        }
      }
    }

    for (let dataKey of Object.keys(debouncedFilters)) {
      timesheet = timesheet.filter((timeEntry) =>
        Array.isArray(debouncedFilters[dataKey])
          ? (debouncedFilters[dataKey] as string[]).some((searchQuery) =>
              fuzzyMatch(JSON.stringify(timeEntry[dataKey]), searchQuery)
            )
          : fuzzyMatch(
              JSON.stringify(timeEntry[dataKey]),
              debouncedFilters[dataKey] as string
            )
      );
    }

    return timesheet;
  }, [
    columns,
    debouncedFilters,
    organization.timesheet,
    sortBy,
    sortDirection,
  ]);

  const csvData = useMemo(() => {
    const rows = [
      [
        t("screens.users.title").toUpperCase(),
        t("screens.timesheet.workLogDate").toUpperCase(),
        t("screens.timesheet.workLogDay").toUpperCase(),

        t("screens.timesheet.workLog").toUpperCase(),
      ],
    ];
    switch (true) {
      case timesheetType === timesheetTypes[0].uid:
        rows[0].push(t("screens.users.contractualHours").toUpperCase());
        break;
      case timesheetType === timesheetTypes[1].uid:
        rows[0].push(t("screens.timesheet.projectTitle").toUpperCase());
        rows[0].push(t("screens.timesheet.customerNominative").toUpperCase());
        break;
      case timesheetType === timesheetTypes[2].uid:
        rows[0].push(t("screens.timesheet.customerNominative").toUpperCase());
        break;
    }
    if (timesheetType === timesheetTypes[0].uid) {
      const numberOfDays = moment
        .duration(moment(period.to).diff(moment(period.from)))
        .asDays();
      const usersIds = timesheet
        ? timesheet.reduce((acc, data) => {
            if (acc.indexOf(data.userId) === -1) {
              return [...acc, data.userId];
            } else {
              return acc;
            }
          }, [] as any[])
        : [];
      for (const userId in usersIds) {
        let day = moment(period.from);
        for (let i = 0; i < numberOfDays + 1; i++) {
          const currentTimesheetRow = timesheet.find((data) => {
            return (
              moment(data.workLogDate).format("YYYY-MM-DD") ===
                day.format("YYYY-MM-DD") && data.userId === usersIds[userId]
            );
          });
          const dayOfWeek = day.day();
          if (currentTimesheetRow) {
            if (
              (dayOfWeek !== 6 && dayOfWeek !== 0) ||
              !!currentTimesheetRow.workLog
            ) {
              rows.push([
                currentTimesheetRow.nominative,
                moment(currentTimesheetRow.workLogDate).format("YYYY-MM-DD"),
                t(
                  `dayOfWeekShort.${new Date(
                    currentTimesheetRow.workLogDate
                  ).getDay()}`
                ),
                decimalTime2ApproximatedTime(
                  time2Decimal(sec2time(currentTimesheetRow.workLog, "HH:MM"))
                )
                  ?.toString()
                  .replace(".", ","),
                isTimesheetByDayRow(currentTimesheetRow)
                  ? currentTimesheetRow.contractType === "full-time"
                    ? currentTimesheetRow.contractualHours?.toString()
                    : currentTimesheetRow.contractualHoursByDay?.[
                        dayOfWeek
                      ]?.toString() || "0"
                  : isTimesheetByProjectRow(currentTimesheetRow)
                  ? currentTimesheetRow.projectTitle
                  : "",
              ]);
            }
          } else {
            if (dayOfWeek !== 6 && dayOfWeek !== 0) {
              const timesheetData = timesheet.find(
                (data) => data.userId === usersIds[userId]
              );
              rows.push([
                timesheetData?.nominative || "",
                day.format("YYYY-MM-DD"),
                t(`dayOfWeekShort.${dayOfWeek}`),
                time2Decimal("00:00").toString(),
                isTimesheetByDayRow(timesheetData)
                  ? timesheetData?.contractType === "full-time"
                    ? timesheetData?.contractualHours?.toString()
                    : timesheetData?.contractualHoursByDay?.[
                        dayOfWeek
                      ]?.toString() || "0"
                  : isTimesheetByProjectRow(timesheetData)
                  ? timesheetData?.projectTitle || ""
                  : "",
              ]);
            }
          }
          day.add(1, "days");
        }
      }
    } else if (timesheetType === timesheetTypes[1].uid) {
      for (let i = 0; i < timesheet.length; i++) {
        let day = moment(timesheet[i].workLogDate);
        rows.push([
          timesheet[i].nominative || "",
          day.format("YYYY-MM-DD"),
          t(`dayOfWeekShort.${day.day()}`),
          decimalTime2ApproximatedTime(
            time2Decimal(sec2time(timesheet[i].workLog, "HH:MM"))
          )
            ?.toString()
            .replace(".", ","),
          //@ts-ignore
          timesheet[i].projectTitle || "",
          //@ts-ignore
          timesheet[i].customerNominative || "",
        ]);
      }
    } else if (timesheetType === timesheetTypes[2].uid) {
      for (let i = 0; i < timesheet.length; i++) {
        let day = moment(timesheet[i].workLogDate);
        rows.push([
          timesheet[i].nominative || "",
          day.format("YYYY-MM-DD"),
          t(`dayOfWeekShort.${day.day()}`),
          decimalTime2ApproximatedTime(
            time2Decimal(sec2time(timesheet[i].workLog, "HH:MM"))
          )
            ?.toString()
            .replace(".", ","),
          //@ts-ignore
          timesheet[i].customerNominative || "",
        ]);
      }
    }
    return rows;
  }, [period.from, period.to, t, timesheet, timesheetType, timesheetTypes]);

  const timesheetTypeOptions = useMemo(() => {
    const options = timesheetTypes.map((type) => ({
      label: type.name,
      value: type.uid,
    }));
    return options;
  }, [timesheetTypes]);

  const getSelectedTimesheetType: any = (typeId) => {
    const timesheetType = timesheetTypes.find((type) => type.uid === typeId)!;

    return {
      value: timesheetType.uid,
      label: timesheetType.name,
    };
  };

  const handleSort = useCallback(
    (dataKey: string) => {
      if (dataKey === sortBy && sortDirection === "ASC") {
        setSortDirection("DESC");
      } else if (dataKey === sortBy && sortDirection === "DESC") {
        setSortBy(undefined);
        setSortDirection(undefined);
      } else {
        setSortBy(dataKey);
        setSortDirection("ASC");
      }
    },
    [sortBy, sortDirection, setSortBy, setSortDirection]
  );

  useEffect(() => {
    if (timesheet.length > 0 && !!period.to && !!period.from) {
      handleGenerate();
    }
  }, [handleGenerate, period.from, period.to, timesheet.length, timesheetType]);

  return (
    <StyledStack w="100%" h="100%">
      <PageHeader>
        <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
          {t<string>("screens.timesheet.title")}
        </Heading>
        <ActionGroup>
          <Select
            id={"timesheetTypeId"}
            options={timesheetTypeOptions}
            value={getSelectedTimesheetType(timesheetType)}
            isLoading={false}
            onChange={(option) => setTimesheetType(option?.value || "0")}
            styles={SELECT_STYLES}
          />
          <DatePeriodPicker value={period} onChange={handleChangePeriod} />
          <Button
            colorScheme="teal"
            disabled={!period.to || !period.from}
            onClick={handleGenerate}
            isLoading={organization.isFetchingReport}
            style={{ marginLeft: "0.5rem" }}
          >
            {t<string>("screens.timesheet.generate")}
          </Button>
          {organization.timesheet && (
            <DownloadCSV data={csvData} target={"_blank"} separator={";"} />
          )}
        </ActionGroup>
      </PageHeader>

      <VirtualizedTable
        rowCount={timesheet.length}
        rowGetter={({ index }) => timesheet[index]}
        columns={columns}
        rowStyle={(row) =>
          row.index % 2 === 0 ? { background: "#EDF1FB" } : {}
        }
        activeFilters={organization.timesheetFilters}
        onHeaderCellClick={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
    </StyledStack>
  );
});

const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
`;
