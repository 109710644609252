import React, { ChangeEvent, useCallback, useMemo } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { Input } from "@chakra-ui/input";
import * as Yup from "yup";
import { ModalFooterForm } from "../ModalFooterForm";
import { ProjectStatus, Webhook } from "../../types";
import { Box, Heading, HStack, Stack } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  CheckboxGroup,
  Checkbox,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { WEBHOOK_EVENTS } from "../../config/webhooks";
import { Select } from "../Select";

interface Values {
  uid: string | undefined;
  url: string;
  description: string;
  events: string[];
  enabled: boolean;
  conditions: Webhook["conditions"];
}

type Props = {
  webhook: Webhook | null;
  projectsWorkflows: ProjectStatus[];
  onCancel: () => any;
  onSubmit: (webhook: Values) => any;
  onDelete?: () => any;
};

export const WebhookForm: React.FC<Props> = ({
  webhook,
  projectsWorkflows,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uid: webhook?.uid || undefined,
      url: webhook?.url || "",
      description: webhook?.description || "",
      events: webhook?.events || [],
      enabled: !!webhook?.enabled,
      conditions: webhook?.conditions || {},
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      url: Yup.string()
        .required(t("messages.formValidations.webhookForm.urlRequired"))
        .min(10, t("messages.formValidations.webhookForm.urlRequired")),
    }),
  });

  const projectsWorkflowOptions = useMemo(
    () =>
      projectsWorkflows.map((status) => ({
        label: status.name,
        value: status.uid,
      })),
    [projectsWorkflows]
  );

  const getSelectedProjectsWorkflows: any = (event: string) => {
    const values = (formik.values.conditions[event] || []) as string[];

    return values.map((val: string) => {
      const projectWorkflow = projectsWorkflows.find(
        (workflow) => workflow.uid === val
      );
      return {
        value: projectWorkflow?.uid || "",
        label: projectWorkflow?.name || "",
      };
    });
  };

  const handleEventChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let activeEvents = [...formik.values.events];

      if (e.target.checked) {
        activeEvents.push(e.target.value);
      } else {
        activeEvents = activeEvents.filter((event) => event !== e.target.value);
      }

      formik.setFieldValue("events", activeEvents);
    },
    [formik]
  );

  const handleEventConditionChange = useCallback(
    (event: string, value: string) => {
      const activeConditions = { ...formik.values.conditions };

      if (value === "no-condition") {
        delete activeConditions[event];
      } else {
        activeConditions[event] = [];
      }

      formik.setFieldValue("conditions", activeConditions);
    },
    [formik]
  );

  const getConditionValue = useCallback(
    (event: string) => {
      return formik.values.conditions[event] ? "condition" : "no-condition";
    },
    [formik]
  );

  const handleSelectedProjectsWorkflows = useCallback(
    (event: string, options: { label: string; value: string }[] | null) => {
      const activeConditions = { ...formik.values.conditions };
      activeConditions[event] = options?.map((option) => option.value) || "";

      formik.setFieldValue("conditions", activeConditions);
    },
    [formik]
  );

  return (
    <form onSubmit={formik.handleSubmit} className="webhooks-form">
      <Stack spacing={"1rem"} direction={"column"}>
        <FormControl
          isInvalid={
            Boolean(formik.errors.url) && formik.getFieldMeta("url").touched
          }
        >
          <StyledFormLabel>{t<string>("screens.webhooks.url")}</StyledFormLabel>
          <Input
            name={"url"}
            value={formik.values.url}
            onChange={formik.handleChange}
            placeholder={t<string>("screens.webhooks.urlPlaceholder")}
          />
          <FormErrorMessage>{formik.errors.url}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={
            Boolean(formik.errors.description) &&
            formik.getFieldMeta("description").touched
          }
        >
          <StyledFormLabel>
            {t<string>("screens.webhooks.description")}
          </StyledFormLabel>
          <Textarea
            name={"description"}
            value={formik.values.description}
            onChange={formik.handleChange}
            placeholder={t<string>("screens.webhooks.descriptionPlaceholder")}
          />
          <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <StyledFormLabel>
            {t<string>("screens.webhooks.events")}
          </StyledFormLabel>
          <CheckboxGroup colorScheme="teal" value={formik.values.events}>
            <Accordion allowToggle>
              {Object.entries(WEBHOOK_EVENTS).map((eventCategory) => (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {t<string>(
                          `screens.webhooks.eventCategories.${eventCategory[0]}`
                        )}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <HStack>
                      {eventCategory[1].map((event) => (
                        <FormControl>
                          <Checkbox
                            value={event.name}
                            onChange={handleEventChange}
                          >
                            {event.name}
                          </Checkbox>
                          <Stack marginLeft="1.5rem">
                            <FormHelperText>
                              {t<string>(
                                `screens.webhooks.eventsDescriptions.${event.name.replace(
                                  /\./g,
                                  "-"
                                )}`
                              )}
                            </FormHelperText>
                            {formik.values.events.find(
                              (activeEvent) => activeEvent === event.name
                            ) && (
                              <Stack paddingTop="0.5rem">
                                <Heading as="h6" size="xs">
                                  {t<string>("screens.webhooks.condition")}
                                </Heading>
                                <RadioGroup
                                  colorScheme="teal"
                                  value={getConditionValue(event.name)}
                                  onChange={(value) =>
                                    handleEventConditionChange(
                                      event.name,
                                      value
                                    )
                                  }
                                >
                                  <Stack>
                                    <Radio value="no-condition">
                                      {t<string>(
                                        "screens.webhooks.conditions.always"
                                      )}
                                    </Radio>
                                    <HStack w="100%">
                                      <Radio value="condition" />
                                      <FormControl>
                                        <Select
                                          //@ts-ignore
                                          isMulti
                                          options={projectsWorkflowOptions}
                                          value={getSelectedProjectsWorkflows(
                                            event.name
                                          )}
                                          onChange={(options) =>
                                            handleSelectedProjectsWorkflows(
                                              event.name,
                                              // @ts-ignore
                                              options
                                            )
                                          }
                                        />
                                      </FormControl>
                                    </HStack>
                                  </Stack>
                                </RadioGroup>
                              </Stack>
                            )}
                          </Stack>
                        </FormControl>
                      ))}
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </CheckboxGroup>
        </FormControl>

        <ModalFooterForm
          onCancel={onCancel}
          onDelete={(webhook && onDelete) || undefined}
        />
      </Stack>
    </form>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
