import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../services/api";
import { Organization, Overhead } from "../types";

export const useOverheads = (organizationId: Organization["uid"]) =>
  useQuery([`overheads-${organizationId}`, organizationId], () =>
    api.fetchOverheads(organizationId)
  );

export const useCreateOverhead = (organizationId: Organization["uid"]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Partial<Overhead>) => api.addOverhead(organizationId, data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(`overheads-${organizationId}` as any);
      },
    }
  );
};

export const useUpdateOverhead = (organizationId: Organization["uid"]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Partial<Overhead>) =>
      api.updateOverhead(organizationId, data.uid || "", data),
    {
      onSettled: () => {
        queryClient.invalidateQueries(`overheads-${organizationId}` as any);
      },
    }
  );
};

export const useDeleteOverhead = (organizationId: Organization["uid"]) => {
  const queryClient = useQueryClient();

  return useMutation(
    (overheadId: Overhead["uid"]) =>
      api.deleteOverhead(organizationId, overheadId),
    {
      onSettled: () => {
        queryClient.invalidateQueries(`overheads-${organizationId}` as any);
      },
    }
  );
};
