import { Table, Thead, Tbody, Tr, Th, Td, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Role } from "../../types";

type Props = {
  roles: Role[];
  onItemClick: (role: Role) => any;
};

export const OrganizationRolesTable: React.FC<Props> = ({
  roles,
  onItemClick,
}) => {
  const { t } = useTranslation();
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="gray.400">{t<string>("screens.roles.title")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {roles
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((role) => (
            <Tr key={role.uid}>
              <Td>
                <Link
                  href={"#"}
                  style={{
                    color: "var(--chakra-colors-teal-600)",
                    fontWeight: 400,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    onItemClick(role);
                  }}
                >
                  {role.name}
                </Link>
              </Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
};
