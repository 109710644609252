import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Observer } from "mobx-react";
import { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import Project from "../../../../models/Project";
import { ProjectLinks, ProjectUpdate } from "../../../../types";
import ProjectDetailsLinkForm from "./ProjectDetailsLinkForm";
import { ProjectDetailsSection } from "./ProjectDetailsSection";
import ProjectUpdateForm from "./ProjectUpdateForm";
import { ProjectUpdateSection } from "./ProjectUpdateSection";
import { projectLinksStore } from "../../../../models/ProjectLInks";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { projectUpdateStore } from "../../../../models/ProjectUpdate";

interface ProjectDetailsProps {
  currentProject: Project;
}

export interface ActionLinks {
  type: string;
  payload?: {
    links: ProjectLinks | null;
  };
}

const initialLinksState = {
  links: null,
  isModalVisible: false,
};

const linksReducer = (state, action: ActionLinks) => {
  switch (action.type) {
    case "new-links":
      return {
        links: null,
        isModalVisible: true,
      };
    case "edit-links":
      return {
        links: action.payload ? action.payload.links : null,
        isModalVisible: true,
      };
    case "close-links":
      return {
        ...initialLinksState,
      };
    default:
      throw new Error();
  }
};

export interface ActionUpdate {
  type: string;
  payload?: {
    update: ProjectUpdate | null;
  };
}

const initialUpdateState = {
  update: null,
  isModalVisible: false,
};

const updateReducer = (state, action: ActionUpdate) => {
  switch (action.type) {
    case "new-update":
      return {
        update: null,
        isModalVisible: true,
      };
    case "edit-update":
      return {
        update: action.payload ? action.payload.update : null,
        isModalVisible: true,
      };
    case "close-update":
      return {
        ...initialUpdateState,
      };
    default:
      throw new Error();
  }
};

const StyledAccordion = styled(Accordion)`
  width: 100%;
  padding-bottom: 65px;
  //   max-width: 800px;
  //   margin: 0 auto;
`;

export const ProjectDetailsBeta = ({ currentProject }: ProjectDetailsProps) => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const toast = useToast();

  const [isLinkModel, setIsLinkModel] = useState(false);
  const [isupdateModel, setIsupdateModel] = useState(false);
  const [StateLinks, linksDispatchLinks] = useReducer(
    linksReducer,
    initialLinksState
  );
  const [UpdateLinks, updatesDispatchUpdate] = useReducer(
    updateReducer,
    initialUpdateState
  );

  const onCloseLink = () => {
    // setIsLinkModel(false)
    linksDispatchLinks({ type: "close-links" });
  };
  const onCloseUpdate = () => {
    // setIsupdateModel(false);
    updatesDispatchUpdate({ type: "close-update" });
  };

  useEffect(() => {
    if (projectId) {
      projectLinksStore.fetchProjectLinks(projectId);
      projectUpdateStore.fetchProjectUpdates(projectId);
    }
  }, [projectId]);

  function handleLInksSubmit(links: ProjectLinks) {
    if (links && projectId) {
      projectLinksStore.submitLinks(projectId, links);
      linksDispatchLinks({ type: "close-links" });

      toast({
        title: t("screens.projects.projectLinks.actions.craeted"),
        status: "success",
        position: "bottom-left",
      });
    }
  }

  async function handleDeletLinks(links: ProjectLinks) {
    if (links.linkId && projectId) {
      if (window.confirm(t("screens.projects.projectLinks.actions.delete"))) {
        try {
          await projectLinksStore?.deleteProjectLinks(projectId, links.linkId);
          toast({
            title: t("common.actionExecutedSuccessfully"),
            status: "success",
            position: "bottom-left",
          });
          linksDispatchLinks({ type: "close-links" });
        } catch (err) {
          console.log(err);
          toast({
            title: t("common.error"),
            status: "error",
            position: "bottom-left",
          });
        }
      }
    }
  }

  function handleUpdateSubmit(update: ProjectUpdate) {
    if (update && projectId) {
      projectUpdateStore.submitUpdate(projectId, update);
      // alert(JSON.stringify(update, null, 2))

      if (update.updateId) {
        toast({
          title: t("screens.projects.projectUpdate.actions.update"),
          status: "success",
          position: "bottom-left",
        });
      } else {
        toast({
          title: t("screens.projects.projectUpdate.actions.created"),
          status: "success",
          position: "bottom-left",
        });
      }
      updatesDispatchUpdate({ type: "close-update" });
    }
  }

  async function handleDeleteUpdate(update: ProjectUpdate) {
    if (update.updateId && projectId) {
      if (window.confirm(t("screens.projects.projectUpdate.actions.delete"))) {
        try {
          await projectUpdateStore?.deleteProjectUpdate(
            projectId,
            update.updateId
          );
          toast({
            title: t("common.actionExecutedSuccessfully"),
            status: "success",
            position: "bottom-left",
          });
          updatesDispatchUpdate({ type: "close-update" });
        } catch (err) {
          console.log(err);
          toast({
            title: t("common.error"),
            status: "error",
            position: "bottom-left",
          });
        }
      }
    }
  }

  return (
    <Observer>
      {() => (
        <>
          <StyledAccordion allowMultiple defaultIndex={[1]}>
            <AccordionItem borderTopWidth={0}>
              <h2>
                <AccordionButton height={"70px"}>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight={"500"} fontSize="17px" color="#101828">
                      Details
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <ProjectDetailsSection
                  currentProject={currentProject}
                  linksDispatchLinks={linksDispatchLinks}
                  setIsLinkModel={() => {
                    setIsLinkModel(true);
                  }}
                  projectLInks={projectLinksStore.projectLinks}
                />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton height={"70px"}>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight={"500"} fontSize="17px" color="#101828">
                      {" "}
                      Updates
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <ProjectUpdateSection
                  currentProject={currentProject}
                  updatesDispatchUpdate={updatesDispatchUpdate}
                  setIsupdateModel={() => {
                    setIsupdateModel(true);
                  }}
                  projectUpdate={projectUpdateStore.projectUpdate}
                />
              </AccordionPanel>
            </AccordionItem>
          </StyledAccordion>
          {/* <ProjectDetailsSection currentProject={currentProject}
                        linksDispatchLinks={linksDispatchLinks}
                        setIsLinkModel={() => {
                            setIsLinkModel(true);
                        }}
                    />
                    <ProjectUpdateSection currentProject={currentProject}
                        setIsupdateModel={() => {
                            setIsupdateModel(true);
                        }}
                    /> */}
          {StateLinks.isModalVisible && (
            <ProjectDetailsLinkForm
              isOpen={StateLinks.isModalVisible}
              onClose={onCloseLink}
              onSubmit={handleLInksSubmit}
              pLInks={StateLinks.links}
              onDelete={handleDeletLinks}
            />
          )}
          {UpdateLinks.isModalVisible && (
            <ProjectUpdateForm
              pUpdate={UpdateLinks.update}
              isOpen={UpdateLinks.isModalVisible}
              onSubmit={handleUpdateSubmit}
              onClose={onCloseUpdate}
              onDelete={handleDeleteUpdate}
            />
          )}
        </>
      )}
    </Observer>
  );
};
