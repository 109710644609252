import { HStack } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as HighIcon } from "../../assets/icons/priorities/high.svg";
import { ReactComponent as HighestIcon } from "../../assets/icons/priorities/highest.svg";
import { ReactComponent as LowIcon } from "../../assets/icons/priorities/low.svg";
import { ReactComponent as LowestIcon } from "../../assets/icons/priorities/lowest.svg";
import { ReactComponent as MediumIcon } from "../../assets/icons/priorities/medium.svg";
import { TASK_PRIORITIES } from "../../config/tasks";
import { TaskPriority } from "../../types";

export const TASK_PRIORITY_ICONS = {
  10: LowestIcon,
  20: LowIcon,
  30: MediumIcon,
  40: HighIcon,
  50: HighestIcon,
};

type PriorityIconProps = {
  priority: TaskPriority;
  size?: string;
  extendend?: boolean;
};

export const PriorityIcon: React.FC<PriorityIconProps> = ({
  priority,
  size,
  extendend = false,
}) => {
  const { t } = useTranslation();
  const iconSize =
    size === "xsmall"
      ? "0.65rem"
      : size === "small"
      ? "1rem"
      : size === "large"
      ? "2.0rem"
      : size === "xlarge"
      ? "3rem"
      : size === "xxlarge"
      ? "3.8rem"
      : "1.4rem";

  const priorityComponent = React.createElement(TASK_PRIORITY_ICONS[priority], {
    style: { width: iconSize, height: iconSize },
  });

  return extendend ? (
    <HStack>
       {priorityComponent}
      <span style={{ marginLeft: "0.5em" }}>
        {t<string>(`tasks.priorities.${TASK_PRIORITIES[priority].name}`)}
      </span>
    </HStack>
  ) : (
    <Tooltip
      label={t<string>(`tasks.priorities.${TASK_PRIORITIES[priority].name}`)}
      placement="top"
    >
      {priorityComponent}
    </Tooltip>
  );
};
