export const generateQuickGuid = (): string => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const fuzzyMatch = (text: string, searchToken: string): boolean => {
  const normalizedText = String(text).trim().toLowerCase();
  const normalizedSearchToken = String(searchToken).trim().toLowerCase();
  return normalizedText.includes(normalizedSearchToken);
};

export const toCurrency = (value: number): string => {
  const formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  });
  return formatter.format(value);
};

export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(" ");

  return value
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, "-"); // separator
};
