import React from "react";
import RSelect, { Props, components } from "react-select";

export const Option = components.Option;
export const SingleValue = components.SingleValue;

export const Select: React.FC<Props> = (props) => {
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "var(--chakra-sizes-10)",
      borderRadius: "var(--chakra-radii-md)",
      borderColor: "inherit",
      width: "100%",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    container: (styles) => ({
      ...styles,
      flex: props.isFlex ? 1 : 0,
    }),
  };

  return <RSelect styles={selectStyles} {...props} />;
};
