import React from "react";
import { Formik, Field, Form } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { Input } from "@chakra-ui/input";
import { CirclePicker } from "react-color";
import * as Yup from "yup";
import { ModalFooterForm } from "../ModalFooterForm";
import { TaskCategory } from "../../types";
import { Checkbox, HStack } from "@chakra-ui/react";

interface Values {
  uid: string;
  name: string;
  color: string;
  isUsedForTasks: boolean;
  isUsedForPlanning: boolean;
}

type TaskCategoryFormProps = {
  selectedCategory?: TaskCategory | null;
  onCancel: () => any;
  onSubmit: (category: Values) => any;
  onDelete?: (uid: TaskCategory["uid"]) => any;
};

export const TaskCategoryForm: React.FC<TaskCategoryFormProps> = ({
  selectedCategory,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        uid: selectedCategory?.uid || "",
        name: selectedCategory?.name || "",
        color: selectedCategory?.color || "",
        isUsedForTasks:
          selectedCategory && selectedCategory.isUsedForTasks !== null
            ? selectedCategory?.isUsedForTasks
            : true,
        isUsedForPlanning:
          selectedCategory && selectedCategory.isUsedForPlanning !== null
            ? selectedCategory?.isUsedForPlanning
            : true,
      }}
      onSubmit={(values: Values, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit(values);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required(t("screens.projects.taskCategory.validations.nameRequired"))
          .min(1, t("screens.projects.taskCategory.validations.nameRequired")),
        color: Yup.string()
          .required(
            t("screens.projects.taskCategory.validations.colorRequired")
          )
          .min(1, t("screens.projects.taskCategory.validations.colorRequired")),
      })}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Field type={"hidden"} name={"uid"} />

        <Field id={"name"} name={"name"}>
          {({ field, form: { setFieldValue }, meta }) => (
            <FormControl isInvalid={meta.error && meta.touched}>
              <StyledFormLabel htmlFor="name">
                {t<string>("screens.projects.taskCategory.name")}
              </StyledFormLabel>
              <Input
                name={field.name}
                value={field.value}
                onChange={(event) =>
                  setFieldValue(field.name, event.target.value)
                }
              />
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Field name={"color"}>
          {({
            field, // { name, value, onChange, onBlur }
            form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <FormControl isInvalid={meta.error && meta.touched}>
              <StyledFormLabel htmlFor={"color"}>
                {t<string>("screens.projects.taskCategory.color")}
              </StyledFormLabel>
              <CirclePicker
                // name={field.name}
                // @ts-ignore
                color={{ hex: field.value }}
                onChangeComplete={(color) =>
                  setFieldValue(field.name, color.hex)
                }
              />
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <HStack marginTop={"5"}>
          <Field name={"isUsedForTasks"}>
            {({ field, form: { setFieldValue }, meta }) => (
              <FormControl isInvalid={Boolean(meta.error)}>
                <Checkbox
                  isChecked={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.checked)}
                >
                  {t<string>("screens.projects.taskCategory.isUsedForTasks")}
                </Checkbox>
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name={"isUsedForPlanning"}>
            {({ field, form: { setFieldValue }, meta }) => (
              <FormControl isInvalid={Boolean(meta.error)}>
                <Checkbox
                  isChecked={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.checked)}
                >
                  {t<string>("screens.projects.taskCategory.isUsedForPlanning")}
                </Checkbox>
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </HStack>

        <ModalFooterForm
          onCancel={onCancel}
          onDelete={
            onDelete && selectedCategory?.uid
              ? () => onDelete(selectedCategory?.uid)
              : undefined
          }
        />
      </Form>
    </Formik>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
