import { Button } from "@chakra-ui/button";
import { Stack } from "@chakra-ui/layout";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteRecurrenceActions } from "../../types";

type Props = {
  event?: any;
  isOpen: boolean;
  onConfirm: (
    eventId: string,
    deleteRecurrence: DeleteRecurrenceActions
  ) => void;
  onDismiss: () => void;
};

export const DeleteEventDialog: React.FC<Props> = ({
  event,
  isOpen,
  onDismiss,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const dialogRef = React.useRef(null);

  const [
    deleteRecurrenceAction,
    setDeleteRecurrenceAction,
  ] = useState<DeleteRecurrenceActions>(
    DeleteRecurrenceActions.ONLY_CURRENT_EVENT
  );

  const handleConfirm = useCallback(
    () => onConfirm(event, deleteRecurrenceAction),
    [event, deleteRecurrenceAction, onConfirm]
  );

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={dialogRef}
      onClose={onDismiss}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize={"lg"} fontWeight={"bold"}>
            {event?.extendedProps?.recurrenceId
              ? t("screens.board.event.deleteRecurrenceTitle")
              : t("screens.board.event.deleteTitle")}
          </AlertDialogHeader>

          {event?.extendedProps?.recurrenceId ? (
            <AlertDialogBody>
              <RadioGroup
                // @ts-ignore
                onChange={setDeleteRecurrenceAction}
                value={deleteRecurrenceAction}
              >
                <Stack>
                  <Radio value={DeleteRecurrenceActions.ONLY_CURRENT_EVENT}>
                    {t<string>("screens.board.event.onlyThisEvent")}
                  </Radio>
                  <Radio value={DeleteRecurrenceActions.ALL_RECURRENT_EVENTS}>
                    {t<string>("screens.board.event.thisEventAndFollowing")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </AlertDialogBody>
          ) : (
            <AlertDialogBody>
              {t<string>("screens.board.event.deleteMessage")}
            </AlertDialogBody>
          )}

          <AlertDialogFooter>
            {/* @ts-ignore */}
            <Button ref={dialogRef} onClick={onDismiss}>
              {t<string>("common.cancel")}
            </Button>
            {/* @ts-ignore */}
            <Button colorScheme="red" onClick={handleConfirm} ml={3}>
              {t<string>("common.delete")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
