import { ModalBody, ModalContent, ModalHeader } from "@chakra-ui/modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { OrganizationForm } from "../OrganizationForm";

type Props = {
  isSubmitting: boolean;
  onSubmit: (organization: any) => void;
};

export const OrganizationModal: React.FC<Props> = ({
  isSubmitting,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <ModalContent minWidth={"55vw"}>
      <ModalHeader>{t<string>("screens.organization.createTitle")}</ModalHeader>
      <ModalBody>
        <OrganizationForm
          forceCanEdit={true}
          organization={null}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      </ModalBody>
    </ModalContent>
  );
};
