import "./wdyr";

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import Root from "./screens/Root";
import "../node_modules/react-vis/dist/style.css";
import { ServiceWorkerProvider } from "./hooks/useServiceWorker";
import { IntercomProvider } from "react-use-intercom";
import keys from "./config/keys";
import { configure } from "mobx";
import { CookiesProvider } from "react-cookie";

const container = document.getElementById("root");
const root = createRoot(container!);
configure({ enforceActions: "never" });

root.render(
  <React.StrictMode>
    <ServiceWorkerProvider>
      <IntercomProvider appId={keys.INTERCOM_APP_ID} autoBoot={false}>
        <CookiesProvider>
          <Root />
        </CookiesProvider>
      </IntercomProvider>
    </ServiceWorkerProvider>
  </React.StrictMode>
);
