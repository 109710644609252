import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Plan, User } from "../../types";
import { Checkout } from "./Checkout";
import { Plans } from "./Plans";

type Props = {
  user: User;
  currentPlan: Plan;
  plans: Plan[];
  isFetchingPlans: boolean;
  isOpen: boolean;
  onClose: () => void;
  onPlanChange: (newPlan: Plan) => void;
};

enum Steps {
  Plans,
  Checkout,
}

export const PricingModal: React.FC<Props> = ({
  user,
  currentPlan,
  plans,
  isFetchingPlans,
  isOpen,
  onClose,
  onPlanChange,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(Steps.Plans);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  const handleSelectPlan = useCallback((plan: Plan) => {
    setSelectedPlan(plan);
    setStep(Steps.Checkout);
  }, []);

  const handleResetSelection = useCallback(() => {
    setStep(Steps.Plans);
    setSelectedPlan(null);
  }, []);

  const handleCheckoutCompleted = useCallback(
    (plan: Plan) => {
      setStep(Steps.Plans);
      setSelectedPlan(null);
      onPlanChange(plan);
    },
    [onPlanChange]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent marginTop="0" marginBottom="0">
        <ModalCloseButton />
        <SyledModalBody>
          <Stack alignItems="center" marginTop="2rem">
            <Text fontSize="2xl" fontWeight="500">
              {t<string>("plans.title")}
            </Text>

            {isFetchingPlans && <Spinner />}

            {step === Steps.Plans ? (
              <Plans
                currentPlan={currentPlan}
                plans={plans}
                onSelected={handleSelectPlan}
              />
            ) : selectedPlan ? (
              <Checkout
                plan={selectedPlan}
                user={user}
                goBack={handleResetSelection}
                onCheckoutCompleted={handleCheckoutCompleted}
              />
            ) : null}
          </Stack>
        </SyledModalBody>
      </ModalContent>
    </Modal>
  );
};

const SyledModalBody = styled(ModalBody)`
  background: ${(props) => props.theme.bg1};
`;
