import { StandardEvent, BaseComponent, MinimalEventProps } from "../common";
import { DEFAULT_TABLE_EVENT_TIME_FORMAT } from "./event-rendering";
import React from "react";

export interface TableBlockEventProps extends MinimalEventProps {
  defaultDisplayEventEnd: boolean;
}

export class TableBlockEvent extends BaseComponent<TableBlockEventProps> {
  render() {
    let { props } = this;

    return (
      // @ts-ignore
      <StandardEvent
        {...props}
        extraClassNames={[
          "fc-daygrid-event",
          "fc-daygrid-block-event",
          "fc-h-event",
        ]}
        defaultTimeFormat={DEFAULT_TABLE_EVENT_TIME_FORMAT}
        defaultDisplayEventEnd={props.defaultDisplayEventEnd}
        // @ts-ignore
        disableResizing={!props.seg.eventRange.def.allDay}
      />
    );
  }
}
