import { Button } from "@chakra-ui/button";
import { Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import styled from "styled-components";
import { ActionGroup } from "../../components/ActionGroup";
import { Box } from "../../components/Box";
import { PageHeader } from "../../components/PageHeader";
import { PlanUnit } from "../../components/PlanUnit";
import { PricingModal } from "../../components/PricingModal";
import { useDowngradeToFreePlan } from "../../hooks/useMutationPlans";
import { usePlans } from "../../hooks/usePlans";
import { useStores } from "../../hooks/useStores";
import api from "../../services/api";
import { Plan } from "../../types";

export const SubscriptionDetails: React.FC = observer(() => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const {
    isOpen: isPricingModalOpen,
    onOpen: onPricingModalOpen,
    onClose: onPricingModalClose,
  } = useDisclosure();
  const { session } = useStores();
  const toast = useToast();

  const { isFetching: isFetchingPlans, data: plans } = usePlans();

  const { isLoading, data: organizationData, refetch } = useQuery(
    ["organization", organizationId],
    // @ts-ignore
    () => api.fetchOrganization(organizationId)
  );
  const downgrateToFreePlan = useDowngradeToFreePlan(
    session.user?.organization?.uid || ""
  );

  const handleChangePlan = useCallback(
    (e: any) => {
      e.preventDefault();
      onPricingModalOpen();
    },
    [onPricingModalOpen]
  );

  const handlePlanChange = useCallback(
    async (newPlan: Plan) => {
      onPricingModalClose();

      if (!newPlan.subscription) {
        downgrateToFreePlan.mutate();
        toast({
          title: t("plans.paymentSuccess"),
          status: "success",
          position: "bottom-left",
        });
      }

      if (session.user && session.user.organization) {
        session.user.organization.plan = newPlan;
      }
      refetch();
    },
    [downgrateToFreePlan, onPricingModalClose, refetch, session.user, t, toast]
  );

  return (
    <StyledStack w="100%" h="100%">
      <PageHeader>
        <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
          {t<string>("screens.subscription.title")}
        </Heading>
        <ActionGroup>{isLoading && <Spinner />}</ActionGroup>
      </PageHeader>
      <Stack>
        {organizationData && (
          <Box>
            <HStack>
              <Text fontWeight={600} fontSize="1rem">
                {t<string>(`plans.${organizationData.plan.name}`)}
              </Text>
              <SeparatorWrapper>|</SeparatorWrapper>
              <Button
                colorScheme="teal"
                variant="link"
                onClick={handleChangePlan}
              >
                {t<string>("plans.change")}
              </Button>
            </HStack>
            <HStack justifyContent="space-between" paddingTop="1rem">
              {organizationData.plan.config && organizationData.plan.config.map((planUnit) => (
                <PlanUnit key={planUnit.unitType} unit={planUnit} />
              ))}
            </HStack>
          </Box>
        )}
      </Stack>
      {organizationData && session.user && isPricingModalOpen && (
        <PricingModal
          currentPlan={organizationData.plan}
          isOpen={isPricingModalOpen}
          onClose={onPricingModalClose}
          isFetchingPlans={isFetchingPlans}
          plans={plans || []}
          user={session.user}
          onPlanChange={handlePlanChange}
        />
      )}
    </StyledStack>
  );
});

const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
`;

const SeparatorWrapper = styled.span`
  color: #c1c7d0;
  padding: 0 4px;
`;
