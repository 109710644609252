import { AsanaWorkspace } from "../../types";
import { makeApiRequest } from "./index";

export const getAsanaSignedUrl = async () => {
  const response = await makeApiRequest("GET", `connect/asana`);
  return response;
};

export const disableAsana = async () => {
  const response = await makeApiRequest("DELETE", `connect/asana`);
  return response;
};

export const fetchAsanaWorkspaces = async (): Promise<AsanaWorkspace[]> => {
  const response = await makeApiRequest("GET", `connect/asana/workspaces`);

  return response;
};

export const fetchAsanaTasks = async (
  projectId: string | null
) => {
  const response = await makeApiRequest(
    "GET",
    `connect/asana/tasks?projectId=${projectId}`
  );

  console.log("response (asna)-< ", response);

  return response;
};

export const fetchAsanaProjects = async (workspaceId: string) => {
  const response = await makeApiRequest(
    "GET",
    `connect/asana/projects?workspaceId=${workspaceId}`
  );

  return response;
};

export const updateAsanaTask = async (
  taskId: string
): Promise<{ err: string; success: boolean }> => {
  const response = await makeApiRequest(
    "POST",
    `connect/asana/tasks/${taskId}`
  );

  return response;
};
