import { Container, Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Switch } from "@chakra-ui/switch";
import React, { useCallback, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationIcon } from "../../components/IntegrationIcon";
import {
  JiraAPIKeyForm,
  PaymoAPIKeyForm,
} from "../../components/IntegrationsForms";
import availableIntegrations from "../../config/integrations";
import { useStores } from "../../hooks/useStores";
import { ServiceCode } from "../../types";
import { ListItem } from "./styled";

enum IntegrationModalView {
  enablePaymo,
  enableJira,
  enbleAsana,
}

interface Action {
  type: string;
  payload?: {
    integration: ServiceCode;
  };
}

type IntegrationsState = {
  integration: string | null;
  action: string | null;
  isModalVisible: boolean;
  modalView: IntegrationModalView | null;
};

const initialState = {
  integration: null,
  action: null,
  isModalVisible: false,
  modalView: null,
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case "enable-integration":
      return {
        integration: action.payload?.integration || null,
        action: "enable",
        isModalVisible: true,
        modalView:
          action.payload?.integration === "jira"
            ? IntegrationModalView.enableJira
            : action.payload?.integration === "paymo"
              ? IntegrationModalView.enablePaymo
              : action.payload?.integration === "asana"
                ? IntegrationModalView.enbleAsana
                : null,
      };
    case "close-modal":
      return {
        ...initialState,
      };
    default:
      throw new Error();
  }
};

export const IntegrationsScreen: React.FC = () => {
  const { t } = useTranslation();
  const { session, board } = useStores();

  const [{ integration, isModalVisible, modalView }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const integrations = useMemo(() => {
    return Object.keys(availableIntegrations).map((integration) => ({
      key: integration as ServiceCode,
      title: availableIntegrations[integration].title,
      isActive: session.user?.integrations.find(
        (enabledIntegration) => enabledIntegration.service === integration
      )
        ? true
        : false,
    }));
  }, [session.user]);

  const handleToggleIntegration = useCallback(
    async (integration, enabled) => {
      if (integration === "todoist" && enabled) {
        board.enableTodoist();
      } else if (integration === "todoist" && !enabled) {
        board.disableOauthIntegration("todoist");
      } else if (integration === "asana" && enabled) {
        board.enableAsana();
      } else if (integration === "asana" && !enabled) {
        board.disableOauthIntegration("asana");
      } else if (enabled && integration !== "gcalendar") {
        dispatch({ type: "enable-integration", payload: { integration } });
      } else if (!enabled && window.confirm("Disattivare l'integrazione?")) {
        await session.disableIntegration(integration);
      }
    },
    [board, session]
  );

  const handleSubmitPaymoAPIKey = useCallback(
    async ({ apiKey }) => {
      if (integration) {
        await session.enableIntegration(integration, apiKey, "APIKEY");
        dispatch({ type: "close-modal" });
      }
    },
    [integration, session]
  );

  const handleSubmitJiraAPIKey = useCallback(
    async ({ email, apiKey, domain }) => {
      if (integration) {
        //  https://developer.atlassian.com/cloud/jira/platform/basic-auth-for-rest-apis/
        const base64Token = btoa(`${email}:${apiKey}`);
        const metadata = {
          domain,
        };
        await session.enableIntegration(
          integration,
          base64Token,
          "Basic",
          metadata
        );
        dispatch({ type: "close-modal" });
      }
    },
    [integration, session]
  );

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "space-evenly",
        backgroundColor: "white",
        overflow: "auto",
      }}
    >
      <Container maxW="container.md">
        <Stack
          direction={"column"}
          spacing={"1.5rem"}
          style={{ marginTop: "2.5rem" }}
        >
          <Heading as={"h2"} size={"lg"} color={"gray.500"}>
            {t<string>("screens.integrations.title")}
          </Heading>

          <section>
            {integrations.map((integration) => (
              <ListItem key={integration.key}>
                <HStack spacing={"1.25rem"}>
                  <IntegrationIcon service={integration.key} size={"large"} />
                  <Stack>
                    <Text fontWeight={500}>{integration.title}</Text>
                    {integration.key === "gcalendar" && (
                      <Text>Integration enabled via Google authentication</Text>
                    )}
                    {integration.key === "todoist" && (
                      <Text>
                        Integration not created by, affiliated with, or
                        supported by Doist
                      </Text>
                    )}
                  </Stack>
                </HStack>
                <Switch
                  isChecked={
                    integration.isActive || integration.key === "gcalendar"
                  }
                  onChange={(e) =>
                    integration.key !== "gcalendar" &&
                    handleToggleIntegration(integration.key, e.target.checked)
                  }
                  disabled={integration.key === "gcalendar"}
                />
              </ListItem>
            ))}
          </section>
        </Stack>
      </Container>

      {isModalVisible && (
        <Modal
          isOpen={isModalVisible}
          onClose={() => dispatch({ type: "close-modal" })}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              {modalView === IntegrationModalView.enablePaymo
                ? t("screens.integrations.enablePaymo")
                : t("screens.integrations.enableJira")}
            </ModalHeader>
            <ModalBody>
              {modalView === IntegrationModalView.enablePaymo ? (
                <PaymoAPIKeyForm
                  onCancel={() => dispatch({ type: "close-modal" })}
                  onSubmit={handleSubmitPaymoAPIKey}
                />
              ) : modalView === IntegrationModalView.enableJira ? (
                <JiraAPIKeyForm
                  onCancel={() => dispatch({ type: "close-modal" })}
                  onSubmit={handleSubmitJiraAPIKey}
                />
              ) : null}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};
