import React, { ReactNode } from "react";
import { StackProps, Stack, Text } from "@chakra-ui/react";
import styled from "styled-components";
import { transparentize } from "polished";

export const Nav: React.FC<StackProps> = Stack;

type NavGroupProps = {
  title: string;
  children?: ReactNode;
};

export const NavGroup: React.FC<NavGroupProps> = ({ title, children }) => {
  return (
    <Stack marginTop="1rem" marginBottom="0.5rem">
      <Text
        marginLeft="1rem"
        textTransform="uppercase"
        fontSize="0.75rem"
        fontWeight="600"
        lineHeight="1.45"
      >
        {title}
      </Text>
      <Stack>{children}</Stack>
    </Stack>
  );
};

export const NavItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 42px;
  margin: 0.5em !important;
  opacity: ${(props) => (props.isActive ? 1 : 0.75)};
  font-weight: ${(props) => (props.isActive ? 500 : 400)};
  border-radius: 0.5em;
  color: ${(props) =>
    props.isActive ? props.theme.primary1 : props.theme.text1};
  background-color: ${(props) =>
    props.isActive ? transparentize(0.9, props.theme.primary1) : "transparent"};
  padding-left: 0.5rem;
  cursor: pointer;
  // padding-left: $ {(props) => props.theme.global.padding.xsmall};
  // padding-right: $ {(props) => props.theme.global.padding.xsmall};

  transition: background-color 1s cubic-bezier(0.2, 0, 0, 1) 0s,
    color 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  // svg {
  //   fill: $ {(props) => props.theme.global.colors.secondaryColor};
  //   transition: fill 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  // }

  &:hover {
    opacity: 1;
  }

  &.active {
    // opacity: 1;
    // font-weight: 500;
    // background-color: $ {(props) =>
    //   transparentize(0.9, props.theme.global.colors.brand)};
    // color: $ {(props) => props.theme.global.colors.brand};

    // svg {
    //   fill: $ {(props) => props.theme.global.colors.brand};
    // }
  }
`;

export const NavItemIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-left: -0.5rem;
`;

export const NavItemText = styled.span``;
