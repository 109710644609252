import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Customer } from "../../../types";
import { ModalFooterForm } from "../../ModalFooterForm";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Stack } from "@chakra-ui/react";
import { Input } from "@chakra-ui/input";
import * as Yup from "yup";
import styled from "styled-components";

export type CustomerFormType = {
  uid: string | null;
  nominative: string;
  email: string | null;
  fiscalCode: string | null;
  vatNumber: string | null;
  externalId: string | null;
};

type CustomerFormProps = {
  customer: Customer | null;
  onSubmit: (customer: CustomerFormType) => any;
  onCancel: () => any;
  onDelete: (customerId: Customer["uid"]) => any;
};

export const CustomerForm: React.FC<CustomerFormProps> = ({
  customer,
  onSubmit,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uid: customer?.uid || null,
      nominative: customer?.nominative || "",
      email: customer?.email || "",
      fiscalCode: customer?.fiscalCode || "",
      vatNumber: customer?.vatNumber || "",
      externalId: customer?.externalId || "",
    },
    onSubmit,
    // validationSchema: Yup.object().shape({
    //   email: Yup.string().required(t("profile.emailRequired")),
    //   newPassword: Yup.string().required(t("profile.emailRequired")),
    //   confirmNewPassword: Yup.string().required(t("profile.emailRequired")),
    // }),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1rem"} direction={"column"}>
        <FormControl>
          <StyledFormLabel>
            {t<string>("screens.customers.nominative")}
          </StyledFormLabel>
          <Input
            size="lg"
            name={"nominative"}
            value={formik.values.nominative}
            onChange={formik.handleChange}
          />
        </FormControl>
        <FormControl>
          <StyledFormLabel>
            {t<string>("screens.customers.email")}
          </StyledFormLabel>
          <Input
            size="lg"
            name={"email"}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </FormControl>
        <FormControl>
          <StyledFormLabel>
            {t<string>("screens.customers.fiscalCode")}
          </StyledFormLabel>
          <Input
            size="lg"
            name={"fiscalCode"}
            value={formik.values.fiscalCode}
            onChange={formik.handleChange}
          />
        </FormControl>
        <FormControl>
          <StyledFormLabel>
            {t<string>("screens.customers.vatNumber")}
          </StyledFormLabel>
          <Input
            size="lg"
            name={"vatNumber"}
            value={formik.values.vatNumber}
            onChange={formik.handleChange}
          />
        </FormControl>
        <FormControl>
          <StyledFormLabel>
            {t<string>("screens.customers.externalId")}
          </StyledFormLabel>
          <Input
            size="lg"
            name={"externalId"}
            value={formik.values.externalId}
            onChange={formik.handleChange}
          />
        </FormControl>

        <ModalFooterForm
          onCancel={onCancel}
          onDelete={(customer?.uid && onDelete) || undefined}
        />
      </Stack>
    </form>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
