import { makeApiRequest } from ".";
import { Organization, ProjectStatus } from "../../types";
import { toProjectStatus } from "../utils";

export const fetchProjectsWorkflows = async (
  organizationId: Organization["uid"]
): Promise<ProjectStatus[]> => {
  const response = await makeApiRequest(
    "GET",
    `organization/${organizationId}/projects-workflows`
  );

  return response.map((item: any) => toProjectStatus(item));
};

export const addProjectWorkflow = async (
  organizationId: Organization["uid"],
  status: Partial<ProjectStatus>
): Promise<ProjectStatus> => {
  const response = await makeApiRequest(
    "POST",
    `organization/${organizationId}/projects-workflows`,
    status
  );

  return toProjectStatus(response);
};

export const updateProjectWorkflow = async (
  organizationId: Organization["uid"],
  statusId: ProjectStatus["uid"],
  status: Partial<ProjectStatus>
): Promise<ProjectStatus> => {
  const response = await makeApiRequest(
    "PATCH",
    `organization/${organizationId}/projects-workflows/${statusId}`,
    status
  );

  return toProjectStatus(response);
};

export const deleteProjectWorkflow = async (
  organizationId: Organization["uid"],
  statusId: ProjectStatus["uid"]
): Promise<ProjectStatus> => {
  const response = await makeApiRequest(
    "DELETE",
    `organization/${organizationId}/projects-workflows/${statusId}`
  );

  return toProjectStatus(response);
};
