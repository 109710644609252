import { Checkbox } from "@chakra-ui/checkbox";
import { Heading, Stack } from "@chakra-ui/layout";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton } from "../../components/Button";
import { Column } from "../../components/Column";
import { Icon } from "../../components/Icon";
import integrations from "../../config/integrations";
import api from "../../services/api";
import { ColumnHeader } from "./styled";
import { Draggable } from "../../libs/@fullcalendar/interaction";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { useTranslation } from "react-i18next";
import { ExternalEvent } from "../../components/ExternalEvent";
import { useToast } from "@chakra-ui/react";
import { closeTodoistTask } from "../../services/api/todoist";
import { TaskPriority } from "../../types";

/**
 * @param url to normalize
 * @returns url without link and brackets
 * @example1 [Search on Google](https://google.com/) -> Search on Google
 * @example2 [Search on Google](https://google.com/), [Search on FB](https://fb.com/) -> Search on Google, Search on FB
 */
const normalizeTodoistUrl = (url: string) => {
  const reg = new RegExp("\\[[^\\]]*]\\([^)]*\\)");
  const titleReg = new RegExp("\\[[^\\]]*]");
  const squareBrackets = new RegExp("[\\[-\\]]", "g");
  let urlMatch = url.match(reg);

  while (urlMatch) {
    const titleMatch = url.match(titleReg);

    if (titleMatch) {
      const title = titleMatch[0].replace(squareBrackets, "");
      url = url.replace(urlMatch[0], title);
      urlMatch = url.match(reg);
    }
  }

  return url;
};

export const TodoistView: React.FC = observer(() => {
  const SOURCE = "todoist";

  const { t } = useTranslation();
  const [projects, setProjects] = useState<any[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const [isUpdatingTask, setIsUpdatingTask] = useState(false);
  const toast = useToast();

  const filteredTask = useMemo(() => {
    if (!activeFilter) {
      return tasks;
    } else if (activeFilter === "today") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return tasks.filter((task) => {
        const taskDueDate = task.due ? new Date(task.due) : null;
        if (taskDueDate) {
          taskDueDate.setHours(0, 0, 0, 0);
        }
        return taskDueDate ? taskDueDate.getTime() === today.getTime() : false;
      });
    } else {
      return tasks.filter((task) => task.projectId === activeFilter);
    }
  }, [tasks, activeFilter]);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    const todoistData = await api.getTodoistTasks();

    setProjects(
      todoistData.projects.map((todoistProject) => ({
        id: todoistProject.id,
        name: todoistProject.name,
      }))
    );

    setTasks(
      todoistData.tasks.map((todoistTask) => ({
        id: todoistTask.id,
        title: normalizeTodoistUrl(todoistTask.content),
        elapsedTime: 0,
        due: todoistTask?.due?.date,
        projectId: todoistTask.project_id,
        url: todoistTask.url,
        complete: todoistTask.completed,
        priority: todoistTask.priority,
        description: todoistTask.content.includes("https://")
          ? `<a href="https://${todoistTask.content
              .split("(https://")[1]
              .slice(
                0,
                -1
              )}" target="_blank" style="font-weight: 600;color: rgb(122, 134, 154); display:flex;flex-direction:row;">${t<string>(
              "screens.integrations.tasks.viewLink"
            )}<div style="font-size: 1.4rem;"><svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg></div></a>`
          : "",
        //extendedProps: event.extendedProps || {},
      }))
    );
    setIsFetching(false);
  }, [setProjects, setTasks, setIsFetching]);

  useEffect(() => {
    fetchData();

    let draggableEl = document.getElementById("external-events");
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("data-title");
          let eventSourceId = eventEl.getAttribute("data-source-id");
          let source = eventEl.getAttribute("data-source");
          return {
            title,
            eventSourceId,
            source,
          };
        },
      });
    }
  }, [fetchData]);

  const handleToggleFilter = useCallback(
    (key, checked) => {
      setActiveFilter(checked ? key : null);
    },
    [setActiveFilter]
  );

  const handleCompletedTask = useCallback(
    async (taskId: string) => {
      try {
        setIsUpdatingTask(true);

        const taskCompleted = await closeTodoistTask(taskId);

        if (taskCompleted.success) {
          toast({
            title: t("common.actionExecutedSuccessfully"),
            status: "success",
            position: "bottom-left",
          });

          setTasks(filteredTask.filter((task) => task.id !== taskId));
        } else {
          throw new Error(taskCompleted.err.toString());
        }
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      } finally {
        setIsUpdatingTask(false);
      }
    },
    [filteredTask, t, toast]
  );

  return (
    <Column>
      <ColumnHeader>
        <Heading as={"h4"} size={"md"} fontWeight={500}>
          {integrations[SOURCE].title}
        </Heading>
        <div>
          <Popover>
            <PopoverTrigger>
              <IconButton style={{ marginLeft: "1rem" }}>
                <Icon iconName={"HiOutlineFilter"} />
              </IconButton>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack>
                  <Checkbox
                    isChecked={activeFilter === "today"}
                    onChange={(event) =>
                      handleToggleFilter("today", event.target.checked)
                    }
                  >
                    {t<string>("calendar.today")}
                  </Checkbox>

                  {projects.map((project) => (
                    <Checkbox
                      key={project.id}
                      isChecked={activeFilter === project.id}
                      onChange={(event) =>
                        handleToggleFilter(project.id, event.target.checked)
                      }
                    >
                      {project.name}
                    </Checkbox>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <IconButton onClick={fetchData}>
            <Icon iconName={"HiRefresh"} />
          </IconButton>
        </div>
      </ColumnHeader>
      {isFetching && <div>Loading...</div>}

      <div
        id={"external-events"}
        style={{
          height: "100%",
          flex: "1 1 auto",
          position: "relative",
          overflowY: "auto",
          marginLeft: "0.2rem",
          overflowX: "hidden",
        }}
      >
        {filteredTask.map((task) => (
          <ExternalEvent
            className={"fc-event"}
            title={task.title}
            sourceId={task.id}
            key={task.id}
            source={SOURCE}
            description={task.description}
            icon={task.icon}
            onCompleted={handleCompletedTask}
            t={t}
            showId={false}
            due={task.due}
            permalinkUrl={task.url}
            disabled={isUpdatingTask}
            // TODO: ho moltiplicato per 10 perché
            // il tipo di priority è 10 | 20 | 30 | 40 | 50;
            priority={(task.priority * 10) as TaskPriority}
          >
            {task.title}
          </ExternalEvent>
        ))}
      </div>
    </Column>
  );
});
