import { Button } from "@chakra-ui/button";
import { Stack, Text } from "@chakra-ui/layout";
import { Tag } from "@chakra-ui/tag";
import { useTranslation } from "react-i18next";
import { Plan } from "../../types";
import { toCurrency } from "../../utils/string";
import { Box } from "../Box";
import { InlinePlanUnit } from "../InlinePlanUnit";

type Props = {
  currentPlan?: Plan;
  plan: Plan;
  plain?: boolean;
  onSelected?: (plan: Plan) => void;
};

export const PlanCard: React.FC<Props> = ({
  currentPlan,
  plan,
  plain = true,
  onSelected,
}) => {
  const { t } = useTranslation();

  const content = (
    <>
      <Tag>{t<string>(`plans.${plan.name}`).toUpperCase()}</Tag>
      <Text fontSize="2xl" fontWeight="500">
        {plan.subscription
          ? toCurrency(parseInt(plan.subscription.recurringPrice.EUR))
          : t("plans.gratis")}
      </Text>

      <Stack w="100%" marginBottom="1rem" paddingTop="1.5rem">
        {plan.config.map((config) => (
          <InlinePlanUnit key={config.unitType} unit={config} />
        ))}
      </Stack>

      {currentPlan?.name === plan.name ? (
        <Text h="40px">{t<string>("plans.currentPlan")}</Text>
      ) : onSelected ? (
        <Button colorScheme="teal" onClick={() => onSelected(plan)}>
          {t<string>("plans.selectPlan", { name: t(`plans.${plan.name}`) })}
        </Button>
      ) : null}
    </>
  );

  return plain ? (
    <Stack width="300px" padding="1.25rem" alignItems="center">
      {content}
    </Stack>
  ) : (
    <Box width="300px" padding="1.25rem" alignItems="center">
      {content}
    </Box>
  );
};
