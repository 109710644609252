import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    NumberInput,
    NumberInputField,
    VStack
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MdDateRange } from "react-icons/md";
import Select from "react-select";
import styled from "styled-components";
import * as Yup from "yup";
import ds from "../config/projects";
import Project from "../models/Project";
import {
    Customer,
    EditedProject,
    ProjectCategory,
    ProjectStatus,
    User,
} from "../types";
import { DatePicker } from "./DatePicker";
import { ModalFooterForm } from "./ModalFooterForm";

type ProjectFormProps = {
    project: Project | null;
    customers: Customer[];
    categories: ProjectCategory[];
    projectsWorkflows: ProjectStatus[];
    users: User[];
    onCreateCustomer: () => any;
    onCancel: () => any;
    onDelete?: (projectId: Project["uid"]) => any;
    onSubmit: (project: EditedProject) => any;
    isFetchingCategories: boolean;
    isFetchingCustomers: boolean;
    isFetchingProjectsWorkflows: boolean;
    onLoad?: () => any;
};

interface IOption {
    label: string;
    value: string;
}

export const ProjectFormBeta: React.FC<ProjectFormProps> = ({
    project,
    customers,
    categories,
    projectsWorkflows,
    users,
    onCreateCustomer,
    onCancel,
    onDelete,
    onSubmit,
    onLoad,
}) => {
    useEffect(() => {
        onLoad && onLoad();
    }, []);

    const { t } = useTranslation();

    const customersOptions = useMemo(
        () =>
            customers.map((customer) => ({
                label: customer.nominative || "",
                value: customer.uid,
            })),
        [customers]
    );

    const usersOptions: IOption[] = useMemo(
        () =>
            users.filter(e => e.status === 'active').map((user) => ({
                label: user.nominative,
                value: user.uid,
            })),
        [users]
    );

    const categoriesOptions = useMemo(() => {
        const options = categories.map((category) => ({
            label: category.name,
            value: category.uid,
        }));
        options.push({ label: "Nessuna categoria", value: "" });
        return options;
    }, [categories]);

    const statusOptions = useMemo(
        () =>
            projectsWorkflows.map((status) => ({
                label: status.name,
                value: status.uid,
            })),
        [projectsWorkflows]
    );

    const projectTypeOptions = useMemo(
        () =>
            ds.project_type.map((e, ind) => {
                return {
                    label: t(`screens.projects.projectTypes.${ind}`),
                    value: e,
                };
            }),
        [ds]
    );

    const initialValues: EditedProject = {
        uid: project?.uid || null,
        title: project?.title || "",
        budget: project?.budget ? project?.budget : 0,
        marginabilityPercentage: project?.marginabilityPercentage
            ? project?.marginabilityPercentage
            : 30,
        notBillable: project?.notBillable || false,
        toleranceDays: project?.toleranceDays ? project?.toleranceDays : 0,
        externalReference: project?.externalReference || null,
        categoryId: project?.category?.uid || null,
        startDate: project?.startDate || null,
        endDate: project?.endDate || null,
        projectType: project?.projectType || null,
        statusId:
            project?.status?.uid ||
            projectsWorkflows.find((status) => status.key === "open")?.uid ||
            null,
        customerId: project?.customer?.uid || null,
        contactName: project?.contactName || null,
        contactSurname: project?.contactSurname || null,
        contactRole: project?.contactRole || null,
        contactEmail: project?.contactEmail || null,
        projectManagerId: project?.projectManager?.uid || "",
        salesAccount: project?.member
            ? project?.member
                ?.filter((e) => e.userTags.includes("sales"))
                .map((e) => e.uid)
            : [] || [],
        responsible: project?.member
            ? project?.member
                ?.filter((e) => e.userTags.includes("responsible"))
                .map((e) => e.uid)
            : [] || [],
        accountable: project?.member
            ? project?.member
                ?.filter((e) => e.userTags.includes("accountable"))
                .map((e) => e.uid)
            : [] || [],
        consulted: project?.member
            ? project?.member
                ?.filter((e) => e.userTags.includes("consulted"))
                .map((e) => e.uid)
            : [] || [],
        informed: project?.member
            ? project?.member
                ?.filter((e) => e.userTags.includes("informed"))
                .map((e) => e.uid)
            : [] || [],
        closingDate: project?.closingDate || null,
        description: project?.description || null,
    };

    const getSelectedStatus: any = (statusId: ProjectStatus["uid"] | null) => {
        if (!statusId) {
            return null;
        }
        const status = projectsWorkflows.find(
            (workflow) => workflow.uid === statusId
        );

        return {
            value: status?.uid,
            label: status?.name,
        };
    };

    const getSelectedProjectTyps: any = (pType: string | null) => {
        if (!pType) {
            return null;
        }
        const index = ds.project_type.findIndex(
            (workflow) => workflow === pType
        );
        if (index > -1) {
            return {
                value: ds.project_type[index],
                label: t(`screens.projects.projectTypes.${index}`),
            };
        } else {
            return null
        }
    };

    const getSelectedCategory: any = (categoryId) => {
        if (!categoryId || (categoryId && categoryId.length === 0))
            return { label: "Nessuna categoria", value: "" };

        const category = categories.find((category) => category.uid === categoryId);

        return category
            ? {
                value: category.uid,
                label: category.name,
            }
            : { label: "Nessuna categoria", value: "" };
    };

    const getSelectedUser: any = (userId) => {
        if (!userId) return "";

        const user = users.find((user) => user.uid === userId);

        return user
            ? {
                value: user.uid,
                label: user.nominative,
            }
            : "";
    };


    const getSelectedCustomer: any = (customerId) => {
        if (!customerId) return "";

        const customer = customers.find((customer) => customer.uid === customerId);

        return customer
            ? {
                value: customer.uid,
                label: customer?.nominative || "",
            }
            : "";
    };

    const handleSubmit = (values, actions) => {

        console.log(values);
        // alert(JSON.stringify(values, null, 2));
        const categoryId =
            values.categoryId && values.categoryId?.length > 0
                ? values.categoryId
                : null;
        actions.setSubmitting(false);
        onSubmit({ ...values, categoryId });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
                title: Yup.string()
                    .required(t("screens.projects.validations.titleRequired"))
                    .min(1, t("screens.projects.validations.titleRequired")),
                customerId: Yup.string()
                    .required(t("screens.projects.validations.customerRequired"))
                    .min(1, t("screens.projects.validations.customerRequired"))
                    .nullable(t("screens.projects.validations.customerRequired")),
                projectManagerId: Yup.string()
                    .required(t("screens.projects.validations.projectManagerRequired"))
                    .min(1, t("screens.projects.validations.projectManagerRequired")),
            })}
        >
            {({ values, setFieldValue, touched, errors }) => (
                <Form>
                    <Field type={"hidden"} name={"uid"} />
                    {/* @ts-ignore */}
                    <VStack spacing={4} align="stretch">
                        <HStack>
                            <FormControl
                                isInvalid={touched.title && errors.title ? true : false}
                            >
                                <StyledFormLabel htmlFor="title">{t<string>('screens.projects.title')}</StyledFormLabel>
                                <Field as={Input} id="title" name="title" variant="outline" />
                                <FormErrorMessage>{errors.title}</FormErrorMessage>
                            </FormControl>

                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.externalReference')}</StyledFormLabel>
                                <Field
                                    as={Input}
                                    id="externalReference"
                                    name="externalReference"
                                    variant="outline"
                                />
                            </FormControl>
                        </HStack>
                        <HStack>
                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.budget')}</StyledFormLabel>
                                <Field as={NumberInput} name="budget">
                                    <InputGroup>
                                        <InputLeftAddon children={"€"} />
                                        <NumberInputField
                                            value={values.budget?.toString()}
                                            onChange={(event) =>
                                                setFieldValue("budget", event.target.value)
                                            }
                                        />
                                    </InputGroup>
                                </Field>
                            </FormControl>

                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.marginabilityPercentage')}</StyledFormLabel>
                                <Field as={NumberInput} name="marginabilityPercentage">
                                    <InputGroup>
                                        <InputLeftAddon children={"%"} />
                                        <NumberInputField
                                            value={values.marginabilityPercentage?.toString()}
                                            onChange={(event) =>
                                                setFieldValue(
                                                    "marginabilityPercentage",
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </InputGroup>
                                </Field>
                            </FormControl>
                        </HStack>

                        <FormControl>
                            <Field name="notBillable">
                                {({ field, form }) => (
                                    <Checkbox
                                        {...field}
                                        isChecked={field.value}
                                        onChange={(e) =>
                                            form.setFieldValue(field.name, e.target.checked)
                                        }
                                    >
                                        Not billable
                                    </Checkbox>
                                )}
                            </Field>
                        </FormControl>

                        <HStack>
                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.toleranceDays')}</StyledFormLabel>
                                <Field as={NumberInput} name="toleranceDays">
                                    <NumberInputField
                                        value={values.toleranceDays?.toString()}
                                        onChange={(event) =>
                                            setFieldValue("toleranceDays", event.target.value)
                                        }
                                    />
                                </Field>
                            </FormControl>

                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.category')}</StyledFormLabel>
                                <Select
                                    options={categoriesOptions}
                                    // @ts-ignore
                                    // isLoading={isFetchingCategories}
                                    // @ts-ignore
                                    value={getSelectedCategory(values.categoryId)}
                                    onChange={(option) =>
                                        setFieldValue("categoryId", option?.value || null)
                                    }
                                />
                            </FormControl>
                        </HStack>

                        <HStack>
                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.startDate')}</StyledFormLabel>
                                <InputGroup>
                                    <DatePicker
                                        name="startDate"
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            values.startDate ? new Date(values.startDate) : null
                                        }
                                        onChange={(date) => setFieldValue("startDate", date)}
                                    //   onBlur={handleBlur}
                                    />
                                    <InputRightAddon children={<MdDateRange />} />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.endDate')}</StyledFormLabel>
                                <InputGroup>
                                    <DatePicker
                                        name="endDate"
                                        dateFormat="dd/MM/yyyy"
                                        selected={values.endDate ? new Date(values.endDate) : null}
                                        onChange={(date) => setFieldValue("endDate", date)}
                                    />
                                    <InputRightAddon children={<MdDateRange />} />
                                </InputGroup>
                            </FormControl>
                        </HStack>

                        <HStack>
                            <FormControl>
                                <StyledFormLabel>Project type</StyledFormLabel>
                                <Select
                                    options={projectTypeOptions}
                                    // @ts-ignore
                                    value={getSelectedProjectTyps(values.projectType)}
                                    onChange={(option) => setFieldValue("projectType", option.value)}
                                />
                            </FormControl>

                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.status')}</StyledFormLabel>
                                <Select
                                    options={statusOptions}
                                    // @ts-ignore
                                    value={getSelectedStatus(values.statusId)}
                                    // isLoading={isFetchingProjectsWorkflows}
                                    onChange={(option) =>
                                        setFieldValue("statusId", option?.value)
                                    }
                                />
                            </FormControl>
                        </HStack>

                        <Divider orientation="horizontal" h={"1px"} bg={"#D0D5DD"} />

                        <HStack>
                            <Field name={"customerId"}>
                                {({
                                    field,
                                    meta,
                                }) => (
                                    <FormControl isInvalid={meta.error && meta.touched}>
                                        <StyledFormLabel>{t<string>('screens.projects.customer')}</StyledFormLabel>
                                        <div style={{ flexDirection: "row", display: "flex" }}>
                                            <Select
                                                options={customersOptions}
                                                // @ts-ignore
                                                value={getSelectedCustomer(values.customerId)}
                                                // isLoading={isFetchingCustomers}
                                                onChange={(option) =>
                                                    setFieldValue("customerId", option?.value || null)
                                                }
                                                styles={{
                                                    container: (style) => ({
                                                        ...style,
                                                        flex: 1,
                                                    }),
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: meta.error && meta.touched ? '#E53E3E' : baseStyles.borderColor,
                                                        boxShadow: meta.error && meta.touched ? '0 0 0 1px #E53E3E' : baseStyles.boxShadow,
                                                    }),
                                                }}
                                                onBlur={field.onBlur}
                                            />
                                            {/* @ts-ignore */}
                                            <Button
                                                size="sm"
                                                h="-moz-max-content"
                                                marginLeft={3}
                                                onClick={() => onCreateCustomer()}
                                            >
                                                {t<string>("screens.customers.actions.new")}
                                            </Button>
                                        </div>
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </HStack>

                        <HStack>
                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.contactName')}</StyledFormLabel>
                                <Field as={Input} name="contactName" />
                            </FormControl>

                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.contactSurname')}</StyledFormLabel>
                                <Field as={Input} name="contactSurname" />
                            </FormControl>
                        </HStack>

                        <HStack>
                            <FormControl>
                                <StyledFormLabel>Customer reference role</StyledFormLabel>
                                <Field as={Input} name="contactRole" />
                            </FormControl>

                            <FormControl>
                                <StyledFormLabel>{t<string>('screens.projects.contactEmail')}</StyledFormLabel>
                                <Field as={Input} type="email" name="contactEmail" />
                            </FormControl>
                        </HStack>

                        <Divider orientation="horizontal" h={"1px"} bg={"#D0D5DD"} />

                        <HStack>
                            <FormControl isInvalid={errors.projectManagerId !== undefined && touched.projectManagerId}>
                                <StyledFormLabel>{t<string>('screens.projects.projectManager')}</StyledFormLabel>
                                <Select
                                    options={usersOptions}
                                    value={getSelectedUser(values.projectManagerId)}
                                    onChange={(option) =>
                                        setFieldValue("projectManagerId", option?.value)
                                    }
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: errors.projectManagerId && touched.projectManagerId ? '#E53E3E' : baseStyles.borderColor,
                                            boxShadow: errors.projectManagerId && touched.projectManagerId ? '0 0 0 1px #E53E3E' : baseStyles.boxShadow,
                                        })
                                    }}
                                />
                                <FormErrorMessage>{errors.projectManagerId}</FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <StyledFormLabel>Sales account</StyledFormLabel>
                                <Select
                                    isMulti
                                    options={usersOptions}
                                    value={usersOptions.filter((option) =>
                                        values.salesAccount.includes(option.value)
                                    )}
                                    onChange={(
                                        newValue: readonly { label: string; value: string }[] | null
                                    ) =>
                                        setFieldValue(
                                            "salesAccount",
                                            newValue ? newValue.map((option) => option.value) : []
                                        )
                                    }
                                // isMulti
                                // // @ts-ignore
                                // options={usersOptions}
                                // value={values.salesAccount}
                                // onChange={(options: { label: string; value: string; }[]) => setFieldValue('salesAccount', options.map(e => e?.value))}
                                />
                            </FormControl>
                        </HStack>

                        <HStack>
                            <FormControl>
                                <StyledFormLabel>Responsible</StyledFormLabel>
                                <Select
                                    // // @ts-ignore
                                    // options={usersOptions}
                                    // value={values.responsible}
                                    // onChange={(options) => setFieldValue('responsible', options)}
                                    isMulti
                                    options={usersOptions}
                                    value={usersOptions.filter((option) =>
                                        values.responsible.includes(option.value)
                                    )}
                                    onChange={(
                                        newValue: readonly { label: string; value: string }[] | null
                                    ) =>
                                        setFieldValue(
                                            "responsible",
                                            newValue ? newValue.map((option) => option.value) : []
                                        )
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <StyledFormLabel>Accountable</StyledFormLabel>
                                <Select
                                    // isMulti
                                    // // @ts-ignore
                                    // options={usersOptions}
                                    // value={values.accountable}
                                    // onChange={(options) => setFieldValue('accountable', options)}
                                    isMulti
                                    options={usersOptions}
                                    value={usersOptions.filter((option) =>
                                        values.accountable.includes(option.value)
                                    )}
                                    onChange={(
                                        newValue: readonly { label: string; value: string }[] | null
                                    ) =>
                                        setFieldValue(
                                            "accountable",
                                            newValue ? newValue.map((option) => option.value) : []
                                        )
                                    }
                                />
                            </FormControl>
                        </HStack>

                        <HStack>
                            <FormControl>
                                <StyledFormLabel>Consulted</StyledFormLabel>
                                <Select
                                    // isMulti
                                    // // @ts-ignore
                                    // options={usersOptions}
                                    // value={values.consulted}
                                    // onChange={(options) => setFieldValue('consulted', options)}
                                    isMulti
                                    options={usersOptions}
                                    value={usersOptions.filter((option) =>
                                        values.consulted.includes(option.value)
                                    )}
                                    onChange={(
                                        newValue: readonly { label: string; value: string }[] | null
                                    ) =>
                                        setFieldValue(
                                            "consulted",
                                            newValue ? newValue.map((option) => option.value) : []
                                        )
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <StyledFormLabel>Informed</StyledFormLabel>
                                <Select
                                    // isMulti
                                    // // @ts-ignore
                                    // options={usersOptions}
                                    // value={values.informed}
                                    // onChange={(options) => setFieldValue('informed', options)}
                                    isMulti
                                    options={usersOptions}
                                    value={usersOptions.filter((option) =>
                                        values.informed.includes(option.value)
                                    )}
                                    onChange={(
                                        newValue: readonly { label: string; value: string }[] | null
                                    ) =>
                                        setFieldValue(
                                            "informed",
                                            newValue ? newValue.map((option) => option.value) : []
                                        )
                                    }
                                />
                            </FormControl>
                        </HStack>
                        <ModalFooterForm
                            onCancel={onCancel}
                            onDelete={
                                onDelete && project?.uid
                                    ? () => onDelete(project.uid)
                                    : undefined
                            }
                        />
                    </VStack>
                </Form>
            )}
        </Formik>
    );
};

// export default ProjectFormBeta;

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
