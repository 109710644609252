import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Heading, Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import styled from "styled-components";
import { ActionGroup } from "../../components/ActionGroup";
import { Box } from "../../components/Box";
import { PageHeader } from "../../components/PageHeader";
import { WebhookModal } from "../../components/WebhookModal";
import {
  useCreateWebhook,
  useDeleteWebhook,
  useUpdateWebhook,
  useWebhooks,
} from "../../hooks/webhooks.hooks";
import { Webhook } from "../../types";
import { WebhooksTable } from "../../components/WebhooksTable";
import { useProjectsWorkflows } from "../../hooks/useProjectsWorkflows";

export const Webhooks: React.FC = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const [activeItem, setActiveItem] = useState<Webhook | null>(null);
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const {
    isFetching: isFetchingProjectsWorkflows,
    data: projectsWorkflows = [],
  } = useProjectsWorkflows(organizationId!);
  const { isFetching: isFetchingWebhooks, data: webhooks = [] } = useWebhooks(
    organizationId!
  );

  const { isLoading: isSubmitting, mutateAsync: addWebhook } = useCreateWebhook(
    organizationId!
  );

  const {
    isLoading: isUpdating,
    mutateAsync: updateWebhook,
  } = useUpdateWebhook(organizationId!);

  const {
    isLoading: isDeleting,
    mutateAsync: deleteWebhook,
  } = useDeleteWebhook(organizationId!);

  const handleItemClick = useCallback(
    (webhook: Webhook) => {
      setActiveItem(webhook);
      onModalOpen();
    },
    [onModalOpen]
  );

  const handleSubmit = useCallback(
    async (webhook: any) => {
      if (webhook.uid) {
        await updateWebhook(webhook);
        setActiveItem(null);
      } else {
        await addWebhook(webhook);
      }
      onModalClose();
    },
    [addWebhook, onModalClose, updateWebhook]
  );

  const handleDelete = useCallback(async () => {
    if (activeItem && window.confirm(t("screens.webhooks.deleteWebhook"))) {
      await deleteWebhook(activeItem);
      onModalClose();
      setActiveItem(null);
    }
  }, [activeItem, deleteWebhook, onModalClose, t]);

  return (
    <StyledStack w="100%" h="100%">
      <PageHeader>
        <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
          {t<string>("screens.webhooks.title")}
        </Heading>
        <ActionGroup>
          {(isFetchingWebhooks ||
            isSubmitting ||
            isUpdating ||
            isDeleting ||
            isFetchingProjectsWorkflows) && <Spinner />}
          <Button
            colorScheme={"teal"}
            onClick={onModalOpen}
            disabled={isFetchingProjectsWorkflows}
          >
            {t<string>("screens.workflows.bttAdd")}
          </Button>
        </ActionGroup>
      </PageHeader>
      <Stack>
        <Box>
          <WebhooksTable onItemClick={handleItemClick} webhooks={webhooks} />
        </Box>
      </Stack>

      {isModalOpen && (
        <WebhookModal
          webhook={activeItem}
          projectsWorkflows={projectsWorkflows}
          isVisible={isModalOpen}
          onDismiss={() => {
            setActiveItem(null);
            onModalClose();
          }}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      )}
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
`;
