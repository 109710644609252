import { Button } from "@chakra-ui/button";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react';
import { TFunction } from "i18next";
import { useGoogleLogout } from "react-google-login";
import { useNavigate } from "react-router-dom";
import keys from "../config/keys";
import { useStores } from "../hooks/useStores";

const LogoutModal = ({
    isOpen = false,
    onClose,
    t,
}: {
    isOpen: boolean, t: TFunction;
    onClose: () => void;
}) => {
    const navigate = useNavigate();
    const { session } = useStores();

    const { signOut } = useGoogleLogout({
        clientId: keys.GOOGLE_CLIENT_ID,
        onLogoutSuccess: () => {
            console.log('Google logout successful');
            session.resetSession();
            navigate("/", { replace: true });
        },
        onFailure: () => {
            console.error('Google logout failed');
        },
    });

    const handleLogout = () => {
        signOut(); // Attempt Google logout
        onClose(); // Close the modal
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}
            blockScrollOnMount={true}
            scrollBehavior={"inside"}
        >
            <ModalOverlay />
            {/* @ts-ignore */}
            <ModalContent>
                <ModalHeader>{t<string>("screens.board.confirmLogout")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>{t<string>("screens.board.confrimLogoutMsg")}</Text>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="red" mr={3} onClick={handleLogout}>
                        {t<string>("screens.board.logoutButton")}
                    </Button>
                    <Button variant="ghost" onClick={onClose}>{t<string>("screens.board.logoutButton")}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default LogoutModal;
