import { makeAutoObservable } from "mobx";
import { ServiceCode, Stores } from "../types";
import { MenuItemKeys } from "../screens/Sidebar";

export class UiStore {
  stores: Stores;

  activeScreen: MenuItemKeys = MenuItemKeys.board;
  isSecondarySidebarVisible: boolean = false;
  isSidebarCollapsed: boolean = false;
  dataSource: ServiceCode | "tasks" | null = null;

  constructor(stores: Stores) {
    this.stores = stores;

    // HACKFIX: mobx new auto observable doesn't work properl when passing the empty stores object so you need to update the
    // value of the stores variable manually AFTER all the stores are created
    this.stores.session.stores = stores;
    this.stores.board.stores = stores;
    this.stores.organization.stores = stores;

    makeAutoObservable(this);
  }

  setActiveScreen = (activeScreen: MenuItemKeys) => {
    this.activeScreen = activeScreen;
  };

  setIsSecondarySidebarVisible = (visible: boolean) => {
    this.isSecondarySidebarVisible = visible;
  };

  setIsSidebarCollapsed = (collapsed: boolean) => {
    this.isSidebarCollapsed = collapsed;
  };

  setDataSource = (source: ServiceCode | "tasks" | null) => {
    this.dataSource = source;
  };
}
