import React from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { Input } from "@chakra-ui/input";
import { CirclePicker } from "react-color";
import * as Yup from "yup";
import { ModalFooterForm } from "../ModalFooterForm";
import { ProjectCategory } from "../../types";

interface Values {
  uid: string;
  name: string;
  color: string;
}

type ProjectCategoryFormProps = {
  uid?: string;
  name?: string;
  color?: string | null;
  onCancel: () => any;
  onSubmit: (category: Values) => any;
  onDelete?: (uid: ProjectCategory["uid"]) => any;
};

export const ProjectCategoryForm: React.FC<ProjectCategoryFormProps> = ({
  uid,
  name,
  color,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        uid: uid || "",
        name: name || "",
        color: color || "",
      }}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        setSubmitting(false);
        onSubmit(values);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required(
            t("screens.projects.projectCategory.validations.nameRequired")
          )
          .min(
            1,
            t("screens.projects.projectCategory.validations.nameRequired")
          ),
        color: Yup.string()
          .required(
            t("screens.projects.projectCategory.validations.colorRequired")
          )
          .min(
            1,
            t("screens.projects.projectCategory.validations.colorRequired")
          ),
      })}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Field type={"hidden"} name={"uid"} />

        <Field id={"name"} name={"name"}>
          {({ field, form: { setFieldValue }, meta }) => (
            <FormControl isInvalid={meta.error && meta.touched}>
              <StyledFormLabel htmlFor="name">
                {t<string>("screens.projects.projectCategory.name")}
              </StyledFormLabel>
              <Input
                name={field.name}
                value={field.value}
                onChange={(event) =>
                  setFieldValue(field.name, event.target.value)
                }
              />
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Field name={"color"}>
          {({
            field, // { name, value, onChange, onBlur }
            form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <FormControl isInvalid={meta.error && meta.touched}>
              <StyledFormLabel htmlFor={"color"}>
                {t<string>("screens.projects.projectCategory.color")}
              </StyledFormLabel>
              <CirclePicker
                // name={field.name}
                // @ts-ignore
                color={{ hex: field.value }}
                onChangeComplete={(color) =>
                  setFieldValue(field.name, color.hex)
                }
              />
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <ModalFooterForm
          onCancel={onCancel}
          onDelete={onDelete && uid ? () => onDelete(uid) : undefined}
        />
      </Form>
    </Formik>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
