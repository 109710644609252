import { useMutation, useQueryClient } from "react-query";
import {
  addProjectWorkflow,
  deleteProjectWorkflow,
  updateProjectWorkflow,
} from "../services/api";
import { Organization, ProjectStatus } from "../types";

export const useCreateProjectWorkflow = (
  organizationId: Organization["uid"]
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (status: Partial<ProjectStatus>) =>
      addProjectWorkflow(organizationId, status),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          `projects-workflows-${organizationId}` as any
        );
      },
    }
  );
};

export const useUpdateProjectWorkflow = (
  organizationId: Organization["uid"]
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (status: Partial<ProjectStatus>) =>
      updateProjectWorkflow(organizationId, status.uid || "", status),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          `projects-workflows-${organizationId}` as any
        );
      },
    }
  );
};

export const useDeleteProjectWorkflow = (
  organizationId: Organization["uid"]
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (status: ProjectStatus) =>
      deleteProjectWorkflow(organizationId, status.uid),
    {
      onSettled: () => {
        queryClient.invalidateQueries(
          `projects-workflows-${organizationId}` as any
        );
      },
    }
  );
};
