import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nTranslations from "../i18n";
import LanguageDetector from "i18next-browser-languagedetector";

const lng = localStorage.getItem("i18nextLng");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "it",
    lng: lng || window.navigator.language,
    detection: {
      caches: ["localStorage"],
      order: ["localStorage"],
      lookupLocalStorage: "i18nextLng",
    },
    resources: {
      ...i18nTranslations,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
